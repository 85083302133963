import { WholesaleLanguage } from 'libs/shared-lib/language/language';
/* If you update here the CheckoutsSlugs, you should also update robots.txt*/
export const NewsSlugs = {
    de: {
        list: 'news',
    },
    fr: {
        list: 'news',
    },
    it: {
        list: 'news',
    },
    sl: {
        list: 'news',
    },
} as const;

export function getNewsListUrl(lang: WholesaleLanguage, dealerSlug: string): string {
    return `/${dealerSlug}/${lang}/${NewsSlugs[lang].list}`;
}

export function getNewsDetailUrl(lang: WholesaleLanguage, slug: string, dealerSlug: string): string {
    return `${getNewsListUrl(lang, dealerSlug)}/${slug}`;
}
