import { CtaStyle, provideCtaStyle } from 'libs/ui/src/lib/modules/060-vehicle-modules/m060-c110-cta/styles';

const style: CtaStyle = {
    containerGap: 'block mb-24',
    leasing: {
        container: 'bg-grey-100',
        divider: 'bg-accent w-80p h-1 lg:h-2',
    },
    usePriceModule: true,
};

export const provideVdpCtaStyleOpel = () => provideCtaStyle(style);
