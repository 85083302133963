import {
    VehicleMarketPlatformListStyleData,
    provideVehicleMarketPlatformListStyle,
} from 'libs/ui/src/lib/pages/vehicle-market/vehicle-market-list/styles';

export const style: VehicleMarketPlatformListStyleData = {
    container: 'mx-lg lg:mx-auto pt-lg lg:max-w-1060 lg:pb-75 pb-45',
    vehicleCount: 'text-48 leading-normal mt-32 mb-16 text-center',
    vehicleCountLabel: 'font-light pl-10',
    buttonTagContainer: 'gap-x-8 gap-y-3 mb-32',
    buttonActionContainer: 'md:justify-between gap-lg md:mb-xl mt-32',
    select: {
        container:
            'cursor-pointer absolute w-full h-full top-0 left-0 z-10 bg-transparent text-transparent negative-text-indent appearance-none outline-0',
        option: 'text-light-text mx-10',
    },
    mobileVehicleCount: 'text-14 leading-22 font-semibold mt-lg mb-16',
    mobileVehicleCountNumber: '',
    mobileButtonTagContainer: 'gap-x-8 gap-y-3 mb-24',
    grid: 'gap-24',
    priceDarkCTA: true,
    buttonMore: 'mt-2xl',
    hardselling: {
        highlight: 'text-accentWarn font-semibold',
        wrapper: 'text-14 leading-22 bg-grey-100 px-16 py-8 text-light-text mb-24',
    },
    outerContainer: 'bg-grey-180',
    pagination: {
        wrapper: 'gap-16 mt-45 lg:mt-75',
        first: {
            container: '',
            default: 'text-16 h-15 flex items-center',
            active: 'text-light-text',
            disabled: 'text-grey-400',
            icon: {
                path: 'assets/icons/chevron_double_right.svg',
                default: 'w-27 h-24',
                active: 'fill-light-text',
                disabled: 'fill-grey-400',
            },
        },
        prevNext: {
            container: '',
            containerPrev: 'mr-8',
            containerNext: 'ml-8',
            default: 'text-16 h-15 flex items-center',
            active: 'text-light-text',
            disabled: 'text-grey-400',
            icon: {
                path: 'assets/icons/chevron_right.svg',
                default: 'w-24 h-24',
                active: 'fill-light-text',
                disabled: 'fill-grey-400',
            },
        },
        number: {
            default: 'text-16 h-15 flex items-center',
            inactive: 'font-normal',
            active: 'font-bold underline',
        },
    },
    tagBackgroundTheme: 'light-bg',
    tagTextTheme: 'light-text',
    smallPaddingForSortButtonMobile: false,
    bottomDivider: 'hidden',
    customTagBackgroundClass: '',
    tagLargeFont: false,
};

export const provideOpelVehicleMarketListStyle = () => provideVehicleMarketPlatformListStyle(style);
