import { Injectable, inject } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATED, getRouterSelectors } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { COUNTRY_TOKEN } from 'libs/ui/src/lib/utilities/country-token';
import { PLATFORM_TOKEN } from 'libs/ui/src/lib/utilities/platform-token';
import { exhaustMap, map, switchMap } from 'rxjs';
import { EventTrackingService } from '../services/event-tracking.service';
import { WNestApiService } from '../services/wholesale-nest-api';
import { getDeliveryType, getSubSystem } from '../utilities/tracking-helpers';
import { dealerFeature } from './dealer.reducer';
import { trackingActions } from './tracking.actions';
import { trackingFeature } from './tracking.reducer';
import { wholesaleFeature } from './wholesale.reducer';

@Injectable()
export class TrackingEffects {
    protected readonly actions$ = inject(Actions);
    protected readonly store = inject(Store);
    protected readonly country = inject(COUNTRY_TOKEN);
    protected readonly platform = inject(PLATFORM_TOKEN);
    protected readonly trackingService = inject(EventTrackingService);
    protected readonly wNestApiService = inject(WNestApiService);

    pageView$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ROUTER_NAVIGATED),
            concatLatestFrom(() => [
                this.store.select(getRouterSelectors().selectRouteData),
                this.store.select(wholesaleFeature.selectLanguage),
                this.store.select(dealerFeature.selectActiveDealer),
                this.store.select(trackingFeature.selectIpIsInternal),
            ]),
            map(([, routeData, language, dealer, isInternal]) => {
                const dataLayer = {
                    country: this.country,
                    language: language,
                    page_Type: routeData['pageType'] ? routeData['pageType'] : '',
                    dealer: dealer ? `${dealer.dealerId} ${dealer.name}` : '',
                    sub_system: getSubSystem(this.platform),
                    internal: isInternal ? '1' : '0',
                    event: 'pageView',
                };
                this.trackingService.pushDataLayer(dataLayer);
                return trackingActions.eventTrackingPush({ dataLayer });
            }),
        );
    });

    // eslint-disable-next-line max-lines-per-function
    trackBuyCheckout$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(trackingActions.trackBuyCheckout),
            // eslint-disable-next-line max-lines-per-function
            map((action) => {
                const eventName =
                    action.step === 1
                        ? 'begin_checkout'
                        : action.step === 2
                          ? 'add_shipping_info'
                          : action.step === 3
                            ? 'add_payment_info'
                            : action.step === 4
                              ? 'gaEvent'
                              : '';
                let addtionalData = {};
                if (action.step === 4) {
                    addtionalData = {
                        event_name: 'checkout_step_4_onsite_confirmation',
                        payment_type: 'buy',
                        model_text: action.vehicle.model.value,
                        car_id: action.vehicle.id,
                        car_brand: action.vehicle.make.value,
                        condition_type_text: action.vehicle.conditionType.value,
                        commission_number: action.vehicle.id,
                        fuel_type_text: action.vehicle.fuelType.value,
                        delivery: getDeliveryType(action.pickupOrDelivery),
                        sub_system: getSubSystem(this.platform),
                        dealer: `${action.vehicle.seller.id} ${action.vehicle.seller.name}`,
                        checkout_type: 'Buy',
                    };
                }
                const dataLayer = {
                    event: eventName,
                    form_step: action.step,
                    ...addtionalData,
                    ecommerce: {
                        payment_type: 'buy',
                        items: [
                            {
                                item_name: action.vehicle.model.value,
                                item_id: action.vehicle.id,
                                price: action.vehicle.price.value,
                                currency: 'CHF',
                                promotion: '',
                                item_brand: action.vehicle.make.value,
                                item_category: '',
                                item_category2: action.vehicle.conditionType.value,
                                item_category3: action.vehicle.id,
                                item_category4: `${action.vehicle.seller.id} ${action.vehicle.seller.name}`,
                                item_category5: '',
                                item_variant: '',
                                item_list_name: '',
                                item_list_id: 'ToDo: URL of product list',
                                quantity: '1',
                                model_text: action.vehicle.model.value,
                                car_brand: action.vehicle.make.value,
                                condition_type_text: action.vehicle.conditionType.value,
                                commission_number: action.vehicle.id,
                                fuel_type_text: action.vehicle.fuelType.value,
                                delivery: getDeliveryType(action.pickupOrDelivery),
                                sub_system: getSubSystem(this.platform),
                                dealer: `${action.vehicle.seller.id} ${action.vehicle.seller.name}`,
                                checkout_type: 'Buy',
                            },
                        ],
                    },
                };

                this.trackingService.pushDataLayer(dataLayer);
                return trackingActions.eventTrackingPush({ dataLayer });
            }),
        );
    });

    // eslint-disable-next-line max-lines-per-function
    trackLeaseCheckout$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(trackingActions.trackLeaseCheckout),
            // eslint-disable-next-line max-lines-per-function
            map((action) => {
                const eventName =
                    action.step === 1
                        ? 'begin_checkout'
                        : action.step === 2
                          ? 'add_payment_info'
                          : action.step === 3
                            ? 'add_shipping_info'
                            : action.step === 4
                              ? 'checkout_step_4_pick_options'
                              : action.step === 5
                                ? 'gaEvent'
                                : '';
                let addtionalData = {};
                if (action.step === 5) {
                    addtionalData = {
                        event_name: 'checkout_step_5_confirmation',
                        payment_type: 'lease',
                        model_text: action.vehicle.model.value,
                        car_brand: action.vehicle.make.value,
                        condition_type_text: action.vehicle.conditionType.value,
                        commission_number: action.vehicle.id,
                        fuel_type_text: action.vehicle.fuelType.value,
                        delivery: getDeliveryType(action.pickupOrDelivery),
                        sub_system: getSubSystem(this.platform),
                        dealer: `${action.vehicle.seller.id} ${action.vehicle.seller.name}`,
                        checkout_type: 'Leasing',
                    };
                }
                const dataLayer = {
                    event: eventName,
                    form_step: action.step,
                    ...addtionalData,
                    ecommerce: {
                        payment_type: 'lease',
                        items: [
                            {
                                item_name: action.vehicle.model.value,
                                item_id: action.vehicle.id,
                                price:
                                    action.leasingData.rate * action.leasingData.duration + action.leasingData.deposit,
                                currency: 'CHF',
                                item_brand: action.vehicle.make.value,
                                item_category: '',
                                item_category2: action.vehicle.conditionType.value,
                                item_category3: action.vehicle.id,
                                item_category4: `${action.vehicle.seller.id} ${action.vehicle.seller.name}`,
                                item_category5: '',
                                item_variant: '',
                                item_list_name: '',
                                item_list_id: 'ToDo: URL of product list',
                                quantity: '1',
                                model_text: action.vehicle.model.value,
                                car_brand: action.vehicle.make.value,
                                condition_type_text: action.vehicle.conditionType.value,
                                commission_number: action.vehicle.id,
                                fuel_type_text: action.vehicle.fuelType.value,
                                delivery: getDeliveryType(action.pickupOrDelivery),
                                sub_system: getSubSystem(this.platform),
                                dealer: `${action.vehicle.seller.id} ${action.vehicle.seller.name}`,
                                checkout_type: 'Leasing',
                                duration: `${action.leasingData.duration}mt`,
                            },
                        ],
                    },
                };

                this.trackingService.pushDataLayer(dataLayer);
                return trackingActions.eventTrackingPush({ dataLayer });
            }),
        );
    });

    // eslint-disable-next-line max-lines-per-function
    trackTestdriveCheckout$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(trackingActions.trackTestdriveCheckout),
            map((action) => {
                const eventName = action.step === 1 ? 'begin_checkout' : action.step === 2 ? 'add_payment_info' : '';
                const dataLayer = {
                    event: eventName,
                    form_step: action.step,
                    ecommerce: {
                        payment_type: 'Test Drive Specific',
                        items: [
                            {
                                item_name: action.vehicle.model.value,
                                item_id: action.vehicle.id,
                                price: action.vehicle.price.value,
                                currency: 'CHF',
                                promotion: '',
                                item_brand: action.vehicle.make.value,
                                item_category: '',
                                item_category2: action.vehicle.conditionType.value,
                                item_category3: action.vehicle.id,
                                item_category4: `${action.vehicle.seller.id} ${action.vehicle.seller.name}`,
                                item_category5: '',
                                item_variant: '',
                                item_list_name: '',
                                item_list_id: 'ToDo: URL of product list',
                                quantity: '1',
                                model_text: action.vehicle.model.value,
                                car_brand: action.vehicle.make.value,
                                condition_type_text: action.vehicle.conditionType.value,
                                commission_number: action.vehicle.id,
                                fuel_type_text: action.vehicle.fuelType.value,
                                sub_system: getSubSystem(this.platform),
                                dealer: `${action.vehicle.seller.id} ${action.vehicle.seller.name}`,
                                checkout_type: 'Test Drive Specific',
                            },
                        ],
                    },
                };

                this.trackingService.pushDataLayer(dataLayer);
                return trackingActions.eventTrackingPush({ dataLayer });
            }),
        );
    });

    // eslint-disable-next-line max-lines-per-function
    trackVdpView$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(trackingActions.trackVDPView),
            map((action) => {
                const dataLayer = {
                    event: 'view_item',
                    ecommerce: {
                        currency: 'CHF',
                        items: [
                            {
                                item_name: action.vehicle.model.value,
                                item_id: action.vehicle.id,
                                price: action.vehicle.price.value,
                                promotion: '',
                                item_brand: action.vehicle.make.value,
                                item_category: '',
                                item_category2: action.vehicle.conditionType.value,
                                item_category3: action.vehicle.id,
                                item_category4: `${action.vehicle.seller.id} ${action.vehicle.seller.name}`,
                                item_category5: '',
                                item_variant: '',
                                item_list_name: '',
                                item_list_id: 'ToDo: URL of product list',
                                model_text: action.vehicle.model.value,
                                car_brand: action.vehicle.make.value,
                                condition_type_text: action.vehicle.conditionType.value,
                                commission_number: action.vehicle.id,
                                fuel_type_text: action.vehicle.fuelType.value,
                                sub_system: getSubSystem(this.platform),
                                dealer: `${action.vehicle.seller.id} ${action.vehicle.seller.name}`,
                            },
                        ],
                    },
                };

                this.trackingService.pushDataLayer(dataLayer);
                return trackingActions.eventTrackingPush({ dataLayer });
            }),
        );
    });

    // eslint-disable-next-line max-lines-per-function
    trackAddToCart$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(trackingActions.trackAddToCart),
            map((action) => {
                const dataLayer = {
                    event: 'add_to_cart',
                    ecommerce: {
                        currency: 'CHF',
                        items: [
                            {
                                item_name: action.vehicle.model.value,
                                item_id: action.vehicle.id,
                                price: action.vehicle.price.value,
                                promotion: '',
                                item_brand: action.vehicle.make.value,
                                item_category: '',
                                item_category2: action.vehicle.conditionType.value,
                                item_category3: action.vehicle.id,
                                item_category4: `${action.vehicle.seller.id} ${action.vehicle.seller.name}`,
                                item_category5: '',
                                item_variant: '',
                                item_list_name: '',
                                item_list_id: 'ToDo: URL of product list',
                                model_text: action.vehicle.model.value,
                                car_brand: action.vehicle.make.value,
                                condition_type_text: action.vehicle.conditionType.value,
                                commission_number: action.vehicle.id,
                                fuel_type_text: action.vehicle.fuelType.value,
                                sub_system: getSubSystem(this.platform),
                                dealer: `${action.vehicle.seller.id} ${action.vehicle.seller.name}`,
                            },
                        ],
                    },
                };

                this.trackingService.pushDataLayer(dataLayer);
                return trackingActions.eventTrackingPush({ dataLayer });
            }),
        );
    });

    // eslint-disable-next-line max-lines-per-function
    trackServiceCheckout$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(trackingActions.trackServiceCheckout),
            // eslint-disable-next-line max-lines-per-function
            map((action) => {
                const dataLayer = {
                    event: 'gaEvent',
                    event_name: 'service_appointment_checkout',
                    service_appointment_checkout: {
                        form_step: action.step,
                    },
                };

                this.trackingService.pushDataLayer(dataLayer);
                return trackingActions.eventTrackingPush({ dataLayer });
            }),
        );
    });

    // eslint-disable-next-line max-lines-per-function
    trackTradeInCheckout$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(trackingActions.trackTradeInCheckout),
            // eslint-disable-next-line max-lines-per-function
            map((action) => {
                let additionalData = {};
                if (action.make) {
                    additionalData = {
                        car_brand: action.make,
                        model_text: action.model ? action.model : '',
                    };
                }
                const dataLayer = {
                    event: 'gaEvent',
                    event_name: 'wkia_form_checkout',
                    wkia_form_checkout: {
                        ...additionalData,
                        form_step: action.step,
                    },
                };

                this.trackingService.pushDataLayer(dataLayer);
                return trackingActions.eventTrackingPush({ dataLayer });
            }),
        );
    });

    setIsInternalIp$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(trackingActions.setIsInternalIP),
            concatLatestFrom(() => [this.store.select(wholesaleFeature.selectLanguage)]),
            switchMap(([, locale]) => {
                return this.wNestApiService
                    .getClientIpAdressIsInternalValue(locale)
                    .pipe(exhaustMap((value) => [trackingActions.setIsInternalIPSuccess({ isInternal: value.data })]));
            }),
        );
    });
}
