import { Params, Route, Routes } from '@angular/router';
import { aboutUsActions } from 'libs/ng-common-lib/src/+state/about-us.actions';
import { aboutUsFeature } from 'libs/ng-common-lib/src/+state/about-us.reducer';
import { dealerActions } from 'libs/ng-common-lib/src/+state/dealer.actions';
import { dealerFeature } from 'libs/ng-common-lib/src/+state/dealer.reducer';
import { serviceActions } from 'libs/ng-common-lib/src/+state/service.actions';
import { serviceFeature } from 'libs/ng-common-lib/src/+state/service.reducer';
import { dealerLanguageGuard } from 'libs/ng-common-lib/src/guards/dealer-language.guard';
import { ngrxDeloaderGuard } from 'libs/ng-common-lib/src/guards/ngrx-deloader.guard';
import { ngrxLoaderGuard } from 'libs/ng-common-lib/src/guards/ngrx-loader.guard';
import { PAGE_TYPE } from 'libs/shared-lib/interfaces/global/tracking';
import { WholesaleLanguage } from 'libs/shared-lib/language/language';
import { AboutUsSlugs, getAboutUsUrl } from 'libs/shared-lib/slugs/about-us.slug';
import { getHomeDealerUrl } from 'libs/shared-lib/slugs/home-pages.slug';
import { getNotFoundUrl } from 'libs/shared-lib/slugs/not-found.slug';
import { ServiceSlugs, getServiceDetailUrl, getServiceListUrl } from 'libs/shared-lib/slugs/service-page.slug';
import {
    SubDealerSlugs,
    getSubDealerAboutUsOverviewUrl,
    getSubDealerAboutUsUrl,
} from 'libs/shared-lib/slugs/sub-dealer.slugs';
import { VehicleMarketPlatformSlugs } from 'libs/shared-lib/slugs/vehicle-market.slugs';
import { getVehicleMarketRoutes } from 'libs/vehicle-market-lib/src/lib/routing/vehicle-market.routes';
import { map } from 'rxjs';
import { getCheckoutsRoutes } from '../pages/checkouts/checkouts.routes';
import { getNewsRoutes } from '../pages/news/news.routes';
import { getPromotionsRoutes } from '../pages/promotions/promotions.routes';

function getSubDealerChildren(lang: WholesaleLanguage) {
    return [
        {
            path: '',
            loadComponent: () => import('../pages/about-us/about-us.component'),
            canActivate: [
                ngrxLoaderGuard({
                    actionToDispatch: aboutUsActions.loadAboutUs(),
                    waitUntilLoadedSelector: aboutUsFeature.selectIsLoadingAboutUs,
                    dataToCheckSelector: aboutUsFeature.selectActiveAboutUs,
                    redirectOnFailUrl: (route, store) =>
                        store
                            .select(dealerFeature.selectActiveDealer)
                            .pipe(map((dealer) => getNotFoundUrl(lang, dealer.slug))),
                }),
            ],
            data: {
                pageType: PAGE_TYPE.ABOUT_US,
            },
        },
    ];
}

function getSubDealerRoutes(lang: WholesaleLanguage): Route {
    return {
        path: SubDealerSlugs[lang].location,
        children: [
            {
                path: ':subDealerSlug',
                canActivate: [
                    ngrxLoaderGuard({
                        actionToDispatch: (route) =>
                            dealerActions.setSubDealerBySlug({ subDealerSlug: route.params['subDealerSlug'] }),
                        waitUntilLoadedSelector: dealerFeature.selectIsLoadingSubDealer,
                        dataToCheckSelector: dealerFeature.selectSubDealerExists,
                        redirectOnFailUrl: (route, store) =>
                            store
                                .select(dealerFeature.selectActiveDealer)
                                .pipe(map((dealer) => getNotFoundUrl(lang, dealer.slug))),
                    }),
                ],
                canDeactivate: [
                    ngrxDeloaderGuard({
                        actionToDispatch: dealerActions.unloadSubDealer(),
                        waitUntilDeloadedSelector: dealerFeature.selectHasNoActiveSubdealer,
                    }),
                ],
                children: getSubDealerChildren(lang),
                data: {
                    pageType: PAGE_TYPE.LOCATIONS,
                    languageSwitchUrlGenerator: (lang: WholesaleLanguage, params: Params) =>
                        getSubDealerAboutUsUrl(lang, params['dealerSlug'], params['subDealerSlug']),
                },
            },
        ],
    };
}

function getAboutUsRoute(lang: WholesaleLanguage): Route {
    return {
        path: AboutUsSlugs[lang].aboutUs,
        loadComponent: () => import('../pages/about-us/about-us.component'),
        canActivate: [
            ngrxLoaderGuard({
                actionToDispatch: aboutUsActions.loadAboutUs(),
                waitUntilLoadedSelector: aboutUsFeature.selectIsLoadingAboutUs,
                dataToCheckSelector: aboutUsFeature.selectActiveAboutUs,
                redirectOnFailUrl: (route, store) =>
                    store
                        .select(dealerFeature.selectActiveDealer)
                        .pipe(map((dealer) => getNotFoundUrl(lang, dealer.slug))),
            }),
        ],
        data: {
            pageType: PAGE_TYPE.ABOUT_US,
            languageSwitchUrlGenerator: (lang: WholesaleLanguage, params: Params) =>
                getAboutUsUrl(lang, params['dealerSlug']),
        },
    };
}

function getServiceRoutes(lang: WholesaleLanguage): Route {
    return {
        path: ServiceSlugs[lang].serviceList,
        children: [
            {
                path: '',
                loadComponent: () => import('../pages/service/service-list/service-list.component'),
                data: {
                    pageType: PAGE_TYPE.SERVICES,
                    languageSwitchUrlGenerator: (lang: WholesaleLanguage, params: Params) =>
                        getServiceListUrl(lang, params['dealerSlug']),
                },
            },
            {
                path: ':slug',
                loadComponent: () => import('../pages/service/service-detail/service-detail.component'),
                canActivate: [
                    ngrxLoaderGuard({
                        actionToDispatch: (route) =>
                            serviceActions.loadServiceDetail({ serviceSlug: route.params['slug'] }),
                        waitUntilLoadedSelector: serviceFeature.selectIsLoadingServiceDetail,
                        dataToCheckSelector: serviceFeature.selectServiceDetail,
                        verifyData: (data) => !!data,
                        redirectOnFailUrl: (route, store) =>
                            store
                                .select(dealerFeature.selectActiveDealer)
                                .pipe(map((dealer) => getNotFoundUrl(lang, dealer.slug))),
                    }),
                ],
                data: {
                    pageType: PAGE_TYPE.SERVICES,
                    languageSwitchSelector: serviceFeature.selectServiceDetail,
                    languageSwitchUrlGenerator: (lang: WholesaleLanguage, params: Params) =>
                        getServiceDetailUrl(lang, params['slug'], params['dealerSlug']),
                },
            },
        ],
    };
}

function getLocationsRoute(lang: WholesaleLanguage): Route {
    return {
        path: SubDealerSlugs[lang].location,
        loadComponent: () => import('../pages/locations/locations.component'),
        canActivate: [
            ngrxLoaderGuard({
                actionToDispatch: dealerActions.loadSubDealerDetails(),
                waitUntilLoadedSelector: dealerFeature.selectIsLoadingSubDealerDetails,
                dataToCheckSelector: dealerFeature.selectSubDealerDetails,
                verifyData: (data) => !!data && data.length > 0,
                redirectOnFailUrl: (route, store) =>
                    store
                        .select(dealerFeature.selectActiveDealer)
                        .pipe(map((dealer) => getNotFoundUrl(lang, dealer.slug))),
            }),
        ],
        data: {
            pageType: PAGE_TYPE.LOCATIONS,
            languageSwitchUrlGenerator: (lang: WholesaleLanguage, params: Params) =>
                getSubDealerAboutUsOverviewUrl(lang, params['dealerSlug']),
        },
    };
}

function getPartnerWebRoutes(locale: WholesaleLanguage): Routes {
    return [
        getHome(),
        getLocationsRoute(locale),
        getAboutUsRoute(locale),
        getSubDealerRoutes(locale),
        getServiceRoutes(locale),
        ...getNewsRoutes(locale),
        ...getPromotionsRoutes(locale),
    ];
}

function getHome(): Route {
    return {
        path: '',
        loadComponent: () => import('../pages/home/home.component'),
        data: {
            pageType: PAGE_TYPE.HOMEPAGE,
            languageSwitchUrlGenerator: (locale: WholesaleLanguage, params: Params) =>
                getHomeDealerUrl(locale, params['dealerSlug']),
        },
    };
}

function getNotFoundPage(): Route {
    return {
        path: '**',
        canActivate: [
            ngrxLoaderGuard({
                actionToDispatch: (route) => dealerActions.setDealerBySlug({ dealerSlug: route.params['dealerSlug'] }),
                waitUntilLoadedSelector: dealerFeature.selectIsLoadingDealer,
                dataToCheckSelector: dealerFeature.selectActiveDealer,
            }),
        ],
        loadComponent: () => import('libs/ng-common-lib/src/components/not-found-page/not-found-page.component'),
    };
}

function getVehicleNotAvailablePage(locale: WholesaleLanguage): Route {
    return {
        path: VehicleMarketPlatformSlugs[locale].notAvailable,
        loadComponent: () =>
            import('libs/ng-common-lib/src/components/vehicle-not-available-page/vehicle-not-available-page.component'),
        data: {
            languageSwitchUrlGenerator: (lang: WholesaleLanguage) => getVehicleNotAvailablePage(lang),
        },
    };
}

function getDealerSearchPage(locales: WholesaleLanguage[]): Route[] {
    return [
        ...locales.map((locale) => ({
            loadComponent: () => import('../pages/dealer-search/dealer-search.component'),
            path: locale,
            data: {
                locale,
                languageSwitchSelector: dealerFeature.selectDealerList,
                languageSwitchUrlGenerator: (lang: WholesaleLanguage) => `/${lang}`,
            },
            canActivate: [
                ngrxLoaderGuard({
                    actionToDispatch: () => dealerActions.loadDealerList({ filter: 'all' }),
                }),
            ],
        })),
    ];
}

// eslint-disable-next-line max-lines-per-function
export function createRoutes(
    rootRedirectUrl: string,
    locales: WholesaleLanguage[],
    homeRoute: Route,
    dealerSearch: boolean,
    serviceForm: boolean,
): Routes {
    const children: Routes = [
        ...locales.map(
            (locale) =>
                ({
                    path: locale,
                    children: [
                        homeRoute,
                        ...getPartnerWebRoutes(locale),
                        ...getVehicleMarketRoutes(locale),
                        ...getCheckoutsRoutes(locale, serviceForm),
                        getVehicleNotAvailablePage(locale),
                        getNotFoundPage(),
                    ],
                    canActivate: [dealerLanguageGuard],
                    data: {
                        locale,
                    },
                }) as Route,
        ),
        {
            path: '',
            loadComponent: () => import('libs/ng-common-lib/src/components/not-found-page/not-found-page.component'),
            canActivate: [dealerLanguageGuard],
        },
        {
            path: '**',
            redirectTo: locales[0],
        },
    ];
    const dealerRoutes: Routes = [
        {
            path: '',
            children: [
                {
                    path: ':dealerSlug',
                    canActivate: [
                        ngrxLoaderGuard({
                            actionToDispatch: (route) =>
                                dealerActions.setDealerBySlug({ dealerSlug: route.params['dealerSlug'] }),
                            waitUntilLoadedSelector: dealerFeature.selectIsLoadingDealer,
                            dataToCheckSelector: dealerFeature.selectActiveDealer,
                            redirectOnFailUrl: rootRedirectUrl,
                        }),
                    ],
                    children,
                },
            ],
            loadComponent: () => import('libs/partner-web-platform/src/lib/pages/partner/partner.component'),
        },
    ];

    if (dealerSearch) {
        return [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'de',
            },
            ...getDealerSearchPage(locales),
            ...dealerRoutes,
        ] as Routes;
    }

    return dealerRoutes;
}
