import { inject, Injectable } from '@angular/core';
import { WNestApi } from 'libs/shared-lib/interfaces/';
import { WholesaleLanguage } from 'libs/shared-lib/language/language';
import { Observable } from 'rxjs';
import { WNestApiService } from './wholesale-nest-api';

@Injectable({
    providedIn: 'root',
})
export class AboutUsService {
    protected readonly wnestApiService: WNestApiService = inject(WNestApiService);

    loadAboutUsByDealer(
        dealerId: string,
        locale: WholesaleLanguage,
        subDealerId?: string,
    ): Observable<WNestApi.AboutUs> {
        if (subDealerId) {
            return this.wnestApiService.getAboutUsForSubDealer(dealerId, subDealerId, locale);
        }
        return this.wnestApiService.getAboutUs(dealerId, locale);
    }
}
