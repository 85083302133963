import { DealerSearchLabels } from 'libs/shared-lib/interfaces/dealersearch/dealer-search-labels';

export const DEALER_SEARCH_LABELS_IT: DealerSearchLabels = {
    item: {
        ourModels: 'Nostri modelli',
        route: 'Itinerario',
        toSite: 'Al sito web',
        distance: function (distance: number): string {
            return `Distanza: ${distance} km`;
        },
        sell: 'Vendita',
        service: 'Servizio',
        warehouse: 'Magazzino',
        time: function (time: string): string {
            return `${time} Uhr`;
        },
        status: {
            opened: 'Aperto ora',
            closed: 'Chiuso',
            closingSoon: 'Chiude tra poco',
            openingSoon: 'Apre tra poco',
        },
    },
    resultList: {
        showMore: 'Mostrare di più',
    },
    autocomplete: {
        currentLocation: 'Utilizzare la posizione attuale',
        searchPlaceholder: 'Ricerca garage',
    },
    backToMap: 'Tornare alla cartina',
    backToList: 'Tornare alla lista',
    searchInput: {
        search: 'Inserire nome garage, NPA o luogo',
    },
    filter: {
        button: 'Filtro',
        reset: 'Ripristinare filtri',
        headline: 'Filtrare i risultati',
        sell: 'Sedi con vendita',
        service: 'Sedi con servizio',
    },
};
