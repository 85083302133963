import { styleProvider } from 'libs/ng-common-lib/src/utilities/style-provider';

export interface M050C010MainnavigationStyleData {
    brandName: string;
    div: {
        open: string;
        mobileTransparentBackround: string;
    };
    navigation: {
        outerDiv: string;
        div: string;
        logo: {
            container: string;
            icon: {
                path: string;
                size: string;
                default: string;
                open: string;
                bright: string;
            };
            noBurgerMenu?: string;
        };
        link: {
            container: string;
            default: string;
            active: string;
            notLast: string;
        };
        icons: {
            container: string;
            languageSwitch: {
                div: string;
                icon: {
                    size: string;
                    default: string;
                    open: string;
                    bright: string;
                };
                span: {
                    span: string;
                    default: string;
                    open: string;
                    bright: string;
                };
            };
            burgerMenu: {
                condition: (isCheckout: boolean, isHome: boolean) => boolean;
                container: string;
                closed: {
                    size: string;
                    color: string;
                };
                open: {
                    size: string;
                    color: string;
                };
            };
        };
        open: {
            div: string;
            link: string;
            linkLast?: string;
            linkIconPath?: string;
            linkIconClasslist?: string[];
        };
    };
}
export const [injectM050C010MainnavigationStyleData, provideM050C010MainnavigationStyleData] =
    styleProvider<M050C010MainnavigationStyleData>('M050C010MainnavigationStyleToken');
