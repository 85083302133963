import { InjectionToken } from '@angular/core';

export const M030C010HeroStyleToyota: M030C010HeroStyleData = {
    container: 'relative lg:flex lg:items-center lg:justify-end lg:mx-auto overflow-x-hidden',
    mobileOverlay: 'h-100vh md:hero-height w-100vw lg:hidden',
    div: 'absolute top-0 lg:relative flex-shrink-0 flex-grow-0 lg:ml-8p min-w-365 w-full lg:w-365 z-10 flex items-center lg:mb-15p h-100vh md:hero-height lg:h-auto lg:mr-54',
    contentContainer: 'inline-flex mx-auto flex-col w-full lg:w-auto',
    h1: 'text-dark-text lg:text-light-text text-40 lg:text-28 leading-60 lg:leading-32 px-lg lg:px-0',
    showDivider: true,
    divider: 'mt-lg mb-2xl lg:mb-60 lg:mt-30 border-b-3 border-b-accent max-w-40p lg:max-w-60',
    firstButtonInverted: true,
    buttonContainer: 'mt-0',
    button: 'px-lg lg:px-0',
    showBounceIcon: true,
    buttonHero: 'mt-0 md:mt-lg',
    icon: {
        container: 'flex items-center justify-center lg:hidden mt-60',
        size: 'w-80 h-80',
        color: 'fill-dark-text',
    },
    showOverlay: true,
    overlay: 'absolute inset-0 block lg:hidden h-100vh md:hero-height w-100vw bg-dark-bg opacity-60',
    image: {
        container: 'absolute top-0 lg:relative inset-0',
        image: 'h-100vh md:hero-height w-full object-cover',
        ignoreHeight: true,
        desktop: {
            width: 900,
            height: 792,
        },
        tablet: {
            width: 900,
            height: 792,
        },
        mobile: {
            width: 767,
            height: 920,
        },
    },
    buttonWrapper: 'gap-lg mt-lg flex-col',
};

export const M030C010HeroStyleOpel: M030C010HeroStyleData = {
    container: 'bg-grey-100 relative min-h-290 md:min-h-530 transition-all',
    mobileOverlay: 'min-h-290 md:min-h-530 w-100vw md:hidden',
    div: 'relative z-1 max-w-1180 px-16 mx-auto md:pt-80 pb-32 pt-16 md:py-0 transition-all',
    firstButtonInverted: false,
    contentContainer: 'inline-flex mx-auto flex-col w-full md:w-auto',
    h1: 'font-primary font-light text-22 md:text-48 leading-22 md:leading-48 text-center md:text-left pb-16 md:pb-0 transition-all',
    showDivider: false,
    divider: '',
    buttonContainer: 'md:mt-32',
    button: '',
    buttonHero: '',
    showBounceIcon: false,
    icon: {
        container: '',
        size: '',
        color: '',
    },
    showOverlay: false,
    overlay: '',
    image: {
        container: 'absolute inset-0',
        image: 'w-full object-cover min-h-290 md:min-h-530 max-h-290 md:max-h-530 transition-all',
        ignoreHeight: false,
        desktop: {
            width: 1920,
            height: 530,
        },
        tablet: {
            width: 768,
            height: 441,
        },
        mobile: {
            width: 390,
            height: 224,
        },
    },
    buttonWrapper: 'gap-lg mt-lg flex-col',
};

export const M030C010HeroStyleLexus: M030C010HeroStyleData = {
    container:
        'bg-dark-bg relative h-100dvh64 lg:h-auto lg:min-h-570 transition-all flex flex-col-reverse lg:block overflow-hidden',
    mobileOverlay: '',
    div: 'relative z-1 container lg:pt-136 pb-32 pt-32 lg:py-0 transition-all flex-shrink-0',
    firstButtonInverted: false,
    contentContainer: 'inline-flex mx-auto flex-col w-full lg:w-auto',
    h1: 'uppercase text-dark-text tracking-2 text-32 lg:text-48 leading-40 lg:leading-60 transition-all',
    showDivider: true,
    divider: 'w-32 h-1 bg-white mt-24 lg:mt-40',
    buttonContainer: 'mt-16 lg:mt-32',
    button: '',
    buttonHero: '',
    showBounceIcon: false,
    icon: {
        container: '',
        size: '',
        color: '',
    },
    showOverlay: false,
    overlay: '',
    image: {
        container: 'lg:absolute lg:inset-0 flex-grow flex-shrink',
        image: 'w-full object-cover lg:min-h-570 !h-full lg:max-h-570 transition-all lg:object-left',
        ignoreHeight: false,
        desktop: {
            width: 1920,
            height: 570,
        },
        tablet: {
            width: 768,
            height: 455,
        },
        mobile: {
            width: 375,
            height: 455,
        },
    },
    buttonWrapper: 'flex-col',
};

export const M030C010HeroStyleLandRover: M030C010HeroStyleData = {
    container:
        'bg-gradient relative lg:h-100dvh64 lg:min-h-570 transition-all grid lg:grid-cols-3 grid-rows-3 lg:grid-rows-1 ',
    mobileOverlay: '',
    div: 'bg-white relative py-32 lg:pt-48 lg:pb-80 lg:px-80 px-10 transition-all flex-shrink-0 flex-1 order-4 lg:flex lg:items-center lg:col-span-3',
    firstButtonInverted: false,
    contentContainer: 'container w-full lg:!max-w-1100',
    h1: 'uppercase text-black font-bold text-24 lg:text-40 leading-[38px] transition-all',
    showDivider: false,
    divider: 'w-full h-1 bg-grey-300 mt-24 lg:mt-40',
    buttonContainer: '',
    button: '',
    buttonHero: '',
    heroButtonIcon: { path: '/assets/icons/chevron_right.svg', classes: 'w-15 h-15' },
    showBounceIcon: false,
    icon: {
        container: '',
        size: '',
        color: '',
    },
    showOverlay: false,
    overlay: '',
    image: {
        container: 'flex-1 h-full relative max-h-230 lg:max-h-none',
        image: 'w-full object-cover h-full transition-all object-center',
        ignoreHeight: false,
        desktop: {
            width: 480,
            height: 776,
        },
        tablet: {
            width: 375,
            height: 50,
        },
        mobile: {
            width: 375,
            height: 50,
        },
        advandecdTeaserImage: {
            container:
                'absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col text-center items-center gap-40',
            btn: 'font-secondary text-10 px-24 flex items-center gap-8 whitespace-nowrap border-none bg-white py-10 text-center font-semibold tracking-2.5 uppercase',
            svg: 'fill-white h-16 w-auto',
            label: 'text-48 whitespace-nowrap font-bold text-white',
        },
    },
    buttonWrapper: 'flex-row',
    heroButtonWrapper: 'flex lg:flex-row lg:items-center gap-x-64 gap-y-32 mt-32 flex-col',
};

export const M030C010HeroStyleJaguar: M030C010HeroStyleData = {
    container:
        'relative flex flex-col-reverse lg:flex-row lg:items-center lg:justify-end lg:mx-auto overflow-x-hidden bg-dark-bg',
    mobileOverlay: 'w-100vw lg:hidden',
    div: 'top-0 relative flex-shrink-0 flex-grow-0 lg:ml-2p min-w-395 w-full lg:w-395 z-10 flex items-center lg:mb-5p lg:h-auto lg:mr-54',
    contentContainer: 'inline-flex mx-auto flex-col w-full lg:w-auto py-42 lg:py-0',
    h1: 'text-dark-text font-primary text-19 leading-20 lg:text-24 lg:leading-26 px-lg lg:px-0 uppercase font-bold',
    showDivider: true,
    divider: 'my-24 mx-lg lg:my-32 border-b-1 border-b-grey-strokes-stealth',
    firstButtonInverted: true,
    buttonContainer: 'mt-0',
    button: 'px-lg lg:px-0',
    showBounceIcon: true,
    buttonHero: '',
    icon: {
        container: 'hidden ',
        size: '',
        color: '',
    },
    showOverlay: false,
    overlay: '',
    image: {
        container: 'top-0 relative inset-0 min-h-367 lg:min-h-570 max-h-570 w-full overflow-hidden max-w-1200',
        image: 'h-full w-full object-cover object-center min-h-367 lg:h-full absolute',
        ignoreHeight: true,
        desktop: {
            width: 1100,
            height: 570,
        },
        tablet: {
            width: 1100,
            height: 570,
        },
        mobile: {
            width: 375,
            height: 367,
        },
    },
    buttonWrapper: 'gap-lg mt-lg flex-col',
    heroButtonContainer: 'group',
    heroButtonIcon: {
        path: '/assets/icons/chevron_right.svg',
        classes: 'w-12 h-12 transition-all group-hover:animate-bouncingLeft fill-dark-text',
    },
};

export const M030C010HeroStyleToken = new InjectionToken<M030C010HeroStyleData>('M030C010HeroStyleToken');

export function provideM030C010HeroStyleData(style: M030C010HeroStyleData) {
    return {
        provide: M030C010HeroStyleToken,
        useValue: style,
    };
}

export interface M030C010HeroStyleData {
    mobileOverlay: string;
    container: string;
    div: string;
    contentContainer: string;
    h1: string;
    showDivider: boolean;
    divider: string;
    firstButtonInverted: boolean;
    buttonContainer: string;
    button: string;
    buttonHero: string;
    heroButtonIcon?: {
        path: string;
        classes?: string;
    };
    heroButtonWrapper?: string;
    showBounceIcon: boolean;
    icon: {
        container: string;
        size: string;
        color: string;
    };
    overlay: string;
    showOverlay: boolean;
    image: {
        container: string;
        image: string;
        ignoreHeight: boolean;
        desktop: {
            width: number;
            height: number;
        };
        tablet: {
            width: number;
            height: number;
        };
        mobile: {
            width: number;
            height: number;
        };
        advandecdTeaserImage?: {
            container: string;
            btn: string;
            svg: string;
            label: string;
        };
    };
    buttonWrapper: string;
    heroButtonContainer?: string;
}
