import { InjectionToken, Provider } from '@angular/core';
import { WholesaleLanguage } from 'libs/shared-lib/language/language';

export interface MenuItem {
    label: { [locale: string]: string };
    urlFunction: (locale: WholesaleLanguage, withLangPath: boolean, dealerSlug?: string) => string;
    fragmentFunction?: (locale: WholesaleLanguage) => string;
}

export const MENU_ITEM_TOKEN = new InjectionToken<MenuItem[]>('MENU_ITEM_TOKEN');

export function provideMenuItem(menuItem: MenuItem): Provider {
    return {
        provide: MENU_ITEM_TOKEN,
        useValue: menuItem,
        multi: true,
    };
}
