import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivateFn } from '@angular/router';
import { Action, MemoizedSelector, Store } from '@ngrx/store';
import { Observable, filter, of, take } from 'rxjs';

export interface NgrxDeloaderGuardOptions<TState, TComponent> {
    actionToDispatch: Action | ((route: ActivatedRouteSnapshot, component: TComponent) => Action | undefined);
    waitUntilDeloadedSelector?: MemoizedSelector<TState, boolean>;
}

export function ngrxDeloaderGuard<TState = unknown, TComponent = unknown>(
    opt: NgrxDeloaderGuardOptions<TState, TComponent>,
): CanDeactivateFn<TComponent> {
    return (component: TComponent, route: ActivatedRouteSnapshot): Observable<boolean> => {
        const store = inject(Store);

        const action =
            opt.actionToDispatch instanceof Function ? opt.actionToDispatch(route, component) : opt.actionToDispatch;
        if (action) {
            store.dispatch(action);
        }

        if (!opt.waitUntilDeloadedSelector) {
            return of(true);
        }

        return store.select(opt.waitUntilDeloadedSelector).pipe(
            filter((isLoading) => isLoading),
            take(1),
        );
    };
}
