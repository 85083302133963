import { CheckoutsLabels } from 'libs/shared-lib/interfaces/checkout/checkouts.interface';
import {
    errorInvalid,
    errorMessage,
    errorRequired,
    footerLinks,
    gender,
    m060C050vehicleOverviewLabels,
    phoneNumberPrefixes,
} from '../../shared/shared.de';

export const CHECKOUTS_LABELS_DE: CheckoutsLabels = {
    buy: {
        S010ContactData: {
            stepLabel: 'Kontaktdaten',
            howToBox: {
                headline: 'So einfach kommst du online zur Kaufofferte für dein Wunschauto:',
                stepText: [
                    'Kontaktdaten eingeben',
                    'Abholung oder Lieferung wählen',
                    'Zusatzleistungen wählen',
                    'Fertig! Dein persönlicher Verkaufsberater meldet sich in Kürze bei dir!',
                ],
            },
            title: 'Deine Kontaktdaten',
            headline: 'Für deine Offertanfrage fülle die folgenden Angaben bitte vollständig aus.',
            gender: gender,
            firstName: 'Vorname',
            lastName: 'Nachname',
            email: 'E-Mail-Adresse',
            phoneNumber: 'Deine Telefonnummer',
            privacyNoticeBefore: 'Ich habe die ',
            privacyNoticeLink: 'Datenschutzerklärung',
            privacyNoticeUrl: footerLinks.DATA_POLICY,
            privacyNoticeAfter: 'gelesen und verstanden.',
            nextBtn: 'Weiter',
            errors: {
                required: errorRequired,
                pattern: errorInvalid,
            },
            phoneNumberPrefixes: phoneNumberPrefixes,
            preferredContact: {
                headline: 'Ich bin einverstanden mit der Kontaktaufnahme per:',
                phone: 'Telefon',
                email: 'E-Mail',
                mail: 'Post',
                sms: 'SMS/Whatsapp',
            },
            footnote: 'Die mit * gekennzeichneten Felder sind Pflichtfelder.',
        },
        S020PickupOrDelivery: {
            stepLabel: 'Abholung/Lieferung',
            title: 'Abholung/Lieferung',
            headline: 'Wie möchtest du dein Wunschauto erhalten?',
            tabs: {
                pickup: 'Beim Opel Partner abholen',
                delivery: 'Lieferung an diese Adresse',
            },
            gender: gender,
            dealer: 'Autohändler wählen',
            street: 'Strasse',
            streetNumber: 'Nr.',
            zip: 'PLZ',
            city: 'Ort',
            nextBtn: 'Weiter',
            summary: {
                headline: 'Für die Übergabe deines Wunschautos an dich haben wir Folgendes notiert:',
            },
            errors: {
                required: errorRequired,
                pattern: errorInvalid,
            },
            requiredNotice: 'Die mit * gekennzeichneten Felder sind Pflichfelder.',
        },
        S030AdditionalBenefits: {
            stepLabel: 'Zusatzleistungen',
            title: 'Zusatzleistungen',
            headline: 'Wovon möchtest du zusätzlich profitieren?',
            benefits: [
                {
                    identifier: 'multiassurance_advantage',
                    headline: 'Schnell, einfach, umfangreich und aus einer Hand: unsere Fahrzeugversicherung.',
                    subline: 'Nutze alle Vorteile unserer MultiAssurance-Versicherung für dich:',
                    items: [
                        'Erweiterte Glasdeckung ohne Selbstbehalt',
                        'Reiseschutzbrief MultiAssistance ein Jahr lang gratis für dich und deine Familie',
                    ],
                },
                {
                    identifier: 'car_return',
                    headline: 'Fahrzeugeintausch mit Bewertung vom Profi.',
                    subline: 'Möchtest du ein Fahrzeug in Zahlung geben?',
                    items: [
                        'Wir bewerten dein Fahrzeug gerne kostenlos und unverbindlich vor Ort bei deinem Opel Partner.',
                    ],
                },
            ],
            select: 'Auswählen',
            summary: {
                headline: 'Zusatzleistungen',
                subline: 'Für deine gewählten Zusatzleistungen erhältst du separate Offerten:',
            },
            nextBtn: 'Absenden',
            errors: {
                required: errorRequired,
                pattern: errorInvalid,
            },
            M030C070AdditionalPackages: {
                headline: 'Unsere Zubehörempfehlung',
                subheadline: '',
                from: 'ab',
            },
        },
        S040ThanksWhithVehicle: {
            error: errorMessage,
            headline: 'Vielen Dank,',
            headlineAfter: '!',
            female: 'Frau',
            male: 'Herr',
            copy: 'Wir haben deine Anfrage erhalten. Wir kontaktieren dich sobald wie möglich.<br><br>Dein Opel Partner ',
            contactCard: {
                headline: 'Können wir sonst noch etwas für dich tun?',
                copy: 'Unsere Berater helfen dir gerne.',
                phone: 'Anrufen',
                mail: 'E-Mail',
            },
        },
        M060C050vehicleOverview: m060C050vehicleOverviewLabels,
    },
    tradeIn: {
        menuItem: 'DEPRECATED',
        S010ContactData: {
            stepLabel: 'Kontaktdaten',
            title: 'Deine Kontaktdaten',
            headline:
                'Für eine kostenlose Bewertung deines Fahrzeugs fülle die folgenden Angaben bitte vollständig aus.',
            gender: gender,
            firstName: 'Vorname',
            lastName: 'Nachname',
            email: 'E-Mail-Adresse',
            phoneNumber: 'Deine Telefonnummer',
            privacyNoticeBefore: 'Ich habe die ',
            privacyNoticeLink: 'Datenschutzerklärung',
            privacyNoticeUrl: footerLinks.DATA_POLICY,
            privacyNoticeAfter: 'gelesen und verstanden.',
            nextBtn: 'Absenden',
            errors: {
                required: errorRequired,
                pattern: errorInvalid,
            },
            dealer: {
                headline: '',
                label: '',
            },
            phoneNumberPrefixes: phoneNumberPrefixes,
            footnote: 'Die mit * gekennzeichneten Felder sind Pflichtfelder.',
            preferredContact: {
                headline: 'Ich bin einverstanden mit der Kontaktaufnahme per:',
                phone: 'Telefon',
                email: 'E-Mail',
                mail: 'Post',
                sms: 'SMS/Whatsapp',
            },
        },
        S060VehicleSelect: {
            stepLabel: 'Fahrzeugdaten',
            headline: 'Angaben zu deinem Fahrzeug',
            copy: 'Fülle die Felder mit den Angaben zu deinem Fahrzeug aus.',
            make: 'Marke',
            model: 'Modell',
            engine: 'Motorisierung (Optional)',
            firstReg: '1. Inverkehrsetzung',
            mileage: 'Kilometerstand',
            footnote: 'Die mit * gekennzeichneten Felder sind Pflichtfelder.',
            nextBtn: 'Weiter',
            errorRequired: 'Dieses Feld ist erforderlich',
            errorInvalid: 'Ungültige Eingabe',
        },
        S070ThanksNoVehicle: {
            headline: {
                before: 'Danke',
                after: '!',
            },
            female: gender.female,
            male: gender.male,
            copy: 'Vielen Dank, dass du uns dein Auto zum Kauf angeboten hast. Wir freuen uns, mit dir Kontakt aufzunehmen.',
            nextStep: {
                headline: 'Wie geht’s weiter?',
                copy: 'Wir werden uns für die detaillierte Bewertung deines Fahrzeuges bei dir melden.',
                name: 'Dein Opel Partner',
            },
        },
        M060C050vehicleOverview: m060C050vehicleOverviewLabels,
    },
    testDrive: {
        S050TestDriveContactData: {
            stepLabel: 'Kontaktdaten',
            title: 'Kontaktdaten',
            headline: 'Gib hier bitte deine Kontaktdaten ein:',
            subheadline: 'Bitte kontaktiere mich per:',
            gender: gender,
            phoneSwitch: {
                email: 'E-Mail',
                phone: 'Telefon',
            },
            wishDate: 'Wunschtermin',
            firstName: 'Vorname',
            lastName: 'Nachname',
            zip: 'PLZ',
            email: 'E-Mail Adresse',
            message: 'Deine Nachricht',
            phoneNumber: 'Telefonnummer',
            // contactTime: 'Wann sind Sie am besten erreichbar?',
            // contactTimeOptions: [
            //     {
            //         value: 'Vormittags',
            //         label: 'Vormittags',
            //     },
            //     {
            //         value: 'Mittags',
            //         label: 'Mittags',
            //     },
            //     {
            //         value: 'Nachmittags',
            //         label: 'Nachmittags',
            //     },
            // ],
            contactsHeadline: 'Ich stimme der Kontaktaufnahme zu per:',
            contacts: {
                email: 'E-Mail',
                phone: 'Telefon',
                mail: 'Post',
                messenger: 'SMS/WhatsApp',
            },
            privacyNoticeBefore: 'Ich habe die ',
            privacyNoticeLink: 'Datenschutzerklärung',
            privacyNoticeUrl: footerLinks.DATA_POLICY,
            privacyNoticeAfter: 'gelesen und verstanden.',
            requiredNotice: 'Die mit * gekennzeichneten Felder sind Pflichtfelder.',
            nextBtn: 'Absenden',
            errors: {
                required: errorRequired,
                pattern: errorInvalid,
            },
            summary: {
                name: 'Name',
                address: 'PLZ',
                email: 'E-Mail',
                phone: 'Telefon',
                wishDate: 'Wunschtermin',
                seller: 'Garage',
            },
            phoneNumberPrefixes: phoneNumberPrefixes,
        },
        S080ThanksTestDrive: {
            error: errorMessage,
            headline: 'Deine Anfrage wurde erfolgreich versendet.',
            headlineAfter: '',
            female: '',
            male: '',
            copy: 'Vielen Dank, wir haben deine Anfrage erhalten. <br>Wir kontaktieren dich sobald wie möglich.<br><br> Dein Opel Partner<br>',
            subheadline: 'Zusammenfassung',
            M060C050VehicleOverview: m060C050vehicleOverviewLabels,
            contactCard: {
                headline: 'Können wir sonst noch etwas für Sie tun?',
                copy: 'Unsere Berater helfen Ihnen gerne.',
                phone: 'Anrufen',
                mail: 'E-Mail',
            },
            iconSummary: {
                headline: 'Wunschtermin',
            },
        },
        M060C050vehicleOverview: m060C050vehicleOverviewLabels,
    },
    leasing: {
        S010ContactData: {
            stepLabel: 'Kontaktdaten',
            howToBox: {
                headline: 'So einfach kommen Sie online zur Leasingofferte für Ihr Wunschauto:',
                stepText: [
                    'Kontaktdaten eingeben',
                    'Leasingrate mit wenigen Klicks einstellen',
                    'Zusatzleistungen wählen',
                    'Fertig! Ihr persönlicher Verkaufsberater meldet sich in Kürze bei Ihnen!',
                ],
            },
            title: 'Kontaktdaten',
            headline: 'Schnell und einfach Ihre Daten hinterlegen.',
            gender: gender,
            firstName: 'Vorname',
            lastName: 'Nachname',
            email: 'E-Mail Adresse',
            phoneNumber: 'Ihre Telefonnummer',
            privacyNoticeBefore: 'Ich habe die ',
            privacyNoticeLink: 'Datenschutzerklärung',
            privacyNoticeUrl: footerLinks.DATA_POLICY,
            privacyNoticeAfter: 'gelesen und verstanden.',
            nextBtn: 'Weiter',
            errors: {
                required: errorRequired,
                pattern: errorInvalid,
            },
            phoneNumberPrefixes: phoneNumberPrefixes,
            leasingSummary: {
                annualPercentageRate: 'Effektiver Jahreszins',
                debitInterest: 'Sollzins',
                monthlyRate: 'Monatsrate',
                footnoteBound: '(1) Gebunden für die gesamte Vertragslaufzeit.',
                footnoteCosts:
                    '(2) Die Überführungskosten sind in der monatlichen Rate inbegriffen. Alle Angaben ohne Gewähr.',
                footnoteDelivery: '(3) exkl. Ablieferungspauschale',
            },
        },
        S020PickupOrDelivery: {
            stepLabel: 'Abholung / Lieferung',
            title: 'Abholung / Lieferung',
            headline: 'Wie möchten Sie Ihr Wunschauto erhalten?',
            tabs: {
                pickup: 'Beim Opel Partner abholen',
                delivery: 'Lieferung an diese Adresse',
            },
            gender: gender,
            dealer: 'Autohändler wählen',
            street: 'Strasse',
            streetNumber: 'Nr.',
            zip: 'PLZ',
            city: 'Ort',
            nextBtn: 'Weiter',
            summary: {
                headline: 'Für die Übergabe Ihres Wunschautos an Sie haben wir Folgendes notiert:',
            },
            errors: {
                required: errorRequired,
                pattern: errorInvalid,
            },
            requiredNotice: 'Die mit * gekennzeichneten Felder sind Pflichfelder.',
        },
        S040ThanksWhithVehicle: {
            error: errorMessage,
            headline: 'Vielen Dank,',
            headlineAfter: '!',
            female: 'Frau',
            male: 'Herr',
            copy: 'Wir haben Ihre Anfrage erhalten. Ihr Opel Partner wird sich in Kürze bei Ihnen melden und Sie fachmännisch beraten!',
            contactCard: {
                headline: 'Können wir sonst noch etwas für Sie tun?',
                copy: 'Unsere Berater helfen Ihnen gerne.',
                phone: 'Anrufen',
                mail: 'E-Mail',
            },
        },
        M060C050vehicleOverview: m060C050vehicleOverviewLabels,
        S090Leasing: {
            stepLabel: 'Leasingrechner',
            headline: 'Wunschauto verwirklichen und finanzielle Freiräume behalten.',
            copy: 'Stellen Sie sich Ihren Leasingvertrag individuell zusammen.',
            durationHeadline: 'Laufzeit Leasingvertrag in Monaten',
            runtimeHeadline: 'Laufleistung Wunschauto pro Jahr',
            commissionHeadline: 'Anzahlung',
            nextButton: 'Wunschauto leasen',
            anualPercentageRate: 'Effektiver Jahreszins',
            debitInterest: 'Sollzins',
            monthlyRate: 'Monatliche Rate',
            footnoteBound: '(1) Gebunden für die gesamte Vertragslaufzeit.',
            footnoteCosts:
                '(2) Die Überführungskosten sind in der monatlichen Rate inbegriffen. Alle Angaben ohne Gewähr.',
            footnoteDelivery: '(3) exkl. Ablieferungspauschale',
            runtimeYearly: 'Laufleistung p.a.',
            months: 'Monate',
            duration: 'Laufzeit',
            calcError: 'Diese Konfiguration ist leider ungültig.',
            unit: 'km',
        },
        S030AdditionalBenefits: {
            stepLabel: 'Zusatzleistungen',
            title: 'Zusatzleistungen',
            headline: 'Wovon möchten Sie zusätzlich profitieren?',
            benefits: [
                {
                    identifier: 'multiassurance_advantage',
                    headline: 'Schnell, einfach, umfangreich und aus einer Hand: unsere Fahrzeugversicherung.',
                    subline: 'Nutzen Sie alle Vorteile unserer MultiAssurance-Versicherung für sich:',
                    items: [
                        'Erweiterte Glasdeckung ohne Selbstbehalt',
                        'Reiseschutzbrief MultiAssistance ein Jahr lang gratis für Sie und Ihre Familie',
                    ],
                },
                {
                    identifier: 'car_return',
                    headline: 'Fahrzeugeintausch mit Bewertung vom Profi.',
                    subline: 'Möchten Sie ein Fahrzeug in Zahlung geben?',
                    items: [
                        'Wir bewerten Ihr Fahrzeug gerne kostenlos und unverbindlich vor Ort bei Ihrem Opel Partner.',
                    ],
                },
            ],
            select: 'Auswählen',
            summary: {
                headline: 'Zusatzleistungen',
                subline: 'Für Ihre gewählten Zusatzleistungen erhalten Sie separate Offerten:',
            },
            nextBtn: 'Absenden',
            errors: {
                required: errorRequired,
                pattern: errorInvalid,
            },
            M030C070AdditionalPackages: {
                headline: 'Unsere Zubehörempfehlung',
                subheadline: '',
                from: 'ab',
            },
        },
    },
    service: {
        S020PickupOrDelivery: {
            stepLabel: 'Wunschtermin',
            title: 'Servicetermin vereinbaren',
            headline: 'Ort und Wunschtermin wählen:',
            tabs: {
                pickup: 'In die Garage bringen',
                delivery: 'Hol- und Bring-Service nutzen',
                deliveryHeadline: '',
                deliveryCopy:
                    'Gerne kannst du uns hier unverbindlich dein Interesse an einem Hol- und Bring-Service mitteilen. Wir werden uns mit dir in Verbindung setzen, um die Möglichkeiten zu besprechen.',
            },
            gender: gender,
            dealer: 'Autohändler wählen ',
            street: 'Strasse',
            streetNumber: 'Nr.',
            zip: 'PLZ',
            city: 'Ort',
            nextBtn: 'Weiter',
            summary: {
                wishDate: 'Wunschtermin',
            },
            errors: {
                required: errorRequired,
                pattern: errorInvalid,
            },
            requiredNotice: 'Die mit * gekennzeichneten Felder sind Pflichtfelder.',
            wishDate: {
                label: 'Wunschtermin',
                btnLabel: 'ändern',
            },
            note: {
                headline: 'Hinweis:',
                copy: 'Falls du kurzfristig einen Termin benötigst, bitten wir dich, uns telefonisch zu kontaktieren. Bitte beachte, dass es sich hierbei um eine Anfrage handelt und dein gewählter Termin bis zur Bestätigung unverbindlich ist.',
            },
            pickupService: {
                headline: 'Hol- und Bring-Service',
                value: 'Ja',
            },
        },
        S100Services: {
            stepLabel: 'Leistungen',
            title: 'Leistungen',
            headline: 'Wähle die gewünschten Leistungen:*',
            other: 'Andere',
            summary: {
                headline: 'Leistungen',
            },
            nextBtn: 'Weiter',
            errors: {
                required: errorRequired,
                pattern: errorInvalid,
            },
            requiredNotice: 'Die mit * gekennzeichneten Felder sind Pflichfelder.',
        },
        S015ContactDataExtended: {
            stepLabel: 'Kontaktdaten',
            title: 'Deine Kontaktdaten',
            headline: 'Gib hier bitte deine Kontaktdaten ein:',
            gender: gender,
            firstName: 'Vorname',
            lastName: 'Nachname',
            email: 'E-Mail Adresse',
            phoneNumber: 'Deine Telefonnummer',
            privacyNoticeBefore: 'Ich habe die ',
            privacyNoticeLink: 'Datenschutzerklärung',
            privacyNoticeUrl: footerLinks.DATA_POLICY,
            privacyNoticeAfter: 'gelesen und verstanden.',
            nextBtn: 'Senden',
            errors: {
                required: errorRequired,
                pattern: errorInvalid,
            },
            phoneNumberPrefixes: phoneNumberPrefixes,
            footnote: 'Die mit * gekennzeichneten Felder sind Pflichtfelder.',
            preferredContact: {
                headline: 'Ich bin einverstanden mit der Kontaktaufnahme per:',
                phone: 'Telefon',
                email: 'E-Mail',
                mail: 'Post',
                sms: 'SMS/Whatsapp',
            },
            alreadyCustomer: {
                label: 'Bist du bereits Kunde bei uns?',
                yes: 'Ja',
                no: 'Nein',
            },
            corporateClient: {
                label: 'Bist du Firmenkunde?',
                yes: 'Ja',
                no: 'Nein',
            },
            corporate: 'Firma',
            corporateStreet: 'Straße',
            corporateStreetnumber: 'Nr.',
            corporateZip: 'PLZ',
            corporateCity: 'Ort',
            contactTime: 'Wann bist du am besten erreichbar?',
            contactTimeOptions: [
                {
                    value: 'Vormittags',
                    label: 'Vormittags',
                },
                {
                    value: 'Mittags',
                    label: 'Mittags',
                },
                {
                    value: 'Nachmittags',
                    label: 'Nachmittags',
                },
            ],
        },
        S065VehicleData: {
            stepLabel: 'Fahrzeugdaten',
            headline: 'Deine Fahrzeugdaten',
            copy: 'Fülle für den Servicetermin die folgenden Fahrzeugdaten bitte vollständig aus.',
            make: 'Marke',
            model: 'Modell',
            controlSign: 'Kontrollschild (z.B. ZH 123 456)',
            firstReg: '1. Inverkehrsetzung (z.B. 2018)',
            mileage: 'Kilometerstand',
            message: 'Deine Nachricht',
            footnote: 'Die mit * gekennzeichneten Felder sind Pflichtfelder.',
            nextBtn: 'Weiter',
            errorRequired: 'Dieses Feld ist erforderlich',
            errorInvalid: 'Ungültige Eingabe',
            summary: {
                controlSign: 'Kontrollschild',
                firstReg: '1. Inverkehrsetzung',
            },
        },
        S110ThanksService: {
            headline: 'Deine Anfrage wurde erfolgreich versendet.',
            copy: 'Vielen Dank, wir haben deine Anfrage erhalten. Schnellstmöglich wirst du von deiner zuständigen Garage kontaktiert.',
            team: 'Dein Opel Partner ',
            summary: {
                headline: 'Zusammenfassung',
                copy: 'Deine gewählten Optionen',
            },
            errorMessage,
        },
        M060C050vehicleOverview: {
            ...m060C050vehicleOverviewLabels,
            noVehicle: {
                headline: 'Zusammenfassung',
                subheadline: 'Deine gewählten Optionen',
            },
        },
    },
};
