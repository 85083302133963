import { inject, Injectable } from '@angular/core';
import { WNestApi } from 'libs/shared-lib/interfaces/';
import { WholesaleLanguage } from 'libs/shared-lib/language/language';
import { Observable } from 'rxjs';
import { WNestApiService } from './wholesale-nest-api';

@Injectable({
    providedIn: 'root',
})
export class ServiceService {
    protected readonly wnestApiService: WNestApiService = inject(WNestApiService);

    loadAllServicesByDealerSlug(dealerId: string, locale: WholesaleLanguage): Observable<WNestApi.ServicePage> {
        return this.wnestApiService.getServicesForDealer(dealerId, locale);
    }

    loadServiceListByDealerSlug(dealerId: string, locale: WholesaleLanguage): Observable<WNestApi.ServiceListPage> {
        return this.wnestApiService.getServiceListForDealer(dealerId, locale);
    }

    loadServiceBySlug(slug: string, locale: WholesaleLanguage): Observable<WNestApi.ServicePage> {
        return this.wnestApiService.getServicesForDealer(slug, locale);
    }

    loadServiceDetailByDealerSlug(
        dealerId: string,
        locale: WholesaleLanguage,
        dealerSlug: string,
        serviceSlug: string,
    ): Observable<WNestApi.ServiceDetailPage> {
        return this.wnestApiService.getServiceDetailForDealer(dealerId, locale, dealerSlug, serviceSlug);
    }

    loadWorkshopServicesByDealerId(
        dealerId: string,
        locale: WholesaleLanguage,
        dealerSlug: string,
    ): Observable<WNestApi.WorkshopServiceTeaser[]> {
        return this.wnestApiService.getWorkshopServicesDealerId(dealerId, locale, dealerSlug);
    }
}
