import { routerReducer, RouterState } from '@ngrx/router-store';
import { ActionReducerMap } from '@ngrx/store';
import { DealerSearchEffects } from 'libs/dealer-search-lib/src/lib/+state/dealer-search.effects';
import { AboutUsEffects } from 'libs/ng-common-lib/src/+state/about-us.effects';
import { CheckoutsEffects } from 'libs/ng-common-lib/src/+state/checkouts.effects';
import { DealerEffects } from 'libs/ng-common-lib/src/+state/dealer.effects';
import { NewsEffects } from 'libs/ng-common-lib/src/+state/news.effects';
import { PromotionEffects } from 'libs/ng-common-lib/src/+state/promotion.effects';
import { ServiceEffects } from 'libs/ng-common-lib/src/+state/service.effects';
import { WholesaleEffects } from 'libs/ng-common-lib/src/+state/wholesale.effects';
import { AppEffects } from './app.effects';

export interface State {
    router: RouterState;
}

export const reducers: ActionReducerMap<State> = {
    router: routerReducer,
};

export const effects = [
    AppEffects,
    DealerEffects,
    WholesaleEffects,
    CheckoutsEffects,
    PromotionEffects,
    NewsEffects,
    ServiceEffects,
    AboutUsEffects,
    DealerSearchEffects,
];
