import { PhoneNumberPrefix } from 'libs/shared-lib/interfaces';
import { M060C010VehicleCardLabels } from 'libs/shared-lib/interfaces/ui/m060-c010-vehicle-card.interface';

export const errorMessage = "Une erreur s'est produite. Veuillez réessayer.";
export const errorRequired = 'Ce champ est obligatoire';
export const errorInvalid = 'Entrée invalide';

export const gender = {
    female: 'Madame',
    male: 'Monsieur',
};

export const footerLinks = {
    LEGAL: 'https://www.opel.ch/fr/outils/opel-copyrights.html',
    COOKIES: 'https://www.opel.ch/fr/outils/politique-des-cookies.html',
    DATA_POLICY: 'https://www.opel.ch/fr/outils/politique-de-confidentialite.html',
};

export const m060C050vehicleOverviewLabels = {
    consumption: 'Consommation mixte',
    consumptionAfter: 'l/100km',
    emission: 'Émissions de CO2 mixtes',
    emissionAfter: 'g/km',
    efficiency: 'Rendement énergétique',
    mileageFromOdometer: 'string',
    dateVehicleFirstRegistered: 'string',
    vehicleTransmission: 'string',
    additionalProperty: 'string',
    fuelType: 'string',
    driveWheelConfiguration: 'string',
    summary: 'Résumé',
    monthly: ' /m.',
    noVehicle: {
        headline: '',
        subheadline: '',
    },
};

export const phoneNumberPrefixes: PhoneNumberPrefix[] = [
    {
        prefix: '+41',
        label: 'Suisse',
        iconPath: 'assets/icons/flags/Switzerland.svg',
    },
    {
        prefix: '+49',
        label: 'Allemagne',
        iconPath: 'assets/icons/flags/Germany.svg',
    },
];

export const m070c040DealerContact = {
    headline: 'Heures d’ouverture',
    rating: 'chez Google',
    desktop: {
        cta: 'En savoir plus sur nous',
        route: 'Calculer l’itinéraire',
    },
    mobile: {
        phone: 'Appeler',
        route: 'Itinéraire',
        email: 'Contact',
        chat: 'Live-Chat',
    },
    toLocation: 'Vers le site',
};

export const m030c195CtaImageTeasers = {
    headline: 'Nos sites compétents',
    listButtonLabel: 'Vers l’aperçu',
};

const occasionsProgram = 'Des Blitz récentes';

export const occasionsProgramOverlay = {
    headline: 'des Blitz récentes – Une voiture d’occasion peut être aussi flambant neuve',
    copy: '<p class="text-left mb-10">Découvre nos jeunes véhicules d\'occasion :</p><ul class="text-left disclistNarrow"><li>les voitures qui n\'ont pas plus de 12 mois d\'âge</li><li>les voitures qui n\'ont pas roulé plus de 5000 km</li><li>Autorisations journalières</li><li>Véhicules de démonstration</li><li>  Et en général les jeunes voitures d\'occasion (pas plus de 12 mois et pas plus de 5000 KM)</li></ul><p class="text-left">Achète dès maintenant un véhicule presque neuf avec un taux de leasing attractif de 1,99%. </p><p class="text-left">Rendez-vous chez ton concessionnaire pour en profiter!</p>',
    image: {
        image: 'https://media.graphassets.com/Q9zWaz78QhCjSS6cJKLA',
        width: 300,
        height: 225,
    },
};

export const actionTags = {
    occasionsProgram: {
        text: occasionsProgram,
        overlay: occasionsProgramOverlay,
    },
};

export const vehicleCardLabels: M060C010VehicleCardLabels = {
    currency: 'CHF',
    leasing: 'Leasing dès',
    monthly: '/mois',
    actionTags: actionTags,
    newPrice: 'Prix neuf',
    firstPriceText: '',
    lastPriceText: 'Leasing dès',
    consumption: 'Consommation mixte',
    emission: 'Émissions de CO2 mixtes',
};

export const searchCriteriaLabels = {
    headline: 'Filtrer les résultats',
    button: 'Afficher %s véhicules',
    reset: 'Réinitialiser filtres',
    location: {
        currentLocation: 'Utiliser site actuel',
        subheading: 'Recherche par adresse',
    },
    selected: 'choisi',
    allSelected: 'Tous',
    to: 'jusqu’à',
    monthly: '/mois',
    ratefrom: 'Mensualité dès',
    rateto: 'Mensualité jusqu’à',
    pricefrom: 'Prix à partir de',
    priceto: 'Prix jusqu’à',
    vehicle_tags: {
        occasionsProgram: occasionsProgramOverlay,
    },
};

export const m060c080QuickSearchLabels = {
    headline: 'Trouve la voiture de ton choix',
    model: 'Modèle',
    price: 'Prix',
    ctaLabel: 'Afficher %s véhicules',
    buttonAdvancedSearchLabel: 'Recherche avancée',
    selected: 'choisi',
    allSelected: 'Tous',
    ratefrom: 'Mensualité dès',
    rateto: 'Mensualité jusqu’à',
    pricefrom: 'Prix à partir de',
    priceto: 'Prix jusqu’à',
    to: 'jusqu’à',
    monthly: '/mois',
};
