import {
    VehicleMarketPlatformDetailStyleData,
    provideVehicleMarketDetailStyle,
} from 'libs/ui/src/lib/pages/vehicle-market/vehicle-market-detail/styles';

const style: VehicleMarketPlatformDetailStyleData = {
    brand: 'opel',
    container: 'w-full max-w-1280 mx-auto lg:flex lg:justify-between lg:gap-x-24 lg:mt-32',
    ctaWrapper: 'px-16 lg:px-0 lg:flex-grow-0 lg:flex-shrink',
    gallery: {
        container: 'xl:flex-grow xl:flex-shrink-0 xl:max-w-800 lg:min-w-690',
        hardFacts: {
            container1: 'px-16 1.5xl:px-0 w-full',
            vehicle: {
                container: 'flex justify-between mb-16 mt-40 lg:pr-32',
                model: 'text-24 leading-22 font-semibold',
                shareIcon: {
                    size: 'w-32 h-32',
                    color: 'fill-light-text',
                },
            },
            container2: 'flex justify-between mb-16 lg:pr-18',
            seller: {
                text: 'text-14 leading-20 flex items-center mb-14 lg:mb-24 ',
                locationIcon: {
                    container: 'mr-12 lg:mr-15',
                    size: 'w-14 h-20',
                    color: 'fill-light-text',
                },
            },
            headerInfo: {
                container: 'text-14 leading-20',
                span: 'mr-16',
                button: 'lg:mb-3',
                buttonTextLeft: true,
            },
            actionTag: 'mt-16',
            price: {
                container: 'mt-8',
                label: 'text-right text-12 leading-16',
                value: 'text-right text-24 leading-32 font-semibold',
                currency: 'text-12',
                newPrice: 'font-semibold',
            },
            container3: '',
        },
    },

    promise: 'lg:w-full lg:bg-grey-100 ',
    highlightsHeadline: 'lg:hidden text-24 leading-28 font-light pt-24 pb-4 text-center',
    technicalInfo: {
        container: 'lg:flex lg:flex-row-reverse lg:justify-between lg:gap-x-5p lg:max-w-1280 lg:mx-auto lg:mt-70',
        tabs: 'block mt-40 lg:mt-0 lg:max-w-70p w-full max-w-full',
        warranty: 'mt-16 lg:mt-0 lg:-mx-16',
    },
    similarVehicles: 'mt-24 lg:mt-28 mb-24 lg:mb-0',
    similarVehiclesDarkCta: true,
    similarVehiclesCtaFirstArrow: true,
    teckData: {
        container: 'px-16 lg:px-0 pt-32 pb-24 lg:flex lg:justify-between lg:gap-x-24 lg:flex-wrap',
        vehicleProperties: 'lg:max-w-400',
        div: 'lg:max-w-400 lg:w-full flex-shrink-1',
        divider: 'lg:hidden mt-24 border-grey-200',
        envkv: 'text-24 leading-28 font-light my-24 lg:mt-0 lg:mb-32 text-center lg:text-left',
        ul: 'w-full',
        li: 'flex justify-between mb-18',
        label: 'text-16 leading-20 text-grey-300',
        value: 'text-16 leading-20 font-light text-right text-light-text',
        envkvProperties: 'lg:mb-24',
    },
    equipment: {
        container: 'px-16 lg:px-0 pt-32 pb-24',
        optional: {
            headline: 'text-24 text-light-text leading-28 font-light mb-24 lg:mt-8 lg:mb-16 pl-20 pr-16',
            ul: 'ml-20',
            li: 'font-primary text-light-text text-16 leading-24 hyphen-auto',
        },
        standard: {
            headline: 'text-24 text-light-text leading-28 font-light mt-40 mb-24 lg:mt-24 lg:mb-16 pl-20 pr-16',
            ul: 'ml-20',
            li: 'font-primary text-light-text text-16 leading-24 hyphen-auto',
        },
    },
    comment: {
        container: 'px-16 lg:px-0 pt-32 pb-24',
        value: 'text-light-text text-16 leading-20',
    },
    hardselling: {
        container: 'hidden lg:block mb-24 mt-8',
        topVehicle: {
            text: 'text-12 leading-16 text-accent flex items-center mb-4 lg:mr-16',
            icon: 'fill-accentWarn w-24 h-24',
        },
        topDeal: { text: 'text-12 leading-16 text-accentOk flex items-center', icon: 'fill-accentOk w-24 h-24' },
        box: {
            person: 'text-accentWarn font-semibold',
            wrapper: 'text-14 leading-22 bg-grey-100 px-16 py-8 text-light-text',
        },
    },
    vehicleHighlightsHeadlineContainer: 'bg-accentSecondary',
    singleLeasingOrMoveContainer: 'lg:bg-grey-180 lg:pt-55 lg:pb-70',
    singleLeasingOrMoveDiv: 'lg:max-w-1280 lg:px-110 lg:pt-35 lg:pb-65 w-full bg-white px-16 lg:mx-auto',
    section: '',
};

export const provideVehicleMarketDetailStyleDataOpel = () => provideVehicleMarketDetailStyle(style);
