import { CheckoutsSeoConfig, SeoReplaceValues } from 'libs/shared-lib/interfaces';
export const CHECKOUTS_SEO_FR: CheckoutsSeoConfig = {
    buy: {
        title: 'Acheter',
        description: '',
        image: '',
        twitter: {
            card: 'summary',
            title: 'Acheter',
            description: '',
        },
        og: {
            title: 'Acheter',
            description: '',
        },
        robots: {
            noIndex: true,
            noFollow: true,
        },
    },
    tradeIn: {
        title: `${SeoReplaceValues.DEALER_NAME}: Reprise de voiture ou vente, en ligne et à des conditions équitables`,
        description:
            'Vendre facilement sa voiture, ou bénéficier d’une reprise à l’achat d’une nouvelle Opel et profiter de conditions équitables. Demandez une offre !',
        image: '',
        twitter: {
            card: 'summary',
            title: `${SeoReplaceValues.DEALER_NAME}: Reprise de voiture ou vente, en ligne et à des conditions équitables`,
            description:
                'Vendre facilement sa voiture, ou bénéficier d’une reprise à l’achat d’une nouvelle Opel et profiter de conditions équitables. Demandez une offre !',
        },
        og: {
            title: `${SeoReplaceValues.DEALER_NAME}: Reprise de voiture ou vente, en ligne et à des conditions équitables`,
            description:
                'Vendre facilement sa voiture, ou bénéficier d’une reprise à l’achat d’une nouvelle Opel et profiter de conditions équitables. Demandez une offre !',
        },
        robots: {
            noIndex: false,
            noFollow: false,
        },
    },
    testDrive: {
        title: 'Essai sur route',
        description: '',
        image: '',
        twitter: {
            card: 'summary',
            title: 'Essai sur route',
            description: '',
        },
        og: {
            title: 'Essai sur route',
            description: '',
        },
        robots: {
            noIndex: true,
            noFollow: true,
        },
    },
    leasing: {
        title: 'Leasing',
        description: '',
        image: '',
        twitter: {
            card: 'summary',
            title: 'Leasing',
            description: '',
        },
        og: {
            title: 'Leasing',
            description: '',
        },
        robots: {
            noIndex: true,
            noFollow: true,
        },
    },
    service: {
        title: 'Prendre un rendez-vous',
        description: '',
        image: '',
        twitter: {
            card: 'summary',
            title: 'Prendre un rendez-vous',
            description: '',
        },
        og: {
            title: 'Prendre un rendez-vous',
            description: '',
        },
        robots: {
            noIndex: true,
            noFollow: true,
        },
    },
};
