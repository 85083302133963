import { PartnerWebEnvironment } from 'libs/shared-lib/interfaces/partner-web/environment';

export const environment: PartnerWebEnvironment = {
    production: true,
    wholesaleNestApiUrl: 'https://nest.wholesale.efg.prod.pixelgenau.dev',
    imgIXBaseUrlAutoScout: 'https://autoscout.imgix.net',
    imgIXBaseUrlCms: 'https://cmsimg.imgix.net',
    imgIXPartnerProfileAutoglobal: 'https://pp-autoglobal.imgix.net',
    baseUrlPartnerProfileAutoglobal:
        'https://partnerprofile.autoglobal.ch/backend/cdb_backend_ch/rest/public/v1/content',
    baseUrlAutoScout: 'https://cas01.autoscout24.ch',
    googleMapsApiKey: 'AIzaSyC_tXgVcECWV-3665eOq52GSjPNR8-tX5w',
    hardSellingApiUrl: 'wss://nest.wholesale.efg.prod.pixelgenau.dev',
    cmsAssetUrl: 'https://media.graphassets.com',
    fuseDeckId: '6XVTLiZCsY',
    fuseDeckUrl: 'https://io.fusedeck.net/d/tm.js',
};
