import { isPlatformServer } from '@angular/common';
import { Inject, inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Params, Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class UrlService {
    protected readonly router = inject(Router);

    constructor(@Inject(PLATFORM_ID) protected readonly platformId: string) {}

    // eslint-disable-next-line max-statements
    setUrlParams(params: Params, mergeParams = true) {
        if (isPlatformServer(this.platformId)) {
            return;
        }
        let newParamsString = '';
        const queryString = new URLSearchParams(window.location.search);
        let p = params;
        if (mergeParams) {
            p = { ...p, ...Object.fromEntries(queryString.entries()) };
        }
        for (const [key, value] of Object.entries(p)) {
            if (Array.isArray(value)) {
                for (const item of value) {
                    newParamsString += `${key.includes('[]') ? key : `${key}[]`}=${item}&`;
                }
            } else {
                newParamsString += `${key}=${value}&`;
            }
        }
        newParamsString = newParamsString.slice(0, -1);
        const url = new URL(window.location.href);
        url.search = encodeURI(newParamsString);
        window.history.pushState({}, '', url.toString());
    }

    getMergedUrlParams(newParams: Params, currentParams: Params): Params {
        // merge params with current filters, add new params and replace existing ones
        const result: Params = { ...currentParams };

        for (const [key, value] of Object.entries(newParams)) {
            if (key.endsWith('[]')) {
                const baseKey = key.slice(0, -2);
                if (result[baseKey] === value) {
                    delete result[baseKey];
                    delete result[key];
                } else {
                    result[key] = value;
                }
            } else {
                result[key] = value;
            }
        }

        return result;
    }

    rewriteZeroValueParams(): void {
        if (isPlatformServer(this.platformId)) {
            return;
        }
        const url = new URL(window.location.href);
        if (!url.search) {
            return;
        }
        url.search = url.search.replace(new RegExp(/%5B0%5D/), '%5B%5D');
        window.history.pushState({}, '', url.toString());
    }
}
