import { SeoReplaceValues } from 'libs/shared-lib/interfaces';
import { SeoVehicleMarket } from 'libs/shared-lib/interfaces/seo/seo-vehicle-market';

export const VEHICLE_MARKET_SEO_FR: SeoVehicleMarket = {
    vehicleNotAvailablePage: {
        title: 'Le véhicule n’a pas été trouvé',
        description: 'Le véhicule n’a pas été trouvé',
        image: '',
        twitter: {
            card: 'summary',
            title: 'Le véhicule n’a pas été trouvé',
            description: 'Le véhicule n’a pas été trouvé',
        },
        og: {
            title: 'Le véhicule n’a pas été trouvé',
            description: 'Le véhicule n’a pas été trouvé',
        },
        robots: {
            noIndex: true,
            noFollow: true,
        },
    },
    vehicleMarket: {
        list: {
            title: `${SeoReplaceValues.DEALER_NAME}: Trouver en ligne des occasions et des modèles de démonstration Opel et acheter`,
            description:
                'Trouver en ligne des voitures de démonstration et des occasions Opel et acheter. Moteurs thermiques, hybrides, hybrides rechargeables et 100 % électriques. Découvrez-les maintenant !',
            image: '',
            twitter: {
                card: 'summary',
                title: `${SeoReplaceValues.DEALER_NAME}: Trouver en ligne des occasions et des modèles de démonstration Opel et acheter`,
                description:
                    'Trouver en ligne des voitures de démonstration et des occasions Opel et acheter. Moteurs thermiques, hybrides, hybrides rechargeables et 100 % électriques. Découvrez-les maintenant !',
            },
            og: {
                title: `${SeoReplaceValues.DEALER_NAME}: Trouver en ligne des occasions et des modèles de démonstration Opel et acheter`,
                description:
                    'Trouver en ligne des voitures de démonstration et des occasions Opel et acheter. Moteurs thermiques, hybrides, hybrides rechargeables et 100 % électriques. Découvrez-les maintenant !',
            },
            robots: {
                noIndex: false,
                noFollow: false,
            },
        },
        detail: {
            title: `${SeoReplaceValues.VEHICLE_BRAND_NAME} ${SeoReplaceValues.VEHICLE_MODEL_NAME} : Réserver un essai sur route !`,
            description: `${SeoReplaceValues.VEHICLE_BRAND_NAME} ${SeoReplaceValues.VEHICLE_MODEL_NAME} ${SeoReplaceValues.VEHICLE_DOORS_COUNT} portes ${SeoReplaceValues.VEHICLE_BODY_TYPE} en ${SeoReplaceValues.VEHICLE_BODY_COLOR} vous attend pour un essai sur route. Prenez rendez-vous en ligne !`,
            image: '', // automatically replaced
            twitter: {
                card: 'summary',
                title: `${SeoReplaceValues.VEHICLE_BRAND_NAME} ${SeoReplaceValues.VEHICLE_MODEL_NAME} : Réserver un essai sur route !`,
                description: `${SeoReplaceValues.VEHICLE_BRAND_NAME} ${SeoReplaceValues.VEHICLE_MODEL_NAME} ${SeoReplaceValues.VEHICLE_DOORS_COUNT} portes ${SeoReplaceValues.VEHICLE_BODY_TYPE} en ${SeoReplaceValues.VEHICLE_BODY_COLOR} vous attend pour un essai sur route. Prenez rendez-vous en ligne !`,
            },
            og: {
                title: `${SeoReplaceValues.VEHICLE_BRAND_NAME} ${SeoReplaceValues.VEHICLE_MODEL_NAME} : Réserver un essai sur route !`,
                description: `${SeoReplaceValues.VEHICLE_BRAND_NAME} ${SeoReplaceValues.VEHICLE_MODEL_NAME} ${SeoReplaceValues.VEHICLE_DOORS_COUNT} portes ${SeoReplaceValues.VEHICLE_BODY_TYPE} en ${SeoReplaceValues.VEHICLE_BODY_COLOR} vous attend pour un essai sur route. Prenez rendez-vous en ligne !`,
            },
            robots: {
                noIndex: false,
                noFollow: false,
            },
        },
    },
    checkouts: {
        buy: {
            title: 'Acheter',
            description: '',
            image: '',
            twitter: {
                card: 'summary',
                title: 'Acheter',
                description: '',
            },
            og: {
                title: 'Acheter',
                description: '',
            },
            robots: {
                noIndex: true,
                noFollow: true,
            },
        },
        tradeIn: {
            title: `${SeoReplaceValues.DEALER_NAME}: Reprise de voiture ou vente, en ligne et à des conditions équitables`,
            description:
                'Vendre facilement sa voiture, ou bénéficier d’une reprise à l’achat d’une nouvelle Opel et profiter de conditions équitables. Demandez une offre !',
            image: '',
            twitter: {
                card: 'summary',
                title: `${SeoReplaceValues.DEALER_NAME}: Reprise de voiture ou vente, en ligne et à des conditions équitables`,
                description:
                    'Vendre facilement sa voiture, ou bénéficier d’une reprise à l’achat d’une nouvelle Opel et profiter de conditions équitables. Demandez une offre !',
            },
            og: {
                title: `${SeoReplaceValues.DEALER_NAME}: Reprise de voiture ou vente, en ligne et à des conditions équitables`,
                description:
                    'Vendre facilement sa voiture, ou bénéficier d’une reprise à l’achat d’une nouvelle Opel et profiter de conditions équitables. Demandez une offre !',
            },
            robots: {
                noIndex: false,
                noFollow: false,
            },
        },
        testDrive: {
            title: 'Essai sur route',
            description: '',
            image: '',
            twitter: {
                card: 'summary',
                title: 'Essai sur route',
                description: '',
            },
            og: {
                title: 'Essai sur route',
                description: '',
            },
            robots: {
                noIndex: true,
                noFollow: true,
            },
        },
        leasing: {
            title: 'Leasing',
            description: '',
            image: '',
            twitter: {
                card: 'summary',
                title: 'Leasing',
                description: '',
            },
            og: {
                title: 'Leasing',
                description: '',
            },
            robots: {
                noIndex: true,
                noFollow: true,
            },
        },
        service: {
            title: 'Prendre un rendez-vous',
            description: '',
            image: '',
            twitter: {
                card: 'summary',
                title: 'Prendre un rendez-vous',
                description: '',
            },
            og: {
                title: 'Prendre un rendez-vous',
                description: '',
            },
            robots: {
                noIndex: true,
                noFollow: true,
            },
        },
    },
};
