import { CheckoutsSeoConfig, SeoReplaceValues } from 'libs/shared-lib/interfaces';
export const CHECKOUTS_SEO_DE: CheckoutsSeoConfig = {
    buy: {
        title: 'Online kaufen',
        description: '',
        image: '',
        twitter: {
            card: 'summary',
            title: 'Online kaufen',
            description: '',
        },
        og: {
            title: 'Online kaufen',
            description: '',
        },
        robots: {
            noIndex: true,
            noFollow: true,
        },
    },
    tradeIn: {
        title: `${SeoReplaceValues.DEALER_NAME}: Auto eintauschen oder verkaufen, online und zu fairen Konditionen`,
        description:
            'Jetzt Ihr Auto einfach verkaufen, oder beim Kauf Ihres neuen Opels eintauschen und von fairen Konditionen profitieren. Jetzt Offerte anfragen!',
        image: '',
        twitter: {
            card: 'summary',
            title: `${SeoReplaceValues.DEALER_NAME}: Auto eintauschen oder verkaufen, online und zu fairen Konditionen`,
            description:
                'Jetzt Ihr Auto einfach verkaufen, oder beim Kauf Ihres neuen Opels eintauschen und von fairen Konditionen profitieren. Jetzt Offerte anfragen!',
        },
        og: {
            title: `${SeoReplaceValues.DEALER_NAME}: Auto eintauschen oder verkaufen, online und zu fairen Konditionen`,
            description:
                'Jetzt Ihr Auto einfach verkaufen, oder beim Kauf Ihres neuen Opels eintauschen und von fairen Konditionen profitieren. Jetzt Offerte anfragen!',
        },
        robots: {
            noIndex: false,
            noFollow: false,
        },
    },
    testDrive: {
        title: 'Probefahrt',
        description: '',
        image: '',
        twitter: {
            card: 'summary',
            title: 'Probefahrt',
            description: '',
        },
        og: {
            title: 'Probefahrt',
            description: '',
        },
        robots: {
            noIndex: true,
            noFollow: true,
        },
    },
    leasing: {
        title: 'Leasing',
        description: '',
        image: '',
        twitter: {
            card: 'summary',
            title: 'Leasing',
            description: '',
        },
        og: {
            title: 'Leasing',
            description: '',
        },
        robots: {
            noIndex: true,
            noFollow: true,
        },
    },
    service: {
        title: 'Terminvereinbarung',
        description: '',
        image: '',
        twitter: {
            card: 'summary',
            title: 'Terminvereinbarung',
            description: '',
        },
        og: {
            title: 'Terminvereinbarung',
            description: '',
        },
        robots: {
            noIndex: true,
            noFollow: true,
        },
    },
};
