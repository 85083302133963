import { Params, Routes } from '@angular/router';
import { dealerFeature } from 'libs/ng-common-lib/src/+state/dealer.reducer';
import { ngrxLoaderGuard } from 'libs/ng-common-lib/src/guards/ngrx-loader.guard';
import { PAGE_TYPE } from 'libs/shared-lib/interfaces/global/tracking';
import { WholesaleLanguage } from 'libs/shared-lib/language/language';
import {
    CheckoutsSlugs,
    getBuyVehicleUrl,
    getLeasingVehicleUrl,
    getServiceFormPageUrl,
    getTestDriveVehicleUrl,
    getTradeInUrl,
} from 'libs/shared-lib/routing/checkouts';
import { getNotFoundUrl } from 'libs/shared-lib/slugs/not-found.slug';
import { vehicleActions } from 'libs/vehicle-market-lib/src/lib/+state/vehicle.actions';
import { vehicleFeature } from 'libs/vehicle-market-lib/src/lib/+state/vehicle.reducer';
import { map } from 'rxjs';

// eslint-disable-next-line max-lines-per-function
export function getCheckoutsRoutes(lang: WholesaleLanguage, serviceForm: boolean): Routes {
    return [
        {
            path: CheckoutsSlugs[lang].buy,
            children: [
                {
                    path: ':slug',
                    loadComponent: () => import('../checkouts/buy-page/buy-page.component'),
                    canActivate: [
                        ngrxLoaderGuard({
                            actionToDispatch: (route) =>
                                vehicleActions.loadVehicleDetail({ slug: route.params['slug'] }),
                            waitUntilLoadedSelector: vehicleFeature.selectVehicleDetailLoading,
                            dataToCheckSelector: vehicleFeature.selectActiveVehicle,
                            redirectOnFailUrl: (route, store) =>
                                store
                                    .select(dealerFeature.selectActiveDealer)
                                    .pipe(map((dealer) => getNotFoundUrl(lang, dealer.slug))),
                            actionToDispatchOnSuccess: vehicleActions.loadAdditionalPackages(),
                        }),
                    ],
                    data: {
                        pageType: PAGE_TYPE.CHECKOUT,
                        languageSwitchUrlGenerator: (lang: WholesaleLanguage, route: Params) =>
                            getBuyVehicleUrl(lang, route['slug'], route['dealerSlug']),
                    },
                },
            ],
        },
        {
            path: CheckoutsSlugs[lang].tradeIn,
            children: [
                {
                    path: '',
                    loadComponent: () => import('../checkouts/trade-in-page/trade-in-page.component'),
                    data: {
                        pageType: PAGE_TYPE.FORM,
                        languageSwitchUrlGenerator: (lang: WholesaleLanguage, route: Params) =>
                            getTradeInUrl(lang, route['dealerSlug']),
                    },
                },
            ],
        },
        {
            path: CheckoutsSlugs[lang].testDrive,
            children: [
                {
                    path: ':slug',
                    loadComponent: () => import('../checkouts/test-drive-page/test-drive-page.component'),
                    canActivate: [
                        ngrxLoaderGuard({
                            actionToDispatch: (route) =>
                                vehicleActions.loadVehicleDetail({ slug: route.params['slug'] }),
                            waitUntilLoadedSelector: vehicleFeature.selectVehicleDetailLoading,
                            dataToCheckSelector: vehicleFeature.selectActiveVehicle,
                            redirectOnFailUrl: (route, store) =>
                                store
                                    .select(dealerFeature.selectActiveDealer)
                                    .pipe(map((dealer) => getNotFoundUrl(lang, dealer.slug))),
                        }),
                    ],
                    data: {
                        pageType: PAGE_TYPE.CHECKOUT,
                        languageSwitchUrlGenerator: (lang: WholesaleLanguage, route: Params) =>
                            getTestDriveVehicleUrl(lang, route['slug'], route['dealerSlug']),
                    },
                },
            ],
        },
        {
            path: CheckoutsSlugs[lang].leasing,
            children: [
                {
                    path: ':slug',
                    loadComponent: () => import('../checkouts/leasing/leasing-page.component'),
                    canActivate: [
                        ngrxLoaderGuard({
                            actionToDispatch: (route) =>
                                vehicleActions.loadVehicleDetail({ slug: route.params['slug'] }),
                            waitUntilLoadedSelector: vehicleFeature.selectVehicleDetailLoading,
                            dataToCheckSelector: vehicleFeature.selectActiveVehicle,
                            redirectOnFailUrl: (route, store) =>
                                store
                                    .select(dealerFeature.selectActiveDealer)
                                    .pipe(map((dealer) => getNotFoundUrl(lang, dealer.slug))),
                            actionToDispatchOnSuccess: vehicleActions.loadAdditionalPackages(),
                        }),
                    ],
                    data: {
                        languageSwitchUrlGenerator: (lang: WholesaleLanguage, route: Params) =>
                            getLeasingVehicleUrl(lang, route['slug']),
                    },
                },
            ],
        },
        ...(serviceForm
            ? [
                  {
                      path: CheckoutsSlugs[lang].service,
                      loadComponent: () => import('../checkouts/service-form-page/service-form-page.component'),
                      data: {
                          pageType: PAGE_TYPE.FORM,
                          languageSwitchUrlGenerator: (lang: WholesaleLanguage, route: Params) =>
                              getServiceFormPageUrl(lang, route['dealerSlug']),
                      },
                  },
              ]
            : []),
    ];
}
