import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { dealerFeature } from 'libs/ng-common-lib/src/+state/dealer.reducer';
import { wholesaleActions } from 'libs/ng-common-lib/src/+state/wholesale.actions';
import { wholesaleFeature } from 'libs/ng-common-lib/src/+state/wholesale.reducer';
import { AdditionalPackagesService } from 'libs/ng-common-lib/src/services/additional-packages.service';
import { WNestApiService } from 'libs/ng-common-lib/src/services/wholesale-nest-api';
import { FilterTagsLabels } from 'libs/shared-lib/interfaces/vehicle-market/vehicle-market-list';
import { AggregatedRequest as AggregatedDetailRequest, Car } from 'libs/shared-lib/interfaces/vehicle-market/w-car';
import { AggregatedRequest } from 'libs/shared-lib/interfaces/vehicle-market/w-cars';
import { catchError, concatMap, exhaustMap, map, switchMap } from 'rxjs/operators';
import { FilterTagsService } from '../services/filter-tags.service';
import { filterFeature } from './filter.reducer';
import { vehicleActions } from './vehicle.actions';
import { vehicleFeature } from './vehicle.reducer';

@Injectable()
export class VehicleEffects {
    constructor(
        private actions$: Actions,
        protected readonly api: WNestApiService,
        protected readonly store: Store,
        protected readonly filterTagsService: FilterTagsService,
        protected readonly additionalsService: AdditionalPackagesService,
    ) {}

    loadVehicleDetail$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(vehicleActions.loadVehicleDetail),
            concatLatestFrom(() => [
                this.store.select(wholesaleFeature.selectLanguage),
                this.store.select(dealerFeature.selectActiveDealer),
            ]),
            switchMap(([action, lang, dealer]) => {
                if (action.slug) {
                    const slug = action.slug.split('-').pop() as string;
                    // const vehicleId = Number(slug);
                    // Check if the vehicle is already set as activeVehicle
                    // if (currentActiveVehicle && vehicleId === currentActiveVehicle.id) {
                    //     return [vehicleActions.activeVehicleAlreadySet()];
                    // }
                    // Load the vehicle from the API
                    const detailRequest: AggregatedDetailRequest = { id: Number(slug) };
                    // Check if the dealerName is set in the routeParams
                    if (dealer) {
                        detailRequest['dealerSlug'] = dealer.slug;
                    }
                    return this.api.getVehicle(detailRequest, lang).pipe(
                        concatMap((response) =>
                            response.error === true
                                ? [
                                      vehicleActions.loadVehicleDetailFailure({
                                          error: 'Could not load vehicle detail data',
                                      }),
                                      wholesaleActions.vehilceNotAvailable(),
                                  ]
                                : [vehicleActions.loadVehicleDetailSuccess({ vehicle: response.vehicle })],
                        ),
                    );
                }
                return [];
            }),
        );
    });

    initVehicleMarketList$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(vehicleActions.initVehicleMarketList),
            exhaustMap((action) => [
                vehicleActions.initVehicleMarketListCompleted({ filterTagLabels: action.filterTagLabels }),
                vehicleActions.loadSearchCriteria(),
            ]),
        );
    });

    setRequestCount$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(vehicleActions.requestCount),
            concatLatestFrom(() => [this.store.select(dealerFeature.selectActiveDealer)]),
            concatMap(([action, activeDealer]) => {
                let aggregatedFilter = { ...action.filter };
                if (activeDealer) {
                    aggregatedFilter = {
                        ...aggregatedFilter,
                        dealerId: [
                            activeDealer.dealerId.toString(),
                            ...activeDealer.subDealers.map((d) => d.dealerId),
                            ...(activeDealer.vehicleSourceDealerIds || []),
                        ],
                        dealerSlug: activeDealer.slug,
                    } as AggregatedRequest;
                }
                return this.api.getVehicleCount({ ...aggregatedFilter }).pipe(
                    switchMap(async (response) => {
                        return !response.error
                            ? vehicleActions.requestCountSuccess({
                                  count: response.totalMatches,
                              })
                            : vehicleActions.requestCountError({ error: 'Could not load vehicle count' });
                    }),
                );
            }),
        );
    });

    initFilterTagsAfterVehicleLoad$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(vehicleActions.loadVehiclesSuccess),
            concatLatestFrom(() => [this.store.select(vehicleFeature.selectFilterTagLabels)]),
            exhaustMap(([, labels]) => [vehicleActions.getFilterTags({ labels: labels as FilterTagsLabels })]),
        );
    });

    initFilterTagsAfterSearchCriteriaLoad$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(vehicleActions.loadSearchCriteriaSuccess),
            concatLatestFrom(() => [this.store.select(vehicleFeature.selectFilterTagLabels)]),
            exhaustMap(([, labels]) => [vehicleActions.getFilterTags({ labels: labels as FilterTagsLabels })]),
        );
    });

    loadVehicles$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(vehicleActions.loadVehicles),
            concatLatestFrom(() => [
                this.store.select(filterFeature.selectVehicleMarketFilter),
                this.store.select(wholesaleFeature.selectLanguage),
                this.store.select(vehicleFeature.selectFirstFetch),
                this.store.select(vehicleFeature.selectLimit),
                this.store.select(dealerFeature.selectActiveDealer),
            ]),
            concatMap(([, filter, lang /*firstFetch*/, , limit, activeDealer]) => {
                const appendVehicles = !!filter.page;
                // if (firstFetch && filter.page && filter.page !== 1) {
                //     // if first fetch and page filter is set
                //     limit = limit * filter.page;
                //     filter = {
                //         ...filter,
                //         page: 1
                //     }
                // }
                if (activeDealer) {
                    filter = {
                        ...filter,
                        dealerId: [
                            activeDealer.dealerId.toString(),
                            ...activeDealer.subDealers.map((d) => d.dealerId),
                            ...(activeDealer.vehicleSourceDealerIds || []),
                        ],
                        dealerSlug: activeDealer.slug,
                    } as AggregatedRequest;
                }
                return this.api.getVehicles({ ...filter, limit }, lang).pipe(
                    switchMap(async (response) => {
                        return !response.error
                            ? vehicleActions.loadVehiclesSuccess({
                                  vehicleMarketListData: response,
                                  appendVehicles,
                              })
                            : vehicleActions.loadVehiclesFailure({ error: 'Could not load vehicles' });
                    }),
                );
            }),
        );
    });

    loadSearchCriteria$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(vehicleActions.loadSearchCriteria),
            concatLatestFrom(() => [
                this.store.select(wholesaleFeature.selectLanguage),
                this.store.select(dealerFeature.selectActiveDealer),
            ]),
            concatMap(([, lang, dealer]) => {
                const filter: AggregatedRequest = {};
                if (dealer) {
                    filter.dealerId = [dealer.dealerId.toString(), ...dealer.subDealers.map((d) => d.dealerId)];
                }
                return this.api.getSearchCriteria(filter, lang).pipe(
                    switchMap(async (response) => {
                        return !response.error
                            ? vehicleActions.loadSearchCriteriaSuccess({
                                  searchCriteria: { sorting: response.sorting, filter: response.filter },
                              })
                            : vehicleActions.loadSearchCriteriaFailure({ error: 'Could not load search criteria' });
                    }),
                );
            }),
        );
    });

    getFilterTags$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(vehicleActions.getFilterTags),
            concatLatestFrom(() => [
                this.store.select(filterFeature.selectVehicleMarketFilter),
                this.store.select(vehicleFeature.selectSearchCriteria),
                this.store.select(wholesaleFeature.selectLanguage),
            ]),
            map(([actions, activeFilter, searchCriteria, lang]) => {
                if (!activeFilter || !searchCriteria) {
                    return vehicleActions.noFilterTags();
                }
                const filterTagsToSet = this.filterTagsService.getFilterTags(
                    activeFilter,
                    searchCriteria,
                    lang,
                    actions.labels,
                );
                return vehicleActions.filterTagsSet({ tags: filterTagsToSet });
            }),
        );
    });

    removeFilterTag$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(vehicleActions.removeFilterTag),
            concatLatestFrom(() => [this.store.select(vehicleFeature.selectFilterTags)]),
            map(([actions, activeFilterTags]) => {
                if (!activeFilterTags) {
                    return vehicleActions.noFilterTags();
                }
                const filterTagsToSet = this.filterTagsService.removeFilterTag(activeFilterTags, actions.tag);
                return vehicleActions.filterTagsSet({ tags: filterTagsToSet });
            }),
        );
    });

    loadAdditionalPackages$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(vehicleActions.loadAdditionalPackages),
            concatLatestFrom(() => [
                this.store.select(vehicleFeature.selectActiveVehicle),
                this.store.select(wholesaleFeature.selectLanguage),
            ]),
            switchMap(([, vehicle, locale]) => {
                if (!vehicle) {
                    return [];
                }
                return this.additionalsService.getAdditionalPackages(vehicle, locale).pipe(
                    map((response) =>
                        vehicleActions.loadAdditionalPackagesSuccess({
                            additionals: response,
                        }),
                    ),
                );
            }),
        );
    });

    loadTopCars$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(vehicleActions.loadTopCars),
            concatLatestFrom(() => [
                this.store.select(wholesaleFeature.selectLanguage),
                this.store.select(dealerFeature.selectActiveDealer),
            ]),
            concatMap(([action, lang, dealer]) => {
                return this.api.getTopCars({ ...action.queries }, lang, dealer.slug).pipe(
                    switchMap(async (response) => {
                        return !response
                            ? vehicleActions.loadTopCarsSuccess({ topCars: response })
                            : vehicleActions.loadTopCarsFailure({ error: 'Could not load vehicles' });
                    }),
                );
            }),
        );
    });

    loadServicePromotionTopCars$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(vehicleActions.loadPromotionTopCars),
            concatLatestFrom(() => [
                this.store.select(wholesaleFeature.selectLanguage),
                this.store.select(dealerFeature.selectActiveDealer),
            ]),
            concatMap(([action, lang, dealer]) => {
                return this.api
                    .getTopCars(
                        {
                            ...action.queries,
                            dealerId: [dealer.dealerId.toString(), ...dealer.subDealers.map((d) => d.dealerId)],
                        },
                        lang,
                        dealer.slug,
                    )
                    .pipe(
                        switchMap(async (response: Car[]) => {
                            return vehicleActions.loadPromotionTopCarsSuccess({ topCars: response });
                        }),
                    );
            }),
        );
    });

    loadServicePromotionCarsList$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(vehicleActions.loadPromotionCarsList),
            concatLatestFrom(() => [
                this.store.select(wholesaleFeature.selectLanguage),
                this.store.select(dealerFeature.selectActiveDealer),
            ]),
            concatMap(([action, lang, dealer]) => {
                const queries = { ...action.queries, limit: 6, dealerSlug: dealer.slug } as AggregatedRequest;
                return this.api
                    .getVehicles(
                        {
                            ...queries,
                            dealerId: [dealer.dealerId.toString(), ...dealer.subDealers.map((d) => d.dealerId)],
                        },
                        lang,
                    )
                    .pipe(
                        switchMap(async (response) => {
                            return !response.error
                                ? vehicleActions.loadPromotionCarsListSuccess({
                                      promotionCarsListData: response,
                                      appendVehicles: response.currentPage > 1,
                                  })
                                : vehicleActions.loadPromotionCarsListFailure({
                                      error: 'Could not load vehicles',
                                  });
                        }),
                    );
            }),
        );
    });

    loadConfiguration$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(vehicleActions.loadConfiguration),
            concatLatestFrom(() => [this.store.select(wholesaleFeature.selectLanguage)]),
            switchMap(([action, lang]) => this.api.getConfiguration(action.slug, lang)),
            map((configuration) => vehicleActions.loadConfigurationSuccess({ configuration })),
            catchError((error) => [vehicleActions.loadConfigurationFailure({ error: error.message })]),
        );
    });
}
