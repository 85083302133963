import { SeoReplaceValues } from 'libs/shared-lib/interfaces';
import { ServicesSeoConfig } from 'libs/shared-lib/interfaces/seo/seo-partner-web';
export const SERVICE_SEO_IT: ServicesSeoConfig = {
    list: {
        title: `${SeoReplaceValues.DEALER_NAME}: I nostri Servizi`,
        description: '',
        image: '',
        twitter: {
            card: 'summary',
            title: `${SeoReplaceValues.DEALER_NAME}: I nostri Servizi`,
            description: '',
        },
        og: {
            title: `${SeoReplaceValues.DEALER_NAME}: I nostri Servizi`,
            description: '',
        },
        robots: {
            noIndex: false,
            noFollow: false,
        },
    },
    detail: {
        title: 'Service Detail',
        description: '',
        image: '',
        twitter: {
            card: 'summary',
            title: 'service-detail',
            description: '',
        },
        og: {
            title: 'Service Detail',
            description: '',
        },
        robots: {
            noIndex: false,
            noFollow: false,
        },
    },
};
