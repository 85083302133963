import { Injectable, inject } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { dealerSearchActions } from 'libs/dealer-search-lib/src/lib/+state/dealer-search.actions';
import { dealerSearchFeature } from 'libs/dealer-search-lib/src/lib/+state/dealer-search.reducer';
import { dealerActions } from 'libs/ng-common-lib/src/+state/dealer.actions';
import { dealerFeature } from 'libs/ng-common-lib/src/+state/dealer.reducer';
import { wholesaleFeature } from 'libs/ng-common-lib/src/+state/wholesale.reducer';
import { GoogleMapsService } from 'libs/ng-common-lib/src/services/google-maps/google-maps.service';
import { WNestApiService } from 'libs/ng-common-lib/src/services/wholesale-nest-api';
import { Filter as DealerSearchCriteria } from 'libs/shared-lib/interfaces/vehicle-market/w-dealer-search-criteria';
import { DealerListDto } from 'libs/shared-lib/interfaces/w-nest';
import { exhaustMap, forkJoin, map, switchMap } from 'rxjs';
@Injectable()
export class DealerSearchEffects {
    protected readonly wNestApiService = inject(WNestApiService);
    protected readonly actions$ = inject(Actions);
    protected readonly store = inject(Store);
    protected readonly googleMapsService = inject(GoogleMapsService);

    /**
     * Wenn suchfilter eingegeben wird wird die autocomplete action getriggert
     */
    search$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(dealerSearchActions.searchInput),
            exhaustMap(() => {
                return [dealerSearchActions.loadAutocomplete(), dealerSearchActions.resetResultDealers()];
            }),
        );
    });

    /**
     * Wenn Suchfilter eingegeben wird werden autocomplete Daten geladen werden
     */
    loadAutocomplete$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(dealerSearchActions.loadAutocomplete),
            concatLatestFrom(() => [
                this.store.select(dealerSearchFeature.selectSearch),
                this.store.select(dealerFeature.selectDealerList),
            ]),
            switchMap(([, filter, dealers]) => {
                if (!filter) return [];

                const filterDealers = dealers?.filter((dealer) => {
                    const dataStr = Object.keys({
                        name: dealer.name,
                        zip: dealer.zip,
                        city: dealer.city,
                        phone: dealer.phone,
                        slug: dealer.slug,
                        street: dealer.street,
                        email: dealer.email,
                        pname: dealer.parentDealer?.name ?? '',
                        pslug: dealer.parentDealer?.slug ?? '',
                    })
                        .reduce((currentTerm, key) => {
                            return `${currentTerm + dealer[key as keyof DealerListDto]}◬`;
                        }, '')
                        .toLowerCase();
                    return dataStr.includes(filter.toLowerCase());
                });
                return this.googleMapsService.getPlacePredictions({ input: filter }).pipe(
                    switchMap((predictions) => {
                        const locations = predictions.results.map((r) => {
                            return { id: r.place_id, label: r.description };
                        });
                        return [
                            dealerSearchActions.loadAutocompleteSuccess({
                                locations,
                                garages: filterDealers ?? [],
                            }),
                        ];
                    }),
                );
            }),
        );
    });

    resetSearchInput$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(dealerSearchActions.resetSearchInput),
            exhaustMap(() => {
                return [dealerSearchActions.resetAutocomplete()];
            }),
        );
    });

    resultDealersSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(dealerSearchActions.resultDealersSuccess),
            exhaustMap(() => {
                return [dealerSearchActions.dealerHoveredEnded()];
            }),
        );
    });

    dealersLoaded$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(dealerActions.loadDealerListSuccess),
            exhaustMap(() => {
                return [dealerSearchActions.loadMapMarkers()];
            }),
        );
    });

    searchCriteriaLoaded$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(dealerSearchActions.loadDealerSearchCriteria),
            concatLatestFrom(() => [this.store.select(wholesaleFeature.selectLanguage)]),
            switchMap(([, local]) => {
                return this.wNestApiService.getDealerSearchCriteria(local).pipe(
                    map((data) => {
                        if (data.error) {
                            return { error: true, filter: [] as DealerSearchCriteria[] };
                        } else {
                            const { filter } = data;
                            return { error: false, filter };
                        }
                    }),
                );
            }),
            exhaustMap(({ error, filter }) => {
                if (!error) {
                    return [
                        dealerSearchActions.loadDealerSearchCriteriaSuccess({
                            searchCriteria: filter,
                        }),
                    ];
                } else {
                    return [
                        dealerSearchActions.loadDealerSearchCriteriaSuccess({
                            searchCriteria: [],
                        }),
                    ];
                }
            }),
        );
    });

    filteredDealersLoaded$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(dealerSearchActions.resultDealersSuccess),
            exhaustMap(() => {
                return [dealerSearchActions.loadMapMarkers()];
            }),
        );
    });

    loadMapMarker$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(dealerSearchActions.loadMapMarkers),
            concatLatestFrom(() => [
                this.store.select(dealerSearchFeature.selectResultDealers),
                this.store.select(dealerFeature.selectDealerList),
                this.store.select(dealerSearchFeature.selectFilter),
            ]),
            switchMap(([, filteredDealers, dealers, filter]) => {
                if (!dealers) return [];

                const activeFilter = filter.filter((f) => f.length > 0).length > 0;

                const mappedDealers = (activeFilter && filteredDealers ? filteredDealers : dealers).map((dealer) => {
                    return { dealer: dealer, lat: dealer.latitude, lng: dealer.longitude };
                });

                const zoomDealers = filteredDealers
                    ? filteredDealers.map((dealer) => {
                          return { dealer: dealer, lat: dealer.latitude, lng: dealer.longitude };
                      })
                    : mappedDealers;

                return [
                    dealerSearchActions.loadMapMarkersSuccess({ mapMarkers: mappedDealers, zoomMarkers: zoomDealers }),
                ];
            }),
        );
    });

    setResultDealers$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(dealerSearchActions.setResultDealer),
            switchMap((dealer) => [
                dealerSearchActions.resetAutocomplete(),
                dealerSearchActions.resultDealersSuccess({ resultDealers: [dealer] }),
            ]),
        );
    });

    setUserLocation$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(dealerSearchActions.setUserLocation),
            concatLatestFrom(({ userLocation }) => [
                this.store.select(dealerFeature.selectDealerList).pipe(
                    switchMap((dealers) => {
                        if (!dealers) return [];

                        const mappedDealers = dealers.map((dealer) => {
                            return this.googleMapsService
                                .calcDistanceBetween(userLocation, {
                                    lat: dealer.latitude,
                                    lng: dealer.longitude,
                                })
                                .pipe(
                                    map((distance) => {
                                        const dealerWithDistance: DealerListDto & { distance: number } = {
                                            ...dealer,
                                            distance: Math.round(distance / 1000),
                                        };
                                        return dealerWithDistance;
                                    }),
                                );
                        });
                        const forkedDealerDistances = forkJoin(mappedDealers);
                        return forkedDealerDistances;
                    }),
                ),
            ]),
            switchMap(([, dealers]) => {
                if (!dealers) {
                    return [dealerSearchActions.resetAutocomplete()];
                }

                return [
                    dealerSearchActions.resetAutocomplete(),
                    dealerSearchActions.resultDealersSuccess({
                        resultDealers: dealers.sort((a, b) => a.distance - b.distance),
                    }),
                ];
            }),
        );
    });

    // eslint-disable-next-line max-lines-per-function
    setInputLoaction$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(dealerSearchActions.setInputLoaction),

            switchMap(({ inputLocation, count }) => {
                return this.googleMapsService.getLatLngLiteralById(inputLocation.id).pipe(
                    map((data) => {
                        if (!data) {
                            throw new Error('LatLng konnte nicht ermittelt werden anhand der ID');
                        }

                        return { ...data, count };
                    }),
                );
            }),
            concatLatestFrom((location) => {
                if (!location) throw new Error('Location could not be found');

                return [
                    this.store.select(dealerFeature.selectDealerList).pipe(
                        switchMap((dealers) => {
                            if (!dealers) return [];

                            const mappedDealers = dealers.map((dealer) => {
                                return this.googleMapsService
                                    .calcDistanceBetween(location, {
                                        lat: dealer.latitude,
                                        lng: dealer.longitude,
                                    })
                                    .pipe(
                                        map((distance) => {
                                            const dealerWithDistance: DealerListDto & { distance: number } = {
                                                ...dealer,
                                                distance: Math.round(distance / 1000),
                                            };
                                            return dealerWithDistance;
                                        }),
                                    );
                            });

                            const forkedDealerDistances = forkJoin(mappedDealers);
                            return forkedDealerDistances;
                        }),
                    ),
                ];
            }),
            switchMap(([inputLoaction, dealers]) => {
                if (!dealers) {
                    return [dealerSearchActions.resetAutocomplete()];
                }

                return [
                    dealerSearchActions.resetAutocomplete(),
                    dealerSearchActions.resultDealersSuccess({
                        resultDealers: dealers.sort((a, b) => a.distance - b.distance).splice(0, inputLoaction.count),
                    }),
                ];
            }),
        );
    });

    setFilter$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(dealerSearchActions.filterDealer),
            concatLatestFrom(() => {
                return [this.store.select(dealerSearchFeature.selectFilteredResultDealers)];
            }),
            switchMap(([, dealers]) => {
                return [dealerSearchActions.resultDealersSuccess({ resultDealers: dealers })];
            }),
        );
    });

    resultDealers$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(dealerSearchActions.setResultDealer),
            switchMap((dealer) => [
                dealerSearchActions.resetAutocomplete(),
                dealerSearchActions.resultDealersSuccess({ resultDealers: [dealer] }),
            ]),
        );
    });

    resetDealers$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(dealerSearchActions.resetResultDealers),
            switchMap(() => [dealerSearchActions.loadMapMarkers()]),
        );
    });
    resetFilter$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(dealerSearchActions.resetFilterDealer),
            switchMap(() => [dealerSearchActions.resetResultDealers()]),
        );
    });
}
