import { SeoPage } from 'libs/shared-lib/interfaces';

export const PROMOTION_NOT_AVAILABLE_SEO_IT: SeoPage = {
    title: 'Promozione non trovata / Promozione non disponibile',
    description: 'Non è stato possibile trovare la promozione.',
    image: '',
    twitter: {
        card: 'summary',
        title: 'Promozione non trovata / Promozione non disponibile',
        description: 'Non è stato possibile trovare la promozione.',
    },
    og: {
        title: 'Promozione non trovata / Promozione non disponibile',
        description: 'Non è stato possibile trovare la promozione.',
    },
    robots: {
        noIndex: true,
        noFollow: true,
    },
};
