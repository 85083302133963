import { SeoPage } from 'libs/shared-lib/interfaces';
export const NOT_FOUND_SEO_DE: SeoPage = {
    title: '404',
    description: 'Die von Ihnen angeforderte Seite existiert leider nicht.',
    image: '',
    twitter: {
        card: 'summary',
        title: '404',
        description: 'Die von Ihnen angeforderte Seite existiert leider nicht.',
    },
    og: {
        title: '404',
        description: 'Die von Ihnen angeforderte Seite existiert leider nicht.',
    },
    robots: {
        noIndex: true,
        noFollow: true,
    },
};
