import { LazyElementConfig } from '@pixelgenau/ngx-px-lazy-awesome/';

import * as m050c040mainnavigationpw from 'libs/ui/src/lib/modules/050-navigation-footer-overlays/m050-c040-mainnavigation-pw/m050-c040-mainnavigation-pw.lazy';
import * as m050c050footerpw from 'libs/ui/src/lib/modules/050-navigation-footer-overlays/m050-c050-footer-pw/m050-c050-footer-pw.lazy';
import * as m050c055footerpwtoyota from 'libs/ui/src/lib/modules/050-navigation-footer-overlays/m050-c055-footer-pw-toyota/m050-c055-footer-pw-toyota.lazy';
import * as m050c080footerOpelVehicleMarket from 'libs/ui/src/lib/modules/050-navigation-footer-overlays/m050-c080-footer-opel-vehicle-market/m050-c080-footer-opel-vehicle-market.lazy';
import * as m050c050footerDealerSearch from 'libs/ui/src/lib/modules/050-navigation-footer-overlays/m050-c090-footer-dealer-search/m050-c090-footer-dealer-search.lazy';
import * as ctaAlternative from 'libs/ui/src/lib/modules/060-vehicle-modules/m060-c110-cta-alternative/m060-c110-cta-alternative.lazy';
import * as cta from 'libs/ui/src/lib/modules/060-vehicle-modules/m060-c110-cta/m060-c110-cta.lazy';
import * as leasingCheckout from 'libs/ui/src/lib/pages/checkouts/leasing/leasing.lazy';
import * as locationsTemplate from 'libs/ui/src/lib/pages/locations/locations.lazy';
import * as notFoundPageTemplate from 'libs/ui/src/lib/pages/not-found-page-template/not-found-page-template.lazy';
import * as serviceDetailTemplate from 'libs/ui/src/lib/pages/service/service-detail-template/service-detail-template.lazy';
import * as serviceListTemplate from 'libs/ui/src/lib/pages/service/service-list-template/service-list-template.lazy';
import * as serviceTemplate from 'libs/ui/src/lib/pages/service/service-template.lazy';
import * as vehicleMarketListTemplate from 'libs/ui/src/lib/pages/vehicle-market/vehicle-market-list/vehicle-market-list.lazy';
import * as vehicleNotAvailablePageTemplate from 'libs/ui/src/lib/pages/vehicle-not-available-page-template/vehicle-not-available-page-template.lazy';

export const LAZY_ELEMENTS: LazyElementConfig[] = [
    vehicleMarketListTemplate,
    leasingCheckout,
    serviceTemplate,
    m050c040mainnavigationpw,
    m050c050footerpw,
    m050c055footerpwtoyota,
    m050c080footerOpelVehicleMarket,
    locationsTemplate,
    serviceListTemplate,
    serviceDetailTemplate,
    notFoundPageTemplate,
    vehicleNotAvailablePageTemplate,
    m050c050footerDealerSearch,
    cta,
    ctaAlternative,
];
