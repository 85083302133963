import { Params, Routes } from '@angular/router';
import { dealerFeature } from 'libs/ng-common-lib/src/+state/dealer.reducer';
import { ngrxLoaderGuard } from 'libs/ng-common-lib/src/guards/ngrx-loader.guard';
import { PAGE_TYPE } from 'libs/shared-lib/interfaces/global/tracking';
import { WholesaleLanguage } from 'libs/shared-lib/language/language';
import {
    VehicleMarketPlatformSlugs,
    getVehicleMarketDetailUrl,
    getVehicleMarketListUrl,
    getVehicleNotAvailableUrl,
} from 'libs/shared-lib/slugs/vehicle-market.slugs';
import { map } from 'rxjs';
import { vehicleActions } from '../+state/vehicle.actions';
import { vehicleFeature } from '../+state/vehicle.reducer';

// eslint-disable-next-line max-lines-per-function
export function getVehicleMarketRoutes(lang: WholesaleLanguage): Routes {
    return [
        {
            path: VehicleMarketPlatformSlugs[lang].list,
            children: [
                {
                    path: '',
                    loadComponent: () => import('../pages/vehicle-market-list-page/vehicle-market-list-page.component'),
                    data: {
                        pageType: PAGE_TYPE.SERP,
                        languageSwitchUrlGenerator: (lang: WholesaleLanguage, route: Params) =>
                            getVehicleMarketListUrl(lang, route['dealerSlug']),
                    },
                },

                {
                    path: ':slug',
                    loadComponent: () =>
                        import('../pages/vehicle-market-detail-page/vehicle-market-detail-page.component'),
                    canActivate: [
                        ngrxLoaderGuard({
                            actionToDispatch: (route) =>
                                vehicleActions.loadVehicleDetail({ slug: route.params['slug'] }),
                            waitUntilLoadedSelector: vehicleFeature.selectVehicleDetailLoading,
                            dataToCheckSelector: vehicleFeature.selectActiveVehicle,
                            redirectOnFailUrl: (route, store) =>
                                store
                                    .select(dealerFeature.selectActiveDealer)
                                    .pipe(map((dealer) => getVehicleNotAvailableUrl(lang, dealer?.slug))),
                            redirectOnFailCode: 301,
                        }),
                    ],
                    data: {
                        pageType: PAGE_TYPE.VDP,
                        languageSwitchSelector: vehicleFeature.selectActiveVehicle,
                        languageSwitchUrlGenerator: (lang: WholesaleLanguage, route: Params) =>
                            getVehicleMarketDetailUrl(lang, route['slug'], route['dealerSlug']),
                    },
                },
            ],
        },
        {
            path: VehicleMarketPlatformSlugs[lang].notAvailable,
            loadComponent: () =>
                import(
                    'libs/ng-common-lib/src/components/vehicle-not-available-page/vehicle-not-available-page.component'
                ),
            data: {
                languageSwitchUrlGenerator: (lang: WholesaleLanguage, route: Params) =>
                    getVehicleNotAvailableUrl(lang, route['dealerSlug']),
            },
        },
    ];
}
