import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { REQUEST, RESPONSE } from 'libs/ng-common-lib/src/provider/express.token';
import { getHomeDealerUrl } from 'libs/shared-lib/slugs/home-pages.slug';
import { Observable, map, take } from 'rxjs';
import { dealerFeature } from '../+state/dealer.reducer';

export function dealerLanguageGuard(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    const store = inject(Store);
    const router = inject(Router);
    const response = inject(RESPONSE, { optional: true });
    const request = inject(REQUEST, { optional: true });

    return store.select(dealerFeature.selectActiveDealer).pipe(
        take(1),
        map((dealer) => {
            if (!dealer) {
                return false;
            }
            if (!route.data['locale']) {
                if (response && request) {
                    const params = new URLSearchParams((request?.query ?? {}) as unknown as string[][]);
                    const url = getHomeDealerUrl(dealer.defaultLocale, dealer.slug);
                    const urlWithParams = params.toString().length > 0 ? `${url}?${params.toString()}` : url;
                    response.redirect(302, urlWithParams);
                    return false;
                }
                return router.createUrlTree(['/', dealer.slug, dealer.defaultLocale]);
            }

            if (dealer.locales.includes(route.data['locale'])) {
                return true;
            }
            return router.createUrlTree(['/', dealer.slug, dealer.locales[0]]);
        }),
    );
}
