import { SeoPartnerWeb } from 'libs/shared-lib/interfaces/seo/seo-partner-web';
import { ABOUT_US_SEO_FR } from './about-us/about-us.fr.seo';
import { CHECKOUTS_SEO_FR } from './checkouts/checkouts.fr.seo';
import { HOME_SEO_FR } from './home/home.fr.seo';
import { LOCATIONS_SEO_FR } from './locations/locations.fr.seo';
import { NEWS_SEO_FR } from './news/news.fr.seo';
import { NOT_FOUND_SEO_FR } from './not-found/not-found.fr.seo';
import { PROMOTION_NOT_AVAILABLE_SEO_FR } from './promotion-not-available/promotion-not-available.fr.seo';
import { PROMOTIONS_SEO_FR } from './promotions/promotions.fr.seo';
import { SERVICE_SEO_FR } from './service/service.fr.seo';
import { SUB_DEALER_SEO_FR } from './sub-dealer/sub-dealer.fr.seo';
import { VEHICLE_NOT_AVAILABLE_SEO_FR } from './vehicle-not-available/vehicle-not-available.fr.seo';

export const PARTNER_WEB_SEO_FR: SeoPartnerWeb = {
    home: HOME_SEO_FR,
    checkouts: CHECKOUTS_SEO_FR,
    news: NEWS_SEO_FR,
    promotions: PROMOTIONS_SEO_FR,
    aboutUs: ABOUT_US_SEO_FR,
    service: SERVICE_SEO_FR,
    locations: LOCATIONS_SEO_FR,
    subDealer: SUB_DEALER_SEO_FR,
    notFound: NOT_FOUND_SEO_FR,
    vehicleNotAvailable: VEHICLE_NOT_AVAILABLE_SEO_FR,
    promotionNotAvailable: PROMOTION_NOT_AVAILABLE_SEO_FR,
};
