import { Injectable, inject } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { exhaustMap, filter, switchMap } from 'rxjs';

import { Dealer } from 'libs/shared-lib/interfaces/w-nest';
import { AboutUsService } from '../services/about-us.service';
import { aboutUsActions } from './about-us.actions';
import { dealerFeature } from './dealer.reducer';
import { wholesaleFeature } from './wholesale.reducer';

@Injectable()
export class AboutUsEffects {
    protected readonly actions$ = inject(Actions);
    protected readonly store = inject(Store);
    protected readonly aboutUsService: AboutUsService = inject(AboutUsService);

    loadAboutUsByDealer$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(aboutUsActions.loadAboutUs),
            concatLatestFrom(() => [
                this.store.select(dealerFeature.selectActiveDealer),
                this.store.select(dealerFeature.selectActiveSubDealer),
                this.store.select(wholesaleFeature.selectLanguage),
            ]),
            filter(([, dealer, subDealer]) => {
                return !!dealer || !!subDealer;
            }),
            switchMap(([, dealer, subDealer, locale]) => {
                return this.aboutUsService
                    .loadAboutUsByDealer((dealer as Dealer).dealerId, locale, subDealer?.dealerId)
                    .pipe(
                        exhaustMap((aboutUs) => {
                            if (aboutUs) {
                                return [aboutUsActions.loadAboutUsSuccess({ aboutUs })];
                            } else {
                                return [aboutUsActions.loadAboutUsFailure({ error: 'TODO 🔥 SOME ERROR' })];
                            }
                        }),
                    );
            }),
        );
    });
}
