import { ChangeDetectionStrategy, Component, inject, ViewEncapsulation } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { SVGAwesomeDirective } from '@pixelgenau/ngx-px-svg-awesome/browser';
import { ZonelessRouting } from 'libs/ng-common-lib/src/utilities/zoneless/zone-less-routing.service';
import { M010C080PhoneRibbonComponent } from 'libs/ui/src/lib/modules/010-buttons-and-links/m010-c080-phone-ribbon/m010-c080-phone-ribbon.component';

@Component({
    selector: 'wholesale-root',
    template: `
        <div class="max-w-1920 mx-auto">
            <router-outlet />
        </div>
    `,
    standalone: true,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [M010C080PhoneRibbonComponent, SVGAwesomeDirective, M010C080PhoneRibbonComponent, RouterOutlet],
})
export class PartnerWebAppComponent {
    protected readonly zonelessRouting = inject(ZonelessRouting).init();
}
