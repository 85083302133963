import { NavigationLabels } from 'libs/shared-lib/interfaces/partner-web/navigation';

export const NAV_LABELS_FR: NavigationLabels = {
    vehicleList: 'Trouver un véhicule',
    aboutUs: 'À propos de nous',
    service: 'Service',
    ourLocations: 'Nos sites',
    overview: 'Vers l’aperçu',
    route: 'Itinéraire',
    contact: 'Contact',
    phone: 'Appeler',
    phoneRibbonDesktopText: 'Nous sommes là pour toi !',
    phoneDesktop: 'Nous te conseillons avec plaisir :',
    tradeIn: 'Vendre ton véhicule',
};
