import { FooterLabels } from 'libs/shared-lib/interfaces/partner-web/footer';

export const FOOTER_LABELS_DE: FooterLabels = {
    quickLinks: {
        service: 'Servicetermin vereinbaren',
        testDrive: 'Probefahrt vereinbaren',
        call: 'Anrufen',
        mail: 'E-Mail senden',
    },
    locations: 'Unsere Standorte',
    copyrightText: `Die Zukunft gehört allen © Opel ${new Date().getFullYear()}`,
    privacyLinks: [
        {
            name: 'Impressum',
            url: 'https://www.opel.ch/de/tools/opel-copyright.html',
        },
        {
            name: 'Cookie-Richtlinie',
            url: 'https://www.opel.ch/de/tools/cookie-richtlinie.html',
        },
        {
            name: 'Datenschutzrichtlinie',
            url: 'https://www.opel.ch/de/tools/datenschutzrichtlinie.html',
        },
        {
            name: 'WLTP|Neue Verbrauchswerte',
            url: 'https://www.opel.ch/de/tools/wltp-verbrauchswerte.html',
        },
        {
            name: 'Opel Worldwide',
            url: 'http://www.opel.com/',
        },
    ],
    corporateLinks: [
        {
            name: 'MyOpel',
            url: 'https://my.opel.ch/de/',
        },
        {
            name: 'Elektro-Mobilität',
            url: 'https://www.opel.ch/de/simply-electric/overview.html',
        },
        {
            name: 'Opel-Flex Care',
            url: 'https://www.opel.ch/de/service-zubehoer/garantie/flexcare.html',
        },
    ],
    legalTextHTML:
        'Funktionsbeschreibungen und Abbildungen können sich auf Sonderausstattungen, die nicht zum serienmässigen Lieferumfang gehören, beziehen oder diese zeigen. Der Inhalt entspricht dem Stand bei Veröffentlichung. Irrtümer und Änderungen vorbehalten. Wir behalten uns Änderungen von Konstruktion und Ausstattung vor. Alle Preisangaben sind unverbindlich und Preisänderungen bleiben jederzeit vorbehalten. Die abgebildeten Farben entsprechen nur annähernd den tatsächlichen Farben. Gezeigte Sonderausstattungen sind gegen Mehrpreis erhältlich. Verfügbarkeit, Technik und Ausstattung unserer Fahrzeuge können variieren oder sind nicht in jedem Land oder nur gegen Aufpreis erhältlich. Bitte informiere dich über die genaue Ausstattung unserer Fahrzeuge bei deinem Opel Partner.<br><br>CO2-Emissionen: 122 g/km Durchschnitt aller verkauften neuen Personenwagen . CO2-Zielwert: 118 g/km (provisorischer Zielwert nach neuem Prüfzyklus WLTP).',
    wltpLink: {
        name: 'Klicke hier für weitere Informationen zu WLTP-Prüfverfahren.',
        url: 'https://www.opel.ch/de/tools/wltp-verbrauchswerte.html',
    },
};
