import { CheckoutsLabels } from 'libs/shared-lib/interfaces/checkout/checkouts.interface';
import {
    errorInvalid,
    errorMessage,
    errorRequired,
    footerLinks,
    gender,
    m060C050vehicleOverviewLabels,
    phoneNumberPrefixes,
} from '../../shared/shared.fr';

export const CHECKOUTS_LABELS_FR: CheckoutsLabels = {
    buy: {
        S010ContactData: {
            stepLabel: 'Coordonnées',
            howToBox: {
                headline: 'C’est si facile d’obtenir une offre pour la voiture de tes souhaits en ligne :',
                stepText: [
                    'Saisir tes coordonnées',
                    'Choisir récupération ou livraison',
                    'Sélectionner prestations supplémentaires',
                    'C’est fait ! Ton conseiller de vente personnel te contactera d’ici peu !',
                ],
            },
            title: 'Tes coordonnées',
            headline: 'Pour ton demande d’offre, merci de compléter toutes les informations suivantes.',
            gender: gender,
            firstName: 'Prénom',
            lastName: 'Nom',
            email: 'Adresse e-mail',
            phoneNumber: 'Ton numéro de téléphone*',
            privacyNoticeBefore: 'J’ai lu et compris les ',
            privacyNoticeLink: ' dispositions relatives à la protection des données.',
            privacyNoticeUrl: footerLinks.DATA_POLICY,
            privacyNoticeAfter: '',
            nextBtn: 'Suivant',
            errors: {
                required: errorRequired,
                pattern: errorInvalid,
            },
            phoneNumberPrefixes: phoneNumberPrefixes,
            preferredContact: {
                headline: 'J’accepte la prise de contact par :',
                email: 'E-Mail',
                phone: 'Téléphone',
                mail: 'Courrier postal',
                sms: 'SMS/WhatsApp',
            },
            footnote: "Les champs marqués d'un * sont obligatoires.",
        },
        S020PickupOrDelivery: {
            stepLabel: 'Récupération/livraison',
            title: 'Récupération/livraison',
            headline: 'Comment voudrais-tu prendre possession de la voiture de tes souhaits ?',
            tabs: {
                pickup: 'Récupérer auprès du partenaire Opel',
                delivery: 'Faire livrer à cette adresse',
            },
            gender: gender,
            dealer: 'Sélectionner garage',
            street: 'Rue',
            streetNumber: 'N°',
            zip: 'NPA',
            city: 'Lieu',
            nextBtn: 'Suivant',
            summary: {
                headline: 'Voici ce que nous avons noté à propos de la remise de la voiture de tes souhaits :',
            },
            errors: {
                required: errorRequired,
                pattern: errorInvalid,
            },
            requiredNotice: "Les champs marqués d'un * sont obligatoires.",
        },
        S030AdditionalBenefits: {
            stepLabel: 'Prestations supplémentaires',
            title: 'Prestations supplémentaires',
            headline: 'De quoi souhaites-tu profiter en plus ?',
            benefits: [
                {
                    identifier: 'multiassurance_advantage',
                    headline: 'Rapide, facile, complet et tout en un : notre assurance auto.',
                    subline: 'Profite de tous les avantages de notre assurance MultiAssurance :',
                    items: [
                        'Couverture bris de glace élargie sans franchise',
                        'Assurance voyage de MultiAssistance gratuite pendant un an pour vous et votre famille',
                    ],
                },
                {
                    identifier: 'car_return',
                    headline: 'Reprise de véhicule avec évaluation professionnelle.',
                    subline: 'Veux-tu bénéficier d’une reprise de véhicule ?',
                    items: [
                        'Nous procédons volontiers à une évaluation gratuite et sans engagement en personne sur place dans notre garage Opel.',
                    ],
                },
            ],
            select: 'Sélectionner',
            summary: {
                headline: 'Prestations supplémentaires',
                subline:
                    'Vous recevrez des offres séparées pour les prestations supplémentaires que vous avez sélectionnées :',
            },
            nextBtn: 'Envoyer',
            errors: {
                required: errorRequired,
                pattern: errorInvalid,
            },
            M030C070AdditionalPackages: {
                headline: 'Recommandation accessoires',
                subheadline: '',
                from: 'à partir de ',
            },
        },
        S040ThanksWhithVehicle: {
            error: errorMessage,
            headline: 'Merci beaucoup',
            headlineAfter: ' !',
            female: 'Madame',
            male: 'Monsieur',
            copy: 'Nous avons bien reçu ta demande. Nous reviendrons vers toi dès que possible.<br><br>Ton partenaire Opel ',
            contactCard: {
                headline: 'Comment pouvons-nous encore t’être utile ?',
                copy: 'Nos conseillers t’aident avec plaisir.',
                phone: 'Appeler',
                mail: 'Courriel',
            },
        },
        M060C050vehicleOverview: m060C050vehicleOverviewLabels,
    },
    tradeIn: {
        menuItem: 'DEPRECATED',
        S010ContactData: {
            stepLabel: 'Coordonnées',
            title: 'Tes coordonnées',
            headline: 'Pour une évaluation gratuite de ton véhicule, complète les informations suivantes.',
            gender: gender,
            firstName: 'Prénom',
            lastName: 'Nom',
            email: 'Adresse e-mail',
            phoneNumber: 'Ton numéro de téléphone',
            privacyNoticeBefore: 'J’ai lu et compris la ',
            privacyNoticeLink: 'dispositions relatives à la protection des données.',
            privacyNoticeUrl: footerLinks.DATA_POLICY,
            privacyNoticeAfter: '',
            nextBtn: 'Envoyer',
            errors: {
                required: errorRequired,
                pattern: errorInvalid,
            },
            dealer: {
                headline: '',
                label: '',
            },
            phoneNumberPrefixes: phoneNumberPrefixes,
            footnote: "Les champs marqués d'un * sont obligatoires.",
            preferredContact: {
                headline: 'J’accepte la prise de contact par :',
                email: 'E-Mail',
                phone: 'Téléphone',
                mail: 'Courrier postal',
                sms: 'SMS/WhatsApp',
            },
        },
        S060VehicleSelect: {
            stepLabel: 'VehicleSelect',
            headline: 'Données concernant ton véhicule',
            copy: 'Remplis tous les champs concernant les données de ton véhicule.',
            make: 'Marque',
            model: 'Modèle',
            engine: 'Type de moteur (facultatif)',
            firstReg: '1re mise en circulation',
            mileage: 'Kilométrage',
            footnote: "Les champs marqués d'un * sont obligatoires.",
            nextBtn: 'Suivant',
            errorRequired: 'Ce champ est obligatoire',
            errorInvalid: 'Entrée invalide',
        },
        S070ThanksNoVehicle: {
            headline: {
                before: 'Merci',
                after: '!',
            },
            female: gender.female,
            male: gender.male,
            copy: 'Merci de nous avoir proposé l’achat de votre voiture. Nous nous réjouissons de prendre contact avec vous.',
            nextStep: {
                headline: 'Quelle est la suite?',
                copy: 'Votre partenaire Opel vous contactera pour une évaluation détaillée de votre véhicule.',
                name: 'L’équipe Opel',
            },
        },
        M060C050vehicleOverview: m060C050vehicleOverviewLabels,
    },
    testDrive: {
        S050TestDriveContactData: {
            stepLabel: 'Coordonnées',
            title: 'Coordonnées',
            headline: 'Saisis ici tes coordonnées :',
            subheadline: 'Contacte-moi par :',
            gender: gender,
            phoneSwitch: {
                email: 'E-Mail',
                phone: 'Téléphone',
            },
            wishDate: 'Rendez-vous souhaité',
            firstName: 'Prénom',
            lastName: 'Nom',
            zip: 'NPA',
            email: 'Adresse e-mail',
            message: 'Ton message',
            phoneNumber: 'Numéro de téléphone',
            // contactTime: 'Quand êtes-vous le plus facilement joignable ?',
            // contactTimeOptions: [
            //     {
            //         value: 'Matin',
            //         label: 'Matin',
            //     },
            //     {
            //         value: 'Midi',
            //         label: 'Midi',
            //     },
            //     {
            //         value: 'Après-midi',
            //         label: 'Après-midi',
            //     },
            // ],
            contactsHeadline: 'J’accepte la prise de contact par :',
            contacts: {
                email: 'E-Mail',
                phone: 'Téléphone',
                mail: 'Courrier postal',
                messenger: 'SMS/WhatsApp',
            },
            privacyNoticeBefore: 'J’ai lu et compris les ',
            privacyNoticeLink: 'dispositions relatives à la protection des données.',
            privacyNoticeUrl: footerLinks.DATA_POLICY,
            privacyNoticeAfter: '',
            requiredNotice: "Les champs marqués d'un * sont obligatoires.",
            nextBtn: 'Envoyer',
            errors: {
                required: errorRequired,
                pattern: errorInvalid,
            },
            summary: {
                name: 'Nom',
                address: 'NPA',
                email: 'Adresse e-mail',
                phone: 'Numéro de téléphone',
                wishDate: 'Rendez-vous souhaité',
                seller: 'Garage',
            },
            phoneNumberPrefixes: phoneNumberPrefixes,
        },
        S080ThanksTestDrive: {
            error: errorMessage,
            headline: 'Ta demande a été envoyée avec succès.',
            headlineAfter: ' ',
            female: '',
            male: '',
            copy: 'Merci, nous avons bien reçu ta demande. <br> Nous reviendrons vers toi dès que possible.<br><br>Ton partenaires Opel<br>',
            subheadline: 'Résumé',
            M060C050VehicleOverview: m060C050vehicleOverviewLabels,
            contactCard: {
                headline: 'Comment pouvons-nous encore vous être utiles?',
                copy: 'Nos conseillers vous aident avec plaisir.',
                phone: 'Appeler',
                mail: 'Courriel',
            },
            iconSummary: {
                headline: 'Rendez-vous souhaité',
            },
        },
        M060C050vehicleOverview: m060C050vehicleOverviewLabels,
    },
    leasing: {
        S010ContactData: {
            stepLabel: 'Coordonnées',
            howToBox: {
                headline: 'C’est si facile d’obtenir une offre de leasing pour la voiture de vos souhaits en ligne:',
                stepText: [
                    'Saisir ses coordonnées',
                    'Configurer taux de leasing en quelques clics',
                    'Sélectionner prestations supplémentaires',
                    'C’est fait! Votre conseiller de vente personnel vous contactera d’ici peu!',
                ],
            },
            title: 'Coordonnées',
            headline: 'Saisir vos données – c’est simple et rapide.',
            gender: gender,
            firstName: 'Prénom',
            lastName: 'Nom',
            email: 'Adresse e-mail',
            phoneNumber: 'Votre numéro de téléphone',
            privacyNoticeBefore: 'J’ai lu et compris les ',
            privacyNoticeLink: ' dispositions relatives à la protection des données.',
            privacyNoticeUrl: footerLinks.DATA_POLICY,
            privacyNoticeAfter: '',
            nextBtn: 'Suivant',
            errors: {
                required: errorRequired,
                pattern: errorInvalid,
            },
            phoneNumberPrefixes: phoneNumberPrefixes,
            leasingSummary: {
                annualPercentageRate: 'Taux de leasing effectif',
                debitInterest: 'Intérêt débiteur',
                monthlyRate: 'Mensualité',
                footnoteBound: '(1) Fixe pendant toute la durée du contrat.',
                footnoteCosts:
                    '(2) Les frais de transfert sont inclus dans la mensualité. Toutes les indications sont fournies sans garantie.',
                footnoteDelivery: '(3) Forfait de livraison excl.',
            },
        },
        S020PickupOrDelivery: {
            stepLabel: 'Récupération/livraison',
            title: 'Récupération/livraison',
            headline: 'Comment souhaitez-vous prendre possession de la voiture de vos souhaits ?',
            tabs: {
                pickup: 'Récupérer auprès du partenaire Opel',
                delivery: 'Faire livrer à cette adresse',
            },
            gender: gender,
            dealer: 'Sélectionner garage',
            street: 'Rue',
            streetNumber: 'N°',
            zip: 'NPA',
            city: 'Lieu',
            nextBtn: 'Suivant',
            summary: {
                headline: 'Voici ce que nous avons noté à propos de la remise de la voiture de vos souhaits:',
            },
            errors: {
                required: errorRequired,
                pattern: errorInvalid,
            },
            requiredNotice: "Les champs marqués d'un * sont obligatoires.",
        },
        S040ThanksWhithVehicle: {
            error: errorMessage,
            headline: 'Merci beaucoup',
            headlineAfter: ' !',
            female: 'Madame',
            male: 'Monsieur',
            copy: 'Nous avons bien reçu votre demande. Votre partenaire Opel vous contactera d’ici peu afin de vous prodiguer des conseils de spécialiste.',
            contactCard: {
                headline: 'Comment pouvons-nous encore vous être utiles?',
                copy: 'Nos conseillers vous aident avec plaisir.',
                phone: 'Appeler',
                mail: 'Courriel',
            },
        },
        M060C050vehicleOverview: m060C050vehicleOverviewLabels,
        S090Leasing: {
            stepLabel: 'Calculateur de leasing',
            headline: 'Réalisez votre souhait d’une nouvelle voiture tout en préservant une liberté financière.',
            copy: 'Configurez votre contrat de leasing sur mesure.',
            durationHeadline: 'Durée du contrat de leasing en mois',
            runtimeHeadline: 'Kilométrage annuel de la voiture de vos souhaits',
            commissionHeadline: 'Acompte',
            nextButton: 'La voiture de vos souhaits en leasing',
            anualPercentageRate: 'Taux de leasing effectif',
            debitInterest: 'Intérêt débiteur',
            monthlyRate: 'Mensualité',
            footnoteBound: '(1) Fixe pendant toute la durée du contrat.',
            footnoteCosts:
                '(2) Les frais de transfert sont inclus dans la mensualité. Toutes les indications sont fournies sans garantie.',
            footnoteDelivery: '(3) Forfait de livraison excl.',
            runtimeYearly: 'Kilométrage annuel',
            months: 'mois',
            duration: 'Durée',
            calcError: "Cette configuration n'est malheureusement pas valable.",
            unit: 'km',
        },
        S030AdditionalBenefits: {
            stepLabel: 'Prestations supplémentaires',
            title: 'Prestations supplémentaires',
            headline: 'De quelles autres prestations souhaitez-vous bénéficier ?',
            benefits: [
                {
                    identifier: 'multiassurance_advantage',
                    headline: 'Rapide, facile, complet et tout en un : notre assurance auto.',
                    subline: 'Profitez de tous les avantages de notre assurance MultiAssurance :',
                    items: [
                        'Couverture bris de glace élargie sans franchise',
                        'Assurance voyage de MultiAssistance gratuite pendant un an pour vous et votre famille',
                    ],
                },
                {
                    identifier: 'car_return',
                    headline: 'Reprise de véhicule avec évaluation professionnelle.',
                    subline: 'Voulez-vous bénéficier d’une reprise de véhicule ?',
                    items: [
                        'Nous procédons volontiers à une évaluation gratuite et sans engagement en personne auprès de votre partenaire Opel.',
                    ],
                },
            ],
            select: 'Sélectionner',
            summary: {
                headline: 'Prestations supplémentaires',
                subline:
                    'Vous recevrez des offres séparées pour les prestations supplémentaires que vous avez sélectionnées :',
            },
            nextBtn: 'Envoyer',
            errors: {
                required: errorRequired,
                pattern: errorInvalid,
            },
            M030C070AdditionalPackages: {
                headline: 'Recommandation accessoires',
                subheadline: '',
                from: 'à partir de ',
            },
        },
    },
    service: {
        S020PickupOrDelivery: {
            stepLabel: 'Rendez-vous souhaité',
            title: 'Prendre un rendez-vous de service',
            headline: 'Choisir le lieu et le rendez-vous souhaitée',
            tabs: {
                pickup: 'Amener au garage',
                delivery: 'Utiliser le service de récupération et de restitution',
                deliveryHeadline: '',
                deliveryCopy:
                    'Tu peux nous faire part ici, sans engagement, de ton intérêt pour un service de récupération et de restitution. Nous prendrons contact avec toi pour discuter des possibilités.',
            },
            gender: gender,
            dealer: 'Sélectionner garage',
            street: 'Rue',
            streetNumber: 'N°',
            zip: 'NPA',
            city: 'Lieu',
            nextBtn: 'Suivant',
            summary: {
                wishDate: 'Rendez-vous souhaité',
            },
            errors: {
                required: errorRequired,
                pattern: errorInvalid,
            },
            requiredNotice: "Les champs marqués d'un * sont obligatoires.",
            wishDate: {
                label: 'Rendez-vous souhaité',
                btnLabel: 'changer',
            },
            note: {
                headline: 'Remarque:',
                copy: 'Si tu as besoin d’un rendez-vous à court terme, nous te prions de nous contacter par téléphone. Note qu’il s’agit ici d’une demande et que le rendez-vous sélectionné n’est définitif qu’après confirmation.',
            },
            pickupService: {
                headline: 'Service de récupération et de restitution',
                value: 'Oui',
            },
        },
        S100Services: {
            stepLabel: 'Prestations',
            title: 'Prestations',
            headline: 'Choisis les prestations souhaitées :',
            summary: {
                headline: 'Prestations',
            },
            nextBtn: 'Suivant',
            errors: {
                required: errorRequired,
                pattern: errorInvalid,
            },
            requiredNotice: "Les champs marqués d'un * sont obligatoires.",
            other: 'Autre',
        },
        S015ContactDataExtended: {
            stepLabel: 'Coordonnées',
            title: 'Tes coordonnées',
            headline: 'Saisis ici tes coordonnées :',
            gender: gender,
            firstName: 'Prénom',
            lastName: 'Nom',
            email: 'Adresse e-mail',
            phoneNumber: 'Il tuo numero di telefono',
            privacyNoticeBefore: 'J’ai lu et compris la ',
            privacyNoticeLink: 'dispositions relatives à la protection des données.',
            privacyNoticeUrl: footerLinks.DATA_POLICY,
            privacyNoticeAfter: '',
            nextBtn: 'Envoyer',
            errors: {
                required: errorRequired,
                pattern: errorInvalid,
            },
            phoneNumberPrefixes: phoneNumberPrefixes,
            footnote: "Les champs marqués d'un * sont obligatoires.",
            preferredContact: {
                headline: 'J’accepte la prise de contact par :',
                email: 'E-Mail',
                phone: 'Téléphone',
                mail: 'Courrier postal',
                sms: 'SMS/WhatsApp',
            },
            alreadyCustomer: {
                label: 'Sei già nostro cliente?',
                yes: 'Oui',
                no: 'Non',
            },
            corporateClient: {
                label: 'Siete un cliente aziendale?',
                yes: 'Oui',
                no: 'Non',
            },
            corporate: 'Azienda',
            corporateStreet: 'Via',
            corporateStreetnumber: 'N°',
            corporateZip: 'NPA',
            corporateCity: 'Luogo',
            contactTime: 'Quando sei più disponibili?',
            contactTimeOptions: [
                {
                    value: 'Matin',
                    label: 'Matin',
                },
                {
                    value: 'Midi',
                    label: 'Midi',
                },
                {
                    value: 'Après-midi',
                    label: 'Après-midi',
                },
            ],
        },
        S065VehicleData: {
            stepLabel: 'Données du véhicule',
            headline: 'Les données de ton véhicule',
            copy: 'Pour le rendez-vous de service, merci de remplir toutes les informations suivantes concernant le véhicule.',
            make: 'Marque',
            model: 'Modèle',
            controlSign: 'N° d’immatriculation (p. ex. VD 123 456)',
            firstReg: '1re mise en circulation (p. ex. 2018)',
            mileage: 'Kilométrage',
            message: 'Ton message',
            footnote: "Les champs marqués d'un * sont obligatoires.",
            nextBtn: 'Suivant',
            errorRequired: 'Ce champ est obligatoire',
            errorInvalid: 'Entrée invalide',
            summary: {
                controlSign: 'N° d’immatriculation',
                firstReg: '1re mise en circulation',
            },
        },
        S110ThanksService: {
            headline: 'Ta demande a été envoyée avec succès.',
            copy: 'Merci, nous avons bien reçu ta demande. Nous reviendrons vers toi dès que possible. ',
            team: 'Ton partenaire Opel ',
            summary: {
                headline: 'Résumé',
                copy: 'Tes options choisies',
            },
            errorMessage,
        },
        M060C050vehicleOverview: {
            ...m060C050vehicleOverviewLabels,
            noVehicle: {
                headline: 'Résumé',
                subheadline: 'Tes options choisies',
            },
        },
    },
};
