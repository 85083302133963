import { NewsLabels } from 'libs/shared-lib/interfaces/partner-web/news';
import { m030c195CtaImageTeasers, m070c040DealerContact } from '../../shared/shared.it';

export const NEWS_LABELS_IT: NewsLabels = {
    list: {
        headline: 'Notizie più recenti',
        showMore: 'Per saperne di più',
    },
    detail: {
        m070c040DealerContact,
        m030c195CtaImageTeasers,
        at: 'Presso',
        in: 'a',
        and: 'e',
        region: 'nella regione',
        andLocation: 'e presso la nostra sede a',
        andLocations: 'e presso le nostre sedi a',
    },
};
