import { SeoPage, SeoReplaceValues } from 'libs/shared-lib/interfaces';
export const HOME_SEO_IT: SeoPage = {
    title: `${SeoReplaceValues.DEALER_NAME}: Acquistare veicoli Opel d’occasione e dimostrativi e vendere auto online'`,
    description: `Qui potete trovare e acquistare auto dimostrative e d’occasione proposte dai ${SeoReplaceValues.DEALER_NAME}. Oppure vendere la vostra auto. Adesso e online!`,
    image: '',
    twitter: {
        card: 'summary',
        title: `${SeoReplaceValues.DEALER_NAME}: Acquistare veicoli Opel d’occasione e dimostrativi e vendere auto online'`,
        description: `Qui potete trovare e acquistare auto dimostrative e d’occasione proposte dai ${SeoReplaceValues.DEALER_NAME}. Oppure vendere la vostra auto. Adesso e online!`,
    },
    og: {
        title: `${SeoReplaceValues.DEALER_NAME}: Acquistare veicoli Opel d’occasione e dimostrativi e vendere auto online'`,
        description: `Qui potete trovare e acquistare auto dimostrative e d’occasione proposte dai ${SeoReplaceValues.DEALER_NAME}. Oppure vendere la vostra auto. Adesso e online!`,
    },
    robots: {
        noIndex: false,
        noFollow: false,
    },
};
