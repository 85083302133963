import { SeoReplaceValues } from 'libs/shared-lib/interfaces';
import { SeoVehicleMarket } from 'libs/shared-lib/interfaces/seo/seo-vehicle-market';

export const VEHICLE_MARKET_SEO_DE: SeoVehicleMarket = {
    vehicleNotAvailablePage: {
        title: 'Fahrzeug nicht gefunden',
        description: 'Das Fahrzeug konnte nicht gefunden werden',
        image: '',
        twitter: {
            card: 'summary',
            title: 'Fahrzeug nicht gefunden',
            description: 'Das Fahrzeug konnte nicht gefunden werden',
        },
        og: {
            title: 'Fahrzeug nicht gefunden',
            description: 'Das Fahrzeug konnte nicht gefunden werden',
        },
        robots: {
            noIndex: true,
            noFollow: true,
        },
    },
    vehicleMarket: {
        list: {
            title: `${SeoReplaceValues.DEALER_NAME}: Opel Occasionen und Vorführmodelle online kaufen und Auto verkaufen`,
            description:
                'Hier finden und kaufen Sie Opel Vorführwagen und Occasionen von offiziellen Opel Partnern. Oder verkaufen Sie Ihr Auto. Jetzt gleich online!',
            image: '',
            twitter: {
                card: 'summary',
                title: `${SeoReplaceValues.DEALER_NAME}: Opel Occasionen und Vorführmodelle online kaufen und Auto verkaufen`,
                description:
                    'Hier finden und kaufen Sie Opel Vorführwagen und Occasionen von offiziellen Opel Partnern. Oder verkaufen Sie Ihr Auto. Jetzt gleich online!',
            },
            og: {
                title: `${SeoReplaceValues.DEALER_NAME}: Opel Occasionen und Vorführmodelle online kaufen und Auto verkaufen`,
                description:
                    'Hier finden und kaufen Sie Opel Vorführwagen und Occasionen von offiziellen Opel Partnern. Oder verkaufen Sie Ihr Auto. Jetzt gleich online!',
            },
            robots: {
                noIndex: false,
                noFollow: false,
            },
        },
        detail: {
            title: `${SeoReplaceValues.VEHICLE_BRAND_NAME} ${SeoReplaceValues.VEHICLE_MODEL_NAME}: Jetzt Probefahrt buchen!`,
            description: `${SeoReplaceValues.VEHICLE_BRAND_NAME} ${SeoReplaceValues.VEHICLE_MODEL_NAME} ${SeoReplaceValues.VEHICLE_DOORS_COUNT} Türen ${SeoReplaceValues.VEHICLE_BODY_TYPE} in ${SeoReplaceValues.VEHICLE_BODY_COLOR} steht für eine Probefahrt für Sie bereit. Jetzt Termin online buchen.`,
            image: '', // automatically replaced
            twitter: {
                card: 'summary',
                title: `${SeoReplaceValues.VEHICLE_BRAND_NAME} ${SeoReplaceValues.VEHICLE_MODEL_NAME}: Jetzt Probefahrt buchen!`,
                description: `${SeoReplaceValues.VEHICLE_BRAND_NAME} ${SeoReplaceValues.VEHICLE_MODEL_NAME} ${SeoReplaceValues.VEHICLE_DOORS_COUNT} Türen ${SeoReplaceValues.VEHICLE_BODY_TYPE} in ${SeoReplaceValues.VEHICLE_BODY_COLOR} steht für eine Probefahrt für Sie bereit. Jetzt Termin online buchen.`,
            },
            og: {
                title: `${SeoReplaceValues.VEHICLE_BRAND_NAME} ${SeoReplaceValues.VEHICLE_MODEL_NAME}: Jetzt Probefahrt buchen!`,
                description: `${SeoReplaceValues.VEHICLE_BRAND_NAME} ${SeoReplaceValues.VEHICLE_MODEL_NAME} ${SeoReplaceValues.VEHICLE_DOORS_COUNT} Türen ${SeoReplaceValues.VEHICLE_BODY_TYPE} in ${SeoReplaceValues.VEHICLE_BODY_COLOR} steht für eine Probefahrt für Sie bereit. Jetzt Termin online buchen.`,
            },
            robots: {
                noIndex: false,
                noFollow: false,
            },
        },
    },
    checkouts: {
        buy: {
            title: 'Online kaufen',
            description: '',
            image: '',
            twitter: {
                card: 'summary',
                title: 'Online kaufen',
                description: '',
            },
            og: {
                title: 'Online kaufen',
                description: '',
            },
            robots: {
                noIndex: true,
                noFollow: true,
            },
        },
        tradeIn: {
            title: `${SeoReplaceValues.DEALER_NAME}: Auto eintauschen oder verkaufen, online und zu fairen Konditionen`,
            description:
                'Jetzt Ihr Auto einfach verkaufen, oder beim Kauf Ihres neuen Opels eintauschen und von fairen Konditionen profitieren. Jetzt Offerte anfragen!',
            image: '',
            twitter: {
                card: 'summary',
                title: `${SeoReplaceValues.DEALER_NAME}: Auto eintauschen oder verkaufen, online und zu fairen Konditionen`,
                description:
                    'Jetzt Ihr Auto einfach verkaufen, oder beim Kauf Ihres neuen Opels eintauschen und von fairen Konditionen profitieren. Jetzt Offerte anfragen!',
            },
            og: {
                title: `${SeoReplaceValues.DEALER_NAME}: Auto eintauschen oder verkaufen, online und zu fairen Konditionen`,
                description:
                    'Jetzt Ihr Auto einfach verkaufen, oder beim Kauf Ihres neuen Opels eintauschen und von fairen Konditionen profitieren. Jetzt Offerte anfragen!',
            },
            robots: {
                noIndex: false,
                noFollow: false,
            },
        },
        testDrive: {
            title: 'Probefahrt',
            description: '',
            image: '',
            twitter: {
                card: 'summary',
                title: 'Probefahrt',
                description: '',
            },
            og: {
                title: 'Probefahrt',
                description: '',
            },
            robots: {
                noIndex: true,
                noFollow: true,
            },
        },
        leasing: {
            title: 'Leasing',
            description: '',
            image: '',
            twitter: {
                card: 'summary',
                title: 'Leasing',
                description: '',
            },
            og: {
                title: 'Leasing',
                description: '',
            },
            robots: {
                noIndex: true,
                noFollow: true,
            },
        },
        service: {
            title: 'Terminvereinbarung',
            description: '',
            image: '',
            twitter: {
                card: 'summary',
                title: 'Terminvereinbarung',
                description: '',
            },
            og: {
                title: 'Terminvereinbarung',
                description: '',
            },
            robots: {
                noIndex: true,
                noFollow: true,
            },
        },
    },
};
