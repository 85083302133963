import { SeoPage } from 'libs/shared-lib/interfaces';

export const PROMOTION_NOT_AVAILABLE_SEO_DE: SeoPage = {
    title: 'Aktion nicht gefunden / Aktion nicht verfügbar',
    description: 'Die Aktion konnte nicht gefunden werden.',
    image: '',
    twitter: {
        card: 'summary',
        title: 'Aktion nicht gefunden / Aktion nicht verfügbar',
        description: 'Die Aktion konnte nicht gefunden werden.',
    },
    og: {
        title: 'Aktion nicht gefunden / Aktion nicht verfügbar',
        description: 'Die Aktion konnte nicht gefunden werden.',
    },
    robots: {
        noIndex: true,
        noFollow: true,
    },
};
