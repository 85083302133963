import { SeoReplaceValues } from 'libs/shared-lib/interfaces';
import { SeoVehicleMarket } from 'libs/shared-lib/interfaces/seo/seo-vehicle-market';

export const VEHICLE_MARKET_SEO_IT: SeoVehicleMarket = {
    vehicleNotAvailablePage: {
        title: 'Il veicolo non è stato trovato',
        description: 'Il veicolo non è stato trovato',
        image: '',
        twitter: {
            card: 'summary',
            title: 'Il veicolo non è stato trovato',
            description: 'Il veicolo non è stato trovato',
        },
        og: {
            title: 'Il veicolo non è stato trovato',
            description: 'Il veicolo non è stato trovato',
        },
        robots: {
            noIndex: true,
            noFollow: true,
        },
    },
    vehicleMarket: {
        list: {
            title: `${SeoReplaceValues.DEALER_NAME}: Trovare e acquistare online veicoli d’occasione e dimostrativi Opel`,
            description:
                'Trovare e acquistare online auto dimostrative e d’occasione Opel. Con motore a combustione, ibrido, ibrido plug-in o completamente elettrico. Scoprire ora!',
            image: '',
            twitter: {
                card: 'summary',
                title: `${SeoReplaceValues.DEALER_NAME}: Trovare e acquistare online veicoli d’occasione e dimostrativi Opel`,
                description:
                    'Trovare e acquistare online auto dimostrative e d’occasione Opel. Con motore a combustione, ibrido, ibrido plug-in o completamente elettrico. Scoprire ora!',
            },
            og: {
                title: `${SeoReplaceValues.DEALER_NAME}: Trovare e acquistare online veicoli d’occasione e dimostrativi Opel`,
                description:
                    'Trovare e acquistare online auto dimostrative e d’occasione Opel. Con motore a combustione, ibrido, ibrido plug-in o completamente elettrico. Scoprire ora!',
            },
            robots: {
                noIndex: false,
                noFollow: false,
            },
        },
        detail: {
            title: `${SeoReplaceValues.VEHICLE_BRAND_NAME} ${SeoReplaceValues.VEHICLE_MODEL_NAME}: Prenotate ora una prova su strada!`,
            description: `${SeoReplaceValues.VEHICLE_BRAND_NAME} ${SeoReplaceValues.VEHICLE_MODEL_NAME} ${SeoReplaceValues.VEHICLE_DOORS_COUNT} porte ${SeoReplaceValues.VEHICLE_BODY_TYPE} ${SeoReplaceValues.VEHICLE_BODY_COLOR} è a vostra disposizione per una prova su strada. Prenotate ora online un appuntamento!`,
            image: '', // automatically replaced
            twitter: {
                card: 'summary',
                title: `${SeoReplaceValues.VEHICLE_BRAND_NAME} ${SeoReplaceValues.VEHICLE_MODEL_NAME}: Prenotate ora una prova su strada!`,
                description: `${SeoReplaceValues.VEHICLE_BRAND_NAME} ${SeoReplaceValues.VEHICLE_MODEL_NAME} ${SeoReplaceValues.VEHICLE_DOORS_COUNT} porte ${SeoReplaceValues.VEHICLE_BODY_TYPE} ${SeoReplaceValues.VEHICLE_BODY_COLOR} è a vostra disposizione per una prova su strada. Prenotate ora online un appuntamento!`,
            },
            og: {
                title: `${SeoReplaceValues.VEHICLE_BRAND_NAME} ${SeoReplaceValues.VEHICLE_MODEL_NAME}: Prenotate ora una prova su strada!`,
                description: `${SeoReplaceValues.VEHICLE_BRAND_NAME} ${SeoReplaceValues.VEHICLE_MODEL_NAME} ${SeoReplaceValues.VEHICLE_DOORS_COUNT} porte ${SeoReplaceValues.VEHICLE_BODY_TYPE} ${SeoReplaceValues.VEHICLE_BODY_COLOR} è a vostra disposizione per una prova su strada. Prenotate ora online un appuntamento!`,
            },
            robots: {
                noIndex: false,
                noFollow: false,
            },
        },
    },
    checkouts: {
        buy: {
            title: 'Acquistare',
            description: '',
            image: '',
            twitter: {
                card: 'summary',
                title: 'Acquistare',
                description: '',
            },
            og: {
                title: 'Acquistare',
                description: '',
            },
            robots: {
                noIndex: true,
                noFollow: true,
            },
        },
        tradeIn: {
            title: `${SeoReplaceValues.DEALER_NAME}: Permutare o vendere l’auto online e a condizioni eque`,
            description:
                'Vendete ora la vostra auto, oppure permutatela all’acquisto della vostra nuova Opel e beneficiate di condizioni eque. Richiedere un’offerta!',
            image: '',
            twitter: {
                card: 'summary',
                title: `${SeoReplaceValues.DEALER_NAME}: Permutare o vendere l’auto online e a condizioni eque`,
                description:
                    'Vendete ora la vostra auto, oppure permutatela all’acquisto della vostra nuova Opel e beneficiate di condizioni eque. Richiedere un’offerta!',
            },
            og: {
                title: `${SeoReplaceValues.DEALER_NAME}: Permutare o vendere l’auto online e a condizioni eque`,
                description:
                    'Vendete ora la vostra auto, oppure permutatela all’acquisto della vostra nuova Opel e beneficiate di condizioni eque. Richiedere un’offerta!',
            },
            robots: {
                noIndex: false,
                noFollow: false,
            },
        },
        testDrive: {
            title: 'Giro di prova',
            description: '',
            image: '',
            twitter: {
                card: 'summary',
                title: 'Giro di prova',
                description: '',
            },
            og: {
                title: 'Giro di prova',
                description: '',
            },
            robots: {
                noIndex: true,
                noFollow: true,
            },
        },
        leasing: {
            title: 'Leasing',
            description: '',
            image: '',
            twitter: {
                card: 'summary',
                title: 'Leasing',
                description: '',
            },
            og: {
                title: 'Leasing',
                description: '',
            },
            robots: {
                noIndex: true,
                noFollow: true,
            },
        },
        service: {
            title: 'Fissare un appuntamento',
            description: '',
            image: '',
            twitter: {
                card: 'summary',
                title: 'Fissare un appuntamento',
                description: '',
            },
            og: {
                title: 'Fissare un appuntamento',
                description: '',
            },
            robots: {
                noIndex: true,
                noFollow: true,
            },
        },
    },
};
