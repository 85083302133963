import { PlatformConfig } from 'libs/shared-lib/interfaces/platform-config';

export const PLATFORM_CONFIG: PlatformConfig = {
    wholesaler: 'opel',
    country: 'ch',
    platform: 'partner-web',
    devPort: 7210,
    xDefaultLocale: 'de-CH',
    languageSuffix: '-CH',
};
