import { SeoPage, SeoReplaceValues } from 'libs/shared-lib/interfaces';
export const HOME_SEO_FR: SeoPage = {
    title: `${SeoReplaceValues.DEALER_NAME}: Acheter en ligne des occasions et des modèles de démonstration Opel et vendre sa voiture'`,
    description: `Trouvez et achetez des véhicules de démonstration et des occasions Opel issus chez ${SeoReplaceValues.DEALER_NAME}. Ou vendez votre voiture. Tout de suite, en ligne !`,
    image: '',
    twitter: {
        card: 'summary',
        title: `${SeoReplaceValues.DEALER_NAME}: Acheter en ligne des occasions et des modèles de démonstration Opel et vendre sa voiture'`,
        description: `Trouvez et achetez des véhicules de démonstration et des occasions Opel issus chez ${SeoReplaceValues.DEALER_NAME}. Ou vendez votre voiture. Tout de suite, en ligne !`,
    },
    og: {
        title: `${SeoReplaceValues.DEALER_NAME}: Acheter en ligne des occasions et des modèles de démonstration Opel et vendre sa voiture'`,
        description: `Trouvez et achetez des véhicules de démonstration et des occasions Opel issus chez ${SeoReplaceValues.DEALER_NAME}. Ou vendez votre voiture. Tout de suite, en ligne !`,
    },
    robots: {
        noIndex: false,
        noFollow: false,
    },
};
