import {
    S010ContactDataStyleOpel,
    provideS010ContactDataStyleData,
} from 'libs/ui/src/lib/checkout/checkout-steps/s010-contact-data/s010-contact-data.style';
import {
    S015ContactDataExtendedStyleOpel,
    provideS015ContactDataExtendedStyleData,
} from 'libs/ui/src/lib/checkout/checkout-steps/s015-contact-data-extended/s015-contact-data-extended.style';
import {
    S020PickupOrDeliveryStyleOpel,
    provideS020PickupOrDeliveryStyleData,
} from 'libs/ui/src/lib/checkout/checkout-steps/s020-pickup-or-delivery/s020-pickup-or-delivery.style';
import {
    S030AdditionalBenefitsStyleOpel,
    provideS030AdditionalBenefitsStyleData,
} from 'libs/ui/src/lib/checkout/checkout-steps/s030-additional-benefits/s030-additional-benefits.style';
import {
    S040ThanksWithVehicleStyleOpel,
    provideS040ThanksWithVehicleStyleData,
} from 'libs/ui/src/lib/checkout/checkout-steps/s040-thanks-with-vehicle/s040-thanks-with-vehicle.style';
import {
    S050TestdriveContactDataStyleOpel,
    provideS050TestdriveContactDataStyleData,
} from 'libs/ui/src/lib/checkout/checkout-steps/s050-testdrive-contact-data/s050-testdrive-contact-data.style';
import {
    S060VehicleDataStyleOpel,
    provideS060VehicleDataStyleData,
} from 'libs/ui/src/lib/checkout/checkout-steps/s060-vehicle-select/s060-vehicle-data.style';
import {
    S065VehicleDataNoDropdownStyleOpel,
    provideS065VehicleDataNoDropdownStyleData,
} from 'libs/ui/src/lib/checkout/checkout-steps/s065-vehicle-data/s065-vehicle-data-no-dropdown.style';
import {
    S070ThanksNoVehicleStyleOpel,
    provideS070ThanksNoVehicleStyleData,
} from 'libs/ui/src/lib/checkout/checkout-steps/s070-thanks-no-vehicle/s070-thanks-no-vehicle.style';
import {
    S080ThanksTestdriveStyleOpel,
    provideS080ThanksTestdriveStyleData,
} from 'libs/ui/src/lib/checkout/checkout-steps/s080-thanks-testdrive/s080-thanks-testdrive.style';
import {
    S100ServicesStyleOpel,
    provideS100ServicesStyleData,
} from 'libs/ui/src/lib/checkout/checkout-steps/s100-services/s100-services.style';
import {
    S110ThanksServiceStyleOpel,
    provideS110ThanksServiceStyleData,
} from 'libs/ui/src/lib/checkout/checkout-steps/s110-thanks-service/s110-thanks-service.style';
import {
    CheckoutWrapperStyleOpel,
    provideCheckoutWrapperStyleData,
} from 'libs/ui/src/lib/checkout/checkout-wrapper/checkout-wrapper.style';
import {
    LanguageSwitchStyleOpel,
    provideLanguageSwitchStyleData,
} from 'libs/ui/src/lib/dialogs/language-switch/language-switch.style';
import { TagDialogStyleOpel, provideTagDialogStyleData } from 'libs/ui/src/lib/dialogs/tag-dialog/tag-dialog.style';
import {
    VehicleMarketPlatformFilterDialogStyleOpel,
    provideVehicleMarketFilterDialogStyleData,
} from 'libs/ui/src/lib/dialogs/vehicle-market-filter-dialog/vehicle-market-filter-dialog.style';
import {
    M010C010ButtonPrimaryStyleOpel,
    provideM010C010ButtonPrimaryStyleData,
} from 'libs/ui/src/lib/modules/010-buttons-and-links/m010-c010-button-primary/m010-c010-button-primary.style';
import {
    M010C015ButtonSecondaryStyleOpel,
    provideM010C015ButtonSecondaryStyleData,
} from 'libs/ui/src/lib/modules/010-buttons-and-links/m010-c015-button-secondary/m010-c015-button-secondary.style';
import {
    M010C015ButtonTertiaryStyleOpel,
    provideM010C015ButtonTertiaryStyleData,
} from 'libs/ui/src/lib/modules/010-buttons-and-links/m010-c017-button-tertiary/m010-c017-button-tertiary.style';
import {
    M010C020ButtonHeroStyleOpel,
    provideM010C020ButtonHeroStyleData,
} from 'libs/ui/src/lib/modules/010-buttons-and-links/m010-c020-button-hero/m010-c020-button-hero.style';
import {
    M010C030ButtonMoreStyleOpel,
    provideM010C030ButtonMoreStyleData,
} from 'libs/ui/src/lib/modules/010-buttons-and-links/m010-c030-button-more/m010-c030-button-more.style';
import {
    M010C050ButtonTagStyleOpel,
    provideM010C050ButtonTagStyleData,
} from 'libs/ui/src/lib/modules/010-buttons-and-links/m010-c050-button-tag/m010-c050-button-tag.style';
import {
    M010C060PriceStyleOpel,
    provideM010C060PriceStyleData,
} from 'libs/ui/src/lib/modules/010-buttons-and-links/m010-c060-price/m010-c060-price.style';
import {
    M010C080PhoneRibbonStyleOpel,
    provideM010C080PhoneRibbonStyleData,
} from 'libs/ui/src/lib/modules/010-buttons-and-links/m010-c080-phone-ribbon/m010-c080-phone-ribbon.style';
import {
    M010C090IconButtonStyleOpel,
    provideM010C090IconButtonStyleData,
} from 'libs/ui/src/lib/modules/010-buttons-and-links/m010-c090-icon-button/m010-c090-icon-button.style';
import {
    M010C100TeaserButtonStyleOpel,
    provideM010C100TeaserButtonStyleData,
} from 'libs/ui/src/lib/modules/010-buttons-and-links/m010-c100-teaser-button/m010-c100-teaser-button.style';
import {
    M010C110ArrowButtonStyleOpel,
    provideM010C110ArrowButtonStyleData,
} from 'libs/ui/src/lib/modules/010-buttons-and-links/m010-c110-arrow-button/m010-c110-arrow-button.style';
import {
    M020C010InputTextStyleOpel,
    provideM020C010InputTextStyleData,
} from 'libs/ui/src/lib/modules/020-form-elements/m020-c010-input-text/m020-c010-input-text.style';
import {
    M020C020InputSelectStyleOpel,
    provideM020C020InputSelectStyleData,
} from 'libs/ui/src/lib/modules/020-form-elements/m020-c020-input-select/m020-c020-input-select.style';
import {
    M020C025InputDealerSelectStyleOpel,
    provideM020C025InputDealerSelectStyleData,
} from 'libs/ui/src/lib/modules/020-form-elements/m020-c025-input-dealer-select/m020-c025-input-dealer-select.style';
import {
    M020C040InputCheckboxStyleOpel,
    provideM020C040InputCheckboxStyleData,
} from 'libs/ui/src/lib/modules/020-form-elements/m020-c040-input-checkbox/m020-c040-input-checkbox.style';
import {
    M020C045InputCheckboxListStyleOpel,
    provideM020C045InputCheckboxListStyleData,
} from 'libs/ui/src/lib/modules/020-form-elements/m020-c045-input-checkbox-list/m020-c045-input-checkbox-list.style';
import {
    M020C046InputCheckboxTagListStyleOpel,
    provideM020C046InputCheckboxTagListStyleData,
} from 'libs/ui/src/lib/modules/020-form-elements/m020-c046-input-checkbox-tag-list/m020-c046-input-checkbox-tag-list.style';
import {
    M020C050InputRangeSliderStyleOpel,
    provideM020C050InputRangeSliderStyleData,
} from 'libs/ui/src/lib/modules/020-form-elements/m020-c050-input-range-slider/m020-c050-input-range-slider.style';
import {
    M020C060InputLocationStyleOpel,
    provideM020C060InputLocationStyleData,
} from 'libs/ui/src/lib/modules/020-form-elements/m020-c060-input-location/m020-c060-input-location.style';
import {
    M020C070InputCheckboxIconStyleOpel,
    provideM020C070InputCheckboxIconStyleData,
} from 'libs/ui/src/lib/modules/020-form-elements/m020-c070-input-checkbox-icon/m020-c070-input-checkbox-icon.style';
import {
    M020C080InputCheckboxColorStyleOpel,
    provideM020C080InputCheckboxColorStyleData,
} from 'libs/ui/src/lib/modules/020-form-elements/m020-c080-input-checkbox-color/m020-c080-input-checkbox-color.style';
import {
    M020C085InputCheckboxColorListStyleOpel,
    provideM020C085InputCheckboxColorListStyleData,
} from 'libs/ui/src/lib/modules/020-form-elements/m020-c085-input-checkbox-color-list/m020-c085-input-checkbox-color-list.style';
import {
    M020C090InputCheckboxEfficiencyListStyleOpel,
    provideM020C090InputCheckboxEfficiencyListStyleData,
} from 'libs/ui/src/lib/modules/020-form-elements/m020-c090-input-checkbox-efficiency-list/m020-c090-input-checkbox-efficiency-list.style';
import {
    M020C100InputPhoneStyleOpel,
    provideM020C100InputPhoneStyleData,
} from 'libs/ui/src/lib/modules/020-form-elements/m020-c100-input-phone/m020-c100-input-phone.style';
import {
    M020C110InputRadioStyleOpel,
    provideM020C110InputRadioStyleData,
} from 'libs/ui/src/lib/modules/020-form-elements/m020-c110-input-radio/m020-c110-input-radio.style';
import {
    M020C120InputRangeSelectStyleOpel,
    provideM020C120InputRangeSelectStyleData,
} from 'libs/ui/src/lib/modules/020-form-elements/m020-c120-input-range-select/m020-c120-input-range-select.style';
import {
    M020C130InputRangeSelectTabsStyleOpel,
    provideM020C130InputRangeSelectTabsStyleData,
} from 'libs/ui/src/lib/modules/020-form-elements/m020-c130-input-range-select-tabs/m020-c130-input-range-select-tabs.style';
import {
    M020C140InputDatepickerStyleOpel,
    provideM020C140InputDatepickerStyleData,
} from 'libs/ui/src/lib/modules/020-form-elements/m020-c140-input-datepicker/m020-c140-input-datepicker.style';
import {
    M020C145InputDatepickerOverlayOnlyStyleOpel,
    provideM020C145InputDatepickerOverlayOnlyStyleData,
} from 'libs/ui/src/lib/modules/020-form-elements/m020-c145-input-datepicker-overlay-only/m020-c145-input-datepicker-overlay-only.style';
import {
    M020C150DatepickerStyleOpel,
    provideM020C150DatepickerStyleData,
} from 'libs/ui/src/lib/modules/020-form-elements/m020-c150-datepicker/m020-c150-datepicker.style';
import {
    M020C160InputNumberStyleOpel,
    provideM020C160InputNumberStyleData,
} from 'libs/ui/src/lib/modules/020-form-elements/m020-c160-input-number/m020-c160-input-number.style';
import {
    M020C170InputTextareaStyleOpel,
    provideM020C170InputTextareaStyleData,
} from 'libs/ui/src/lib/modules/020-form-elements/m020-c170-input-textarea/m020-c170-input-textarea.style';
import {
    M030C010HeroStyleOpel,
    provideM030C010HeroStyleData,
} from 'libs/ui/src/lib/modules/030-content-modules/m030-c010-hero/m030-c010-hero.style';
import {
    M030C020TeaserIconStyleOpel,
    provideM030C020TeaserIconStyleData,
} from 'libs/ui/src/lib/modules/030-content-modules/m030-c020-teaser-icon/m030-c020-teaser-icon.style';
import {
    M030C030TeaserTextStyleOpel,
    provideM030C030TeaserTextStyleData,
} from 'libs/ui/src/lib/modules/030-content-modules/m030-c030-teaser-text/m030-c030-teaser-text.style';
import {
    M030C040TeaserTextStripedStyleOpel,
    provideM030C040TeaserTextStripedStyleData,
} from 'libs/ui/src/lib/modules/030-content-modules/m030-c040-teaser-text-striped/m030-c040-teaser-text-striped.style';
import {
    M030C050TeaserCardStripedStyleOpel,
    provideM030C050TeaserCardStripedStyleData,
} from 'libs/ui/src/lib/modules/030-content-modules/m030-c050-teaser-card-striped/m030-c050-teaser-card-striped.style';
import {
    M030C060TeaserListStripedStyleOpel,
    provideM030C060TeaserListStripedStyleData,
} from 'libs/ui/src/lib/modules/030-content-modules/m030-c060-teaser-list-striped/m030-c060-teaser-list-striped.style';
import {
    M030C070AdditionalPackagesStyleOpel,
    provideM030C070AdditionalPackagesStyleData,
} from 'libs/ui/src/lib/modules/030-content-modules/m030-c070-additional-packages/m030-c070-additional-packages.style';
import {
    M030C080ImageCardStyleOpel,
    provideM030C080ImageCardStyleData,
} from 'libs/ui/src/lib/modules/030-content-modules/m030-c080-image-card/m030-c080-image-card.style';
import {
    M030C090HeroBannerCardStyleOpel,
    provideM030C090HeroBannerCardStyleData,
} from 'libs/ui/src/lib/modules/030-content-modules/m030-c090-hero-banner-card/m030-c090-hero-banner-card.style';
import {
    M030C100ImageCardStyleOpel,
    provideM030C100ImageCardStyleData,
} from 'libs/ui/src/lib/modules/030-content-modules/m030-c100-image-text/m030-c100-image-text.style';
import {
    M030C110SmallImageCardStyleOpel,
    provideM030C110SmallImageCardStyleData,
} from 'libs/ui/src/lib/modules/030-content-modules/m030-c110-small-image-card/m030-c110-small-image-card.style';
import {
    M030C120TeaserCardStyleOpel,
    provideM030C120TeaserCardStyleData,
} from 'libs/ui/src/lib/modules/030-content-modules/m030-c120-teaser-card/m030-c120-teaser-card.style';
import {
    M030C140TopTeaserStyleOpel,
    provideM030C140TopTeaserStyleData,
} from 'libs/ui/src/lib/modules/030-content-modules/m030-c140-top-teaser/m030-c140-top-teaser.style';
import {
    M030C150ImageCardConsultingStyleOpel,
    provideM030C150ImageCardConsultingStyleData,
} from 'libs/ui/src/lib/modules/030-content-modules/m030-c150-image-text-consulting/m030-c150-image-text-consulting.style';
import {
    M030C160TeamMemberStyleOpel,
    provideM030C160TeamMemberStyleData,
} from 'libs/ui/src/lib/modules/030-content-modules/m030-c160-team-member/m030-c160-team-member.style';
import {
    M030C170HeadlineTextStyleOpel,
    provideM030C170HeadlineTextStyleData,
} from 'libs/ui/src/lib/modules/030-content-modules/m030-c170-headline-text/m030-c170-headline-text.style';
import {
    M030C180HeadlineTextSecondaryStyleOpel,
    provideM030C180HeadlineTextSecondaryStyleData,
} from 'libs/ui/src/lib/modules/030-content-modules/m030-c180-headline-text-secondary/m030-c180-headline-text-secondary.style';
import {
    M030C190CtaImageCardStyleOpel,
    provideM030C190CtaImageCardStyleData,
} from 'libs/ui/src/lib/modules/030-content-modules/m030-c190-cta-image-card/m030-c190-cta-image-card.style';
import {
    M030C195CtaImageTeasersStyleOpel,
    provideM030C195CtaImageTeasersStyleData,
} from 'libs/ui/src/lib/modules/030-content-modules/m030-c195-cta-image-teasers/m030-c195-cta-image-teasers.style';
import {
    M030C200HeroTeaserStageStyleOpel,
    provideM030C200HeroTeaserStageStyleData,
} from 'libs/ui/src/lib/modules/030-content-modules/m030-c200-hero-teaser-stage/m030-c200-hero-teaser-stage.style';
import {
    M030C205ArrowButtonListStyleOpel,
    provideM030C205ArrowButtonListStyleData,
} from 'libs/ui/src/lib/modules/030-content-modules/m030-c205-arrow-button-list/m030-c205-arrow-button-list.style';
import {
    M030C210TextblockStyleOpel,
    provideM030C210TextblockStyleData,
} from 'libs/ui/src/lib/modules/030-content-modules/m030-c210-textblock/m030-c210-textblock.style';
import {
    M030C215AccordionStyleOpel,
    provideM030C215AccordionStyleData,
} from 'libs/ui/src/lib/modules/030-content-modules/m030-c215-accordion/m030-c215-accordion.style';
import {
    M030C225BlockTeaserStyleOpel,
    provideM030C225BlockTeaserStyleData,
} from 'libs/ui/src/lib/modules/030-content-modules/m030-c225-block-teaser/m030-c225-block-teaser.style';
import {
    M040C010SelectWrapperStyleOpel,
    provideM040C010SelectWrapperStyleData,
} from 'libs/ui/src/lib/modules/040-content-structure/m040-c010-select-wrapper/m040-c010-select-wrapper.style';
import {
    M040C020TabsStyleOpel,
    provideM040C020TabsStyleData,
} from 'libs/ui/src/lib/modules/040-content-structure/m040-c020-tabs/m040-c020-tabs.style';
import {
    M040C030IconBadgeStyleOpel,
    provideM040C030IconBadgeStyleData,
} from 'libs/ui/src/lib/modules/040-content-structure/m040-c030-icon-badge/m040-c030-icon-badge.style';
import {
    M040C040GalleryStyleOpel,
    provideM040C040GalleryStyleData,
} from 'libs/ui/src/lib/modules/040-content-structure/m040-c040-gallery/m040-c040-gallery.style';
import {
    M040C050IconSummaryStyleOpel,
    provideM040C050IconSummaryStyleData,
} from 'libs/ui/src/lib/modules/040-content-structure/m040-c050-icon-summary/m040-c050-icon-summary.style';
import {
    M040C060GalleryOverlayStyleOpel,
    provideM040C060GalleryOverlayStyleData,
} from 'libs/ui/src/lib/modules/040-content-structure/m040-c060-gallery-overlay/m040-c060-gallery-overlay.style';
import {
    M040C070ActionWrapperStyleOpel,
    provideM040C070ActionWrapperStyleData,
} from 'libs/ui/src/lib/modules/040-content-structure/m040-c070-action-wrapper/m040-c070-action-wrapper.style';
import {
    M040C080TopNewsStyleOpel,
    provideM040C080TopNewsStyleData,
} from 'libs/ui/src/lib/modules/040-content-structure/m040-c080-top-news/m040-c080-top-news.style';
import {
    M040C100TeamWrapperStyleOpel,
    provideM040C100TeamWrapperStyleData,
} from 'libs/ui/src/lib/modules/040-content-structure/m040-c090-team-wrapper/m040-c090-team-wrapper.style';
import {
    M040C100TeamAccordeonStyleOpel,
    provideM040C100TeamAccordeonStyleData,
} from 'libs/ui/src/lib/modules/040-content-structure/m040-c100-team-accordeon/m040-c100-team-accordeon.style';
import { provideM050C010MainnavigationOpelStyleData } from 'libs/ui/src/lib/modules/050-navigation-footer-overlays/m050-c010-mainnavigation/styles/opel';
import {
    M050C020FooterStyleOpel,
    provideM050C020FooterStyleData,
} from 'libs/ui/src/lib/modules/050-navigation-footer-overlays/m050-c020-footer/m050-c020-footer.style';
import {
    M050C030NoteStyleOpel,
    provideM050C030NoteStyleData,
} from 'libs/ui/src/lib/modules/050-navigation-footer-overlays/m050-c030-note/m050-c030-note.style';
import {
    M050C040MainnavigationPwStyleOpel,
    provideM050C040MainnavigationPwStyleData,
} from 'libs/ui/src/lib/modules/050-navigation-footer-overlays/m050-c040-mainnavigation-pw/m050-c040-mainnavigation-pw.style';
import {
    M050C050FooterPwStyleOpel,
    provideM050C050FooterPwStyleData,
} from 'libs/ui/src/lib/modules/050-navigation-footer-overlays/m050-c050-footer-pw/m050-c050-footer-pw.style';
import {
    M050C080MainnavigationPwDealerSearchStyleOpel,
    provideM050C080MainnavigationPwDealerSearchStyleData,
} from 'libs/ui/src/lib/modules/050-navigation-footer-overlays/m050-c080-mainnavigation-pw-dealer-search/m050-c080-mainnavigation-pw-dealer-search.style';
import {
    M060C010VehicleCardStyleOpel,
    provideM060C010VehicleCardStyleData,
} from 'libs/ui/src/lib/modules/060-vehicle-modules/m060-c010-vehicle-card/m060-c010-vehicle-card.style';
import {
    M060C020VehicleHighlightStyleOpel,
    provideM060C020VehicleHighlightStyleData,
} from 'libs/ui/src/lib/modules/060-vehicle-modules/m060-c020-vehicle-highlight/m060-c020-vehicle-highlight.style';
import {
    M060C030VehiclePropertiesStyleOpel,
    provideM060C030VehiclePropertiesStyleData,
} from 'libs/ui/src/lib/modules/060-vehicle-modules/m060-c030-vehicle-properties/m060-c030-vehicle-properties.style';
import {
    M060C050VehicleOverviewStyleOpel,
    provideM060C050VehicleOverviewStyleData,
} from 'libs/ui/src/lib/modules/060-vehicle-modules/m060-c050-vehicle-overview/m060-c050-vehicle-overview.style';
import {
    M060C060VehicleCarouselStyleOpel,
    provideM060C060VehicleCarouselStyleData,
} from 'libs/ui/src/lib/modules/060-vehicle-modules/m060-c060-vehicle-carousel/m060-c060-vehicle-carousel.style';
import {
    M060C080QuickSearchStyleOpel,
    provideM060C080QuickSearchStyleData,
} from 'libs/ui/src/lib/modules/060-vehicle-modules/m060-c080-quick-search/m060-c080-quick-search.style';
import {
    M060C090VehicleListStyleOpel,
    provideM060C090VehicleListStyleData,
} from 'libs/ui/src/lib/modules/060-vehicle-modules/m060-c090-vehicle-list-standalone/m060-c090-vehicle-list.style';
import {
    M070C010RatingStyleOpel,
    provideM070C010RatingStyleData,
} from 'libs/ui/src/lib/modules/070-location-modules/m070-c010-rating/m070-c010-rating.style';
import {
    M070C015RatingExtendedStyleOpel,
    provideM070C015RatingExtendedStyleData,
} from 'libs/ui/src/lib/modules/070-location-modules/m070-c015-rating-extended/m070-c015-rating-extended.style';
import {
    M070C020LocationDetailStyleOpel,
    provideM070C020LocationDetailStyleData,
} from 'libs/ui/src/lib/modules/070-location-modules/m070-c020-location-detail/m070-c020-location-detail.style';
import {
    M070C030LocationContactStyleOpel,
    provideM070C030LocationContactStyleData,
} from 'libs/ui/src/lib/modules/070-location-modules/m070-c030-location-contact/m070-c030-location-contact.style';
import {
    M070C040DealerContactStyleOpel,
    provideM070C040DealerContactStyleData,
} from 'libs/ui/src/lib/modules/070-location-modules/m070-c040-dealer-contact/m070-c040-dealer-contact.style';
import {
    M070C050DealerServiceStyleOpel,
    provideM070C050DealerServiceStyleData,
} from 'libs/ui/src/lib/modules/070-location-modules/m070-c050-dealer-service/m070-c050-dealer-service.style';
import {
    M070C060DealerMapStyleOpel,
    provideM070C060DealerMapStyleData,
} from 'libs/ui/src/lib/modules/070-location-modules/m070-c060-dealer-map/m070-c060-dealer-map.style';
import {
    AboutUsTemplateStyleOpel,
    provideAboutUsTemplateStyleData,
} from 'libs/ui/src/lib/pages/about-us/about-us-template.style';
import { TradeInStyleOpel, provideTradeInStyleData } from 'libs/ui/src/lib/pages/checkouts/trade-in/trade-in.style';
import {
    HomePartnerWebStyleOpel,
    provideHomePartnerWebStyleData,
} from 'libs/ui/src/lib/pages/home-partner-web/home-partner-web.style';
import { LocationsStyleOpel, provideLocationsStyleData } from 'libs/ui/src/lib/pages/locations/locations.style';
import {
    NewsDetailTemplateStyleOpel,
    provideNewsDetailTemplateStyleData,
} from 'libs/ui/src/lib/pages/news/news-detail-template/news-detail-template.style';
import {
    NewsListTemplateStyleOpel,
    provideNewsListTemplateStyleData,
} from 'libs/ui/src/lib/pages/news/news-list-template/news-list-template.style';
import {
    NotFoundPageStyleOpel,
    provideNotFoundPageStyleData,
} from 'libs/ui/src/lib/pages/not-found-page-template/not-found-page-template.style';
import {
    PromotionNotAvailablePageStyleOpel,
    providePromotionNotAvailablePageStyleData,
} from 'libs/ui/src/lib/pages/promotion-not-available-page-template/promotion-not-available-page-template.style';
import {
    PromotionsDetailTemplateStyleOpel,
    providePromotionsDetailTemplateStyleData,
} from 'libs/ui/src/lib/pages/promotion/promotion-detail-template/promotion-detail-template.style';
import {
    PromotionListTemplateStyleOpel,
    providePromotionListTemplateStyleData,
} from 'libs/ui/src/lib/pages/promotion/promotion-list-template/promotion-list-template.style';
import {
    ServiceDetailTemplateStyleOpel,
    provideServiceDetailTemplateStyleData,
} from 'libs/ui/src/lib/pages/service/service-detail-template/service-detail-template.style';
import {
    ServiceListTemplateStyleOpel,
    provideServiceListTemplateStyleData,
} from 'libs/ui/src/lib/pages/service/service-list-template/service-list-template.style';
import {
    ServiceTemplateStyleOpel,
    provideServiceTemplateStyleData,
} from 'libs/ui/src/lib/pages/service/service-template.style';
import { provideVehicleMarketDetailStyleDataOpel } from 'libs/ui/src/lib/pages/vehicle-market/vehicle-market-detail/styles/opel';

import { provideVdpCtaStyleOpel } from 'libs/ui/src/lib/modules/060-vehicle-modules/m060-c110-cta/styles/opel';
import { BuyStyleOpel, provideBuyStyleData } from 'libs/ui/src/lib/pages/checkouts/buy/buy.style';
import { LeasingStyleOpel, provideLeasingStyleData } from 'libs/ui/src/lib/pages/checkouts/leasing/leasing.style';
import { provideOpelVehicleMarketListStyle } from 'libs/ui/src/lib/pages/vehicle-market/vehicle-market-list/styles/opel';
import {
    VehicleNotAvailablePageStyleOpel,
    provideVehicleNotAvailablePageStyleData,
} from 'libs/ui/src/lib/pages/vehicle-not-available-page-template/vehicle-not-available-page-template.style';

export const styleProviders = [
    provideM010C010ButtonPrimaryStyleData(M010C010ButtonPrimaryStyleOpel),
    provideM010C015ButtonSecondaryStyleData(M010C015ButtonSecondaryStyleOpel),
    provideM010C015ButtonTertiaryStyleData(M010C015ButtonTertiaryStyleOpel),
    provideM010C020ButtonHeroStyleData(M010C020ButtonHeroStyleOpel),
    provideM010C030ButtonMoreStyleData(M010C030ButtonMoreStyleOpel),
    provideM010C050ButtonTagStyleData(M010C050ButtonTagStyleOpel),
    provideM010C060PriceStyleData(M010C060PriceStyleOpel),
    provideM010C080PhoneRibbonStyleData(M010C080PhoneRibbonStyleOpel),
    provideM010C090IconButtonStyleData(M010C090IconButtonStyleOpel),
    provideM010C100TeaserButtonStyleData(M010C100TeaserButtonStyleOpel),

    provideM020C010InputTextStyleData(M020C010InputTextStyleOpel),
    provideM020C020InputSelectStyleData(M020C020InputSelectStyleOpel),
    provideM020C025InputDealerSelectStyleData(M020C025InputDealerSelectStyleOpel),
    provideM020C040InputCheckboxStyleData(M020C040InputCheckboxStyleOpel),
    provideM020C045InputCheckboxListStyleData(M020C045InputCheckboxListStyleOpel),
    provideM020C046InputCheckboxTagListStyleData(M020C046InputCheckboxTagListStyleOpel),
    provideM020C050InputRangeSliderStyleData(M020C050InputRangeSliderStyleOpel),
    provideM020C060InputLocationStyleData(M020C060InputLocationStyleOpel),
    provideM020C070InputCheckboxIconStyleData(M020C070InputCheckboxIconStyleOpel),
    provideM020C080InputCheckboxColorStyleData(M020C080InputCheckboxColorStyleOpel),
    provideM020C085InputCheckboxColorListStyleData(M020C085InputCheckboxColorListStyleOpel),
    provideM020C090InputCheckboxEfficiencyListStyleData(M020C090InputCheckboxEfficiencyListStyleOpel),
    provideM020C100InputPhoneStyleData(M020C100InputPhoneStyleOpel),
    provideM020C110InputRadioStyleData(M020C110InputRadioStyleOpel),
    provideM020C120InputRangeSelectStyleData(M020C120InputRangeSelectStyleOpel),
    provideM020C130InputRangeSelectTabsStyleData(M020C130InputRangeSelectTabsStyleOpel),
    provideM020C140InputDatepickerStyleData(M020C140InputDatepickerStyleOpel),
    provideM020C145InputDatepickerOverlayOnlyStyleData(M020C145InputDatepickerOverlayOnlyStyleOpel),
    provideM020C150DatepickerStyleData(M020C150DatepickerStyleOpel),
    provideM020C160InputNumberStyleData(M020C160InputNumberStyleOpel),
    provideM020C170InputTextareaStyleData(M020C170InputTextareaStyleOpel),

    provideM030C010HeroStyleData(M030C010HeroStyleOpel),
    provideM030C020TeaserIconStyleData(M030C020TeaserIconStyleOpel),
    provideM030C030TeaserTextStyleData(M030C030TeaserTextStyleOpel),
    provideM030C040TeaserTextStripedStyleData(M030C040TeaserTextStripedStyleOpel),
    provideM030C050TeaserCardStripedStyleData(M030C050TeaserCardStripedStyleOpel),
    provideM030C060TeaserListStripedStyleData(M030C060TeaserListStripedStyleOpel),
    provideM030C070AdditionalPackagesStyleData(M030C070AdditionalPackagesStyleOpel),
    provideM030C080ImageCardStyleData(M030C080ImageCardStyleOpel),
    provideM030C090HeroBannerCardStyleData(M030C090HeroBannerCardStyleOpel),
    provideM030C100ImageCardStyleData(M030C100ImageCardStyleOpel),
    provideM030C110SmallImageCardStyleData(M030C110SmallImageCardStyleOpel),
    provideM030C120TeaserCardStyleData(M030C120TeaserCardStyleOpel),
    provideM030C140TopTeaserStyleData(M030C140TopTeaserStyleOpel),
    provideM030C150ImageCardConsultingStyleData(M030C150ImageCardConsultingStyleOpel),
    provideM030C160TeamMemberStyleData(M030C160TeamMemberStyleOpel),
    provideM030C170HeadlineTextStyleData(M030C170HeadlineTextStyleOpel),
    provideM030C180HeadlineTextSecondaryStyleData(M030C180HeadlineTextSecondaryStyleOpel),
    provideM030C190CtaImageCardStyleData(M030C190CtaImageCardStyleOpel),
    provideM030C195CtaImageTeasersStyleData(M030C195CtaImageTeasersStyleOpel),

    provideM040C010SelectWrapperStyleData(M040C010SelectWrapperStyleOpel),
    provideM040C020TabsStyleData(M040C020TabsStyleOpel),
    provideM040C030IconBadgeStyleData(M040C030IconBadgeStyleOpel),
    provideM040C040GalleryStyleData(M040C040GalleryStyleOpel),
    provideM040C050IconSummaryStyleData(M040C050IconSummaryStyleOpel),
    provideM040C060GalleryOverlayStyleData(M040C060GalleryOverlayStyleOpel),
    provideM040C070ActionWrapperStyleData(M040C070ActionWrapperStyleOpel),
    provideM040C100TeamWrapperStyleData(M040C100TeamWrapperStyleOpel),
    provideM040C100TeamAccordeonStyleData(M040C100TeamAccordeonStyleOpel),

    provideM050C010MainnavigationOpelStyleData(),
    provideM050C020FooterStyleData(M050C020FooterStyleOpel),
    provideM050C030NoteStyleData(M050C030NoteStyleOpel),

    provideM050C040MainnavigationPwStyleData(M050C040MainnavigationPwStyleOpel),
    provideM050C080MainnavigationPwDealerSearchStyleData(M050C080MainnavigationPwDealerSearchStyleOpel),

    provideM050C050FooterPwStyleData(M050C050FooterPwStyleOpel),

    provideM070C010RatingStyleData(M070C010RatingStyleOpel),
    provideM070C015RatingExtendedStyleData(M070C015RatingExtendedStyleOpel),
    provideM070C020LocationDetailStyleData(M070C020LocationDetailStyleOpel),
    provideM070C030LocationContactStyleData(M070C030LocationContactStyleOpel),
    provideM070C040DealerContactStyleData(M070C040DealerContactStyleOpel),
    provideM070C050DealerServiceStyleData(M070C050DealerServiceStyleOpel),
    provideM070C060DealerMapStyleData(M070C060DealerMapStyleOpel),

    provideLanguageSwitchStyleData(LanguageSwitchStyleOpel),
    provideTagDialogStyleData(TagDialogStyleOpel),
    provideVehicleMarketFilterDialogStyleData(VehicleMarketPlatformFilterDialogStyleOpel),

    provideS010ContactDataStyleData(S010ContactDataStyleOpel),
    provideS015ContactDataExtendedStyleData(S015ContactDataExtendedStyleOpel),
    provideS020PickupOrDeliveryStyleData(S020PickupOrDeliveryStyleOpel),
    provideS030AdditionalBenefitsStyleData(S030AdditionalBenefitsStyleOpel),
    provideS040ThanksWithVehicleStyleData(S040ThanksWithVehicleStyleOpel),
    provideS050TestdriveContactDataStyleData(S050TestdriveContactDataStyleOpel),
    provideS060VehicleDataStyleData(S060VehicleDataStyleOpel),
    provideS065VehicleDataNoDropdownStyleData(S065VehicleDataNoDropdownStyleOpel),
    provideS070ThanksNoVehicleStyleData(S070ThanksNoVehicleStyleOpel),
    provideS080ThanksTestdriveStyleData(S080ThanksTestdriveStyleOpel),
    provideS100ServicesStyleData(S100ServicesStyleOpel),
    provideS110ThanksServiceStyleData(S110ThanksServiceStyleOpel),

    provideM060C010VehicleCardStyleData(M060C010VehicleCardStyleOpel),
    provideM060C020VehicleHighlightStyleData(M060C020VehicleHighlightStyleOpel),
    provideM060C030VehiclePropertiesStyleData(M060C030VehiclePropertiesStyleOpel),
    provideM060C050VehicleOverviewStyleData(M060C050VehicleOverviewStyleOpel),
    provideM060C060VehicleCarouselStyleData(M060C060VehicleCarouselStyleOpel),

    provideVehicleMarketDetailStyleDataOpel(),
    provideOpelVehicleMarketListStyle(),

    provideNewsListTemplateStyleData(NewsListTemplateStyleOpel),
    provideNewsDetailTemplateStyleData(NewsDetailTemplateStyleOpel),

    providePromotionListTemplateStyleData(PromotionListTemplateStyleOpel),
    providePromotionsDetailTemplateStyleData(PromotionsDetailTemplateStyleOpel),

    provideAboutUsTemplateStyleData(AboutUsTemplateStyleOpel),

    provideHomePartnerWebStyleData(HomePartnerWebStyleOpel),
    provideLocationsStyleData(LocationsStyleOpel),

    provideServiceTemplateStyleData(ServiceTemplateStyleOpel),

    provideM060C080QuickSearchStyleData(M060C080QuickSearchStyleOpel),

    provideNotFoundPageStyleData(NotFoundPageStyleOpel),
    provideM030C210TextblockStyleData(M030C210TextblockStyleOpel),
    provideServiceListTemplateStyleData(ServiceListTemplateStyleOpel),
    provideM030C200HeroTeaserStageStyleData(M030C200HeroTeaserStageStyleOpel),
    provideM010C110ArrowButtonStyleData(M010C110ArrowButtonStyleOpel),
    provideM030C205ArrowButtonListStyleData(M030C205ArrowButtonListStyleOpel),
    provideServiceDetailTemplateStyleData(ServiceDetailTemplateStyleOpel),
    provideM030C215AccordionStyleData(M030C215AccordionStyleOpel),
    provideM040C080TopNewsStyleData(M040C080TopNewsStyleOpel),

    provideVehicleNotAvailablePageStyleData(VehicleNotAvailablePageStyleOpel),
    providePromotionNotAvailablePageStyleData(PromotionNotAvailablePageStyleOpel),
    provideCheckoutWrapperStyleData(CheckoutWrapperStyleOpel),
    provideM060C090VehicleListStyleData(M060C090VehicleListStyleOpel),

    provideTradeInStyleData(TradeInStyleOpel),
    provideM030C225BlockTeaserStyleData(M030C225BlockTeaserStyleOpel),
    provideBuyStyleData(BuyStyleOpel),
    provideLeasingStyleData(LeasingStyleOpel),
    provideVdpCtaStyleOpel(),
];
