import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Injectable, PLATFORM_ID, Renderer2, RendererFactory2, inject } from '@angular/core';
import { CookieService } from 'libs/ng-common-lib/src/services/cookie.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class FusedeckService {
    public matelsoLoaded$ = new BehaviorSubject<boolean>(false);

    private readonly rendererFactory: RendererFactory2 = inject(RendererFactory2);
    private readonly renderer2: Renderer2;
    private readonly document: Document = inject(DOCUMENT);
    private readonly cookieService: CookieService = inject(CookieService);
    private readonly platformId: object = inject(PLATFORM_ID);

    isLoaded = false;

    constructor() {
        this.renderer2 = this.rendererFactory.createRenderer(null, null);
    }

    initFusedeck(fusedeckId: string, fusedeckUrl: string): void {
        if (isPlatformBrowser(this.platformId)) {
            this.addFuseDeckScriptToDom(fusedeckId, fusedeckUrl);
        }
    }

    private async addFuseDeckScriptToDom(fusedeckId: string, fusedeckUrl: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            if (this.isLoaded) {
                return resolve(this.isLoaded);
            }

            const fuseDeckScript = this.buildFusedeckScriptTag(fusedeckId, fusedeckUrl);
            fuseDeckScript.addEventListener('load', () => {
                return resolve((this.isLoaded = true));
            });
            fuseDeckScript.addEventListener('error', () => {
                return reject(false);
            });

            // eslint-disable-next-line @rx-angular/no-zone-critical-browser-apis
            setTimeout(() => {
                this.renderer2.appendChild(this.document.head, fuseDeckScript);
            });
        });
    }

    private buildFusedeckScriptTag(fusedeckId: string, fusedeckUrl: string) {
        const p = fusedeckId;
        const c = this.cookieService.get(`fdTrackingType${p}`) ?? null;
        const k = this.cookieService.get('fdEnvId') ? `&e=${this.cookieService.get('fdEnvId')}` : '';
        const n_ = null;
        const h = encodeURIComponent(location.href);
        const a = n_ !== null ? encodeURIComponent(n_) : '';
        const fuseDeckScript = this.renderer2.createElement('script');
        fuseDeckScript.async = true;
        fuseDeckScript.id = 'gtm-js';
        fuseDeckScript.src = `${fusedeckUrl}?p=${p}&l=${c ? `&c=${c}` : ''}${k}&h=${h}&a=${a}`;
        return fuseDeckScript;
    }
}
