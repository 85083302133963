import { AboutUsLabels } from 'libs/shared-lib/interfaces/partner-web/about-us';
import { m070c040DealerContact } from '../../shared/shared.de';

export const ABOUT_US_LABELS_DE: AboutUsLabels = {
    aboutUsHeadline: 'Über uns',
    brandTextHeadline: 'This is Opel',
    brandTextCopy:
        'Neue, innovative Technologien, bahnbrechende Erfindungen – wir leben in einer spannenden Zeit. Doch erst wenn neue Erfindungen jedem zur Verfügung stehen, können sie auch die Welt verändern und verbessern. Innovationen einer grossen Zahl von Menschen zugänglich zu machen, ist fest verwurzelt in der DNA der Marke Opel. Mutig, innovativ und mit klarem Design: THIS IS OPEL',
    moreLinkId: 'ueber-uns',
    moreLabel: 'Mehr erfahren',
    phoneLabel: 'Wir beraten dich gern:',
    ourTeamHeadline: 'Unser Team',
    dealerContactLabels: m070c040DealerContact,
    readMore: 'Weiterlesen',
};
