import { NewsLabels } from 'libs/shared-lib/interfaces/partner-web/news';
import { m030c195CtaImageTeasers, m070c040DealerContact } from '../../shared/shared.fr';

export const NEWS_LABELS_FR: NewsLabels = {
    list: {
        headline: 'Dernières nouvelles',
        showMore: 'En savoir plus',
    },
    detail: {
        m070c040DealerContact,
        m030c195CtaImageTeasers,
        at: 'Chez',
        in: 'à',
        and: 'et',
        region: 'dans la région',
        andLocation: 'ainsi que sur notre site à',
        andLocations: 'ainsi que sur nos sites à',
    },
};
