import { isPlatformServer } from '@angular/common';
import { Injectable, PLATFORM_ID, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { trackingActions } from 'libs/ng-common-lib/src/+state/tracking.actions';
import { wholesaleFeature } from 'libs/ng-common-lib/src/+state/wholesale.reducer';
import { isDefined } from 'libs/ng-common-lib/src/utilities/helpers';
import { DataLayer, DataLayerEntry } from 'libs/shared-lib/interfaces/global/tracking';
import { ReplaySubject, Subject, filter, take } from 'rxjs';

declare global {
    interface Window {
        dataLayer: DataLayer;
    }
}

@Injectable({
    providedIn: 'root',
})
export class EventTrackingService {
    protected readonly store = inject(Store);
    private readonly platformId: object = inject(PLATFORM_ID);
    protected readonly dataLayerPushs$ = new ReplaySubject<DataLayerEntry>();

    locale$ = this.store.select(wholesaleFeature.selectLanguageAfterFirstSwitchLanguageOnRoute);
    public clientIp$ = new Subject<string>();

    init(): void {
        this.dataLayerPushs$.subscribe((pushObject) => {
            this.getDataLayer().push(pushObject);
        });
        this.locale$
            .pipe(
                filter((lang) => isDefined(lang)),
                take(1),
            )
            .subscribe(() => {
                this.store.dispatch(trackingActions.setIsInternalIP());
            });
    }

    protected getDataLayer(): DataLayer {
        if (isPlatformServer(this.platformId)) {
            return [];
        }
        if (!window.dataLayer) {
            window.dataLayer = [];
        }
        return window.dataLayer;
    }

    public pushDataLayer(dataLayer: DataLayerEntry): void {
        this.dataLayerPushs$.next(dataLayer);
    }
}
