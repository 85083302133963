import { WholesaleLanguage } from '../language/language';

export const PromotionNotAvailableSlugs = {
    de: 'aktion-nicht-verfuegbar',
    fr: 'action-non-disponible',
    it: 'promozione-non-disponibile',
    sl: '-SL',
} as const;

export function getPromotionNotAvailableUrl(lang: WholesaleLanguage, dealerSlug?: string): string {
    return `${dealerSlug ? `/${dealerSlug}` : ''}/${lang}/${PromotionNotAvailableSlugs[lang]}`;
}
