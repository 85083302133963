import { InjectionToken } from '@angular/core';

export const M030C020TeaserIconStyleToyota: M030C020TeaserIconStyleData = {
    div: 'py-34 lg:pt-0 lg:pb-34 px-60',
    icon: {
        size: 'w-60 h-60',
        color: 'fill-light-text',
    },
    headline: 'text-light-text text-center text-16 leading-24 font-semibold mt-xl mb-lg',
    copy: 'text-light-text text-center text-16 leading-24',
    border: 'block md:hidden border-b border-b-grey-200 mx-lg',
};

export const M030C020TeaserIconStyleOpel: M030C020TeaserIconStyleData = {
    div: '',
    icon: {
        size: 'w-50 h-50',
        color: 'fill-light-text',
    },
    headline:
        'mx-auto max-w-430 md:max-w-270 px-16 md:px-0 my-16 text-light-text font-semibold text-16 leading-24 text-center',
    copy: 'mx-auto max-w-430 md:max-w-270 px-16 md:px-0 font-light text-light-text text-15 leading-24 text-center',
    border: 'block md:hidden border-b border-b-grey-200 mx-16 mt-30',
};

export const M030C020TeaserIconStyleLexus: M030C020TeaserIconStyleData = {
    div: '',
    icon: {
        size: 'w-50 h-50',
        color: 'fill-light-text',
    },
    headline:
        'mx-auto max-w-430 md:max-w-270 px-16 mt-24 mb-16 text-light-text text-18 leading-30 text-center font-book tracking-1',
    copy: 'mx-auto max-w-430 md:max-w-270 px-16 mt-24 mb-16 text-light-text text-18 leading-30 text-center font-book tracking-1',
    border: 'hidden',
};

export const M030C020TeaserIconStyleLandRover: M030C020TeaserIconStyleData = {
    div: '',
    icon: {
        size: 'w-64 h-64',
        color: 'fill-light-text',
        position: 'flex max-w-430 md:max-w-300  ',
    },
    headline: 'max-w-full md:max-w-270 mt-24 mb-16 text-light-text text-16 leading-16 uppercase text-left font-bold',
    copy: 'max-w-full md:max-w-270 font-secondary text-14 leading-21 mt-16 text-light-text text-left',
    border: 'hidden',
};

export const M030C020TeaserIconStyleJaguar: M030C020TeaserIconStyleData = {
    div: '',
    icon: {
        size: 'w-50 h-50',
        color: 'fill-dark-text',
        position: 'flex md:max-w-300',
    },
    headline: 'md:max-w-300 mt-24 text-dark-text text-15 leading-15 text-left font-bold',
    copy: 'md:max-w-300 mt-16 mb-46 md:mb-0 md:pr-30 text-dark-text text-16 leading-22 text-left font-normal',
    border: 'hidden',
};

export const M030C020TeaserIconStyleToken = new InjectionToken<M030C020TeaserIconStyleData>(
    'M030C020TeaserIconStyleToken',
);

export function provideM030C020TeaserIconStyleData(style: M030C020TeaserIconStyleData) {
    return {
        provide: M030C020TeaserIconStyleToken,
        useValue: style,
    };
}

export interface M030C020TeaserIconStyleData {
    div: string;
    icon: {
        size: string;
        color: string;
        position?: string;
    };
    headline: string;
    copy: string;
    border: string;
}
