import { DealerSearchLabels } from 'libs/shared-lib/interfaces/dealersearch/dealer-search-labels';

export const DEALER_SEARCH_LABELS_DE: DealerSearchLabels = {
    item: {
        ourModels: 'Unsere Modelle',
        route: 'Route',
        toSite: 'Zur Webseite',
        distance: function (distance: number): string {
            return `Distanz: ${distance} km`;
        },
        sell: 'Verkauf',
        service: 'Service',
        warehouse: 'Lager',
        time: function (time: string): string {
            return `${time} Uhr`;
        },
        status: {
            opened: 'Jetzt geöffnet',
            closed: 'Geschlossen',
            closingSoon: 'Schließt bald',
            openingSoon: 'Öffnet bald',
        },
    },
    resultList: {
        showMore: 'Mehr anzeigen',
    },
    autocomplete: {
        currentLocation: 'Aktuellen Standort verwenden',
        searchPlaceholder: 'Suche nach Garage',
    },
    backToMap: 'Zurück zur Karte',
    backToList: 'Zurück zur Liste',
    searchInput: {
        search: 'Garagenname, PLZ oder Ort eingeben',
    },
    filter: {
        button: 'Filter',
        reset: 'Filter zurücksetzen',
        headline: 'Ergebnisse filtern',
        sell: 'Standorte mit Verkauf',
        service: 'Standorte mit Service',
    },
};
