import { CheckoutsLabels } from 'libs/shared-lib/interfaces/checkout/checkouts.interface';
import {
    errorInvalid,
    errorMessage,
    errorRequired,
    footerLinks,
    gender,
    m060C050vehicleOverviewLabels,
    phoneNumberPrefixes,
} from '../../shared/shared.it';

export const CHECKOUTS_LABELS_IT: CheckoutsLabels = {
    buy: {
        S010ContactData: {
            stepLabel: 'Dati di contatto',
            howToBox: {
                headline: "Ecco come ottenere un preventivo online per l'auto dei tuoi sogni:",
                stepText: [
                    'Inserire i dati di contatto',
                    'Scegliere il ritiro o la consegna',
                    'Scegliere le prestazioni supplementari',
                    'Finito! Il tuo consulente di vendita personale ti contatterà a breve!',
                ],
            },
            title: 'I tuoi dati di contatto',
            headline: 'Per la richiesta di offerta, si prega di compilare completamente le seguenti informazioni.',
            gender: gender,
            firstName: 'Nome',
            lastName: 'Cognome',
            email: 'Indirizzo e-mail',
            phoneNumber: 'Il tuo numero di telefono',
            privacyNoticeBefore: 'Ho letto e capito la ',
            privacyNoticeLink: 'disposizione in materia di protezione dei dati.',
            privacyNoticeUrl: footerLinks.DATA_POLICY,
            privacyNoticeAfter: '',
            nextBtn: 'Avanti',
            errors: {
                required: errorRequired,
                pattern: errorInvalid,
            },
            phoneNumberPrefixes: phoneNumberPrefixes,
            preferredContact: {
                headline: 'Accetto di essere contattato via:',
                email: 'E-mail',
                phone: 'Telefono',
                mail: 'Posta',
                sms: 'SMS/WhatsApp',
            },
            footnote: 'I campi contrassegnati con un * sono obbligatori.',
        },
        S020PickupOrDelivery: {
            stepLabel: 'Ritiro/consegna',
            title: 'Ritiro/consegna',
            headline: 'Come desideri ricevere l’auto dei tuoi sogni?',
            tabs: {
                pickup: 'Ritirate presso il partner Opel',
                delivery: 'Consegna presso questo indirizzo',
            },
            gender: gender,
            dealer: 'Selezionare garage',
            street: 'Strada',
            streetNumber: 'N°',
            zip: 'NPA',
            city: 'Luogo',
            nextBtn: 'Avanti',
            summary: {
                headline: "Abbiamo annotato quanto segue per la consegna dell'auto dei tuoi sogni:",
            },
            errors: {
                required: errorRequired,
                pattern: errorInvalid,
            },
            requiredNotice: 'I campi contrassegnati con * sono obbligatori.',
        },
        S030AdditionalBenefits: {
            stepLabel: 'Prestazioni supplementari',
            title: 'Prestazioni supplementari',
            headline: 'Di cosa desideri beneficiare aggiuntivamente?',
            benefits: [
                {
                    identifier: 'multiassurance_advantage',
                    headline: "Veloce, semplice, completo e da un'unica fonte: la nostra assicurazione veicoli.",
                    subline: 'Approfitta di tutti i vantaggi della nostra assicurazione MultiAssurance:',
                    items: [
                        'Copertura vetri estesa senza franchigia',
                        'Assicurazione viaggi MultiAssistance gratis per tutta la famiglia per un anno intero.',
                    ],
                },
                {
                    identifier: 'car_return',
                    headline: 'Permuta veicolo con valutazione di un professionista.',
                    subline: 'Volete permutare il tuo veicolo?',
                    items: [
                        'Saremo lieti di valutare il tuo veicolo gratuitamente e senza impegno presso la nostra officina Opel.',
                    ],
                },
            ],
            select: 'Selezionare',
            summary: {
                headline: 'Prestazioni supplementari',
                subline: 'Riceverete dei preventivi separati per le prestazioni supplementari da voi scelti:',
            },
            nextBtn: 'Inviare',
            errors: {
                required: errorRequired,
                pattern: errorInvalid,
            },
            M030C070AdditionalPackages: {
                headline: 'I nostri accessori raccomandati',
                subheadline: '',
                from: 'da ',
            },
        },
        S040ThanksWhithVehicle: {
            error: errorMessage,
            headline: 'Grazie mille,',
            headlineAfter: '!',
            female: gender.female,
            male: gender.male,
            copy: 'Abbiamo ricevuto la tua richiesta. Ti ricontatteremo il prima possibile.<br><br>Il tuo partner Opel ',
            contactCard: {
                headline: "C'è qualcos'altro che possiamo fare per voi?",
                copy: "C'è qualcos'altro che possiamo fare per voi?",
                phone: 'Chiamare',
                mail: 'E-Mail',
            },
        },
        M060C050vehicleOverview: m060C050vehicleOverviewLabels,
    },
    tradeIn: {
        menuItem: 'DEPRECATED',
        S010ContactData: {
            stepLabel: 'Dati di contatto',
            title: 'I tuoi dati di contatto',
            headline:
                'Per una valutazione gratuita del tuo veicolo, ti preghiamo di compilare completamente le seguenti informazioni.',
            gender: gender,
            firstName: 'Nome',
            lastName: 'Cognome',
            email: 'Indirizzo e-mail',
            phoneNumber: 'Il tuo numero di telefono',
            privacyNoticeBefore: 'Ho letto e capito la ',
            privacyNoticeLink: 'disposizione sulla protezione dei dati.',
            privacyNoticeUrl: footerLinks.DATA_POLICY,
            privacyNoticeAfter: '',
            nextBtn: 'Inviare',
            errors: {
                required: errorRequired,
                pattern: errorInvalid,
            },
            dealer: {
                headline: '',
                label: '',
            },
            phoneNumberPrefixes: phoneNumberPrefixes,
            footnote: 'I campi contrassegnati con * sono obbligatori.',
            preferredContact: {
                headline: 'Accetto di essere contattato via:',
                email: 'E-mail',
                phone: 'Telefono',
                mail: 'Posta',
                sms: 'SMS/WhatsApp',
            },
        },
        S060VehicleSelect: {
            stepLabel: 'VehicleSelect',
            headline: 'Informazioni relative alla tua veicolo',
            copy: 'Compila i campi inserendo le informazioni relative al tuo veicolo.',
            make: 'Marchio',
            model: 'Modello',
            engine: 'Motorizzazione (opzionale)',
            firstReg: 'Prima immatricolazione',
            mileage: 'Chilometraggio',
            footnote: 'I campi contrassegnati con * sono obbligatori.',
            nextBtn: 'Avanti',
            errorRequired: 'Questo campo è obbligatorio',
            errorInvalid: 'Inserimento non valido',
        },
        S070ThanksNoVehicle: {
            headline: {
                before: 'Grazie',
                after: '!',
            },
            female: gender.female,
            male: gender.male,
            copy: 'Grazie per averci proposto l’acquisto del suo veicolo. Non vediamo l’ora di prendere contatto con te.',
            nextStep: {
                headline: 'Come andare avanti?',
                copy: 'Vi contatteremo per procedere alla valutazione dettagliata del tuo veicolo.',
                name: 'Il tuo partner Opel ',
            },
        },
        M060C050vehicleOverview: m060C050vehicleOverviewLabels,
    },
    testDrive: {
        S050TestDriveContactData: {
            stepLabel: 'Dati di contatto',
            title: 'Dati di contatto',
            headline: 'Inserite qui i tuoi dati di contatto:',
            subheadline: 'Per favore contattatemi tramite:',
            gender: gender,
            phoneSwitch: {
                email: 'E-Mail',
                phone: 'Telefono',
            },
            wishDate: 'Appuntamento desiderato',
            firstName: 'Nome',
            lastName: 'Cognome',
            zip: 'NPA',
            email: 'Indirizzo e-mail',
            message: 'Il tuo messaggio',
            phoneNumber: 'Numero di telefono',
            // contactTime: 'Quando siete più disponibili?',
            // contactTimeOptions: [
            //     {
            //         value: 'Mattina',
            //         label: 'Mattina',
            //     },
            //     {
            //         value: 'Mezzogiorno',
            //         label: 'Mezzogiorno',
            //     },
            //     {
            //         value: 'Pomeriggio',
            //         label: 'Pomeriggio',
            //     },
            // ],
            contactsHeadline: 'Accetto di essere contattato via:',
            contacts: {
                email: 'E-mail',
                phone: 'Telefono',
                mail: 'Posta',
                messenger: 'SMS/WhatsApp',
            },
            privacyNoticeBefore: 'Ho letto e capito la ',
            privacyNoticeLink: 'disposizione sulla protezione dei dati.',
            privacyNoticeUrl: footerLinks.DATA_POLICY,
            privacyNoticeAfter: '',
            requiredNotice: 'I campi contrassegnati con * sono obbligatori.',
            nextBtn: 'Inviare',
            errors: {
                required: errorRequired,
                pattern: errorInvalid,
            },
            summary: {
                name: 'Nome',
                address: 'NPA',
                email: 'Indirizzo e-mail',
                phone: 'Numero di telefono',
                wishDate: 'Appuntamento desiderato',
                seller: 'Garage',
            },
            phoneNumberPrefixes: phoneNumberPrefixes,
        },
        S080ThanksTestDrive: {
            error: errorMessage,
            headline: 'La tua richiesta è stata inviata con successo.',
            headlineAfter: '',
            female: '',
            male: '',
            copy: 'Grazie! Abbiamo ricevuto la tua richiesta.<br>Ti ricontatteremo il prima possibile. <br><br>Il vostro partner Opel<br>',
            subheadline: 'Riepilogo',
            M060C050VehicleOverview: m060C050vehicleOverviewLabels,
            contactCard: {
                headline: "C'è qualcos'altro che possiamo fare per voi?",
                copy: 'I nostri specialisti saranno lieti di offrirle una consulenza',
                phone: 'Chiamare',
                mail: 'E-Mail',
            },
            iconSummary: {
                headline: 'Cambiare appuntamento desiderato',
            },
        },
        M060C050vehicleOverview: m060C050vehicleOverviewLabels,
    },
    leasing: {
        S010ContactData: {
            stepLabel: 'Dati di contatto',
            howToBox: {
                headline: "Ecco come ottenere un’offerta di leasing online per l'auto dei vostri sogni:",
                stepText: [
                    'Inserire i dati di contatto',
                    'Impostare rata di leasing con soli pochi clic',
                    'Scegliere le prestazioni supplementari',
                    'Finito! Il vostro consulente di vendita personale vi contatterà a breve!',
                ],
            },
            title: 'Dati di contatto',
            headline: 'Inserire i suoi dati in maniera semplice e rapida.',
            gender: gender,
            firstName: 'Nome',
            lastName: 'Cognome',
            email: 'Indirizzo e-mail',
            phoneNumber: 'Numero di telefono',
            privacyNoticeBefore: 'Ho letto e capito la ',
            privacyNoticeLink: 'disposizione in materia di protezione dei dati.',
            privacyNoticeUrl: footerLinks.DATA_POLICY,
            privacyNoticeAfter: '',
            nextBtn: 'Avanti',
            errors: {
                required: errorRequired,
                pattern: errorInvalid,
            },
            phoneNumberPrefixes: phoneNumberPrefixes,
            leasingSummary: {
                annualPercentageRate: 'Tasso annuo effettivo globale',
                debitInterest: 'Interesse passivo',
                monthlyRate: 'Rata mensile',
                footnoteBound: '(1) Legato per l’intera durata del contratto',
                footnoteCosts:
                    '(2) I costi di trasporto sono inclusi nella rata mensile. Tutte le informazioni senza garanzia.',
                footnoteDelivery: '(3) esclusi i costi forfettari di consegna',
            },
        },
        S020PickupOrDelivery: {
            stepLabel: 'Ritiro/consegna',
            title: 'Ritiro/consegna',
            headline: 'Come desiderate ricevere la vostra auto?',
            tabs: {
                pickup: 'Ritirate presso il partner Opel',
                delivery: 'Consegna presso questo indirizzo',
            },
            gender: gender,
            dealer: 'Selezionare garage',
            street: 'Strada',
            streetNumber: 'N°',
            zip: 'NPA',
            city: 'Luogo',
            nextBtn: 'Avanti',
            summary: {
                headline: "Abbiamo annotato quanto segue per la consegna dell'auto dei vostri sogni:",
            },
            errors: {
                required: errorRequired,
                pattern: errorInvalid,
            },
            requiredNotice: 'I campi contrassegnati con * sono obbligatori.',
        },
        S040ThanksWhithVehicle: {
            error: errorMessage,
            headline: 'Grazie mille,',
            headlineAfter: '!',
            female: gender.female,
            male: gender.male,
            copy: 'Abbiamo ricevuto la sua richiesta. Il suo partner Opel la contatterà a breve per una consulenza professionale!',
            contactCard: {
                headline: "C'è qualcos'altro che possiamo fare per voi?",
                copy: "C'è qualcos'altro che possiamo fare per voi?",
                phone: 'Chiamare',
                mail: 'E-Mail',
            },
        },
        M060C050vehicleOverview: m060C050vehicleOverviewLabels,
        S090Leasing: {
            stepLabel: 'Calcolatore di leasing',
            headline: "Guidate l'auto dei vostri sogni e mantenete la libertà finanziaria.",
            copy: 'Create il vostro leasing personalizzato.',
            durationHeadline: 'Durata contratto di leasing in mesi',
            runtimeHeadline: 'Chilometraggio annuo dell’auto dei vostri sogni',
            commissionHeadline: 'Acconto',
            nextButton: "Acquistare ora in leasing l'auto dei sogni",
            anualPercentageRate: 'Tasso annuo effettivo globale',
            debitInterest: 'Interesse passivo',
            monthlyRate: 'Rata mensile',
            footnoteBound: '(1) Legato per l’intera durata del contratto',
            footnoteCosts:
                '(2) I costi di trasporto sono inclusi nella rata mensile. Tutte le informazioni senza garanzia.',
            footnoteDelivery: '(3) esclusi i costi forfettari di consegna',
            runtimeYearly: 'Chilometraggio (km all’anno)',
            months: 'mese',
            duration: 'Durata',
            calcError: 'Purtroppo questa configurazione non è valida.',
            unit: 'km',
        },
        S030AdditionalBenefits: {
            stepLabel: 'Prestazioni supplementari',
            title: 'Prestazioni supplementari',
            headline: 'Di cosa desiderate beneficiare aggiuntivamente?',
            benefits: [
                {
                    identifier: 'multiassurance_advantage',
                    headline: "Veloce, semplice, completo e da un'unica fonte: la nostra assicurazione veicoli.",
                    subline: 'Approfittate di tutti i vantaggi della nostra assicurazione MultiAssurance:',
                    items: [
                        'Copertura vetri estesa senza franchigia',
                        'Assicurazione viaggi MultiAssistance gratis per tutta la famiglia per un anno intero.',
                    ],
                },
                {
                    identifier: 'car_return',
                    headline: 'Permuta veicolo con valutazione di un professionista.',
                    subline: 'Volete permutare il vostro veicolo?',
                    items: [
                        'Saremo lieti di valutare il vostro veicolo gratuitamente e senza impegno presso presso il vostro partner Opel.',
                    ],
                },
            ],
            select: 'Selezionare',
            summary: {
                headline: 'Prestazioni supplementari',
                subline: 'Riceverete dei preventivi separati per le prestazioni supplementari da voi scelti:',
            },
            nextBtn: 'Inviare',
            errors: {
                required: errorRequired,
                pattern: errorInvalid,
            },
            M030C070AdditionalPackages: {
                headline: 'I nostri accessori raccomandati',
                subheadline: '',
                from: 'da ',
            },
        },
    },
    service: {
        S020PickupOrDelivery: {
            stepLabel: 'Appuntamento desiderato',
            title: 'Fissare un appuntamento per il servizio',
            headline: 'Selezionare il luogo e l’appuntamento desiderato',
            tabs: {
                pickup: 'Portare in garage',
                delivery: 'Utilizzare il servizio di ritiro e consegna',
                deliveryHeadline: '',
                deliveryCopy:
                    'Sei invitato a comunicarci qui il tuo interesse per un servizio di ritiro e consegna non vincolante. Vi contatteremo per discutere le possibilità.',
            },
            gender: gender,
            dealer: 'Selezionare garage',
            street: 'Via',
            streetNumber: 'N°',
            zip: 'NPA',
            city: 'Luogo',
            nextBtn: 'Avanti',
            summary: {
                wishDate: 'Appuntamento desiderato',
            },
            errors: {
                required: errorRequired,
                pattern: errorInvalid,
            },
            requiredNotice: 'I campi contrassegnati con * sono obbligatori.',
            wishDate: {
                label: 'Appuntamento desiderato',
                btnLabel: 'cambiare',
            },
            note: {
                headline: 'Avviso:',
                copy: 'Nel caso aveste bisogno di un appuntamento a breve, si prega di contattarci telefonicamente. Nota bene: si tratta solamente di una richiesta di appuntamento e che la data selezionata non è vincolante fino alla conferma.',
            },
            pickupService: {
                headline: 'Servizio di ritiro e consegna',
                value: 'Sì',
            },
        },
        S100Services: {
            stepLabel: 'Servizi',
            title: 'Servizi',
            headline: 'Selezionare i servizi richiesti:',
            summary: {
                headline: 'Servizi',
            },
            nextBtn: 'Avanti',
            errors: {
                required: errorRequired,
                pattern: errorInvalid,
            },
            requiredNotice: 'I campi contrassegnati con * sono obbligatori.',
            other: 'Altro',
        },
        S015ContactDataExtended: {
            stepLabel: 'Dati di contatto',
            title: 'I tuoi dati di contatto',
            headline: 'Inserite qui i tuoi dati di contatto:',
            gender: gender,
            firstName: 'Nome',
            lastName: 'Cognome',
            email: 'Indirizzo e-mail',
            phoneNumber: 'Il tuo numero di telefono',
            privacyNoticeBefore: 'Ho letto e capito la ',
            privacyNoticeLink: 'disposizione sulla protezione dei dati.',
            privacyNoticeUrl: footerLinks.DATA_POLICY,
            privacyNoticeAfter: '',
            nextBtn: 'Inviare',
            errors: {
                required: errorRequired,
                pattern: errorInvalid,
            },
            phoneNumberPrefixes: phoneNumberPrefixes,
            footnote: 'I campi contrassegnati con * sono obbligatori.',
            preferredContact: {
                headline: 'Accetto di essere contattato via:',
                email: 'E-mail',
                phone: 'Telefono',
                mail: 'Posta',
                sms: 'SMS/WhatsApp',
            },
            alreadyCustomer: {
                label: 'Sei già nostro cliente?',
                yes: 'Sì',
                no: 'No',
            },
            corporateClient: {
                label: 'Siete un cliente aziendale?',
                yes: 'Sì',
                no: 'No',
            },
            corporate: 'Azienda',
            corporateStreet: 'Via',
            corporateStreetnumber: 'N°',
            corporateZip: 'NPA',
            corporateCity: 'Luogo',
            contactTime: 'Quando sei più disponibili?',
            contactTimeOptions: [
                {
                    value: 'Mattina',
                    label: 'Mattina',
                },
                {
                    value: 'Mezzogiorno',
                    label: 'Mezzogiorno',
                },
                {
                    value: 'Pomeriggio',
                    label: 'Pomeriggio',
                },
            ],
        },
        S065VehicleData: {
            stepLabel: 'VehicleSelect',
            headline: 'Informazioni relative al suo veicolo:',
            copy: 'Compili ora i campi inserendo le informazioni relative al suo veicolo.',
            make: 'Marchio',
            model: 'Modello',
            controlSign: 'Kontrollschild (z.B. ZH 123 456*)',
            firstReg: 'Prima immatricolazione',
            mileage: 'Chilometraggio',
            message: 'Ihre Nachricht',
            footnote: 'I campi contrassegnati con * sono obbligatori.',
            nextBtn: 'Avanti',
            errorRequired: 'Questo campo è obbligatorio',
            errorInvalid: 'Inserimento non valido',
            summary: {
                controlSign: 'Targa',
                firstReg: 'Prima immatricolazione',
            },
        },
        S110ThanksService: {
            headline: 'La tua richiesta è stata inviata con successo.',
            copy: 'Grazie! Abbiamo ricevuto la tua richiesta. Ti ricontatteremo il prima possibile. ',
            team: 'Il tuo partner Opel ',
            summary: {
                headline: 'Riepilogo',
                copy: 'Il tuo opzioni selezionate',
            },
            errorMessage,
        },
        M060C050vehicleOverview: {
            ...m060C050vehicleOverviewLabels,
            noVehicle: {
                headline: 'Riepilogo',
                subheadline: 'Il tuo opzioni selezionate',
            },
        },
    },
};
