import { inject, Injectable } from '@angular/core';
import { WNestApi } from 'libs/shared-lib/interfaces';
import { WholesaleLanguage } from 'libs/shared-lib/language/language';
import { Observable } from 'rxjs';
import { WNestApiService } from './wholesale-nest-api';

@Injectable({
    providedIn: 'root',
})
export class DealerService {
    protected readonly wNestApiService: WNestApiService = inject(WNestApiService);

    loadDealerBySlug(slug: string, locale: WholesaleLanguage = 'de'): Observable<WNestApi.Dealer | null> {
        return this.wNestApiService.getDealerBySlug(slug, locale);
    }
}
