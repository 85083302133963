import { InjectionToken } from '@angular/core';

export const M050C020FooterStyleToyota: M050C020FooterStyleData = {
    div: 'mt-60 mb-120 md:mb-60',
    divider: 'border-b-2 md:border-b-3 border-b-accent max-w-30p',
    container: 'max-w-1280 mx-auto pt-xl px-lg md:pt-60',
    icon: {
        path: 'assets/toyota.svg',
        color: 'fill-light-text',
        size: 'w-2xl h-2xl md:w-48 md:h-48',
    },
    copyright: 'text-light-text text-16 md:text-20 md:leading-24 my-16 md:mt-60',
    navigation: {
        default: 'text-light-text text-16 md:text-20 md:leading-24 py-8 md:py-lg hover:underline',
        first: 'pt-0 md:pt-16',
    },
    footnote: 'leading-14 my-16 text-10 text-grey-400 tracking-0.25',
};

export const M050C020FooterStyleOpel: M050C020FooterStyleData = {
    div: '',
    divider: '',
    container: '',
    icon: {
        path: 'assets/opel.svg',
        color: 'fill-light-text',
        size: 'w-2xl h-2xl md:w-48 md:h-48',
    },
    copyright: '',
    navigation: {
        default: '',
        first: '',
    },
    footnote: '',
};

export const M050C020FooterStyleLexus: M050C020FooterStyleData = {
    div: 'mt-60 mb-120 md:mb-60',
    divider: 'border-b-2 md:border-b-3 border-b-accent max-w-30p',
    container: 'max-w-1280 mx-auto pt-xl px-lg md:pt-60',
    icon: {
        path: 'assets/logos/logo-lexus.svg',
        color: 'fill-light-text',
        size: 'w-2xl h-2xl md:w-48 md:h-48',
    },
    copyright: 'text-light-text text-16 md:text-20 md:leading-24 my-lg md:mt-60',
    navigation: {
        default: 'text-light-text text-16 md:text-20 md:leading-24 py-8 md:py-lg hover:underline',
        first: 'pt-0 md:pt-16',
    },
    footnote: '',
};

export const M050C020FooterStyleToken = new InjectionToken<M050C020FooterStyleData>('M050C020FooterStyleToken');

export function provideM050C020FooterStyleData(style: M050C020FooterStyleData) {
    return {
        provide: M050C020FooterStyleToken,
        useValue: style,
    };
}

export interface M050C020FooterStyleData {
    div: string;
    divider: string;
    container: string;
    icon: {
        path: string;
        color: string;
        size: string;
    };
    copyright: string;
    navigation: {
        default: string;
        first: string;
    };
    footnote: string;
}
