import { VehicleMarketLabels } from 'libs/shared-lib/interfaces';
import { M060C010VehicleCardLabels } from 'libs/shared-lib/interfaces/ui/m060-c010-vehicle-card.interface';
import { VEHICLE_NOT_AVAILABLE_LABELS_DE } from 'libs/vehicle-market-lib/src/lib/labels/vehicle-not-available/vehicle-not-available.de';

const occasionsProgramOverlay = {
    headline: 'Junge Blitze – So neu kann gebraucht sein. ',
    copy: '<p class="text-left mb-10">Entdecke unsere jungen gebrauchten Fahrzeuge:</p><ul class="text-left disclistNarrow"><li>Autos die nicht älter als 12 Monate alt sind</li><li>Autos die nicht mehr als 5000 km gefahren wurden</li><li>Tageszulassungen</li><li>Demofahrzeuge</li><li>Und generell junge Occasionen (nicht älter als 12 Monate und nicht höher als 5000 KM-Stand)</li></ul><p class="text-left">Fahrzeug zu einem attraktiven Leasing mit 1.99%.</p><p class="text-left">Gehe jetzt zu deinem Händler und profitiere!</p>',
    image: {
        image: 'https://media.graphassets.com/Q9zWaz78QhCjSS6cJKLA',
        width: 300,
        height: 225,
    },
};

const actionTags = {
    occasionsProgram: {
        text: 'Junge Blitze',
        overlay: occasionsProgramOverlay,
    },
};

const vehicleCardLabels: M060C010VehicleCardLabels = {
    currency: 'CHF',
    leasing: 'Leasing ab',
    monthly: '/Mt.',
    actionTags: actionTags,
    firstPriceText: 'CHF',
    newPrice: 'Neupreis',
    lastPriceText: '<span class="font-normal">Leasing ab <strong>CHF</strong></span>', // For Opel the class have to be font-light
    consumption: 'Verbrauch kombiniert',
    emission: 'CO2-Emission kombiniert',
};

const searchCriteriaLabels = {
    headline: 'Ergebnisse filtern',
    button: '%s Fahrzeuge anzeigen',
    reset: 'Filter zurücksetzen',
    location: {
        currentLocation: 'Aktuellen Standort verwenden',
        subheading: 'Suche nach Adresse',
    },
    selected: 'gewählt',
    allSelected: 'Alle',
    to: 'bis',
    monthly: '/Mt.',
    ratefrom: 'Rate ab',
    rateto: 'Rate bis',
    pricefrom: 'Preis ab',
    priceto: 'Preis bis',
    vehicle_tags: {
        occasionsProgram: occasionsProgramOverlay,
    },
};

export const VEHICLE_MARKET_LABELS_DE: VehicleMarketLabels.Labels = {
    list: {
        menuItem: 'Auto finden',
        vehicleCount: 'Fahrzeugangebote',
        sortBtn: 'Sortieren',
        filterBtn: 'Ergebnisse filtern',
        prev: 'Zurück',
        next: 'Weiter',
        vehicleCardLabels: vehicleCardLabels,
        searchCriteria: searchCriteriaLabels,
        filterTags: {
            model: 'Modell',
            trans: 'Getriebe',
            cond: 'Fahrzeugart',
            vehtyp: 'Fahrzeugkategorie',
            drive: 'Antrieb',
            equip: 'Ausstattung',
            efficiency: 'Energieeffizienz',
            bodycol: 'Farbe',
            body: 'Carosserieform',
            fuel: 'Treibstoff',
            location: 'Standortfilter',
            yearfrom: '1. Inv. ab',
            yearto: '1. Inv. bis',
            kmfrom: 'Km ab',
            kmto: 'Km bis',
            seatsfrom: 'Sitze ab',
            seatsto: 'Sitze bis',
            ratefrom: 'Rate ab',
            rateto: 'Rate bis',
            pricefrom: 'Preis ab',
            priceto: 'Preis bis',
            monthly: '/Mt.',
            vehicle_tags: { occasionsProgram: 'Junge Blitze' },
        },
    },
    detail: {
        reserved: {
            copy: 'Dieses Fahrzeug ist reserviert. Wir beraten Sie gerne zu verfügbaren Alternativen.',
            contactVia: 'Sie erreichen uns unter ',
            linkText: 'Jetzt Beratungstermin vereinbaren',
        },
        headline: 'Auto Detail',
        moreDetails: 'mehr Details',
        priceTag: 'Kaufpreis',
        newPrice: 'Neupreis',
        onlineSalesAndLeasingCard: {
            headline: 'Deine Online-Offert-Anfrage:',
            copy: 'Mit wenigen Klicks kannst du uns eine unverbindliche Offert-Anfrage schicken – und das jederzeit.',
            currency: 'CHF',
            leasing: 'Leasing ab',
            monthly: '/Mt.',
            newPrice: 'Neupreis',
            firstPriceText: 'Online kaufen für',
            lastPriceText: '<span class="font-normal">Leasing ab <strong>CHF</strong></span>', // For Opel the class have to be font-light
        },
        testDriveCard: {
            hasLeasing: {
                healdine: 'Fahrzeug erst einmal Probe fahren?',
                copy: 'Selbstverständlich gerne! Gib uns deinen Wunschtermin an und wir kontaktieren dich sobald wie möglich',
                btn: 'Jetzt Probe fahren',
            },
            noLeasing: {
                healdine: 'Fahrzeug erst einmal Probe fahren?',
                copy: 'Selbstverständlich gerne! Gib uns deinen Wunschtermin an und wir kontaktieren dich sobald wie möglich',
                btn: 'Jetzt Probe fahren',
            },
        },
        location: {
            headline: 'Standort',
            contactBox: {
                copyBefore: 'heute von',
                copyMid: 'bis',
                copyAfter: 'Uhr',
                mail: 'E-Mail',
                phone: 'Anrufen',
                route: 'Route',
            },
            ratingBy: 'bei Google',
            openings: {
                headlineToday: 'Wir sind für Sie da',
                today: 'heute',
                from: 'von',
                to: 'bis',
                clock: 'Uhr',
                clockFrom: '',
                clockTo: 'Uhr',
            },
        },
        promise: {
            default: {
                healdine: 'Deine Vorteile beim Fahrzeugkauf bei uns',
                list: [
                    {
                        text: 'Top Beratung von kompetenten Ansprechpartnern',
                        icon: 'assets/icons/done_black.svg',
                    },
                    {
                        text: 'Jederzeit erreichbar für dich',
                        icon: 'assets/icons/done_black.svg',
                    },
                    {
                        text: 'Dein Fachmann für Mobilität',
                        icon: 'assets/icons/done_black.svg',
                    },
                ],
            },
            occasionsProgram: {
                healdine: 'Deine Vorteile beim Fahrzeugkauf bei uns',
                list: [
                    {
                        text: 'Top Beratung von kompetenten Ansprechpartnern',
                        icon: 'assets/icons/done_black.svg',
                    },
                    {
                        text: 'Jederzeit erreichbar für dich',
                        icon: 'assets/icons/done_black.svg',
                    },
                    {
                        text: 'Dein Fachmann für Mobilität',
                        icon: 'assets/icons/done_black.svg',
                    },
                ],
            },
        },
        highlights: {
            headline: 'Fahrzeug-Highlights',
        },
        warranty: {
            headline: 'Garantie',
            copy: 'Alle Neufahrzeuge sind über die Werksgarantie 3 Jahre oder 100’000 Kilometer Laufleistung gedeckt, je nachdem, was früher eintritt.',
        },
        insurance: {
            headline: 'Versicherung',
            copy: 'Vollkaskoversicherung, Autoversicherung mit oder ohne Leasing, GAP Versicherung, Ratenversicherung Secure4You++ im Falle Krankheit oder Arbeitslosigkeit... Um sich bestmöglich deinen Bedürfnissen anzupassen bietet dir Santander Consumer Finance eine Vielzahl an Komplettangeboten an.',
            cta: 'Mehr erfahren',
        },
        similarVehicles: {
            headline: 'Weitere Angebote',
            copy: 'Basierend auf deiner obigen Autoauswahl haben wir für dich die folgenden, passenden attraktiven Angebote gefunden.',
            cta: 'Alle Angebote anzeigen',
        },
        vehicleCardLabels: vehicleCardLabels,
        tabs: {
            teckData: 'Technische Daten',
            equipment: 'Ausstattung',
            comment: 'Kommentar',
            envkv: {
                headline: 'Umwelt- & Energiedaten',
            },
            leasing: 'Leasingrechner',
            move: 'Auto-Abo',
        },
        gallery: 'Bilder',
        equipment: {
            optional: 'Sonderausstattung',
            standard: 'Basisausstattung',
        },
        actionTags: actionTags,
        m060c070Labels: {
            headline: 'Leasing berechnen',
            vdpHeadline: 'Monatliche Rate',
            durationHeadline: 'Laufzeit in Monaten',
            runtimeHeadline: 'Laufleistung pro Jahr',
            commissionHeadline: 'Anzahlung',
            nextButton: 'Wunschauto leasen',
            anualPercentageRate: 'Effektiver Jahreszins',
            debitInterest: 'Sollzins',
            monthlyRate: 'Monatliche Rate',
            footnoteBound: '(1) Gebunden für die gesamte Vertragslaufzeit.',
            footnoteCosts:
                '(2) Die Überführungskosten sind in der monatlichen Rate inbegriffen. Alle Angaben ohne Gewähr.',
            footnoteDelivery: '(3) exkl. Ablieferungspauschale',
            runtimeYearly: 'Laufleistung p.a.',
            months: 'Monate',
            duration: 'Laufzeit',
            calcError: 'Diese Konfiguration ist leider ungültig.',
            unit: 'km',
            monthly: '/Mt.',
        },
    },
    vehicleNotAvailablePage: VEHICLE_NOT_AVAILABLE_LABELS_DE,
};
