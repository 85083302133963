import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, UrlTree } from '@angular/router';
import { Action, MemoizedSelector, Store } from '@ngrx/store';
import { Response } from 'express';
import { RESPONSE } from 'libs/ng-common-lib/src/provider/express.token';
import { Observable, filter, map, of, switchMap, take } from 'rxjs';

export type Loadable<T> = T | null | false;

export interface NgrxLoaderGuardOptions<TData, TState> {
    actionToDispatch: Action | ((route: ActivatedRouteSnapshot) => Action);
    waitUntilLoadedSelector?: MemoizedSelector<TState, boolean>;
    dataToCheckSelector?: MemoizedSelector<TState, TData>;
    verifyData?: (data: TData) => boolean;
    redirectOnFailUrl?: string | ((route: ActivatedRouteSnapshot, store: Store) => string | Observable<string>);
    actionToDispatchOnSuccess?: Action | ((route: ActivatedRouteSnapshot) => Action);
    redirectOnFailCode?: number;
}

export function ngrxLoaderGuard<TData = unknown, TState = unknown>(
    opt: NgrxLoaderGuardOptions<TData, TState>,
): CanActivateFn {
    return function (route) {
        const store = inject(Store);
        const router = inject(Router);
        const res = inject(RESPONSE, { optional: true });

        if (opt.actionToDispatch instanceof Function) {
            store.dispatch(opt.actionToDispatch(route));
        } else {
            store.dispatch(opt.actionToDispatch);
        }
        if (!opt.waitUntilLoadedSelector) {
            return of(true);
        }

        const waitForLoad = store.select(opt.waitUntilLoadedSelector).pipe(
            filter((isLoading) => !isLoading),
            take(1),
        );

        const dataSelector = opt.dataToCheckSelector;

        if (!dataSelector) {
            return waitForLoad;
        }

        return waitForLoad.pipe(
            switchMap(() => store.select(dataSelector)),
            switchMap((data) => {
                if ((opt.verifyData && opt.verifyData(data)) || (!opt.verifyData && data)) {
                    if (opt.actionToDispatchOnSuccess instanceof Function) {
                        store.dispatch(opt.actionToDispatchOnSuccess(route));
                    } else if (opt.actionToDispatchOnSuccess) {
                        store.dispatch(opt.actionToDispatchOnSuccess);
                    }

                    return of(true);
                } else {
                    return handleRedirectOnFailUrl(opt, route, router, store, res);
                }
            }),
        );
    } satisfies CanActivateFn;
}

// eslint-disable-next-line max-statements
function handleRedirectOnFailUrl<TData = unknown, TState = unknown>(
    opt: NgrxLoaderGuardOptions<TData, TState>,
    route: ActivatedRouteSnapshot,
    router: Router,
    store: Store,
    response?: Response | null,
): Observable<UrlTree | false> {
    if (opt.redirectOnFailUrl) {
        if (opt.redirectOnFailUrl instanceof Function) {
            const res = opt.redirectOnFailUrl(route, store);
            if (res instanceof Observable) {
                return res.pipe(
                    take(1),
                    map((url) => {
                        if (response) {
                            response.redirect(opt.redirectOnFailCode ?? 302, url);
                            return false;
                        }
                        return router.createUrlTree([url]);
                    }),
                );
            }
            if (response) {
                response.redirect(opt.redirectOnFailCode ?? 302, res);
                return of(false);
            }
            return of(router.createUrlTree([res]));
        } else {
            if (response) {
                response.redirect(opt.redirectOnFailCode ?? 302, opt.redirectOnFailUrl);
                return of(false);
            }
            if (opt.redirectOnFailUrl.startsWith('http')) {
                window.location.href = opt.redirectOnFailUrl;
                return of(false);
            }
            return of(router.createUrlTree([opt.redirectOnFailUrl]));
        }
    }
    return of(false);
}
