import { VehicleMarketLabels } from 'libs/shared-lib/interfaces';
import { M060C010VehicleCardLabels } from 'libs/shared-lib/interfaces/ui/m060-c010-vehicle-card.interface';
import { VEHICLE_NOT_AVAILABLE_LABELS_FR } from 'libs/vehicle-market-lib/src/lib/labels/vehicle-not-available/vehicle-not-available.fr';

const occasionsProgram = 'Des Blitz récentes';

const occasionsProgramOverlay = {
    headline: 'des Blitz récentes – Une voiture d’occasion peut être aussi flambant neuve',
    copy: '<p class="text-left mb-10">Découvre nos jeunes véhicules d\'occasion :</p><ul class="text-left disclistNarrow"><li>les voitures qui n\'ont pas plus de 12 mois d\'âge</li><li>les voitures qui n\'ont pas roulé plus de 5000 km</li><li>Autorisations journalières</li><li>Véhicules de démonstration</li><li>  Et en général les jeunes voitures d\'occasion (pas plus de 12 mois et pas plus de 5000 KM)</li></ul><p class="text-left">Achète dès maintenant un véhicule presque neuf avec un taux de leasing attractif de 1,99%. </p><p class="text-left">Rendez-vous chez ton concessionnaire pour en profiter!</p>',
    image: {
        image: 'https://media.graphassets.com/Q9zWaz78QhCjSS6cJKLA',
        width: 300,
        height: 225,
    },
};

const actionTags = {
    occasionsProgram: {
        text: occasionsProgram,
        overlay: occasionsProgramOverlay,
    },
};

const vehicleCardLabels: M060C010VehicleCardLabels = {
    currency: 'CHF',
    leasing: 'Leasing dès',
    monthly: '/mois',
    actionTags: actionTags,
    newPrice: 'Prix neuf',
    firstPriceText: '',
    lastPriceText: 'Leasing dès',
    consumption: 'Consommation mixte',
    emission: 'Émissions de CO2 mixtes',
};

const searchCriteriaLabels = {
    headline: 'Filtrer les résultats',
    button: 'Afficher %s véhicules',
    reset: 'Réinitialiser filtres',
    location: {
        currentLocation: 'Utiliser site actuel',
        subheading: 'Recherche par adresse',
    },
    selected: 'choisi',
    allSelected: 'Tous',
    to: 'jusqu’à',
    monthly: '/mois',
    ratefrom: 'Mensualité dès',
    rateto: 'Mensualité jusqu’à',
    pricefrom: 'Prix à partir de',
    priceto: 'Prix jusqu’à',
    vehicle_tags: {
        occasionsProgram: occasionsProgramOverlay,
    },
};

export const VEHICLE_MARKET_LABELS_FR: VehicleMarketLabels.Labels = {
    list: {
        menuItem: 'Trouver un véhicule',
        vehicleCount: 'offres de véhicules',
        sortBtn: 'Trier',
        filterBtn: 'Filtrer les résultats',
        prev: 'Retour',
        next: 'Suivant',
        vehicleCardLabels: vehicleCardLabels,
        searchCriteria: searchCriteriaLabels,
        filterTags: {
            model: 'Modèle',
            trans: 'Transmission',
            cond: 'Type de véhicule',
            vehtyp: 'Catégorie de véhicule',
            drive: 'Entraînement',
            equip: 'Équipement',
            efficiency: 'Rendement énergétique',
            bodycol: 'Couleur',
            body: 'Type de carrosserie',
            fuel: 'Carburant',
            location: 'Filtre de site',
            yearfrom: '1re mise en circulation dès le',
            yearto: '1re mise en circulation jusqu’au',
            kmfrom: 'Min. km',
            kmto: 'Max. km',
            seatsfrom: 'Nbre min. sièges',
            seatsto: 'Nbre max. sièges',
            ratefrom: 'Mensualité dès',
            rateto: 'Mensualité jusqu’à',
            pricefrom: 'Prix à partir de',
            priceto: 'Prix jusqu’à',
            monthly: '/mois',
            vehicle_tags: { occasionsProgram: 'Blitz récentes' },
        },
    },
    detail: {
        reserved: {
            copy: 'ToDo FR: Dieses Fahrzeug ist reserviert. Wir beraten Sie gerne zu verfügbaren Alternativen.',
            contactVia: 'ToDo FR: Sie erreichen uns unter ',
            linkText: 'ToDo FR: Jetzt Beratungstermin vereinbaren',
        },
        headline: 'Auto Detail FR',
        moreDetails: 'En savoir plus',
        priceTag: 'Prix d’achat',
        newPrice: 'Prix neuf',
        onlineSalesAndLeasingCard: {
            headline: 'Ta demande devis en ligne:',
            copy: 'En quelques clics, tu peux nous envoyer une demande de devis sans engagement, et à tout moment.',
            currency: 'CHF',
            leasing: 'Leasing à partir de',
            monthly: '/mois',
            newPrice: 'Prix neuf',
            firstPriceText: 'Acheter directement en ligne pour',
            lastPriceText: 'Leasing dès',
        },
        testDriveCard: {
            hasLeasing: {
                healdine: 'Tu veux essayer un véhicule ?',
                copy: 'Bien sûr, avec plaisir ! Faites-nous part de votre date préférée et nous vous contacterons dès que possible.',
                btn: 'Effectuer essai sur route',
            },
            noLeasing: {
                healdine: 'Tu veux essayer un véhicule ?',
                copy: 'Bien sûr, avec plaisir ! Faites-nous part de votre date préférée et nous vous contacterons dès que possible.',
                btn: 'Effectuer essai sur route',
            },
        },
        location: {
            headline: 'Site',
            contactBox: {
                copyBefore: 'aujourd’hui de',
                copyMid: 'h à',
                copyAfter: 'h',
                mail: 'Courriel',
                phone: 'Appeler',
                route: 'Itinéraire',
            },
            ratingBy: 'chez Google',
            openings: {
                headlineToday: 'Nous sommes là pour vous',
                today: "aujourd'hui",
                from: 'de',
                to: 'à',
                clock: '',
                clockFrom: 'h',
                clockTo: 'h',
            },
        },
        promise: {
            default: {
                healdine: 'Tes avantages en achetant un véhicule chez nous',
                list: [
                    {
                        text: 'Des conseils de haut niveau donnés par des interlocuteurs compétents',
                        icon: 'assets/icons/done_black.svg',
                    },
                    {
                        text: 'Disponible pour toi à tout moment',
                        icon: 'assets/icons/done_black.svg',
                    },
                    {
                        text: 'Ton spécialiste de la mobilité',
                        icon: 'assets/icons/done_black.svg',
                    },
                ],
            },
            occasionsProgram: {
                healdine: 'Tes avantages en achetant un véhicule chez nous',
                list: [
                    {
                        text: 'Des conseils de haut niveau donnés par des interlocuteurs compétents',
                        icon: 'assets/icons/done_black.svg',
                    },
                    {
                        text: 'Disponible pour toi à tout moment',
                        icon: 'assets/icons/done_black.svg',
                    },
                    {
                        text: 'Ton spécialiste de la mobilité',
                        icon: 'assets/icons/done_black.svg',
                    },
                ],
            },
        },
        highlights: {
            headline: 'Points forts du véhicule',
        },
        warranty: {
            headline: 'Garantie',
            copy: 'Tous les véhicules neufs sont couverts par la garantie d’usine pendant 3 ans ou 100’000 kilomètres, selon la première éventualité.',
        },
        insurance: {
            headline: 'Assurance',
            copy: 'Assurance casco complète, assurance auto avec ou sans leasing, assurance GAP, assurance mensualités Secure4You+ en cas de maladie ou de chômage... Afin de s’adapter au mieux à tes besoins, Santander Consumer Finance te propose une grande variété d’offres complètes.',
            cta: 'En savoir plus',
        },
        similarVehicles: {
            headline: 'Autres offres',
            copy: 'En nous appuyant sur les voitures que tu as sélectionnées ci-dessus, nous avons trouvé les offres attrayantes et adaptées suivantes.',
            cta: 'Afficher toutes les offres',
        },
        vehicleCardLabels: vehicleCardLabels,
        tabs: {
            teckData: 'Données techniques',
            equipment: 'Équipement',
            comment: 'Commentaire',
            envkv: {
                headline: 'Environnement et énergie',
            },
            leasing: 'Calculateur de leasing',
            move: 'Abonnement auto',
        },
        gallery: 'Images',
        equipment: {
            optional: 'Équipement spécial',
            standard: 'Équipements standard',
        },
        actionTags: actionTags,
        m060c070Labels: {
            headline: 'Calculer le leasing',
            vdpHeadline: 'Mensualité',
            durationHeadline: 'Durée en mois',
            runtimeHeadline: 'Kilométrage par an',
            commissionHeadline: 'Acompte',
            nextButton: 'La voiture de vos souhaits en leasing',
            anualPercentageRate: 'Taux de leasing effectif',
            debitInterest: 'Intérêt débiteur',
            monthlyRate: 'Mensualité',
            footnoteBound: '(1) Fixe pendant toute la durée du contrat.',
            footnoteCosts:
                '(2) Les frais de transfert sont inclus dans la mensualité. Toutes les indications sont fournies sans garantie.',
            footnoteDelivery: '(3) Forfait de livraison excl.',
            runtimeYearly: 'Kilométrage annuel',
            months: 'mois',
            duration: 'Durée',
            calcError: "Cette configuration n'est malheureusement pas valable.",
            unit: 'km',
            monthly: '/mois',
        },
    },
    vehicleNotAvailablePage: VEHICLE_NOT_AVAILABLE_LABELS_FR,
};
