import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { provideClientHydration } from '@angular/platform-browser';
import { Route, provideRouter, withEnabledBlockingInitialNavigation, withInMemoryScrolling } from '@angular/router';
import { provideEffects } from '@ngrx/effects';
import { provideRouterStore } from '@ngrx/router-store';
import { provideState, provideStore } from '@ngrx/store';
import { provideLazyAwesome } from '@pixelgenau/ngx-px-lazy-awesome';
import { provideSVGAwesome, withInlineSvgReuseInitializer } from '@pixelgenau/ngx-px-svg-awesome/browser';
import { WithCtaProvider } from 'libs/corporate-platform/src/lib/provider/cta.provider';
import { WithFooterProvider } from 'libs/corporate-platform/src/lib/provider/footer.provider';
import { WithNavigationProvider } from 'libs/corporate-platform/src/lib/provider/nav.provider';
import { dealerSearchFeature } from 'libs/dealer-search-lib/src/lib/+state/dealer-search.reducer';
import { aboutUsFeature } from 'libs/ng-common-lib/src/+state/about-us.reducer';
import { checkoutsFeature } from 'libs/ng-common-lib/src/+state/checkouts.reducer';
import { dealerFeature } from 'libs/ng-common-lib/src/+state/dealer.reducer';
import { newsFeature } from 'libs/ng-common-lib/src/+state/news.reducer';
import { promotionFeature } from 'libs/ng-common-lib/src/+state/promotion.reducer';
import { serviceFeature } from 'libs/ng-common-lib/src/+state/service.reducer';
import { FeatureLanguageProvider } from 'libs/ng-common-lib/src/+state/wholesale.actions';
import { wholesaleFeature } from 'libs/ng-common-lib/src/+state/wholesale.reducer';
import { provideEnvironmentSpecificProviders } from 'libs/ng-common-lib/src/env-specific-providers/providers';
import { withLocales } from 'libs/ng-common-lib/src/language/language.provider';
import { ChNumberPipe } from 'libs/ng-common-lib/src/pipes/ch-number.pipe';
import { BASE_URL_TOKEN } from 'libs/ng-common-lib/src/platform-provides/base-url.provider';
import { FeatureType, WholesaleFeature } from 'libs/ng-common-lib/src/platform-provides/wholesale/features';
import {
    CurrencyNumberConfig,
    provideWholesaleCurrencyNumberConfig,
} from 'libs/ng-common-lib/src/provider/currency.provider';
import {
    provideBuyFeature,
    provideLeasingFeature,
    provideTestDriveFeature,
} from 'libs/ng-common-lib/src/provider/feature.provider';
import { provideWholesaleNestApi } from 'libs/ng-common-lib/src/services/wholesale-nest-api';
import { LAZY_ELEMENTS } from 'libs/partner-web-platform/src/lib/lazyElements';
import { CountryType, WholesalerType } from 'libs/shared-lib/interfaces';
import { WholesaleLanguage } from 'libs/shared-lib/language/language';
import { XDefaultLanguageToken, provideXDefaultLanguageToken } from 'libs/shared-lib/token/x-default-language-token';
import { provideCountryToken } from 'libs/ui/src/lib/utilities/country-token';
import { providePlatformToken } from 'libs/ui/src/lib/utilities/platform-token';
import { provideWholesalerToken } from 'libs/ui/src/lib/utilities/wholesaler-token';
import {
    provideVehicleMarketStatesAndEffects,
    withVehicleMarketLanguages,
} from 'libs/vehicle-market-lib/src/lib/provider/with-vehicle-market.provider';
import { effects, reducers } from '../+state';
import { appFeature } from '../+state/app.reducer';
import { createRoutes } from '../routing/partner-web-routes';
export interface PartnerWebPlatformConfig {
    wholesaler: WholesalerType;
    country: CountryType;
    xDefaultLocale: XDefaultLanguageToken['defaultLanguage'];
    languageSuffix: XDefaultLanguageToken['languageSuffix'];
    wholesaleNestApiUrl: string;
    currencyNumberConfig: CurrencyNumberConfig;
    rootRedirectUrl: string;
    serviceForm: boolean;
}

export function withPartnerWebLanguages(...languages: Array<FeatureLanguageProvider<FeatureType.PARTNER_WEB>>) {
    return languages.map((l) => l(FeatureType.PARTNER_WEB));
}

export function withDealerSearch(...languages: Array<FeatureLanguageProvider<FeatureType.DEALER_SEARCH>>) {
    return [provideState(dealerSearchFeature), languages.map((l) => l(FeatureType.DEALER_SEARCH))];
}

// eslint-disable-next-line max-lines-per-function
export function providePartnerWebPlatform(
    config: PartnerWebPlatformConfig,
    components: {
        nav: WithNavigationProvider;
        footer: WithFooterProvider;
        cta?: WithCtaProvider;
    },

    locales: readonly WholesaleLanguage[],
    vehicleMarketLanguages: ReturnType<typeof withVehicleMarketLanguages>,
    partnerwebLanguages: ReturnType<typeof withPartnerWebLanguages>,
    homeRoute: Route,
    dealerSearch?: ReturnType<typeof withDealerSearch>,
    ...features: WholesaleFeature[]
): EnvironmentProviders {
    return makeEnvironmentProviders([
        provideSVGAwesome(
            {
                baseUrl: BASE_URL_TOKEN,
            },
            withInlineSvgReuseInitializer(),
        ),
        ChNumberPipe,
        withLocales(...locales),
        ...vehicleMarketLanguages,
        ...partnerwebLanguages,
        provideXDefaultLanguageToken(config.xDefaultLocale, config.languageSuffix),
        provideWholesalerToken(config.wholesaler),
        providePlatformToken('partner-web'),
        provideCountryToken(config.country),
        provideStore(reducers),
        provideRouterStore(),
        provideState(wholesaleFeature),
        provideState(dealerFeature),
        ...(dealerSearch ? [...dealerSearch] : []),
        provideState(promotionFeature),
        provideState(newsFeature),
        provideState(serviceFeature),
        provideState(appFeature),
        provideState(checkoutsFeature),
        provideState(aboutUsFeature),
        provideEffects(effects),
        provideVehicleMarketStatesAndEffects(),
        provideEnvironmentSpecificProviders(),
        provideClientHydration(),
        ...components.nav.providers,
        ...components.footer.providers,
        ...(components.cta?.providers ?? []),
        provideWholesaleNestApi({
            apiUrl: config.wholesaleNestApiUrl,
        }),
        provideLeasingFeature(),
        provideTestDriveFeature(),
        provideBuyFeature(),
        provideRouter(
            createRoutes(config.rootRedirectUrl, [...locales], homeRoute, !!dealerSearch, config.serviceForm),
            withEnabledBlockingInitialNavigation(),
            withInMemoryScrolling({
                anchorScrolling: 'enabled',
                scrollPositionRestoration: 'enabled',
            }),
        ),
        provideWholesaleCurrencyNumberConfig(config.currencyNumberConfig),
        features?.map((f) => f.providers),
        provideLazyAwesome({
            offset: 0,
            offsetAfterInit: 300,
            elements: LAZY_ELEMENTS,
            debug: false,
        }),
    ]);
}
