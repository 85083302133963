import { Params } from '@angular/router';
import { dealerFeature } from 'libs/ng-common-lib/src/+state/dealer.reducer';
import { promotionActions } from 'libs/ng-common-lib/src/+state/promotion.actions';
import { promotionFeature } from 'libs/ng-common-lib/src/+state/promotion.reducer';
import { ngrxLoaderGuard } from 'libs/ng-common-lib/src/guards/ngrx-loader.guard';
import { PAGE_TYPE } from 'libs/shared-lib/interfaces/global/tracking';
import { WholesaleLanguage } from 'libs/shared-lib/language/language';
import {
    PromotionSlugs,
    getPromotionDetailUrl,
    getPromotionListUrl,
    getServicePromotionDetailUrl,
} from 'libs/shared-lib/routing/promotion';
import {
    PromotionNotAvailableSlugs,
    getPromotionNotAvailableUrl,
} from 'libs/shared-lib/slugs/promotions-not-available.slug';
import { map } from 'rxjs';
// eslint-disable-next-line max-lines-per-function
export function getPromotionsRoutes(lang: WholesaleLanguage) {
    return [
        {
            path: PromotionSlugs[lang].list,
            children: [
                {
                    path: '',
                    loadComponent: () => import('../promotions/promotions-list/promotions-list.component'),
                    data: {
                        pageType: PAGE_TYPE.PROMOTION,
                        languageSwitchUrlGenerator: (lang: WholesaleLanguage) => getPromotionListUrl(lang),
                    },
                },
                {
                    path: ':promotionSlug',
                    loadComponent: () => import('../promotions/promotions-detail/promotions-detail.component'),
                    canActivate: [
                        ngrxLoaderGuard({
                            actionToDispatch: (route) => {
                                return promotionActions.loadPromotionByDealerAndSlug({
                                    slug: route.params['promotionSlug'],
                                });
                            },
                            waitUntilLoadedSelector: promotionFeature.selectIsLoadingPromotion,
                            dataToCheckSelector: promotionFeature.selectActivePromotion,
                            redirectOnFailUrl: (route, store) =>
                                store.select(dealerFeature.selectActiveDealer).pipe(
                                    map((dealer) => {
                                        return getPromotionNotAvailableUrl(lang, dealer.slug);
                                    }),
                                ),
                        }),
                    ],
                    data: {
                        pageType: PAGE_TYPE.PROMOTION,
                        languageSwitchSelector: promotionFeature.selectActivePromotion,
                        languageSwitchUrlGenerator: (lang: WholesaleLanguage, route: Params) =>
                            getPromotionDetailUrl(lang, route['promotionSlug'], route['dealerSlug']),
                    },
                },
            ],
        },
        {
            path: PromotionSlugs[lang].serviceTheme,
            children: [
                {
                    path: ':promotionSlug',
                    loadComponent: () => import('../promotions/promotions-detail/promotions-detail.component'),
                    canActivate: [
                        ngrxLoaderGuard({
                            actionToDispatch: (route) =>
                                promotionActions.loadPromotionByDealerAndSlug({
                                    slug: route.params['promotionSlug'],
                                }),
                            waitUntilLoadedSelector: promotionFeature.selectIsLoadingPromotion,
                            dataToCheckSelector: promotionFeature.selectActivePromotion,
                            redirectOnFailUrl: (route, store) =>
                                store
                                    .select(dealerFeature.selectActiveDealer)
                                    .pipe(map((dealer) => getPromotionNotAvailableUrl(lang, dealer.slug))),
                        }),
                    ],
                    data: {
                        pageType: PAGE_TYPE.PROMOTION,
                        languageSwitchSelector: promotionFeature.selectActivePromotion,
                        languageSwitchUrlGenerator: (lang: WholesaleLanguage, route: Params) =>
                            getServicePromotionDetailUrl(lang, route['promotionSlug'], route['dealerSlug']),
                    },
                },
            ],
        },
        {
            path: PromotionNotAvailableSlugs[lang],
            loadComponent: () =>
                import(
                    'libs/ng-common-lib/src/components/promotion-not-available-page/promotion-not-available-page.component'
                ),
        },
    ];
}
