import { NavigationLabels } from 'libs/shared-lib/interfaces/partner-web/navigation';

export const NAV_LABELS_IT: NavigationLabels = {
    vehicleList: 'Trovare un’auto',
    aboutUs: 'Su di noi',
    service: 'Service',
    ourLocations: 'Le nostre sedi',
    overview: 'Torna alla panoramica',
    route: 'Percorso',
    contact: 'Contatto',
    phone: 'Chiamare',
    phoneRibbonDesktopText: 'Siamo qui per te!',
    phoneDesktop: 'Saremo lieti di consigliarvi:',
    tradeIn: 'Vendere la tua auto',
};
