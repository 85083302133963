export type DataLayerValue = string | number | undefined | DataLayerEntry | DataLayerEntry[] | object[];
export type DataLayerEntry = { [key: string]: DataLayerValue };
export type DataLayer = DataLayerEntry[];

export enum PAGE_TYPE {
    HOMEPAGE = 'homepage',
    SERP = 'serp',
    VDP = 'vdp',
    CHECKOUT = 'checkout',
    SERVICES = 'Services',
    FORM = 'form',
    LOCATIONS = 'locations',
    PROMOTION = 'promotion',
    ABOUT_US = 'about_us',
    NEWS = 'news',
}

export enum SUB_SYSTEM_TYPES {
    PARTNER_WEB = 'partnerweb',
    CORPORATE = 'corporate',
}

export enum DELIVERY_TYPES {
    PICKUP = 'pickup',
    DELIVERY = 'delivery',
}
