import { IMAGE_LOADER, ImageLoaderConfig } from '@angular/common';
import { ImageSizes } from './image-sizes';

export interface ImageLoaderInputData {
    config: ImageLoaderConfig;
    baseUrls: Record<string, string>;
}
function imageLoader(data: ImageLoaderInputData) {
    let imageUrl = data.config.src;
    if (!imageUrl.includes('?')) {
        for (const [key, value] of Object.entries(data.baseUrls)) {
            if (!imageUrl.startsWith(key)) {
                continue;
            }
            if (imageUrl.startsWith('http')) {
                imageUrl = imageUrl.replace(key, value);
            } else {
                imageUrl = `${value}/${imageUrl}`;
            }
            break;
        }
    }
    if (!data.config.loaderParams) {
        return imageUrl;
    }
    const imageSizes: ImageSizes = data.config.loaderParams['imageSizes'];
    const size = imageSizes.getNext();
    return `${imageUrl}?w=${size.width}${size.height ? `&h=${size.height}` : ''}${
        data.config.loaderParams['ignoreHeight'] ? '' : '&fit=crop'
    }&auto=format`;
}
export function provideImageLoader(baseUrls: ImageLoaderInputData['baseUrls']) {
    return {
        provide: IMAGE_LOADER,
        useValue: (config: ImageLoaderConfig) => {
            return imageLoader({ config, baseUrls });
        },
    };
}

// function buildImageUrlCorporate(image: string, environment: CorporatePlatformEnv): string {
//     let imageUrl = image;
//     if (image.startsWith(environment.cmsAssetUrl)) {
//         imageUrl = image.replace(environment.cmsAssetUrl, `${environment.imgIXBaseUrlCms}`);
//     }
//     return imageUrl;
// }

// function buildImageUrlVehicleMarket(image: string, environment: VehicleMarketPlatformEnv): string {
//     let imageUrl = image;
//     if (image.startsWith(environment.baseUrlAutoScout)) {
//         imageUrl = image.replace(environment.baseUrlAutoScout, `${environment.imgIXBaseUrlAutoScout}`);
//     } else if (image.startsWith(environment.cmsAssetUrl)) {
//         imageUrl = image.replace(environment.cmsAssetUrl, `${environment.imgIXBaseUrlCms}`);
//     } else if (image.startsWith(environment.baseUrl)) {
//         imageUrl = image.replace(environment.baseUrl, `${environment.imgIXBaseUrlWholesale}`);
//     } else if (image.startsWith('assets/')) {
//         imageUrl = `${environment.imgIXBaseUrlWholesale}/${image}`;
//     }
//     return imageUrl;
// }

// function buildImageUrlPartnerWeb(image: string, environment: PartnerWebPlatformEnv): string {
//     let imageUrl = image;
//     if (image.includes('?')) {
//         return imageUrl;
//     } else if (image.startsWith(environment.baseUrlAutoScout)) {
//         imageUrl = image.replace(environment.baseUrlAutoScout, `${environment.imgIXBaseUrlAutoScout}`);
//     } else if (image.startsWith(environment.cmsAssetUrl)) {
//         imageUrl = image.replace(environment.cmsAssetUrl, `${environment.imgIXBaseUrlCms}`);
//     } else if (image.startsWith(environment.baseUrlPartnerProfileAutoglobal)) {
//         imageUrl = image.replace(
//             environment.baseUrlPartnerProfileAutoglobal,
//             `${environment.imgIXPartnerProfileAutoglobal}`
//         );
//     }
//     return imageUrl;
// }
