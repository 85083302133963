import { ServiceLabels } from 'libs/shared-lib/interfaces/partner-web/service';
import { m030c195CtaImageTeasers, m070c040DealerContact } from '../../shared/shared.it';

export const SERVICE_LABELS_IT: ServiceLabels = {
    list: {
        contact: {
            routeCta: 'Percorso',
            labels: {
                route: 'Percorso',
                phone: 'Chiamare',
                email: 'E-Mail',
            },
        },
        promotionHeadline: 'Promozioni',
        promotionShowMore: 'Tutte le promozioni',
        employeesHeadline: 'Le tue persone di contatto per il servizio',
        employeesShowMore: 'Mostrare tutte le persone di contatto',
        topTeaserAllLabel: 'Altro',
    },
    detail: {
        route: 'Calcolare l’itinerario',
        allContacts: 'Mostrare tutte le persone di contatto',
        phone: 'Chiamare',
        email: 'Inviare un’e-mail',
        contactPersonsHeadline: 'Le tue persone di contatto per il servizio',
        m070c040DealerContact,
        m030c195CtaImageTeasers,
        at: 'Presso',
        in: 'a',
        and: 'e',
        region: 'nella regione',
        andLocation: 'e presso la nostra sede a',
        andLocations: 'e presso le nostre sedi a',
        serviceFormCta: 'Fissare un appuntamento di servizio',
    },
};
