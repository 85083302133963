import { CheckoutsSeoConfig, SeoReplaceValues } from 'libs/shared-lib/interfaces';
export const CHECKOUTS_SEO_IT: CheckoutsSeoConfig = {
    buy: {
        title: 'Acquistare',
        description: '',
        image: '',
        twitter: {
            card: 'summary',
            title: 'Acquistare',
            description: '',
        },
        og: {
            title: 'Acquistare',
            description: '',
        },
        robots: {
            noIndex: true,
            noFollow: true,
        },
    },
    tradeIn: {
        title: `${SeoReplaceValues.DEALER_NAME}: Permutare o vendere l’auto online e a condizioni eque`,
        description:
            'Vendete ora la vostra auto, oppure permutatela all’acquisto della vostra nuova Opel e beneficiate di condizioni eque. Richiedere un’offerta!',
        image: '',
        twitter: {
            card: 'summary',
            title: `${SeoReplaceValues.DEALER_NAME}: Permutare o vendere l’auto online e a condizioni eque`,
            description:
                'Vendete ora la vostra auto, oppure permutatela all’acquisto della vostra nuova Opel e beneficiate di condizioni eque. Richiedere un’offerta!',
        },
        og: {
            title: `${SeoReplaceValues.DEALER_NAME}: Permutare o vendere l’auto online e a condizioni eque`,
            description:
                'Vendete ora la vostra auto, oppure permutatela all’acquisto della vostra nuova Opel e beneficiate di condizioni eque. Richiedere un’offerta!',
        },
        robots: {
            noIndex: false,
            noFollow: false,
        },
    },
    testDrive: {
        title: 'Giro di prova',
        description: '',
        image: '',
        twitter: {
            card: 'summary',
            title: 'Giro di prova',
            description: '',
        },
        og: {
            title: 'Giro di prova',
            description: '',
        },
        robots: {
            noIndex: true,
            noFollow: true,
        },
    },
    leasing: {
        title: 'Leasing',
        description: '',
        image: '',
        twitter: {
            card: 'summary',
            title: 'Leasing',
            description: '',
        },
        og: {
            title: 'Leasing',
            description: '',
        },
        robots: {
            noIndex: true,
            noFollow: true,
        },
    },
    service: {
        title: 'Fissare un appuntamento',
        description: '',
        image: '',
        twitter: {
            card: 'summary',
            title: 'Fissare un appuntamento',
            description: '',
        },
        og: {
            title: 'Fissare un appuntamento',
            description: '',
        },
        robots: {
            noIndex: true,
            noFollow: true,
        },
    },
};
