import { HomeLabels } from 'libs/shared-lib/interfaces/partner-web/home';
import {
    m030c195CtaImageTeasers,
    m060c080QuickSearchLabels,
    searchCriteriaLabels,
    vehicleCardLabels,
} from '../../shared/shared.de';

export const HOME_LABELS_DE: HomeLabels = {
    phoneCTA: 'Wir beraten dich gerne:',
    topNewsHeadline: 'News und Events',
    topNewsShowMore: 'Alle anzeigen',
    ratingLabel: 'Bewertungen auf Google',
    ratingLabelExtendedPre: 'bei',
    ratingLabelExtendedPost: 'Bewertungen auf reputation.com',
    latestPromotionHeadline: 'Aktionen',
    latestPromotionShowMore: 'Alle Aktionen',
    aboutUsLinkLabel: 'Mehr zu uns',
    quickLinksHeadline: 'Was können wir für dich tun?',
    quickLinks: {
        service: 'Termin vereinbaren',
        locate: 'So findest du uns',
        locateWithSubDealers: 'Unsere Standorte',
        contact: 'Deine Ansprechpartner',
        vehicleMarket: 'Auto finden',
    },
    vehicleCardLabels: vehicleCardLabels,
    topServices: {
        headline: 'Jetzt Werkstattermin vereinbaren',
        listButtonLabel: 'Alle Services und Informationen',
        otherServices: 'Andere',
    },
    m070c040DealerContact: {
        headline: 'Öffnungszeiten',
        rating: 'bei Google',
        desktop: {
            cta: 'Mehr zu uns',
            route: 'Route berechnen',
        },
        mobile: {
            phone: 'ANRUFEN',
            route: 'ANFAHRT',
            email: 'KONTAKT',
            chat: 'CHAT',
        },
        toLocation: 'Mehr zu uns',
    },
    m060c060VehicleCarousel: {
        headline: 'Top Angebote',
        buttonLabel: 'Alle Angebote anzeigen',
        copy: 'Entdecke unsere sofort verfübaren Opel Lagerfahrzeuge. Gerne stehen wir dir für ein Beratungsgespräch und eine Probefahrt zur Verfügung.',
    },
    m030c195CtaImageTeasers: m030c195CtaImageTeasers,
    searchCriteriaLabels: searchCriteriaLabels,
    m060c080QuickSearchLabels: m060c080QuickSearchLabels,
    mvpServiceQuickSearchLabels: {
        headline: 'Jetzt Werkstattermin vereinbaren',
        call: 'Anrufen',
        sendMail: 'E-Mail senden',
    },
};
