import { createFeature, createReducer, on } from '@ngrx/store';
import { trackingActions } from './tracking.actions';

export interface TrackingState {
    ipIsInternal: boolean;
}

const initialState: TrackingState = {
    ipIsInternal: false,
};

export const trackingFeature = createFeature({
    name: 'tracking',
    reducer: createReducer(
        initialState,
        on(trackingActions.setIsInternalIPSuccess, (state, action): TrackingState => {
            return {
                ...state,
                ipIsInternal: action.isInternal,
            };
        }),
    ),
    // extraSelectors: (baseSelectors) => {
    //     const selectIsLoadingAboutUs = createSelector(baseSelectors.selectActiveAboutUs, (aboutUs) => aboutUs === null);
    //     return {
    //         ...baseSelectors,
    //         selectIsLoadingAboutUs,
    //     };
    // },
});
