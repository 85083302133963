import { APP_INITIALIZER, Provider } from '@angular/core';
import { MatelsoService } from 'libs/ng-common-lib/src/services/matelso.service';

export interface WithMatelsoProvider {
    type: 'matelso';
    providers: Provider[];
}

export function withMatelso(): WithMatelsoProvider {
    return {
        type: 'matelso',
        providers: [
            MatelsoService,
            {
                provide: APP_INITIALIZER,
                useFactory: (ms: MatelsoService) => () => {
                    return ms.initMatelso();
                },
                deps: [MatelsoService],
                multi: true,
            },
        ],
    };
}
