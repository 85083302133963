import { EnvironmentProviders } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import { checkoutsFeature } from 'libs/ng-common-lib/src/+state/checkouts.reducer';
import { dealerFeature } from 'libs/ng-common-lib/src/+state/dealer.reducer';
import { FeatureLanguageProvider } from 'libs/ng-common-lib/src/+state/wholesale.actions';
import { wholesaleFeature } from 'libs/ng-common-lib/src/+state/wholesale.reducer';
import { FeatureType, WholesaleFeature } from 'libs/ng-common-lib/src/platform-provides/wholesale/features';
import { MenuItem, provideMenuItem } from 'libs/ng-common-lib/src/platform-provides/wholesale/menu-item';
import { WholesaleLanguage } from 'libs/shared-lib/language/language';
import { getVehicleMarketListUrl } from 'libs/shared-lib/slugs/vehicle-market.slugs';
import { providePlatformToken } from 'libs/ui/src/lib/utilities/platform-token';
import { effects } from '../+state';
import { filterFeature } from '../+state/filter.reducer';
import { vehicleFeature } from '../+state/vehicle.reducer';
import { getVehicleMarketRoutes } from '../routing/vehicle-market.routes';

export function provideVehicleMarketStatesAndEffects(): EnvironmentProviders[] {
    return [
        provideState(checkoutsFeature),
        provideState(wholesaleFeature),
        provideState(dealerFeature),
        provideState(filterFeature),
        provideState(vehicleFeature),
        provideEffects(effects),
    ];
}

export function withVehicleMarketLanguages(...languages: Array<FeatureLanguageProvider<FeatureType.VEHICLE_MARKET>>) {
    return languages.map((l) => l(FeatureType.VEHICLE_MARKET));
}

export function withVehicleMarket(
    ...languages: Array<FeatureLanguageProvider<FeatureType.VEHICLE_MARKET>>
): WholesaleFeature {
    return {
        routes: (locale: WholesaleLanguage) => getVehicleMarketRoutes(locale),
        providers: [
            withVehicleMarketLanguages(...languages),
            providePlatformToken('vehicle-market'),
            provideEffects(effects),
            ...provideVehicleMarketStatesAndEffects(),
            provideMenuItem({
                label: languages.reduce(
                    (acc, l) => {
                        const val = l(FeatureType.VEHICLE_MARKET).useValue;
                        return { ...acc, [val.locale]: val.labels.list.menuItem };
                    },
                    {} as MenuItem['label'],
                ),
                urlFunction: (locale: WholesaleLanguage, withLangPath: boolean, dealerSlug?: string) =>
                    getVehicleMarketListUrl(locale, dealerSlug, withLangPath),
            }),
        ],
    };
}
