import { PhoneNumberPrefix } from 'libs/shared-lib/interfaces';
import { M060C010VehicleCardLabels } from 'libs/shared-lib/interfaces/ui/m060-c010-vehicle-card.interface';

export const errorMessage = 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.';
export const errorRequired = 'Dieses Feld ist erforderlich';
export const errorInvalid = 'Ungültige Eingabe';

export const gender = {
    female: 'Frau',
    male: 'Herr',
};

export const footerLinks = {
    LEGAL: 'https://www.opel.ch/de/tools/opel-copyright.html',
    COOKIES: 'https://www.opel.ch/de/tools/cookie-richtlinie.html',
    DATA_POLICY: 'https://www.opel.ch/de/tools/datenschutzrichtlinie.html',
};

export const phoneNumberPrefixes: PhoneNumberPrefix[] = [
    {
        prefix: '+41',
        label: 'Schweiz',
        iconPath: 'assets/icons/flags/Switzerland.svg',
    },
    {
        prefix: '+49',
        label: 'Deutschland',
        iconPath: 'assets/icons/flags/Germany.svg',
    },
];

export const m060C050vehicleOverviewLabels = {
    consumption: 'Verbrauch kombiniert',
    consumptionAfter: 'l/100km',
    emission: 'CO2-Emissionen kombiniert',
    emissionAfter: 'g/km',
    efficiency: 'Energieeffizienz',
    mileageFromOdometer: 'string',
    dateVehicleFirstRegistered: 'string',
    vehicleTransmission: 'string',
    additionalProperty: 'string',
    fuelType: 'string',
    driveWheelConfiguration: 'string',
    summary: 'Zusammenfassung',
    monthly: ' /mt.',
    noVehicle: {
        headline: '',
        subheadline: '',
    },
};

export const m070c040DealerContact = {
    headline: 'Öffnungszeiten',
    rating: 'bei Google',
    desktop: {
        cta: 'Mehr zu uns',
        route: 'Route berechnen',
    },
    mobile: {
        phone: 'ANRUFEN',
        route: 'ANFAHRT',
        email: 'KONTAKT',
        chat: 'CHAT',
    },
    toLocation: 'Zum Standort',
};

export const m030c195CtaImageTeasers = {
    headline: 'Unsere kompetenten Standorte',
    listButtonLabel: 'zur Übersicht',
};

export const occasionsProgramOverlay = {
    headline: 'Junge Blitze – So neu kann gebraucht sein. ',
    copy: '<p class="text-left mb-10">Entdecke unsere jungen gebrauchten Fahrzeuge:</p><ul class="text-left disclistNarrow"><li>Autos die nicht älter als 12 Monate alt sind</li><li>Autos die nicht mehr als 5000 km gefahren wurden</li><li>Tageszulassungen</li><li>Demofahrzeuge</li><li>Und generell junge Occasionen (nicht älter als 12 Monate und nicht höher als 5000 KM-Stand)</li></ul><p class="text-left">Fahrzeug zu einem attraktiven Leasing mit 1.99%.</p><p class="text-left">Gehe jetzt zu deinem Händler und profitiere!</p>',
    image: {
        image: 'https://media.graphassets.com/Q9zWaz78QhCjSS6cJKLA',
        width: 300,
        height: 225,
    },
};

export const actionTags = {
    occasionsProgram: {
        text: 'Junge Blitze',
        overlay: occasionsProgramOverlay,
    },
};

export const vehicleCardLabels: M060C010VehicleCardLabels = {
    currency: 'CHF',
    leasing: 'Leasing ab',
    monthly: '/Mt.',
    actionTags: actionTags,
    firstPriceText: 'CHF',
    newPrice: 'Neupreis',
    lastPriceText: '<span class="font-normal">Leasing ab <strong>CHF</strong></span>',
    consumption: 'Verbrauch kombiniert',
    emission: 'CO2-Emission kombiniert',
};

export const searchCriteriaLabels = {
    headline: 'Ergebnisse filtern',
    button: '%s Fahrzeuge anzeigen',
    reset: 'Filter zurücksetzen',
    location: {
        currentLocation: 'Aktuellen Standort verwenden',
        subheading: 'Suche nach Adresse',
    },
    selected: 'gewählt',
    allSelected: 'Alle',
    to: 'bis',
    monthly: '/Mt.',
    ratefrom: 'Rate ab',
    rateto: 'Rate bis',
    pricefrom: 'Preis ab',
    priceto: 'Preis bis',
    vehicle_tags: {
        occasionsProgram: occasionsProgramOverlay,
    },
};

export const m060c080QuickSearchLabels = {
    headline: 'Finde dein Wunschauto',
    model: 'Modell',
    price: 'Preis',
    ctaLabel: '%s Fahrzeuge anzeigen',
    buttonAdvancedSearchLabel: 'Erweiterte Suche',
    selected: 'gewählt',
    allSelected: 'Alle',
    ratefrom: 'Rate ab',
    rateto: 'Rate bis',
    pricefrom: 'Preis ab',
    priceto: 'Preis bis',
    to: 'bis',
    monthly: '/Mt.',
};
