import { inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { RouterReducerState } from '@ngrx/router-store';
import { Store } from '@ngrx/store';

import { dealerActions } from 'libs/ng-common-lib/src/+state/dealer.actions';
import { dealerFeature, DealerState } from 'libs/ng-common-lib/src/+state/dealer.reducer';
import { serviceActions } from 'libs/ng-common-lib/src/+state/service.actions';
import { wholesaleFeature, WholesaleState } from 'libs/ng-common-lib/src/+state/wholesale.reducer';
import { EventTrackingService } from 'libs/ng-common-lib/src/services/event-tracking.service';
import { WNestApiService } from 'libs/ng-common-lib/src/services/wholesale-nest-api';
import { concatMap, exhaustMap } from 'rxjs';
import { appActions } from './app.actions';

@Injectable()
export class AppEffects {
    protected readonly wNestApiService = inject(WNestApiService);
    constructor(
        protected readonly actions$: Actions,
        protected readonly routerStore: Store<RouterReducerState>,
        protected readonly wholeSaleStore: Store<WholesaleState>,
        protected readonly dealerStore: Store<DealerState>,
        protected readonly router: Router,
        protected readonly route: ActivatedRoute,
        protected readonly trackingService: EventTrackingService,
    ) {}

    // eslint-disable-next-line max-lines-per-function
    initHomepageData$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(appActions.initHomepageData),
            concatLatestFrom(() => [
                this.wholeSaleStore.select(wholesaleFeature.selectLanguage),
                this.dealerStore.select(dealerFeature.selectActiveDealer),
            ]),
            concatMap(([, lang, dealer]) => {
                if (!dealer || !lang) {
                    return [
                        appActions.initHomepageDataFailure({
                            error: 'Could not fetch Homepage Data (no dealer or language given)',
                        }),
                    ];
                }
                return this.wNestApiService.getHomePageDataByDealerId(dealer.dealerId, lang).pipe(
                    concatMap((data) => {
                        if (data) {
                            return [
                                appActions.initHomepageDataSuccess({
                                    homePageData: data,
                                }),
                            ];
                        } else {
                            return [
                                appActions.initHomepageDataFailure({
                                    error: 'Could not fetch Homepage Data (no data returned)',
                                }),
                            ];
                        }
                    }),
                );
            }),
        );
    });

    initServiceFormData$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(appActions.initServiceFormData),
            exhaustMap(() => {
                return [
                    dealerActions.loadDealerList({ filter: 'onlinesales' }),
                    serviceActions.loadWorkshopServicesForDealer(),
                ];
            }),
        );
    });
}
