import { inject, Injectable } from '@angular/core';
import { WNestApi } from 'libs/shared-lib/interfaces';
import { WholesaleLanguage } from 'libs/shared-lib/language/language';
import { Observable } from 'rxjs';
import { WNestApiService } from './wholesale-nest-api';

@Injectable({
    providedIn: 'root',
})
export class PromotionService {
    protected readonly wnestApiService: WNestApiService = inject(WNestApiService);

    loadAllPromotionsByDealerId(
        dealerId: string,
        locale: WholesaleLanguage,
    ): Observable<WNestApi.PromotionTeaserListView[]> {
        return this.wnestApiService.getPromotionsForDealer(dealerId, locale);
    }

    loadPromotionByDealerAndSlug(
        dealerId: string,
        locale: WholesaleLanguage,
        slug: string,
        dealerSlug: string,
    ): Observable<WNestApi.PromotionDetailView | null> {
        return this.wnestApiService.getPromotionForDealerBySlug(dealerId, slug, locale, dealerSlug);
    }
}
