import { PromotionSeoConfig } from 'libs/shared-lib/interfaces/seo/seo-partner-web';

export const PROMOTIONS_SEO_IT: PromotionSeoConfig = {
    list: {
        title: 'promotion',
        description: '',
        image: '',
        twitter: {
            card: 'summary',
            title: 'promotion',
            description: '',
        },
        og: {
            title: 'News',
            description: '',
        },
        robots: {
            noIndex: false,
            noFollow: false,
        },
    },
    detail: {
        title: 'promotion Detail',
        description: '',
        image: '',
        twitter: {
            card: 'summary',
            title: 'promotion-detail',
            description: '',
        },
        og: {
            title: 'promotion Detail',
            description: '',
        },
        robots: {
            noIndex: false,
            noFollow: false,
        },
    },
};
