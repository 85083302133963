import { inject, Injectable } from '@angular/core';
import { VehicleMarketPlatformListShared, WSearchCriteria } from 'libs/shared-lib/interfaces';
import { FilterTagsLabels } from 'libs/shared-lib/interfaces/vehicle-market/vehicle-market-list';
import { WholesaleLanguage } from 'libs/shared-lib/language/language';

import { Params } from '@angular/router';
import { ChNumberPipe } from 'libs/ng-common-lib/src/pipes/ch-number.pipe';
import { M020C045InputCheckboxListOption } from 'libs/ui/src/lib/modules/020-form-elements/m020-c045-input-checkbox-list/m020-c045-input-checkbox-list.interface';
import { M020C090InputCheckboxEfficiencyListOption } from 'libs/ui/src/lib/modules/020-form-elements/m020-c090-input-checkbox-efficiency-list/m020-c090-input-checkbox-efficiency-list.interface';

@Injectable({
    providedIn: 'root',
})
export class FilterTagsService {
    private readonly chNumber = inject(ChNumberPipe);

    // eslint-disable-next-line max-statements,max-lines-per-function
    getFilterTags(
        params: Params,
        searchCriteria: WSearchCriteria.Response,
        locale: WholesaleLanguage,
        labels: FilterTagsLabels,
    ): VehicleMarketPlatformListShared.FilterTag[] {
        const filter = searchCriteria.filter;
        const tags: VehicleMarketPlatformListShared.FilterTag[] = [];

        for (const [key, value] of Object.entries(params)) {
            const cleanKey = key.replace('[]', '');
            let identifier = cleanKey;
            if (cleanKey === 'color') {
                identifier = 'bodycol';
            }
            const filterObj = filter.find((item) => item.identifier === identifier) as WSearchCriteria.Filter;
            if (cleanKey === 'model') {
                this.setCheckboxFilterTags(
                    filterObj as WSearchCriteria.CheckboxListFilter,
                    cleanKey,
                    value,
                    tags,
                    labels.model,
                );
            } else if (cleanKey === 'trans') {
                this.setCheckboxFilterTags(
                    filterObj as WSearchCriteria.CheckboxListFilter,
                    cleanKey,
                    value,
                    tags,
                    labels.trans,
                );
            } else if (cleanKey === 'cond') {
                this.setCheckboxFilterTags(
                    filterObj as WSearchCriteria.CheckboxListFilter,
                    cleanKey,
                    value,
                    tags,
                    labels.cond,
                );
            } else if (cleanKey === 'vehtyp') {
                this.setCheckboxFilterTags(
                    filterObj as WSearchCriteria.CheckboxListFilter,
                    cleanKey,
                    value,
                    tags,
                    labels.vehtyp,
                );
            } else if (cleanKey === 'drive') {
                this.setCheckboxFilterTags(
                    filterObj as WSearchCriteria.CheckboxListFilter,
                    cleanKey,
                    value,
                    tags,
                    labels.drive,
                );
            } else if (cleanKey === 'equip') {
                this.setCheckboxFilterTags(
                    filterObj as WSearchCriteria.CheckboxListFilter,
                    cleanKey,
                    value,
                    tags,
                    labels.equip,
                );
            } else if (cleanKey === 'efficiency') {
                this.setCheckboxFilterTags(
                    filterObj as WSearchCriteria.CheckboxEfficiencyListFilter,
                    cleanKey,
                    value,
                    tags,
                    labels.efficiency,
                );
            } else if (cleanKey === 'bodycol') {
                this.setCheckboxFilterTags(
                    filterObj as WSearchCriteria.CheckboxColorListFilter,
                    cleanKey,
                    value,
                    tags,
                    labels.bodycol,
                );
            } else if (cleanKey === 'body') {
                this.setCheckboxFilterTags(
                    filterObj as WSearchCriteria.CheckboxIconListFilter,
                    cleanKey,
                    value,
                    tags,
                    labels.body,
                );
            } else if (cleanKey === 'fuel') {
                this.setCheckboxFilterTags(
                    filterObj as WSearchCriteria.CheckboxListFilter,
                    cleanKey,
                    value,
                    tags,
                    labels.fuel,
                );
            } else if (cleanKey === 'lat') {
                tags.push(this.getSingleFilterTag('location', -1, labels.location));
            } else if (cleanKey === 'yearfrom') {
                tags.push(this.getSingleFilterTag('yearfrom', value, value, labels.yearfrom));
            } else if (cleanKey === 'yearto') {
                tags.push(this.getSingleFilterTag('yearto', value, value, labels.yearto));
            } else if (cleanKey === 'kmfrom') {
                const name = this.chNumber.transform(Number(value), locale);
                tags.push(this.getSingleFilterTag('kmfrom', value, name, labels.kmfrom));
            } else if (cleanKey === 'kmto') {
                const name = this.chNumber.transform(Number(value), locale);
                tags.push(this.getSingleFilterTag('kmto', value, name, labels.kmto));
            } else if (cleanKey === 'seatsfrom') {
                const name = this.chNumber.transform(Number(value), locale);
                tags.push(this.getSingleFilterTag('seatsfrom', value, name, labels.seatsfrom));
            } else if (cleanKey === 'seatsto') {
                const name = this.chNumber.transform(Number(value), locale);
                tags.push(this.getSingleFilterTag('seatsto', value, name, labels.seatsto));
            } else if (cleanKey === 'ratefrom') {
                const name = this.chNumber.transform(Number(value), locale, true);
                tags.push(this.getSingleFilterTag('ratefrom', value, name, labels.ratefrom, labels.monthly));
            } else if (cleanKey === 'rateto') {
                const name = this.chNumber.transform(Number(value), locale, true);
                tags.push(this.getSingleFilterTag('rateto', value, name, labels.rateto, labels.monthly));
            } else if (cleanKey === 'pricefrom') {
                const name = this.chNumber.transform(Number(value), locale, true);
                tags.push(this.getSingleFilterTag('pricefrom', value, name, labels.pricefrom));
            } else if (cleanKey === 'priceto') {
                const name = this.chNumber.transform(Number(value), locale, true);
                tags.push(this.getSingleFilterTag('priceto', value, name, labels.priceto));
            } else if (cleanKey === 'qualitytags') {
                tags.push(
                    ...this.setVehicleTagsAndReturnTags(value as number[], filterObj as WSearchCriteria.TagFilter),
                );
            }
        }
        return tags;
    }

    setVehicleTagsAndReturnTags(
        qualityTags: number[],
        filterObj: WSearchCriteria.TagFilter,
    ): VehicleMarketPlatformListShared.FilterTag[] {
        const tagsTmp = [];
        for (let i = 0; i < qualityTags.length; i++) {
            const qualityTag = Number(qualityTags[i]);
            for (const obj of filterObj.values) {
                if (obj.id === qualityTag) {
                    tagsTmp.push(this.getSingleFilterTag('qualitytags', qualityTags[i], obj.name));
                    break;
                }
            }
        }
        return tagsTmp;
    }

    // eslint-disable-next-line max-lines-per-function
    protected setCheckboxFilterTags(
        filterObj:
            | WSearchCriteria.CheckboxListFilter
            | WSearchCriteria.CheckboxColorListFilter
            | WSearchCriteria.CheckboxEfficiencyListFilter
            | WSearchCriteria.CheckboxIconListFilter,
        key: string,
        value: string | string[],
        tags: VehicleMarketPlatformListShared.FilterTag[],
        preName: string,
    ) {
        const values = filterObj?.values as
            | Array<M020C045InputCheckboxListOption<number>>
            | Array<M020C090InputCheckboxEfficiencyListOption<number>>;

        for (const id of this.stringToArray(value)) {
            let child: M020C045InputCheckboxListOption<number> | undefined;

            values.forEach((parent) => {
                if ('children' in parent) {
                    child = parent.children?.find((child) => child.id === Number(id));
                    if (child) {
                        tags.push(this.getSingleFilterTag(key, Number(id), child.name, preName));
                        return;
                    }
                }
            });

            if (!child) {
                let selectedObj: M020C045InputCheckboxListOption<number> | null = null;
                values.forEach(
                    (
                        valueObj:
                            | M020C045InputCheckboxListOption<number>
                            | M020C090InputCheckboxEfficiencyListOption<number>,
                    ) => {
                        if ('id' in valueObj && valueObj.id === Number(id)) {
                            selectedObj = valueObj;
                        } else if ('value' in valueObj && valueObj.value === Number(id)) {
                            selectedObj = this.mapToCheckboxListOption(valueObj);
                        }
                    },
                );
                if (selectedObj) {
                    const casted = selectedObj as M020C045InputCheckboxListOption<number>;
                    tags.push(this.getSingleFilterTag(key, Number(id), casted.name, preName));
                }
            }
        }
    }

    mapToCheckboxListOption(
        checkboxObj: M020C090InputCheckboxEfficiencyListOption<number>,
    ): M020C045InputCheckboxListOption<number> {
        return {
            id: checkboxObj.value,
            name: checkboxObj.label,
            children: undefined,
        };
    }

    protected getSingleFilterTag(
        key: string,
        value: number,
        name?: string,
        preName?: string,
        afterName?: string,
    ): VehicleMarketPlatformListShared.FilterTag {
        name = name || value.toString();

        if (preName) {
            name = `${preName}: ${name}`;
        }

        if (afterName) {
            name += `${afterName}`;
        }

        return {
            category: key,
            id: value,
            name: name,
        };
    }

    stringToArray(value: string | string[]): string[] {
        if (!Array.isArray(value)) {
            return [value];
        }
        return value;
    }

    removeFilterTag(
        activeFilterTags: VehicleMarketPlatformListShared.FilterTag[],
        tag: VehicleMarketPlatformListShared.FilterTag,
    ): VehicleMarketPlatformListShared.FilterTag[] {
        const filteredTags = activeFilterTags.filter((item) => item.id !== tag.id);
        return filteredTags;
    }

    // eslint-disable-next-line max-statements
    removeVehicleMarketFilterViaFilterTag(
        activeParams: Params,
        data: VehicleMarketPlatformListShared.FilterTag,
    ): Params {
        const queryParams = { ...activeParams };
        for (const [key, value] of Object.entries(queryParams)) {
            const cleanKey = key.replace('[]', '');
            if (data.category === 'location') {
                delete queryParams['lat'];
                delete queryParams['lon'];
                delete queryParams['radius'];
                if (queryParams['sort'] === 'location') {
                    delete queryParams['sort'];
                }
                break;
            } else if (cleanKey === data.category) {
                if (Array.isArray(value)) {
                    const filteredValues = value.filter((item: string) => Number(item) !== data.id);
                    // eslint-disable-next-line max-depth
                    if (filteredValues.length > 0) {
                        queryParams[key] = [...filteredValues];
                        break;
                    }
                }
                delete queryParams[key];
                break;
            }
        }
        return queryParams;
    }
}
