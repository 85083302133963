import { Injectable, inject } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { exhaustMap, switchMap } from 'rxjs';
import { NewsService } from '../services/news.service';
import { dealerFeature } from './dealer.reducer';
import { newsActions } from './news.actions';
import { wholesaleFeature } from './wholesale.reducer';

@Injectable()
export class NewsEffects {
    protected readonly actions$ = inject(Actions);
    protected readonly state: Store = inject(Store);
    protected readonly newsService: NewsService = inject(NewsService);

    loadAllNewsByDealer$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(newsActions.loadAllNewsByDealer),
            concatLatestFrom(() => [
                this.state.select(wholesaleFeature.selectLanguage),
                this.state.select(dealerFeature.selectActiveDealer),
            ]),
            switchMap(([, locale, dealer]) => {
                return this.newsService.loadAllNewsByDealerSlug(dealer.dealerId, locale).pipe(
                    exhaustMap((news) => {
                        if (news) {
                            return [newsActions.loadAllNewsByDealerSuccess({ news })];
                        } else {
                            return [newsActions.loadAllNewsByDealerFailure({ error: 'TODO 🔥 SOME ERROR' })];
                        }
                    }),
                );
            }),
        );
    });

    loadNewsDetail$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(newsActions.loadNews),
            concatLatestFrom(() => [
                this.state.select(wholesaleFeature.selectLanguage),
                this.state.select(dealerFeature.selectActiveDealer),
            ]),
            switchMap(([{ slug }, locale, dealer]) => {
                return this.newsService.loadNewsById(slug, dealer.dealerId, locale).pipe(
                    exhaustMap((news) => {
                        if (news) {
                            return [newsActions.loadNewsSuccess({ news })];
                        } else {
                            return [newsActions.loadNewsFailure({ error: 'TODO 🔥 SOME ERROR' })];
                        }
                    }),
                );
            }),
        );
    });
}
