import { APP_INITIALIZER, EnvironmentProviders, Provider, Type } from '@angular/core';
import { FunctionalEffect, provideEffects } from '@ngrx/effects';
import { Action, FeatureSlice, provideState } from '@ngrx/store';
import { EventTrackingService } from 'libs/ng-common-lib/src/services/event-tracking.service';

export interface WithEventTrackingProvider {
    type: 'event-tracking';
    providers: Array<Provider | EnvironmentProviders>;
}

export function withEventTracking<T>(
    stateToProvide: FeatureSlice<T, Action>,
    effectsToApply: Array<Type<unknown> | Record<string, FunctionalEffect>>,
): WithEventTrackingProvider {
    return {
        type: 'event-tracking',
        providers: [
            EventTrackingService,
            {
                provide: APP_INITIALIZER,
                useFactory: (ets: EventTrackingService) => () => {
                    return ets.init();
                },
                deps: [EventTrackingService],
                multi: true,
            },
            provideState(stateToProvide),
            provideEffects(effectsToApply),
        ],
    };
}
