import { PromotionLabels } from 'libs/shared-lib/interfaces/partner-web/promotion';
import { m030c195CtaImageTeasers, m070c040DealerContact, vehicleCardLabels } from '../../shared/shared.it';

export const PROMOTION_LABELS_IT: PromotionLabels = {
    list: {
        headline: 'Promozioni in corso',
        showMore: 'Mostrare di più',
    },
    detail: {
        m070c040DealerContact,
        m030c195CtaImageTeasers,
        at: 'Presso',
        in: 'a',
        and: 'e',
        region: 'nella regione',
        andLocation: 'e presso la nostra sede a',
        andLocations: 'e presso le nostre sedi a',
        vehicleCardLabels: vehicleCardLabels,
        vehicleListMoreButtonLabel: 'Mostrare di più',
    },
};
