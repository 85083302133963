import { ServiceLabels } from 'libs/shared-lib/interfaces/partner-web/service';
import { m030c195CtaImageTeasers, m070c040DealerContact } from '../../shared/shared.de';

export const SERVICE_LABELS_DE: ServiceLabels = {
    list: {
        contact: {
            routeCta: 'Route berechnen',
            labels: {
                route: 'Route berechnen',
                phone: 'Anrufen',
                email: 'E-Mail senden',
            },
        },
        promotionHeadline: 'Aktionen',
        promotionShowMore: 'Alle Aktionen',
        employeesHeadline: 'Ihre Service-Anspechpartner',
        employeesShowMore: 'Alle Ansprechpartner anzeigen',
        topTeaserAllLabel: 'Andere',
    },
    detail: {
        route: 'Route berechnen',
        allContacts: 'Alle Ansprachpartner anzeigen',
        phone: 'Anrufen',
        email: 'E-Mail senden',
        contactPersonsHeadline: 'Deine Service-Ansprechpartner',
        m070c040DealerContact,
        m030c195CtaImageTeasers,
        at: 'Bei',
        in: 'in',
        and: 'und',
        region: 'Region',
        andLocation: 'sowie unserem Standort in',
        andLocations: 'sowie unseren Standorten in',
        serviceFormCta: 'Termin vereinbaren',
    },
};
