import { Injectable, inject } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { exhaustMap, switchMap } from 'rxjs';
import { PromotionService } from '../services/promotion.service';
import { DealerState, dealerFeature } from './dealer.reducer';
import { promotionActions } from './promotion.actions';
import { WholesaleState, wholesaleFeature } from './wholesale.reducer';

@Injectable()
export class PromotionEffects {
    protected readonly actions$ = inject(Actions);
    protected readonly dealerState: Store<DealerState> = inject(Store);
    protected readonly wholesaleState: Store<WholesaleState> = inject(Store);
    protected readonly promotionService: PromotionService = inject(PromotionService);

    loadAllPromotionsByDealer$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(promotionActions.loadAllPromotionsByDealer),

            concatLatestFrom(() => [
                this.dealerState.select(dealerFeature.selectActiveDealer),
                this.wholesaleState.select(wholesaleFeature.selectLanguage),
            ]),
            switchMap(([, dealer, language]) => {
                return this.promotionService.loadAllPromotionsByDealerId(dealer.dealerId, language).pipe(
                    exhaustMap((promotions) => {
                        if (promotions) {
                            return [promotionActions.loadAllPromotionsByDealerSuccess({ promotions })];
                        } else {
                            return [promotionActions.loadAllPromotionsByDealerFailure({ error: 'TODO 🔥 SOME ERROR' })];
                        }
                    }),
                );
            }),
        );
    });

    loadPromotionByDealerAndSlug$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(promotionActions.loadPromotionByDealerAndSlug),

            concatLatestFrom(() => [
                this.dealerState.select(dealerFeature.selectActiveDealer),
                this.wholesaleState.select(wholesaleFeature.selectLanguage),
            ]),
            switchMap(([action, dealer, language]) => {
                if (dealer && language) {
                    return this.promotionService
                        .loadPromotionByDealerAndSlug(dealer.dealerId, language, action.slug, dealer.slug)
                        .pipe(
                            exhaustMap((promotion) => {
                                if (promotion) {
                                    return [promotionActions.loadPromotionByDealerAndSlugSuccess({ promotion })];
                                } else {
                                    return [
                                        promotionActions.loadPromotionByDealerAndSlugFailure({
                                            error: 'TODO 🔥 SOME ERROR',
                                        }),
                                    ];
                                }
                            }),
                        );
                } else {
                    return [promotionActions.loadPromotionByDealerAndSlugFailure({ error: 'TODO 🔥 SOME ERROR' })];
                }
            }),
        );
    });
}
