import { APP_INITIALIZER, Provider } from '@angular/core';
import { FusedeckService } from 'libs/ng-common-lib/src/services/fusedeck.service';

export interface withFusedeckProvider {
    type: 'fusedeck';
    providers: Provider[];
}

export function withFusedeck(fusedeckId: string, fusedeckUrl: string): withFusedeckProvider {
    return {
        type: 'fusedeck',
        providers: [
            FusedeckService,
            {
                provide: APP_INITIALIZER,
                useFactory: (fs: FusedeckService) => () => {
                    return fs.initFusedeck(fusedeckId, fusedeckUrl);
                },
                deps: [FusedeckService],
                multi: true,
            },
        ],
    };
}
