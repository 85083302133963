import { Injectable, inject } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Dealer } from 'libs/shared-lib/interfaces/w-nest';
import { catchError, exhaustMap, of, switchMap } from 'rxjs';
import { ServiceService } from '../services/service.service';
import { DealerState, dealerFeature } from './dealer.reducer';
import { serviceActions } from './service.actions';
import { ServiceState } from './service.reducer';
import { WholesaleState, wholesaleFeature } from './wholesale.reducer';

@Injectable()
export class ServiceEffects {
    protected readonly actions$ = inject(Actions);
    protected readonly serviceState: Store<ServiceState> = inject(Store);
    protected readonly serviceService: ServiceService = inject(ServiceService);
    protected readonly dealerState: Store<DealerState> = inject(Store);
    protected readonly wholesaleState: Store<WholesaleState> = inject(Store);

    loadAllServiceByDealer$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(serviceActions.loadAllServiceByDealer),
            concatLatestFrom(() => [
                this.dealerState.select(dealerFeature.selectActiveDealer),
                this.wholesaleState.select(wholesaleFeature.selectLanguage),
            ]),
            switchMap(([, dealer, language]) => {
                return this.serviceService.loadAllServicesByDealerSlug((dealer as Dealer).dealerId, language).pipe(
                    exhaustMap((service) => {
                        if (service) {
                            return [serviceActions.loadAllServiceByDealerSuccess({ service })];
                        } else {
                            return [serviceActions.loadAllServiceByDealerFailure({ error: 'TODO 🔥 SOME ERROR' })];
                        }
                    }),
                );
            }),
        );
    });

    loadServiceListByDealer$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(serviceActions.loadServiceList),
            concatLatestFrom(() => [
                this.dealerState.select(dealerFeature.selectActiveDealer),
                this.wholesaleState.select(wholesaleFeature.selectLanguage),
            ]),
            switchMap(([, dealer, language]) => {
                return this.serviceService.loadServiceListByDealerSlug(dealer.dealerId, language).pipe(
                    exhaustMap((serviceList) => {
                        if (serviceList) {
                            return [serviceActions.loadServiceListSuccess({ serviceList: serviceList })];
                        } else {
                            return [serviceActions.loadServiceListFailure({ error: 'Failed to load service list' })];
                        }
                    }),
                );
            }),
        );
    });

    loadServiceDetailByDealer$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(serviceActions.loadServiceDetail),
            concatLatestFrom(() => [
                this.dealerState.select(dealerFeature.selectActiveDealer),
                this.wholesaleState.select(wholesaleFeature.selectLanguage),
            ]),
            switchMap(([action, dealer, language]) => {
                return this.serviceService
                    .loadServiceDetailByDealerSlug(dealer.dealerId, language, dealer.slug, action.serviceSlug)
                    .pipe(
                        catchError(() => of(null)),
                        exhaustMap((serviceDetail) => {
                            if (serviceDetail) {
                                return [serviceActions.loadServiceDetailSuccess({ serviceDetail: serviceDetail })];
                            } else {
                                return [serviceActions.loadServiceDetailFailure({ error: 'TODO 🔥 SOME ERROR' })];
                            }
                        }),
                    );
            }),
        );
    });

    loadWorkshopServicesByDealerId$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(serviceActions.loadWorkshopServicesForDealer),
            concatLatestFrom(() => [
                this.dealerState.select(dealerFeature.selectActiveDealer),
                this.wholesaleState.select(wholesaleFeature.selectLanguage),
            ]),
            switchMap(([, dealer, language]) => {
                return this.serviceService
                    .loadWorkshopServicesByDealerId((dealer as Dealer).dealerId, language, (dealer as Dealer).slug)
                    .pipe(
                        exhaustMap((services) => {
                            if (services && services.length > 0) {
                                return [serviceActions.loadWorkshopServicesForDealerSuccess({ services })];
                            } else {
                                return [serviceActions.loadServiceDetailFailure({ error: 'No services available' })];
                            }
                        }),
                    );
            }),
        );
    });
}
