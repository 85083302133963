import { AboutUsLabels } from 'libs/shared-lib/interfaces/partner-web/about-us';
import { m070c040DealerContact } from '../../shared/shared.it';

export const ABOUT_US_LABELS_IT: AboutUsLabels = {
    aboutUsHeadline: 'Su di noi',
    brandTextHeadline: 'This is Opel',
    brandTextCopy:
        'Tra nuove e innovative tecnologie e invenzioni visionarie, non c’è alcun dubbio: viviamo in un’epoca straordinaria. Tuttavia, le nuove invenzioni sono in grado di cambiare il mondo e renderlo un posto migliore soltanto se vengono messe a disposizione di tutti. Rendere le innovazioni accessibili a quante più persone possibili è una missione saldamente ancorata nel DNA del marchio Opel. Audace, innovativo e dal design pulito: THIS IS OPEL.',
    moreLinkId: 'missing-translation-ueber-uns',
    moreLabel: 'Per saperne di più',
    phoneLabel: 'Saremo lieti di consigliarvi:',
    ourTeamHeadline: 'Il nostro team',
    dealerContactLabels: m070c040DealerContact,
    readMore: 'Per saperne di più',
};
