import { PromotionLabels } from 'libs/shared-lib/interfaces/partner-web/promotion';
import { m030c195CtaImageTeasers, m070c040DealerContact, vehicleCardLabels } from '../../shared/shared.de';

export const PROMOTION_LABELS_DE: PromotionLabels = {
    list: {
        headline: 'Aktuelle Aktionen',
        showMore: 'Weitere anzeigen',
    },
    detail: {
        m070c040DealerContact,
        m030c195CtaImageTeasers,
        at: 'Bei',
        in: 'in',
        and: 'und',
        region: 'Region',
        andLocation: 'sowie unserem Standort in',
        andLocations: 'sowie unseren Standorten in',
        vehicleCardLabels,
        vehicleListMoreButtonLabel: 'Mehr anzeigen',
    },
};
