import { Injectable, inject } from '@angular/core';
import { WCar, WNestApi } from 'libs/shared-lib/interfaces';
import { WholesaleLanguage } from 'libs/shared-lib/language/language';
import { Observable } from 'rxjs';
import { WNestApiService } from './wholesale-nest-api';

interface PackagesRequestData {
    modelUid: number;
    year: number;
    month: number;
}
@Injectable({
    providedIn: 'root',
})
export class AdditionalPackagesService {
    protected readonly api = inject(WNestApiService);

    getAdditionalPackages(vehicle: WCar.Car, locale: WholesaleLanguage): Observable<WNestApi.AdditionalsPackage[]> {
        const additionalPackagesRequestData = this.getAdditionalPackagesRequestData(vehicle);
        return this.api.getAdditionalPackages(
            additionalPackagesRequestData.modelUid,
            additionalPackagesRequestData.year,
            additionalPackagesRequestData.month,
            locale,
        );
    }

    // eslint-disable-next-line max-statements
    getAdditionalPackagesRequestData(vehicle: WCar.Car): PackagesRequestData {
        const additionalPackagesRequestData = {
            modelUid: 0,
            year: 0,
            month: 0,
        };
        if (vehicle?.firstRegistration.year) {
            if (typeof vehicle?.firstRegistration.year === 'string') {
                additionalPackagesRequestData.year = parseInt(vehicle?.firstRegistration.year);
            }
            if (typeof vehicle?.firstRegistration.year === 'number') {
                additionalPackagesRequestData.year = vehicle?.firstRegistration.year;
            }
        }
        if (vehicle?.firstRegistration.month) {
            if (typeof vehicle?.firstRegistration.month === 'string') {
                additionalPackagesRequestData.month = parseInt(vehicle?.firstRegistration.month);
            }
            if (typeof vehicle?.firstRegistration.month === 'number') {
                additionalPackagesRequestData.month = vehicle?.firstRegistration.month;
            }
        }
        if (vehicle?.model.id) {
            additionalPackagesRequestData.modelUid = vehicle?.model.id;
        }
        return additionalPackagesRequestData;
    }
}
