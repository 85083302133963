import { NavigationLabels } from 'libs/shared-lib/interfaces/partner-web/navigation';

export const NAV_LABELS_DE: NavigationLabels = {
    vehicleList: 'Auto-finden',
    aboutUs: 'Über uns',
    service: 'Service',
    ourLocations: 'Unsere Standorte',
    overview: 'Übersicht',
    route: 'Anfahrt',
    contact: 'Kontakt',
    phone: 'Anrufen',
    phoneRibbonDesktopText: 'Wir sind für dich da!',
    phoneDesktop: 'Wir beraten dich gerne:',
    tradeIn: 'Dein Auto verkaufen',
};
