import { SeoPage, SeoReplaceValues } from 'libs/shared-lib/interfaces';
export const HOME_SEO_DE: SeoPage = {
    title: `${SeoReplaceValues.DEALER_NAME}: Opel Occasionen und Vorführmodelle online kaufen und Auto verkaufen`,
    description: `Finden und kaufen Sie Opel Vorführwagen und Occasionen bei ${SeoReplaceValues.DEALER_NAME}. Oder verkaufen Sie Ihr Auto. Jetzt gleich online!`,
    image: '',
    twitter: {
        card: 'summary',
        title: `${SeoReplaceValues.DEALER_NAME}: Opel Occasionen und Vorführmodelle online kaufen und Auto verkaufen`,
        description: `Finden und kaufen Sie Opel Vorführwagen und Occasionen bei ${SeoReplaceValues.DEALER_NAME}. Oder verkaufen Sie Ihr Auto. Jetzt gleich online!`,
    },
    og: {
        title: `${SeoReplaceValues.DEALER_NAME}: Opel Occasionen und Vorführmodelle online kaufen und Auto verkaufen`,
        description: `Finden und kaufen Sie Opel Vorführwagen und Occasionen bei ${SeoReplaceValues.DEALER_NAME}. Oder verkaufen Sie Ihr Auto. Jetzt gleich online!`,
    },
    robots: {
        noIndex: false,
        noFollow: false,
    },
};
