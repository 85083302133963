import { DealerSearchLabels } from 'libs/shared-lib/interfaces/dealersearch/dealer-search-labels';

export const DEALER_SEARCH_LABELS_FR: DealerSearchLabels = {
    item: {
        ourModels: 'Nos modèles',
        route: 'Itinéraire',
        toSite: 'Vers le site internet',
        distance: function (distance: number): string {
            return `Distance: ${distance} km`;
        },
        sell: 'Ventes',
        service: 'Service',
        warehouse: 'Entrepôt',
        time: function (time: string): string {
            return `${time} Uhr`;
        },
        status: {
            opened: 'Actuellement ouvert',
            closed: 'Fermé',
            closingSoon: 'Ferme bientôt',
            openingSoon: 'Ouvre bientôt',
        },
    },
    resultList: {
        showMore: 'Afficher plus',
    },
    autocomplete: {
        currentLocation: 'Utiliser la position actuelle',
        searchPlaceholder: 'Rechercher par garage',
    },
    backToMap: 'Retour à la carte',
    backToList: 'Retour à la liste',
    searchInput: {
        search: 'Saisir nom du garage, NPA ou lieu',
    },
    filter: {
        button: 'Filtre',
        reset: 'Réinitialiser filtres',
        headline: 'Filtrer les résultats',
        sell: 'Sites avec ventes',
        service: 'Sites avec service',
    },
};
