import { VehicleMarketLabels } from 'libs/shared-lib/interfaces';
import { M060C010VehicleCardLabels } from 'libs/shared-lib/interfaces/ui/m060-c010-vehicle-card.interface';
import { VEHICLE_NOT_AVAILABLE_LABELS_IT } from 'libs/vehicle-market-lib/src/lib/labels/vehicle-not-available/vehicle-not-available.it';

const occasionsProgram = 'Veicoli recenti';

const occasionsProgramOverlay = {
    headline: 'Veicoli recenti – Così l’usato diventa come nuovo',
    copy: '<p class="text-left mb-10">Scoprite i nostri veicoli usati giovani:</p><ul class="text-left disclistNarrow"><li>Auto che non hanno più di 12 mesi</li><li>Autovetture che non hanno percorso più di 5000 km</li><li>immatricolazioni giornaliere</li><li>Auto dimostrative</li><li> E in generale auto usate giovani (non più vecchie di 12 mesi e non superiori a 5000 KM)</li></ul><p class="text-left">Acquista subito un veicolo come nuovo a un interessante tasso di leasing dell\'1,99%. </p><p class="text-left">Vai dal tuo concessionario e approfitta di questa opportunità!</p>',
    image: {
        image: 'https://media.graphassets.com/5r1Sh2iSwO9Ys2uBQww4',
        width: 300,
        height: 225,
    },
};

const actionTags = {
    occasionsProgram: {
        text: occasionsProgram,
        overlay: occasionsProgramOverlay,
    },
};

const vehicleCardLabels: M060C010VehicleCardLabels = {
    currency: 'CHF',
    leasing: 'Leasing da',
    monthly: '/mese',
    actionTags: actionTags,
    newPrice: 'Prezzo nuovo',
    firstPriceText: '',
    lastPriceText: 'Leasing da',
    consumption: 'Consumo combinato',
    emission: 'Emissioni di CO2 combinate',
};

const searchCriteriaLabels = {
    headline: 'Filtrare i risultati',
    button: 'Mostrare i %s veicoli',
    reset: 'Ripristinare filtri',
    location: {
        currentLocation: 'Utilizzare  la posizione attuale',
        subheading: 'Ricercare per indirizzo',
    },
    selected: 'selezionato',
    allSelected: 'Tutti',
    to: 'Fino a',
    monthly: '/mese',
    ratefrom: 'Rata da',
    rateto: 'Rata a',
    pricefrom: 'Prezzo a partire da',
    priceto: 'Prezzo fino a',
    vehicle_tags: {
        occasionsProgram: occasionsProgramOverlay,
    },
};

export const VEHICLE_MARKET_LABELS_IT: VehicleMarketLabels.Labels = {
    list: {
        menuItem: 'Trovare un’auto',
        vehicleCount: 'offerte di veicoli',
        sortBtn: 'Ordinare',
        filterBtn: 'Filtrare i risultati',
        prev: 'Indietro',
        next: 'Avanti',
        vehicleCardLabels: vehicleCardLabels,
        searchCriteria: searchCriteriaLabels,
        filterTags: {
            model: 'Modello',
            trans: 'Cambio',
            cond: 'Tipo di veicolo',
            vehtyp: 'Categoria di veicoli',
            drive: 'Trazione',
            equip: 'Equipaggiamento',
            efficiency: 'Efficienza energetica',
            bodycol: 'Colore',
            body: 'Tipologia di carrozzeria',
            fuel: 'Carburante',
            location: 'Filtro di sede',
            yearfrom: '1a immatricolazione dal',
            yearto: '1a immatricolazione al',
            kmfrom: 'Da km',
            kmto: 'A km',
            seatsfrom: 'Da posti',
            seatsto: 'A posti',
            ratefrom: 'Rata da',
            rateto: 'Rata a',
            pricefrom: 'Prezzo a partire da',
            priceto: 'Prezzo fino a',
            monthly: '/mese',
            vehicle_tags: { occasionsProgram: 'Veicoli recenti' },
        },
    },
    detail: {
        reserved: {
            copy: 'ToDo IT: Dieses Fahrzeug ist reserviert. Wir beraten Sie gerne zu verfügbaren Alternativen.',
            contactVia: 'ToDo IT: Sie erreichen uns unter ',
            linkText: 'ToDo IT: Jetzt Beratungstermin vereinbaren',
        },
        headline: 'Auto Detail IT',
        moreDetails: 'Per saperne di più',
        priceTag: 'Prezzo d’acquisto',
        newPrice: 'Prezzo nuovo',
        onlineSalesAndLeasingCard: {
            headline: 'La vostra richiesta di offerta online:',
            copy: 'con pochi clic puoi inviarci in qualsiasi momento una richiesta di offerta non vincolante.',
            currency: 'CHF',
            leasing: 'Leasing da',
            monthly: '/mese',
            newPrice: 'Prezzo nuovo',
            firstPriceText: 'Acquistare direttamente online per',
            lastPriceText: 'Leasing da',
        },
        testDriveCard: {
            hasLeasing: {
                healdine: 'Volete provare prima un veicolo?',
                copy: 'Naturalmente con piacere! Comunicateci la vostra data preferita e vi contatteremo al più presto.',
                btn: 'Provare ora su strada',
            },
            noLeasing: {
                healdine: 'Volete provare prima un veicolo?',
                copy: 'Naturalmente con piacere! Comunicateci la vostra data preferita e vi contatteremo al più presto.',
                btn: 'Provare ora su strada',
            },
        },
        location: {
            headline: 'Sede',
            contactBox: {
                copyBefore: 'oggi dalle',
                copyMid: 'alle',
                copyAfter: '',
                mail: 'E-Mail',
                phone: 'Chiamare',
                route: 'Itinerario',
            },
            ratingBy: 'da Google',
            openings: {
                headlineToday: 'Siamo qui per voi:',
                today: 'oggi',
                from: 'dalle',
                to: 'alle',
                clock: '',
                clockFrom: '',
                clockTo: '',
            },
        },
        promise: {
            default: {
                healdine: 'I vostri vantaggi quando acquistate un veicolo da noi',
                list: [
                    {
                        text: 'Consulenza di alto livello da parte di referenti competenti',
                        icon: 'assets/icons/done_black.svg',
                    },
                    {
                        text: 'Disponibile in qualsiasi momento',
                        icon: 'assets/icons/done_black.svg',
                    },
                    {
                        text: 'Il vostro esperto di mobilità',
                        icon: 'assets/icons/done_black.svg',
                    },
                ],
            },
            occasionsProgram: {
                healdine: 'I vostri vantaggi quando acquistate un veicolo da noi',
                list: [
                    {
                        text: 'Consulenza di alto livello da parte di referenti competenti',
                        icon: 'assets/icons/done_black.svg',
                    },
                    {
                        text: 'Disponibile in qualsiasi momento',
                        icon: 'assets/icons/done_black.svg',
                    },
                    {
                        text: 'Il vostro esperto di mobilità',
                        icon: 'assets/icons/done_black.svg',
                    },
                ],
            },
        },
        highlights: {
            headline: 'Caratteristiche del veicolo',
        },
        warranty: {
            headline: 'Garanzia',
            copy: 'Tutti i veicoli nuovi sono coperti dalla garanzia di fabbrica per 3 anni o 100.000 chilometri, a seconda di quale sia la prima scadenza.',
        },
        insurance: {
            headline: 'Assigurazione',
            copy: 'Assicurazione casco totale, assicurazione auto con o senza leasing, assicurazione GAP, assicurazione rateale Secure4You+ in caso di malattia o disoccupazione... Santander Consumer Finance ti propone una vasta gamma di offerte complete create su misura per le tue esigenze.',
            cta: 'Per saperne di più',
        },
        similarVehicles: {
            headline: 'Altre offerte',
            copy: 'In funzione dell’auto che hai selezionato sopra, abbiamo trovato le seguenti interessanti offerte che pensiamo facciano al caso tuo',
            cta: 'Mostrare tutte le offerte',
        },
        vehicleCardLabels: vehicleCardLabels,
        tabs: {
            teckData: 'Dati tecnici',
            equipment: 'Equipaggiamento',
            comment: 'Commento',
            envkv: {
                headline: 'Dati ambientali ed energetici',
            },
            leasing: 'Calcolo del leasing',
            move: 'Abbonamento auto',
        },
        gallery: 'Immagini',
        equipment: {
            optional: 'Equipaggiamento opzionale',
            standard: 'Dotazione di serie',
        },
        actionTags: actionTags,
        m060c070Labels: {
            headline: 'Calcolare leasing',
            vdpHeadline: 'Rata mensile',
            durationHeadline: 'Durata in mesi',
            runtimeHeadline: 'Chilometraggio annuale',
            commissionHeadline: 'Acconto',
            nextButton: "Acquistare ora in leasing l'auto dei sogni",
            anualPercentageRate: 'Tasso annuo effettivo globale',
            debitInterest: 'Interesse passivo',
            monthlyRate: 'Rata mensile',
            footnoteBound: '(1) Legato per l’intera durata del contratto',
            footnoteCosts:
                '(2) I costi di trasporto sono inclusi nella rata mensile. Tutte le informazioni senza garanzia.',
            footnoteDelivery: '(3) esclusi i costi forfettari di consegna',
            runtimeYearly: 'Chilometraggio (km all’anno)',
            months: 'mese',
            duration: 'Durata',
            calcError: 'Purtroppo questa configurazione non è valida.',
            unit: 'km',
            monthly: '/mese',
        },
    },
    vehicleNotAvailablePage: VEHICLE_NOT_AVAILABLE_LABELS_IT,
};
