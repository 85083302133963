import { ApplicationConfig } from '@angular/core';
import { DEALER_SEARCH_LABELS_DE } from 'apps/opel/ch/partner-web/src/app/i18n/dealer-search/de';
import { DEALER_SEARCH_LABELS_FR } from 'apps/opel/ch/partner-web/src/app/i18n/dealer-search/fr';
import { DEALER_SEARCH_LABELS_IT } from 'apps/opel/ch/partner-web/src/app/i18n/dealer-search/it';
import { withFooter } from 'libs/corporate-platform/src/lib/provider/footer.provider';
import { withNavigation } from 'libs/corporate-platform/src/lib/provider/nav.provider';
import { TrackingEffects } from 'libs/ng-common-lib/src/+state/tracking.effects';
import { trackingFeature } from 'libs/ng-common-lib/src/+state/tracking.reducer';
import { withLanguage } from 'libs/ng-common-lib/src/language/language.provider';
import { withEventTracking } from 'libs/ng-common-lib/src/provider/event-tracking.provider';
import { withFusedeck } from 'libs/ng-common-lib/src/provider/fusedeck.provider';
import { withMatelso } from 'libs/ng-common-lib/src/provider/matelso.provider';
import { provideGoogleMapsConfig } from 'libs/ng-common-lib/src/services/google-maps';
import { provideImageLoader } from 'libs/ng-common-lib/src/utilities/image-loader';
import { withHomeDefault } from 'libs/partner-web-platform/src/lib/pages/home/home-default/home-default.provider';
import {
    providePartnerWebPlatform,
    withDealerSearch,
    withPartnerWebLanguages,
} from 'libs/partner-web-platform/src/lib/provider/partner-web.provider';
import { withPromotionDetailOverlay } from 'libs/partner-web-platform/src/lib/provider/promotion-detail-overlay.provider';
import { withHome } from 'libs/partner-web-platform/src/lib/provider/with-home';
import { Currency, LocalesForWholesalerCountryPlatform } from 'libs/shared-lib/interfaces';
import { OpelIframeFormOverlayDialogTag } from 'libs/ui/src/lib/dialogs/opel-iframe-form/opel-iframe-form.dialog';
import { M050C040MainnavigationPwComponent } from 'libs/ui/src/lib/modules/050-navigation-footer-overlays/m050-c040-mainnavigation-pw/m050-c040-mainnavigation-pw.component';
import { tag as footerTag } from 'libs/ui/src/lib/modules/050-navigation-footer-overlays/m050-c050-footer-pw/m050-c050-footer-pw.lazy';
import { providePlaceholderImagesToken } from 'libs/ui/src/lib/utilities/imgix-base-url-token';
import { withVehicleMarketLanguages } from 'libs/vehicle-market-lib/src/lib/provider/with-vehicle-market.provider';
import { PLATFORM_CONFIG } from '../../platform.config';
import { environment } from '../environments/environment';
import { PARTNER_WEB_LABELS_DE } from './i18n/partner-web/partner-web.de';
import { PARTNER_WEB_LABELS_FR } from './i18n/partner-web/partner-web.fr';
import { PARTNER_WEB_LABELS_IT } from './i18n/partner-web/partner-web.it';
import { VEHICLE_MARKET_LABELS_DE } from './i18n/vehicle-market/vehicle-market.de';
import { VEHICLE_MARKET_LABELS_FR } from './i18n/vehicle-market/vehicle-market.fr';
import { VEHICLE_MARKET_LABELS_IT } from './i18n/vehicle-market/vehicle-market.it';
import { PARTNER_WEB_SEO_DE } from './seo/partner-web/partner-web.seo.de';
import { PARTNER_WEB_SEO_FR } from './seo/partner-web/partner-web.seo.fr';
import { PARTNER_WEB_SEO_IT } from './seo/partner-web/partner-web.seo.it';

import { VEHICLE_MARKET_SEO_DE } from './seo/vehicle-market/vehicle-market.de.seo';
import { VEHICLE_MARKET_SEO_FR } from './seo/vehicle-market/vehicle-market.fr.seo';
import { VEHICLE_MARKET_SEO_IT } from './seo/vehicle-market/vehicle-market.it.seo';
import { styleProviders } from './style.providers';

export const appConfig: ApplicationConfig = {
    providers: [
        ...styleProviders,
        provideGoogleMapsConfig({
            apiKey: environment.googleMapsApiKey,
        }),
        providePartnerWebPlatform(
            {
                wholesaler: PLATFORM_CONFIG.wholesaler,
                country: PLATFORM_CONFIG.country,
                xDefaultLocale: PLATFORM_CONFIG.xDefaultLocale,
                languageSuffix: PLATFORM_CONFIG.languageSuffix,
                wholesaleNestApiUrl: environment.wholesaleNestApiUrl,
                currencyNumberConfig: {
                    country: 'CH',
                    currency: Currency.CHF,
                    localeReplacement: {
                        it: 'fr',
                    },
                    numberSuffixOnZeroDigitsLocales: ['de'],
                    fractionSplitterReplacementLocales: ['de'],
                },
                rootRedirectUrl: 'https://www.opel.ch/de/tools/haendlersuche.html',
                serviceForm: true,
            },
            { nav: withNavigation(M050C040MainnavigationPwComponent), footer: withFooter(footerTag) },
            LocalesForWholesalerCountryPlatform['opel']['ch']['partner-web'],
            withVehicleMarketLanguages(
                withLanguage('de', VEHICLE_MARKET_LABELS_DE, VEHICLE_MARKET_SEO_DE),
                withLanguage('fr', VEHICLE_MARKET_LABELS_FR, VEHICLE_MARKET_SEO_FR),
                withLanguage('it', VEHICLE_MARKET_LABELS_IT, VEHICLE_MARKET_SEO_IT),
            ),
            withPartnerWebLanguages(
                withLanguage('de', PARTNER_WEB_LABELS_DE, PARTNER_WEB_SEO_DE),
                withLanguage('fr', PARTNER_WEB_LABELS_FR, PARTNER_WEB_SEO_FR),
                withLanguage('it', PARTNER_WEB_LABELS_IT, PARTNER_WEB_SEO_IT),
            ),
            withHome(withHomeDefault()),
            withDealerSearch(
                withLanguage('de', DEALER_SEARCH_LABELS_DE),
                withLanguage('fr', DEALER_SEARCH_LABELS_FR),
                withLanguage('it', DEALER_SEARCH_LABELS_IT),
            ),
            withFusedeck(environment.fuseDeckId, environment.fuseDeckUrl),
            withMatelso(),
            withEventTracking(trackingFeature, [TrackingEffects]),
            withPromotionDetailOverlay(OpelIframeFormOverlayDialogTag),
        ),
        providePlaceholderImagesToken(
            'https://cmsimg.imgix.net/wwv4eN49RzWCQfUoJUJZ',
            'https://cmsimg.imgix.net/qrxXKwLdTxauuw8Tc2t8',
        ),
        provideImageLoader({
            [environment.baseUrlAutoScout]: environment.imgIXBaseUrlAutoScout,
            [environment.cmsAssetUrl]: environment.imgIXBaseUrlCms,
            [environment.baseUrlPartnerProfileAutoglobal]: environment.imgIXPartnerProfileAutoglobal,
        }),
    ],
};
