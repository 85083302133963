import { Params } from '@angular/router';
import { dealerFeature } from 'libs/ng-common-lib/src/+state/dealer.reducer';
import { newsActions } from 'libs/ng-common-lib/src/+state/news.actions';
import { newsFeature } from 'libs/ng-common-lib/src/+state/news.reducer';
import { ngrxLoaderGuard } from 'libs/ng-common-lib/src/guards/ngrx-loader.guard';
import { PAGE_TYPE } from 'libs/shared-lib/interfaces/global/tracking';
import { WholesaleLanguage } from 'libs/shared-lib/language/language';
import { NewsSlugs, getNewsDetailUrl, getNewsListUrl } from 'libs/shared-lib/routing/news';
import { getNotFoundUrl } from 'libs/shared-lib/slugs/not-found.slug';
import { map } from 'rxjs';

// eslint-disable-next-line max-lines-per-function
export function getNewsRoutes(lang: WholesaleLanguage) {
    return [
        {
            path: NewsSlugs[lang].list,
            children: [
                {
                    path: '',
                    loadComponent: () => import('../news/news-list/news-list.component'),
                    data: {
                        pageType: PAGE_TYPE.NEWS,
                        languageSwitchSelector: newsFeature.selectActiveNews,
                        languageSwitchUrlGenerator: (lang: WholesaleLanguage, route: Params) =>
                            getNewsListUrl(lang, route['dealerSlug']),
                    },
                },
                {
                    path: ':slug',
                    loadComponent: () => import('../news/news-detail/news-detail.component'),
                    canActivate: [
                        ngrxLoaderGuard({
                            actionToDispatch: (route) => newsActions.loadNews({ slug: route.params['slug'] }),
                            waitUntilLoadedSelector: newsFeature.selectIsLoadingNewsDetail,
                            dataToCheckSelector: newsFeature.selectActiveNews,
                            redirectOnFailUrl: (route, store) =>
                                store
                                    .select(dealerFeature.selectActiveDealer)
                                    .pipe(map((dealer) => getNotFoundUrl(lang, dealer.slug))),
                        }),
                    ],
                    data: {
                        pageType: PAGE_TYPE.NEWS,
                        languageSwitchSelector: newsFeature.selectActiveNews,
                        languageSwitchUrlGenerator: (lang: WholesaleLanguage, route: Params) =>
                            getNewsDetailUrl(lang, route['slug'], route['dealerSlug']),
                    },
                },
            ],
        },
    ];
}
