import { FOOTER_LABELS_FR } from 'apps/opel/ch/partner-web/src/app/i18n/partner-web/footer/footer.fr';
import { PROMOTION_NOT_AVAILABLE_LABELS_FR } from 'apps/opel/ch/partner-web/src/app/i18n/partner-web/promotion-not-available/promotion-not-available.fr';
import { PartnerWebLabels } from 'libs/shared-lib/interfaces';
import { ABOUT_US_LABELS_FR } from './about-us/about-us.fr';
import { CHECKOUTS_LABELS_FR } from './checkouts/checkouts.fr';
import { HOME_LABELS_FR } from './home/home.fr';
import { LOCATIONS_LABELS_FR } from './locations/locations.fr';
import { NAV_LABELS_FR } from './nav/nav.fr';
import { NEWS_LABELS_FR } from './news/news.fr';
import { NOT_FOUND_LABELS_FR } from './not-found/not-found.fr';
import { PROMOTION_LABELS_FR } from './promotions/promotions.fr';
import { SERVICE_LABELS_FR } from './service/service.fr';

export const PARTNER_WEB_LABELS_FR: PartnerWebLabels.Labels = {
    home: HOME_LABELS_FR,
    checkouts: CHECKOUTS_LABELS_FR,
    locations: LOCATIONS_LABELS_FR,
    news: NEWS_LABELS_FR,
    promotions: PROMOTION_LABELS_FR,
    aboutUs: ABOUT_US_LABELS_FR,
    service: SERVICE_LABELS_FR,
    footer: FOOTER_LABELS_FR,
    promotionNotAvailablePage: PROMOTION_NOT_AVAILABLE_LABELS_FR,
    notFoundPage: NOT_FOUND_LABELS_FR,
    nav: NAV_LABELS_FR,
};
