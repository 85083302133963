import { Type } from '@angular/core';
import { DefaultExport, Route } from '@angular/router';
import { Params } from 'express-serve-static-core';
import { PAGE_TYPE } from 'libs/shared-lib/interfaces/global/tracking';
import { WholesaleLanguage } from 'libs/shared-lib/language/language';
import { getHomeDealerUrl } from 'libs/shared-lib/slugs/home-pages.slug';

export function withHome(homeComponent: () => Promise<DefaultExport<Type<unknown>>>): Route {
    return {
        path: '',
        loadComponent: homeComponent,
        data: {
            pageType: PAGE_TYPE.HOMEPAGE,
            languageSwitchUrlGenerator: (locale: WholesaleLanguage, params: Params & { dealerSlug: string }) =>
                getHomeDealerUrl(locale, params['dealerSlug']),
        },
    };
}
