import { HomeLabels } from 'libs/shared-lib/interfaces/partner-web/home';
import {
    m030c195CtaImageTeasers,
    m060c080QuickSearchLabels,
    searchCriteriaLabels,
    vehicleCardLabels,
} from '../../shared/shared.it';

export const HOME_LABELS_IT: HomeLabels = {
    phoneCTA: 'Saremo lieti di consigliarvi:',
    topNewsHeadline: 'News&Events',
    topNewsShowMore: 'Mostrare tutti',
    latestPromotionHeadline: 'Promozioni',
    latestPromotionShowMore: 'Tutte le promozioni',
    ratingLabel: 'recensioni su Google.com',
    ratingLabelExtendedPre: 'con',
    ratingLabelExtendedPost: 'recensioni su reputation.com',
    aboutUsLinkLabel: 'Per saperne di più su di noi',
    quickLinksHeadline: 'Cosa possiamo fare per te?',
    quickLinks: {
        service: 'Fissare un appuntamento',
        locate: 'Come trovarci',
        locateWithSubDealers: 'Le nostre sedi',
        contact: 'Le tue persone di contatto',
        vehicleMarket: 'Trovare un’auto',
    },
    vehicleCardLabels: vehicleCardLabels,
    topServices: {
        headline: 'Fissare ora un appuntamento in officina',
        listButtonLabel: 'Tutti i servizi e le informazioni',
        otherServices: 'Altro',
    },
    m070c040DealerContact: {
        headline: 'Orari d’apertura',
        rating: 'da Google',
        desktop: {
            cta: 'Per saperne di più su di noi',
            route: 'Calcolare l’itinerario',
        },
        mobile: {
            phone: 'Chiamare',
            route: 'Percorso',
            email: 'Contatto',
            chat: 'Chat',
        },
        toLocation: 'Per saperne di più su di noi',
    },
    m060c060VehicleCarousel: {
        headline: 'Le migliori offerte',
        buttonLabel: 'Mostrare tutte le offerte',
        copy: 'Scoprite i nostri veicoli Opel in stock immediatamente disponibili. Saremo lieti di offrirvi una consulenza e una prova su strada.',
    },
    m030c195CtaImageTeasers,
    searchCriteriaLabels: searchCriteriaLabels,
    m060c080QuickSearchLabels: m060c080QuickSearchLabels,
    mvpServiceQuickSearchLabels: {
        headline: 'Fissare ora un appuntamento in officina',
        call: 'Chiamare',
        sendMail: 'Inviare un’e-mail',
    },
};
