import { inject, Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { getRouterSelectors, ROUTER_NAVIGATED, RouterReducerState } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { dealerActions } from 'libs/ng-common-lib/src/+state/dealer.actions';
import { dealerFeature } from 'libs/ng-common-lib/src/+state/dealer.reducer';
import { wholesaleFeature } from 'libs/ng-common-lib/src/+state/wholesale.reducer';
import { WCars } from 'libs/shared-lib/interfaces/vehicle-market';
import { getHomeDealerUrl } from 'libs/shared-lib/slugs/home-pages.slug';
import { getVehicleMarketListUrl } from 'libs/shared-lib/slugs/vehicle-market.slugs';
import { exhaustMap, map } from 'rxjs/operators';
import { FilterTagsService } from '../services/filter-tags.service';
import { UrlService } from '../services/url.service';
import { filterActions } from './filter.actions';
import { filterFeature } from './filter.reducer';
import { vehicleActions } from './vehicle.actions';
import { vehicleFeature } from './vehicle.reducer';

@Injectable()
export class FilterEffects {
    protected readonly actions$ = inject(Actions);
    protected readonly routerStore = inject(Store<RouterReducerState>);
    protected readonly store = inject(Store);
    protected readonly urlService = inject(UrlService);
    protected readonly filterTagsService = inject(FilterTagsService);

    protected validParams = {
        'cond[]': [],
        'make[]': [],
        'trans[]': [],
        'body[]': [],
        'fuel[]': [],
        'drive[]': [],
        'bodycol[]': [],
        'vehtyp[]': [],
        'equip[]': [],
        kmfrom: 0,
        kmto: 0,
        'year[]': [],
        yearfrom: 0,
        yearto: 0,
        seatsfrom: 0,
        seatsto: 0,
        pricefrom: 0,
        priceto: 0,
        ratefrom: 0,
        rateto: 0,
        'model[]': [],
        lat: 0,
        lon: 0,
        radius: 0,
        'efficiency[]': [],
        'qualitytags[]': [],
        page: 0,
        limit: 0,
        sort: '',
        'buyingtags[]': [],
    };

    setFiltersOnVehicleListPage$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ROUTER_NAVIGATED),
            concatLatestFrom(() => [
                this.routerStore.select(getRouterSelectors().selectQueryParams),
                this.routerStore.select(getRouterSelectors().selectUrl),
                this.store.select(wholesaleFeature.selectLanguage),
                this.store.select(dealerFeature.selectActiveDealer),
            ]),
            map(([, params, currentUrl, lang, activeDealer]) => {
                if (
                    currentUrl.split('?')[0] !==
                    getVehicleMarketListUrl(lang, activeDealer ? activeDealer.slug : undefined)
                ) {
                    return filterActions.notOnVehicleMarketPage();
                }
                this.urlService.rewriteZeroValueParams();
                const vehicleMarketParams = this.getVehicleMarketFilters(params);
                return filterActions.setVehicleMarketFilterSuccess({ filter: vehicleMarketParams });
            }),
        );
    });

    setFiltersFromUrlWithDealer$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(dealerActions.loadDealerSuccess),
            concatLatestFrom(() => [
                this.routerStore.select(getRouterSelectors().selectQueryParams),
                this.routerStore.select(getRouterSelectors().selectUrl),
                this.store.select(wholesaleFeature.selectLanguage),
                this.store.select(dealerFeature.selectActiveDealer),
            ]),
            map(([, params, currentUrl, lang, activeDealer]) => {
                if (!activeDealer) {
                    return filterActions.noDealerDataAvailable();
                }
                if (currentUrl.split('?')[0] === getHomeDealerUrl(lang, activeDealer ? activeDealer.slug : '')) {
                    const vehicleMarketParams = this.getVehicleMarketFilters(params);
                    return filterActions.setVehicleMarketFilterWithoutLoadingVehiclesSuccess({
                        filter: vehicleMarketParams,
                    });
                }
                if (currentUrl.split('?')[0] !== getVehicleMarketListUrl(lang, activeDealer.slug)) {
                    return filterActions.notOnVehicleMarketPage();
                }
                const vehicleMarketParams = this.getVehicleMarketFilters(params);
                return filterActions.setVehicleMarketFilterSuccess({ filter: vehicleMarketParams });
            }),
        );
    });

    setFilter$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(filterActions.setVehicleMarketFilter),
            concatLatestFrom(() => [this.store.select(filterFeature.selectVehicleMarketFilter)]),
            map(([action, currentFilters]) => {
                if (Object.entries(action.filter).length === 0 && action.merge === false) {
                    return filterActions.setVehicleMarketFilterSuccess({ filter: {} });
                }
                const newParams = this.urlService.getMergedUrlParams(action.filter, currentFilters);
                this.urlService.setUrlParams(newParams);
                const vehicleMarketParams = this.getVehicleMarketFilters(newParams);
                return filterActions.setVehicleMarketFilterSuccess({ filter: vehicleMarketParams });
            }),
        );
    });

    setFilterWithoutLoadingVehicles$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(filterActions.setVehicleMarketFilterWithoutLoadingVehicles),
            map((action) => {
                this.urlService.setUrlParams(action.filter);
                const vehicleMarketParams = this.getVehicleMarketFilters(action.filter);
                return filterActions.setVehicleMarketFilterWithoutLoadingVehiclesSuccess({
                    filter: vehicleMarketParams,
                });
            }),
        );
    });

    loadVehiclesOnSetFilterSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(filterActions.setVehicleMarketFilterSuccess),
            concatLatestFrom(() => [this.store.select(vehicleFeature.selectLimit)]),
            exhaustMap(([, limit]) => [vehicleActions.loadVehicles({ limit })]),
        );
    });

    removeFilterViaFilterTag$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(filterActions.removeVehicleMarketFilterViaFilterTag),
            concatLatestFrom(() => [this.store.select(filterFeature.selectVehicleMarketFilter)]),
            map(([action, activeFilter]) => {
                const filterToSet = this.filterTagsService.removeVehicleMarketFilterViaFilterTag(
                    activeFilter,
                    action.tag,
                );
                this.urlService.setUrlParams(filterToSet, false);
                return filterActions.setVehicleMarketFilterSuccess({ filter: filterToSet });
            }),
        );
    });

    getVehicleMarketFilters(params: Params): Params {
        const value: WCars.Request = {};
        for (let key in params) {
            const paramValue = params[key];
            if (key.includes('[0]')) {
                key = key.replace('[0]', '[]');
            }
            if (key in this.validParams) {
                value[key as keyof WCars.Request] = paramValue;
            }
        }
        return value;
    }
}
