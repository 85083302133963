import { inject, Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, exhaustMap, map, of, switchMap } from 'rxjs';
import { DealerService } from '../services/dealer.service';
import { MatelsoService } from '../services/matelso.service';
import { WNestApiService } from '../services/wholesale-nest-api';
import { dealerActions } from './dealer.actions';
import { dealerFeature } from './dealer.reducer';
import { wholesaleFeature } from './wholesale.reducer';

@Injectable()
export class DealerEffects {
    protected readonly actions$ = inject(Actions);
    protected readonly store = inject(Store);
    protected readonly dealerService = inject(DealerService);
    protected readonly apiService = inject(WNestApiService);
    protected readonly matelsoService = inject(MatelsoService, { optional: true });

    setDealerBySlug$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(dealerActions.setDealerBySlug),
            concatLatestFrom(() => [this.store.select(dealerFeature.selectActiveDealer)]),
            exhaustMap(([action, dealer]) => {
                if (!dealer || dealer.slug !== action.dealerSlug) {
                    return [dealerActions.loadDealer({ dealerSlug: action.dealerSlug })];
                } else {
                    return [dealerActions.loadDealerSuccess({ dealer })];
                }
            }),
        );
    });

    setSubDealerBySlug$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(dealerActions.setSubDealerBySlug),
            concatLatestFrom(() => [
                this.store.select(dealerFeature.selectActiveDealer),
                this.store.select(wholesaleFeature.selectLanguage),
            ]),
            switchMap(([action, dealer, locale]) => {
                if (!dealer) {
                    return [];
                }
                return this.apiService.getSubDealerDetailBySlug(dealer.dealerId, action.subDealerSlug, locale).pipe(
                    switchMap((subDealerDetail) => {
                        if (subDealerDetail) {
                            return [dealerActions.setSubDealerBySlugSuccess({ subDealerDetail })];
                        } else {
                            return [dealerActions.setSubDealerBySlugFailure({ error: 'TODO 🔥 SOME ERROR' })];
                        }
                    }),
                );
            }),
        );
    });

    loadSubDealerDetails$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(dealerActions.loadSubDealerDetails),
            concatLatestFrom(() => [
                this.store.select(dealerFeature.selectActiveDealer),
                this.store.select(wholesaleFeature.selectLanguage),
            ]),
            switchMap(([, dealer, locale]) => {
                if (!dealer) {
                    return [];
                }

                return this.apiService.getSubDealerDetails(dealer.dealerId, locale).pipe(
                    switchMap((subDealerDetails) => {
                        if (subDealerDetails) {
                            return [dealerActions.loadSubDealerDetailsSuccess({ subDealerDetails })];
                        } else {
                            return [dealerActions.loadSubDealerDetailsFailure({ error: 'TODO 🔥 SOME ERROR' })];
                        }
                    }),
                );
            }),
        );
    });

    loadDealerFromApi$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(dealerActions.loadDealer),
            concatLatestFrom(() => [this.store.select(wholesaleFeature.selectLanguage)]),
            switchMap(([action, locale]) => {
                return this.dealerService.loadDealerBySlug(action.dealerSlug, locale).pipe(
                    exhaustMap((dealer) => {
                        if (dealer) {
                            return [dealerActions.loadDealerSuccess({ dealer })];
                        } else {
                            return [dealerActions.loadDealerFailure({ error: 'TODO 🔥 SOME ERROR' })];
                        }
                    }),
                );
            }),
            catchError(() => of(dealerActions.loadDealerFailure({ error: 'Could not fetch Dealer Data' }))),
        );
    });

    loadDealerListFromApi$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(dealerActions.loadDealerList),
            concatLatestFrom(() => [this.store.select(wholesaleFeature.selectLanguage)]),
            switchMap(([action, locale]) => {
                return this.apiService.getDealerList({ filter: action.filter }, locale).pipe(
                    map((response) => {
                        if (response.length > 0) {
                            return dealerActions.loadDealerListSuccess({ dealerList: response });
                        } else {
                            return dealerActions.loadDealerListFailure({ error: 'TODO 🔥 SOME ERROR' });
                        }
                    }),
                );
            }),
        );
    });

    loadMinimalDealerListFromApi$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(dealerActions.loadMinimalDealerList),
            concatLatestFrom(() => [this.store.select(wholesaleFeature.selectLanguage)]),
            switchMap(([action, locale]) => {
                return this.apiService.getMinimalDealerList({ filter: action.filter }, locale).pipe(
                    map((response) => {
                        if (!response.error && response.dealers.length > 0) {
                            return dealerActions.loadMinimalDealerListSuccess({ dealerList: response.dealers });
                        } else {
                            return dealerActions.loadMinimalDealerListFailure({ error: 'TODO 🔥 SOME ERROR' });
                        }
                    }),
                );
            }),
        );
    });

    setDealerMatelsoPhoneNumber$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(dealerActions.setDealerMatelsoPhoneNumber),
            concatLatestFrom(() => [this.store.select(dealerFeature.selectActiveDealer)]),
            switchMap(([, dealer]) => {
                if (!this.matelsoService || !dealer) {
                    return [];
                }
                return this.matelsoService?.getPhoneNumber$(dealer.phone).pipe(
                    map((newPhoneNumber) => {
                        return dealerActions.setDealerMatelsoPhoneNumberSuccess({
                            phoneNumber: newPhoneNumber,
                        });
                    }),
                );
            }),
        );
    });
}
