import { isPlatformBrowser } from '@angular/common';
import { ApplicationRef, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { isZonePresent } from './is-zone-present';

/**
 * A small service encapsulating the hacks needed for routing (and bootstrapping) in zone-less applications
 */
@Injectable({
    providedIn: 'root',
})
export class ZonelessRouting {
    constructor(
        protected readonly router: Router,
        protected readonly appRef: ApplicationRef,
        @Inject(PLATFORM_ID) protected readonly platformId: string,
    ) {}

    init() {
        if (isPlatformBrowser(this.platformId) && !isZonePresent()) {
            this.router.events.subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    this.appRef.tick();
                }
            });
        }
    }
}
