import { ServiceLabels } from 'libs/shared-lib/interfaces/partner-web/service';
import { m030c195CtaImageTeasers, m070c040DealerContact } from '../../shared/shared.fr';

export const SERVICE_LABELS_FR: ServiceLabels = {
    list: {
        contact: {
            routeCta: 'Calculer l’itinéraire',
            labels: {
                route: 'Calculer l’itinéraire',
                phone: 'Appeler',
                email: 'Contact',
            },
        },
        promotionHeadline: 'Actions',
        promotionShowMore: 'Toutes les actions',
        employeesHeadline: 'Tes interlocuteurs de service',
        employeesShowMore: 'Afficher tous les interlocuteurs',
        topTeaserAllLabel: 'Autre',
    },
    detail: {
        route: 'Calculer l’itinéraire',
        allContacts: 'Afficher tous les interlocuteursn',
        phone: 'Appeler',
        email: 'E-Mail',
        contactPersonsHeadline: 'Tes interlocuteurs de service',
        m070c040DealerContact,
        m030c195CtaImageTeasers,
        at: 'Chez',
        in: 'à',
        and: 'et',
        region: 'dans la région',
        andLocation: 'ainsi que sur notre site à',
        andLocations: 'ainsi que sur nos sites à',
        serviceFormCta: 'Prendre un rendez-vous de service',
    },
};
