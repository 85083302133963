import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
// import { REQUEST, RESPONSE } from '@nguniversal/express-engine/tokens';
import { Request, Response } from 'express';
import { REQUEST, RESPONSE } from 'libs/ng-common-lib/src/provider/express.token';

@Injectable({
    providedIn: 'root',
})
export class CookieService {
    constructor(
        @Inject(DOCUMENT) protected readonly document: Document,
        @Inject(PLATFORM_ID) protected readonly platformId: string,
        @Optional() @Inject(REQUEST) protected readonly request: Request,
        @Optional() @Inject(RESPONSE) protected readonly response: Response,
    ) {}

    get(name: string): string | undefined {
        return this.parseCookies()[name];
    }

    has(name: string): boolean {
        return isPlatformBrowser(this.platformId) && !!this.get(name);
    }

    getAll(): Record<string, string> {
        return this.parseCookies();
    }

    set(name: string, value: string, expireDays: number, path = '/'): void {
        if (isPlatformBrowser(this.platformId)) {
            const expires = new Date();
            expires.setTime(expires.getTime() + expireDays * 24 * 60 * 60 * 1000);
            this.document.cookie = `${name}=${value}; expires=${expires.toUTCString()}; path=${path}`;
            return;
        }
        this.response?.cookie(name, value, {
            maxAge: expireDays * 24 * 60 * 60 * 1000,
        });
    }

    delete(name: string): void {
        this.set(name, '', 0);
    }

    protected parseCookies(): Record<string, string> {
        const cookies: Record<string, string> = {};
        let cookieString = '';
        if (isPlatformBrowser(this.platformId)) {
            cookieString = this.document.cookie;
        } else {
            cookieString = this.request?.header('cookie') || '';
        }

        if (!cookieString) {
            return cookies;
        }

        return cookieString.split(';').reduce((cookies, cookie) => {
            const [key, ...value] = cookie.split('=');
            cookies[decodeURIComponent(key.trim())] = decodeURIComponent(value.join('=').trim());
            return cookies;
        }, cookies);
    }
}
