import { FooterLabels } from 'libs/shared-lib/interfaces/partner-web/footer';

export const FOOTER_LABELS_FR: FooterLabels = {
    quickLinks: {
        service: 'Prendre un rendez-vous de service',
        testDrive: 'Faire un essai sur route',
        call: 'Appeler',
        mail: 'E-Mail',
    },
    locations: 'Nos sites',
    copyrightText: `L'avenir appartient à tous © Opel ${new Date().getFullYear()}`,
    privacyLinks: [
        {
            name: 'Mentions légales',
            url: 'https://www.opel.ch/fr/outils/opel-copyrights.html',
        },
        {
            name: 'Politique des cookies',
            url: 'https://www.opel.ch/fr/outils/politique-des-cookies.html',
        },
        {
            name: 'Politique de confidentialité',
            url: 'https://www.opel.ch/fr/outils/politique-de-confidentialite.html',
        },
        {
            name: 'WLTP | nouvelles données de consommation',
            url: 'https://www.opel.ch/fr/outils/cycle-conduite.html',
        },
        {
            name: 'Opel mondialement',
            url: 'http://www.opel.com/',
        },
    ],
    corporateLinks: [
        {
            name: 'myOpel',
            url: 'https://my.opel.ch/de/',
        },
        {
            name: 'e-Mobilité',
            url: 'https://www.opel.ch/fr/simply-electric/overview.html',
        },
        {
            name: 'Opel FlexCare',
            url: 'https://www.opel.ch/fr/service-zubehoer/garantie/flexcare.html',
        },
    ],
    legalTextHTML:
        'Les caractéristiques décrites et illustrées peuvent se rapporter à des équipements optionnels qui ne sont pas compris dans la version de série. Les informations contenues dans ce document sont exactes au moment de la publication. Sauf erreur et omission.Nous nous réservons le droit d’apporter des modifications à la conception et aux équipements. Tous les prix sont sans engagement et peuvent être modifiés à tout moment. Les couleurs montrées peuvent varier par rapport aux couleurs réelles. L’équipement en option illustré est disponible contre un supplément de prix. La disponibilité peut varier selon les régions. Les caractéristiques techniques et l’équipement fournis sur nos véhicules peuvent différer, n’être disponibles que dans certains pays ou contre supplément uniquement. Pour obtenir des informations détaillées au sujet des équipements fournis sur nos véhicules, veuillez contacter ton partenaire Opel local. <br> <br> Emissions de CO2: 122 g/km, moyenne de toutes les véhicules neuves vendues. Valeur cible de CO2: 118 g/km (valeur-cible provisoire selon le nouveau cycle d’essai WLTP). ',
    wltpLink: {
        name: 'Clique ici pour plus d’informations sur la procédure d’essai WLTP.',
        url: 'https://www.opel.ch/fr/outils/cycle-conduite.html',
    },
};
