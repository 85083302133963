import { AboutUsLabels } from 'libs/shared-lib/interfaces/partner-web/about-us';
import { m070c040DealerContact } from '../../shared/shared.fr';

export const ABOUT_US_LABELS_FR: AboutUsLabels = {
    aboutUsHeadline: 'À propos de nous',
    brandTextHeadline: 'This is Opel',
    brandTextCopy:
        'De nouvelles technologies innovantes, des inventions révolutionnaires, nous vivons une époque passionnante. Mais ce n’est que lorsque de nouvelles inventions sont accessibles à tous qu’elles peuvent également changer et améliorer le monde. Rendre les innovations accessibles au plus grand nombre de personnes est profondément ancré dans l’ADN de la marque Opel. Audacieux, innovant et doté d’un design épuré: THIS IS OPEL.',
    moreLinkId: 'missing-translation-ueber-uns',
    moreLabel: 'En savoir plus',
    phoneLabel: 'Nous te conseillons avec plaisir :',
    ourTeamHeadline: 'Notre équipe',
    dealerContactLabels: m070c040DealerContact,
    readMore: 'Lire la suite',
};
