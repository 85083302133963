import { PROMOTION_NOT_AVAILABLE_LABELS_IT } from 'apps/opel/ch/partner-web/src/app/i18n/partner-web/promotion-not-available/promotion-not-available.it';
import { PartnerWebLabels } from 'libs/shared-lib/interfaces';
import { ABOUT_US_LABELS_IT } from './about-us/about-us.it';
import { CHECKOUTS_LABELS_IT } from './checkouts/checkouts.it';
import { FOOTER_LABELS_IT } from './footer/footer.it';
import { HOME_LABELS_IT } from './home/home.it';
import { LOCATIONS_LABELS_IT } from './locations/locations.it';
import { NAV_LABELS_IT } from './nav/nav.it';
import { NEWS_LABELS_IT } from './news/news.it';
import { NOT_FOUND_LABELS_IT } from './not-found/not-found.it';
import { PROMOTION_LABELS_IT } from './promotions/promotions.it';
import { SERVICE_LABELS_IT } from './service/service.it';

export const PARTNER_WEB_LABELS_IT: PartnerWebLabels.Labels = {
    home: HOME_LABELS_IT,
    checkouts: CHECKOUTS_LABELS_IT,
    locations: LOCATIONS_LABELS_IT,
    news: NEWS_LABELS_IT,
    promotions: PROMOTION_LABELS_IT,
    aboutUs: ABOUT_US_LABELS_IT,
    service: SERVICE_LABELS_IT,
    promotionNotAvailablePage: PROMOTION_NOT_AVAILABLE_LABELS_IT,
    notFoundPage: NOT_FOUND_LABELS_IT,
    footer: FOOTER_LABELS_IT,
    nav: NAV_LABELS_IT,
};
