import { SeoPartnerWeb } from 'libs/shared-lib/interfaces/seo/seo-partner-web';
import { ABOUT_US_SEO_DE } from './about-us/about-us.de.seo';
import { CHECKOUTS_SEO_DE } from './checkouts/checkouts.de.seo';
import { HOME_SEO_DE } from './home/home.de.seo';
import { LOCATIONS_SEO_DE } from './locations/locations.de.seo';
import { NEWS_SEO_DE } from './news/news.de.seo';
import { NOT_FOUND_SEO_DE } from './not-found/not-found.de.seo';
import { PROMOTION_NOT_AVAILABLE_SEO_DE } from './promotion-not-available/promotion-not-available.de.seo';
import { PROMOTIONS_SEO_DE } from './promotions/promotions.de.seo';
import { SERVICE_SEO_DE } from './service/service.de.seo';
import { SUB_DEALER_SEO_DE } from './sub-dealer/sub-dealer.de.seo';
import { VEHICLE_NOT_AVAILABLE_SEO_DE } from './vehicle-not-available/vehicle-not-available.de.seo';

export const PARTNER_WEB_SEO_DE: SeoPartnerWeb = {
    home: HOME_SEO_DE,
    checkouts: CHECKOUTS_SEO_DE,
    news: NEWS_SEO_DE,
    promotions: PROMOTIONS_SEO_DE,
    aboutUs: ABOUT_US_SEO_DE,
    service: SERVICE_SEO_DE,
    locations: LOCATIONS_SEO_DE,
    subDealer: SUB_DEALER_SEO_DE,
    notFound: NOT_FOUND_SEO_DE,
    vehicleNotAvailable: VEHICLE_NOT_AVAILABLE_SEO_DE,
    promotionNotAvailable: PROMOTION_NOT_AVAILABLE_SEO_DE,
};
