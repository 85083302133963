import { Injectable, inject } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { WNestApiService } from 'libs/ng-common-lib/src/services/wholesale-nest-api';
import { PaymentFormType, PaymentStatus } from 'libs/shared-lib/interfaces/w-nest';
import { WholesaleLanguage } from 'libs/shared-lib/language/language';
import { Observable, catchError, map, switchMap, throwError } from 'rxjs';
import { PaymentCheckoutData, checkoutsActions } from './checkouts.actions';
import { wholesaleFeature } from './wholesale.reducer';

@Injectable()
export class CheckoutsEffects {
    protected readonly actions$ = inject(Actions);
    protected readonly api = inject(WNestApiService);
    protected readonly store = inject(Store);

    checkouts$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(checkoutsActions.testDriveSubmit),
            switchMap((action) => {
                return this.api
                    .submitTestDriveFrom(action.dashEcoData, action.locale)
                    .pipe(
                        switchMap(async (response) =>
                            response.error === true
                                ? checkoutsActions.testDriveSubmitFail({ fail: true })
                                : checkoutsActions.testDriveSubmitFail({ fail: false }),
                        ),
                    );
            }),
        );
    });

    appointment$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(checkoutsActions.appointmentSubmit),
            switchMap((action) => {
                return this.api
                    .submitAppointmentFrom(action.dashEcoData, action.locale)
                    .pipe(
                        switchMap(async (response) =>
                            response.error === true
                                ? checkoutsActions.appointmentSubmitFail()
                                : checkoutsActions.appointmentSubmitSuccess(),
                        ),
                    );
            }),
        );
    });

    tradeIn$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(checkoutsActions.tradeInSubmit),
            switchMap((action) => {
                return this.api
                    .submitTradeInForm(action.dashEcoData, action.locale)
                    .pipe(
                        switchMap(async (response) =>
                            response.error === true
                                ? checkoutsActions.tradeInSubmitFail({ fail: true })
                                : checkoutsActions.tradeInSubmitFail({ fail: false }),
                        ),
                    );
            }),
        );
    });

    loadTradeInMakesAndModelsFromApi$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(checkoutsActions.loadTradeInMakesAndModels),
            concatLatestFrom(() => [this.store.select(wholesaleFeature.selectLanguage)]),
            switchMap(([, locale]) => {
                return this.api.getMakesAndModels({ filter: 'tradein' }, locale).pipe(
                    map((response) => {
                        if (!response.error && response.makes.length > 0) {
                            return checkoutsActions.loadMakesAndModelsSuccess({ makesAndModels: response.makes });
                        } else {
                            return checkoutsActions.loadMakesAndModelsFailure({ error: 'TODO 🔥 SOME ERROR' });
                        }
                    }),
                );
            }),
        );
    });

    initPaymentStep$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(checkoutsActions.initPaymentStep),
            switchMap((action) => {
                const token = window.sessionStorage.getItem('paymentToken');
                if (token) {
                    return [checkoutsActions.checkPaymentStatus({ token })];
                }
                action.saveState();
                return [checkoutsActions.initPaymentSession({ data: action.data })];
            }),
        );
    });

    initPaymentSession$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(checkoutsActions.initPaymentSession),
            concatLatestFrom(() => [this.store.select(wholesaleFeature.selectLanguage)]),
            switchMap(([action, locale]) => {
                return this.api.createPaymentSession(action.data, locale).pipe(
                    switchMap((response) => {
                        if (response) {
                            window.sessionStorage.setItem('paymentToken', response.token);
                            if (response.orderId) {
                                window.sessionStorage.setItem('orderId', response.orderId);
                            }
                            return [checkoutsActions.redirectToPayment({ url: response.redirectUrl })];
                        } else {
                            return [checkoutsActions.initPaymentSessionFailure({ error: 'No response' })];
                        }
                    }),
                    catchError((error) => {
                        return [checkoutsActions.initPaymentSessionFailure({ error: error.message })];
                    }),
                );
            }),
        );
    });

    checkPaymentStatus$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(checkoutsActions.checkPaymentStatus),
            concatLatestFrom(() => [this.store.select(wholesaleFeature.selectLanguage)]),
            switchMap(([action, locale]) => {
                window.sessionStorage.removeItem('paymentToken');
                const { token } = action;
                return this.api.getPaymentStatus({ token }, locale).pipe(
                    switchMap((response) => {
                        if (response.paymentStatus === PaymentStatus.AUTHORIZED) {
                            return [checkoutsActions.checkPaymentStatusSuccess({ token })];
                        }
                        return [checkoutsActions.checkPaymentStatusFailure({ error: response.paymentStatus })];
                    }),
                    catchError((error) => {
                        return [checkoutsActions.checkPaymentStatusFailure({ error: error.message })];
                    }),
                );
            }),
        );
    });

    redirectToPayment$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(checkoutsActions.redirectToPayment),
                map((action) => {
                    window.location.href = action.url;
                }),
            );
        },
        { dispatch: false },
    );

    submitCheckoutForm$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(checkoutsActions.submitPaymentCheckoutForm),
            concatLatestFrom(() => [this.store.select(wholesaleFeature.selectLanguage)]),
            switchMap(([action, locale]) => {
                return this.submitPaymentFormData(action, locale).pipe(
                    switchMap((res) => {
                        if (res.error) {
                            return throwError(() => new Error('unknown error'));
                        }
                        action.callBack();
                        return [checkoutsActions.submitPaymentCheckoutFormSuccess()];
                    }),
                    catchError((error) => {
                        action.callBack(error.message);
                        return [checkoutsActions.submitPaymentCheckoutFormFailure({ error: error.message })];
                    }),
                );
            }),
        );
    });

    protected submitPaymentFormData(
        data: PaymentCheckoutData,
        locale: WholesaleLanguage,
    ): Observable<{ error: boolean }> {
        switch (data.paymentFormType) {
            case PaymentFormType.onlineSales:
                return this.api
                    .postOnlineSalesCheckoutSubmit(data.data, locale)
                    .pipe(map((response) => ({ error: !response.status })));
            case PaymentFormType.onlineSalesForm:
                return this.api.submitOnlineSalesForm(data.data, locale);
            case PaymentFormType.reservationPaymentForm:
                return this.api.submitReservationPaymentForm(data.data, locale).pipe(
                    map((response) => {
                        return { error: response.error };
                    }),
                );
            case PaymentFormType.unspecificReservationPaymentForm:
                return this.api.submitUnspecificReservationPaymentForm(data.data, locale).pipe(
                    map((response) => {
                        return { error: response.error };
                    }),
                );
        }
        return throwError(() => new Error('Not implemented'));
    }
}
