import { provideHttpClient, withFetch, withJsonpSupport } from '@angular/common/http';
import { EnvironmentProviders, importProvidersFrom } from '@angular/core';
import { ServiceWorkerModule } from '@angular/service-worker';

export function provideEnvironmentSpecificProviders(): EnvironmentProviders[] {
    return [
        importProvidersFrom(
            ServiceWorkerModule.register('ngsw-worker.js', {
                enabled: true,
                registrationStrategy: 'registerWhenStable:30000',
            }),
        ),
        provideHttpClient(withFetch(), withJsonpSupport()),
    ];
}
