import { PhoneNumberPrefix } from 'libs/shared-lib/interfaces';
import { M060C010VehicleCardLabels } from 'libs/shared-lib/interfaces/ui/m060-c010-vehicle-card.interface';

export const errorMessage = 'Si è verificato un errore. Si prega di riprovare.';
export const errorRequired = 'Questo campo è obbligatorio';
export const errorInvalid = 'Inserimento non valido';

export const gender = {
    female: 'Signora',
    male: 'Signor',
};

export const footerLinks = {
    LEGAL: 'https://www.opel.ch/it/tools/copyright-opel.html',
    COOKIES: 'https://www.opel.ch/it/tools/cookie-policy.html',
    DATA_POLICY: 'https://www.opel.ch/it/tools/politica-privacy.html',
};

export const m060C050vehicleOverviewLabels = {
    consumption: 'Consumo combinato',
    consumptionAfter: 'l/100km',
    emission: 'Emissioni CO2 combinate',
    emissionAfter: 'g/km',
    efficiency: 'Efficienza energetica',
    mileageFromOdometer: 'string',
    dateVehicleFirstRegistered: 'string',
    vehicleTransmission: 'string',
    additionalProperty: 'string',
    fuelType: 'string',
    driveWheelConfiguration: 'string',
    summary: 'Riepilogo',
    monthly: ' /mt.',
    noVehicle: {
        headline: '',
        subheadline: '',
    },
};

export const phoneNumberPrefixes: PhoneNumberPrefix[] = [
    {
        prefix: '+41',
        label: 'Svizzera',
        iconPath: 'assets/icons/flags/Switzerland.svg',
    },
    {
        prefix: '+49',
        label: 'Germania',
        iconPath: 'assets/icons/flags/Germany.svg',
    },
];

export const m070c040DealerContact = {
    headline: 'Orari d’apertura',
    rating: 'da Google',
    desktop: {
        cta: 'Per saperne di più su di noi',
        route: 'Calcolare l’itinerario',
    },
    mobile: {
        phone: 'Chiamare',
        route: 'Percorso',
        email: 'Contatto',
        chat: 'Chat',
    },
    toLocation: 'Alla sede',
};

export const m030c195CtaImageTeasers = {
    headline: 'Le nostre sedi competenti',
    listButtonLabel: 'Torna alla panoramica',
};

export const occasionsProgram = 'Veicoli recenti';

export const occasionsProgramOverlay = {
    headline: 'Veicoli recenti – Così l’usato diventa come nuovo',
    copy: '<p class="text-left mb-10">Scoprite i nostri veicoli usati giovani:</p><ul class="text-left disclistNarrow"><li>Auto che non hanno più di 12 mesi</li><li>Autovetture che non hanno percorso più di 5000 km</li><li>immatricolazioni giornaliere</li><li>Auto dimostrative</li><li> E in generale auto usate giovani (non più vecchie di 12 mesi e non superiori a 5000 KM)</li></ul><p class="text-left">Acquista subito un veicolo come nuovo a un interessante tasso di leasing dell\'1,99%. </p><p class="text-left">Vai dal tuo concessionario e approfitta di questa opportunità!</p>',
    image: {
        image: 'https://media.graphassets.com/5r1Sh2iSwO9Ys2uBQww4',
        width: 300,
        height: 225,
    },
};

export const actionTags = {
    occasionsProgram: {
        text: occasionsProgram,
        overlay: occasionsProgramOverlay,
    },
};

export const vehicleCardLabels: M060C010VehicleCardLabels = {
    currency: 'CHF',
    leasing: 'Leasing da',
    monthly: '/mese',
    actionTags: actionTags,
    newPrice: 'Prezzo nuovo',
    firstPriceText: '',
    lastPriceText: 'Leasing da',
    consumption: 'Consumo combinato',
    emission: 'Emissioni di CO2 combinate',
};

export const searchCriteriaLabels = {
    headline: 'Filtrare i risultati',
    button: 'Mostrare i %s veicoli',
    reset: 'Ripristinare filtri',
    location: {
        currentLocation: 'Utilizzare  la posizione attuale',
        subheading: 'Ricercare per indirizzo',
    },
    selected: 'selezionato',
    allSelected: 'Tutti',
    to: 'Fino a',
    monthly: '/mese',
    ratefrom: 'Rata da',
    rateto: 'Rata a',
    pricefrom: 'Prezzo a partire da',
    priceto: 'Prezzo fino a',
    vehicle_tags: {
        occasionsProgram: occasionsProgramOverlay,
    },
};

export const m060c080QuickSearchLabels = {
    headline: 'Trova l’auto dei tuoi sogni',
    model: 'Modello',
    price: 'Prezzo',
    ctaLabel: 'Mostrare i %s veicoli',
    buttonAdvancedSearchLabel: 'Ricerca avanzata',
    selected: 'selezionato',
    allSelected: 'Tutti',
    ratefrom: 'Rata da',
    rateto: 'Rata a',
    pricefrom: 'Prezzo a partire da',
    priceto: 'Prezzo fino a',
    to: 'Fino a',
    monthly: '/mese',
};
