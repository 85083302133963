import { HomeLabels } from 'libs/shared-lib/interfaces/partner-web/home';
import {
    m030c195CtaImageTeasers,
    m060c080QuickSearchLabels,
    searchCriteriaLabels,
    vehicleCardLabels,
} from '../../shared/shared.fr';

export const HOME_LABELS_FR: HomeLabels = {
    phoneCTA: 'Nous te conseillons avec plaisir :',
    topNewsHeadline: 'News&Events',
    topNewsShowMore: 'Tout afficher',
    latestPromotionHeadline: 'Actions',
    latestPromotionShowMore: 'Toutes les actions',
    ratingLabel: 'avis sur Google.com',
    ratingLabelExtendedPre: 'de',
    ratingLabelExtendedPost: 'avis surreputation.com',
    aboutUsLinkLabel: 'En savoir plus sur nous',
    quickLinksHeadline: 'Que pouvons-nous faire pour toi ?',
    quickLinks: {
        service: 'Prendre un rendez-vous',
        locate: 'Comment nous trouver',
        locateWithSubDealers: 'Nos sites',
        contact: 'Tes interlocuteurs',
        vehicleMarket: 'Trouver un véhicule',
    },
    vehicleCardLabels: vehicleCardLabels,
    topServices: {
        headline: 'Prendre un rendez-vous à l’atelier maintenant',
        listButtonLabel: 'Tous les services et informations',
        otherServices: 'Autre',
    },
    m070c040DealerContact: {
        headline: 'Heures d’ouverture',
        rating: 'chez Google',
        desktop: {
            cta: 'En savoir plus sur nous',
            route: 'Calculer l’itinéraire',
        },
        mobile: {
            phone: 'Appeler',
            route: 'Itinéraire',
            email: 'Contact',
            chat: 'Live-Chat',
        },
        toLocation: 'En savoir plus sur nous',
    },
    m060c060VehicleCarousel: {
        headline: 'Top offres',
        buttonLabel: 'Afficher toutes les offres',
        copy: 'Découvre nos véhicules Opel en stock disponibles immédiatement. Nous nous tenons volontiers à ta disposition pour un entretien-conseil et un essai sur route.',
    },
    m030c195CtaImageTeasers,
    searchCriteriaLabels: searchCriteriaLabels,
    m060c080QuickSearchLabels: m060c080QuickSearchLabels,
    mvpServiceQuickSearchLabels: {
        headline: 'Prendre un rendez-vous à l’atelier maintenant',
        call: 'Appeler',
        sendMail: 'E-Mail',
    },
};
