import { inject, Injectable } from '@angular/core';
import { WNestApi } from 'libs/shared-lib/interfaces/';
import { WholesaleLanguage } from 'libs/shared-lib/language/language';
import { Observable } from 'rxjs';
import { WNestApiService } from './wholesale-nest-api';

@Injectable({
    providedIn: 'root',
})
export class NewsService {
    protected readonly wnestApiService: WNestApiService = inject(WNestApiService);

    loadAllNewsByDealerSlug(dealerId: string, locale: WholesaleLanguage): Observable<WNestApi.News[]> {
        return this.wnestApiService.getNewsForDealer(dealerId, locale);
    }

    loadNewsById(slug: string, dealerId: string, locale: WholesaleLanguage): Observable<WNestApi.NewsDetail> {
        return this.wnestApiService.getNewsById(slug, dealerId, locale);
    }
}
