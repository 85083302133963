import {
    M050C010MainnavigationStyleData,
    provideM050C010MainnavigationStyleData,
} from 'libs/ui/src/lib/modules/050-navigation-footer-overlays/m050-c010-mainnavigation/styles';

export const style: M050C010MainnavigationStyleData = {
    brandName: 'Opel',
    div: {
        open: '',
        mobileTransparentBackround: '',
    },
    navigation: {
        outerDiv: 'relative md:shadow-0-1-2-black-0.15',
        div: 'shadow-0-1-2-black-0.15 md:shadow-transparent px-16 -md:mx-16 h-65 lg:h-80 md:max-w-1280',
        logo: {
            container: '',
            icon: {
                path: 'assets/logos/opel.svg',
                size: 'h-32 lg:h-50 lg:w-57 w-36',
                default: 'fill-light-text',
                open: 'fill-light-text',
                bright: 'fill-light-text',
            },
        },
        link: {
            container:
                'hidden md:flex flex-nowrap whitespace-nowrap absolute top-50p left-50p transform -translate-y-50p -translate-x-50p',
            default: 'opel-link font-light leading-24 md:font-normal md:leading-22 md:px-8 md:py-4 md:text-16 text-20',
            active: '',
            notLast: 'mr-8',
        },
        icons: {
            container: '',
            languageSwitch: {
                div: '',
                icon: {
                    size: 'h-24 w-24',
                    default: 'fill-light-text',
                    open: '',
                    bright: 'fill-light-text',
                },
                span: {
                    span: 'hidden',
                    default: '',
                    open: '',
                    bright: '',
                },
            },
            burgerMenu: {
                condition: (isCheckout, isHome) => !isCheckout && !isHome,
                container: 'pr-2xl pl-xl py-lg -mr-2xl',
                closed: {
                    size: 'h-16 w-24',
                    color: 'fill-light-text',
                },
                open: {
                    size: 'h-24 w-24',
                    color: 'fill-light-text',
                },
            },
        },
        open: {
            div: 'flex flex-col gap-24 pt-24 px-32 flex-grow bg-grey-180',
            link: 'opel-link font-light leading-24 md:font-normal md:leading-22 md:px-8 md:py-4 md:text-16 text-20',
        },
    },
};

export const provideM050C010MainnavigationOpelStyleData = () => provideM050C010MainnavigationStyleData(style);
