import { BreakpointObserver } from '@angular/cdk/layout';
import { inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { getRouterSelectors, ROUTER_NAVIGATED, ROUTER_NAVIGATION } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { GenericLocalizationObject, WCar } from 'libs/shared-lib/interfaces';
import { WholesaleLanguage } from 'libs/shared-lib/language/language';
import { getNotFoundUrl } from 'libs/shared-lib/slugs/not-found.slug';
import { getVehicleNotAvailableUrl } from 'libs/shared-lib/slugs/vehicle-market.slugs';
import { distinctUntilChanged, exhaustMap, map, Observable, of, switchMap, tap } from 'rxjs';
import { WHOLESALE_LANGUAGES, WHOLESALE_LOCALES } from '../language/language.provider';
import { SeoService } from '../services/seo.service';
import { dealerFeature } from './dealer.reducer';
import { Labels, LanguageSwitchUrlFunc, Seo, wholesaleActions } from './wholesale.actions';
import { wholesaleFeature } from './wholesale.reducer';

@Injectable()
export class WholesaleEffects {
    protected readonly store = inject(Store);
    protected readonly actions$ = inject(Actions);
    protected readonly router = inject(Router);
    protected readonly route = inject(ActivatedRoute);
    protected readonly seoService = inject(SeoService);
    protected readonly breakpointObserver = inject(BreakpointObserver);
    // optional until the partnerweb has been migrated
    protected readonly languages = inject(WHOLESALE_LANGUAGES, { optional: true });
    protected readonly locales = inject(WHOLESALE_LOCALES, { optional: true });

    constructor() {
        if (this.locales) {
            this.store.dispatch(wholesaleActions.setAvailableLocales({ locales: this.locales }));
        }
    }

    switchLanguageOnRoute$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ROUTER_NAVIGATION),
            concatLatestFrom(() => [
                this.store.select(getRouterSelectors().selectRouteData),
                this.store.select(wholesaleFeature.selectLanguage),
                this.store.select(wholesaleFeature.selectLabels),
            ]),
            exhaustMap(([, routeData, currentLang, labels]) => {
                const lang = (routeData as { locale: WholesaleLanguage }).locale;
                const languageSwitchUrlGenerator = (routeData as { languageSwitchUrlGenerator: LanguageSwitchUrlFunc })
                    ?.languageSwitchUrlGenerator;

                if (this.languages && lang && (lang !== currentLang || Object.entries(labels).length === 0)) {
                    const labels = this.languages
                        .filter((l) => l.locale === lang)
                        .reduce((acc, l) => {
                            acc[l.identifier as 'online-sales'] = l.labels as Labels['online-sales'];
                            return acc;
                        }, {} as Labels);

                    const seo = this.languages
                        .filter((l) => l.locale === lang)
                        .reduce((acc, l) => {
                            acc[l.identifier as 'online-sales'] = l.seo as Seo['online-sales'];
                            return acc;
                        }, {} as Seo);
                    this.store.dispatch(wholesaleActions.setLabels({ labels, seo }));
                }

                return [
                    wholesaleActions.languageChanged({ lang, languageSwitchUrlGenerator }),
                    wholesaleActions.setLanguageNotDefaultLanguage({ lang }),
                ];
            }),
        );
    });

    // eslint-disable-next-line max-lines-per-function
    seoFunctionsOnRoute$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ROUTER_NAVIGATED),
            concatLatestFrom(() => [
                this.store.select(getRouterSelectors().selectRouteData),
                this.store.select(getRouterSelectors().selectRouteParams),
                this.store.select(dealerFeature.selectActiveDealer),
            ]),
            switchMap((data) => {
                if (!data[1]['languageSwitchSelector']) {
                    return of(data);
                }
                return this.store.select(data[1]['languageSwitchSelector']).pipe(
                    map((selectorData) => {
                        return [...data, selectorData];
                    }),
                );
            }),
            distinctUntilChanged(([, , routeParams], [, , routeParams2]) => routeParams === routeParams2),
            tap(([, routeData, routeParams, dealer, withLangPath, selectorData]) => {
                const languageSwitchUrlGenerator = (routeData as { languageSwitchUrlGenerator: LanguageSwitchUrlFunc })
                    .languageSwitchUrlGenerator;
                let genericLocalizationObject: GenericLocalizationObject = {};
                if (!this.locales) {
                    return;
                }
                for (const language of this.locales) {
                    const params = { ...routeParams };
                    if (selectorData && Object.prototype.hasOwnProperty.call(selectorData, 'slugLocalizations')) {
                        const vehicle = selectorData as WCar.Car;
                        params.slug = vehicle.slugLocalizations[language];
                    } else if (
                        withLangPath &&
                        withLangPath.slugLocalizations &&
                        withLangPath.slugLocalizations[language]
                    ) {
                        params.slug = withLangPath.slugLocalizations[language];
                    }

                    if (typeof languageSwitchUrlGenerator !== 'function') {
                        return;
                    }
                    const url = languageSwitchUrlGenerator(language, params, withLangPath);
                    genericLocalizationObject = {
                        ...genericLocalizationObject,
                        [language]: url,
                    };
                }
                this.seoService.setAlternateLinks(genericLocalizationObject, dealer);
            }),
            switchMap(() => {
                const url = this.seoService.setCanonicalURL();
                return [wholesaleActions.canonicalLinkSet({ canonical: url })];
            }),
        );
    });

    navigateOnLanguageChange$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(wholesaleActions.changeLanguage),
                concatLatestFrom(() => [
                    this.store.select(getRouterSelectors().selectRouteData),
                    this.store.select(getRouterSelectors().selectRouteParams),
                    this.store.select(wholesaleFeature.selectWithLanguagePath),
                    this.store.select(dealerFeature.selectActiveDealer),
                ]),
                switchMap((data) => {
                    const languageSwitchSelector = data[1]['languageSwitchSelector'] as () => unknown | undefined;
                    if (!languageSwitchSelector) {
                        return of(data);
                    }

                    return this.store.select(languageSwitchSelector).pipe(
                        map((selectorData) => {
                            return [...data, selectorData];
                        }),
                    ) as Observable<[...typeof data, WCar.Car]>;
                }),
                distinctUntilChanged(([action], [action2]) => action.lang === action2.lang),
                tap(([{ lang }, routeData, routeParams, withLangPath, dealer, selectorData]) => {
                    const languageSwitchUrlGenerator = (
                        routeData as { languageSwitchUrlGenerator: LanguageSwitchUrlFunc }
                    ).languageSwitchUrlGenerator;
                    const params = { ...routeParams };
                    if (selectorData && Object.prototype.hasOwnProperty.call(selectorData, 'slugLocalizations')) {
                        const vehicle = selectorData as WCar.Car;
                        params['slug'] = vehicle.slugLocalizations[lang];
                    }

                    const url = languageSwitchUrlGenerator(lang, params, withLangPath);
                    this.router.navigate([
                        dealer && !url.startsWith(`/${dealer.slug}`) ? `/${dealer.slug}${url}` : url,
                    ]);
                }),
            );
        },
        { dispatch: false },
    );

    redirectToNotFound$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(wholesaleActions.pageNotFound),
                concatLatestFrom(() => [
                    this.store.select(dealerFeature.selectActiveDealer),
                    this.store.select(wholesaleFeature.selectLanguage),
                ]),
                tap(([, dealer, language]) => {
                    const url = getNotFoundUrl(language, dealer?.slug);
                    this.router.navigate([url], { skipLocationChange: true });
                }),
            );
        },
        { dispatch: false },
    );

    redirectToVehilceNotAvailable$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(wholesaleActions.vehilceNotAvailable),
                concatLatestFrom(() => [
                    this.store.select(dealerFeature.selectActiveDealer),
                    this.store.select(wholesaleFeature.selectLanguage),
                ]),
                tap(([, dealer, language]) => {
                    const url = getVehicleNotAvailableUrl(language, dealer?.slug);
                    this.router.navigate([url], { skipLocationChange: true });
                }),
            );
        },
        { dispatch: false },
    );

    breakPointObserver$ = createEffect(() => {
        return this.breakpointObserver
            .observe(['(min-width: 1024px)'])
            .pipe(
                map((result) =>
                    result.matches
                        ? wholesaleActions.viewportChange({ isMobile: false })
                        : wholesaleActions.viewportChange({ isMobile: true }),
                ),
            );
    });

    showMainNavigationOnNavigate$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ROUTER_NAVIGATION),
            map(() => wholesaleActions.setMainNavigationVisibility({ visible: true })),
        );
    });
}
