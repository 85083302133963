import { SeoPage } from 'libs/shared-lib/interfaces';
export const SUB_DEALER_SEO_IT: SeoPage = {
    title: 'ToDo [DE] Sub Dealer Title',
    description: 'ToDo [DE] Sub Dealer Description',
    image: '',
    twitter: {
        card: 'summary',
        title: 'ToDo [DE] Sub Dealer Title',
        description: 'ToDo [DE] Sub Dealer Description',
    },
    og: {
        title: 'ToDo [DE] Sub Dealer Title',
        description: 'ToDo [DE] Sub Dealer Description',
    },
    robots: {
        noIndex: false,
        noFollow: false,
    },
};
