import { PlatformType } from 'libs/shared-lib/interfaces';
import { DELIVERY_TYPES, SUB_SYSTEM_TYPES } from 'libs/shared-lib/interfaces/global/tracking';

export function getDeliveryType(delivery: 'delivery' | 'pickup' | undefined): string {
    switch (delivery) {
        case 'pickup':
            return DELIVERY_TYPES.PICKUP;
        case 'delivery':
            return DELIVERY_TYPES.DELIVERY;
        default:
            return '';
    }
}

export function getSubSystem(platform: PlatformType): string {
    switch (platform) {
        case 'partner-web':
            return SUB_SYSTEM_TYPES.PARTNER_WEB;
        case 'corporate':
            return SUB_SYSTEM_TYPES.CORPORATE;
        case 'vehicle-market':
            return 'corporate';
        default:
            return '';
    }
}
