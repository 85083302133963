import { FooterLabels } from 'libs/shared-lib/interfaces/partner-web/footer';

export const FOOTER_LABELS_IT: FooterLabels = {
    quickLinks: {
        service: 'Fissare un appuntamento di servizio',
        testDrive: 'Fissare una prova su strada',
        call: 'Chiamare',
        mail: 'E-Mail',
    },
    locations: 'Le nostre sedi',
    copyrightText: `Il futuro appartiene a tutti © Opel ${new Date().getFullYear()}`,
    privacyLinks: [
        {
            name: 'Impressum',
            url: 'https://www.opel.ch/it/tools/copyright-opel.html',
        },
        {
            name: 'Condizioni della cookies policy',
            url: 'https://www.opel.ch/it/tools/cookie-policy.html',
        },
        {
            name: 'Condizioni della privacy policy',
            url: 'https://www.opel.ch/it/tools/politica-privacy.html',
        },
        {
            name: 'WLTP | nuovi valori sui consumi',
            url: 'https://www.opel.ch/it/tools/wltp-consumocarburante.html',
        },
        {
            name: 'Opel worldwide',
            url: 'http://www.opel.com/',
        },
    ],
    corporateLinks: [
        {
            name: 'myOpel',
            url: 'https://my.opel.ch/de/',
        },
        {
            name: 'Mobilità elettrica',
            url: 'https://www.opel.ch/it/simply-electric/overview.html',
        },
        {
            name: 'Opel FlexCare',
            url: 'https://www.opel.ch/it/service-zubehoer/garantie/flexcare.html',
        },
    ],
    legalTextHTML:
        'Le descrizioni e le immagini potrebbero riguardare o mostrare equipaggiamenti opzionali non inclusi nella dotazione di serie. Le informazioni contenute sono corrette alla data di pubblicazione. Salvo errori e omissioni. Ci riserviamo il diritto di apportare modifiche di design ed equipaggiamento. Tutti i prezzi non sono vincolanti e sono soggetti a modifiche in qualsiasi momento. I colori rappresentati possono discostarsi dai colori reali. L’equipaggiamento opzionale illustrato è disponibile con sovrapprezzo. La disponibilità, le caratteristiche tecniche e gli equipaggiamenti in dotazione sui nostri veicoli potrebbero variare o essere disponibili solo in alcuni paesi o con sovrapprezzo. Per informazioni più precise sugli equipaggiamenti disponibili sui nostri veicoli, contattare il proprio partner Opel locale.<br><br>Emissioni di CO2: 122 g/km, media di tutte le auto nuove vendute. Obiettivo CO2: 118 g/km (valore-obiettivo provvisorio in base al nuovo ciclo di prova WLTP).',
    wltpLink: {
        name: 'Clicca qui per maggiori informazioni sulle procedure di prova WLTP.',
        url: '',
    },
};
